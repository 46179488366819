import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Stepper from '@mui/material/Stepper';
import './index.scss';
import UserMoreMenu from '../CustomTableMenu/index';

type Anchor = 'right';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    children: any;
    drawerHeader?: any;
    subHeader?: string;
    isForm?: Boolean;
    formData?: any;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
    block?: boolean;
    groupFlag?: boolean;
    alertType?: any;
    trailAnalysis?: boolean;
    activeStep?: any;
    completed?: any;
    handleStep?: any;
    trailKey?: any;
    componentLSKey?: any;
};

export default function TemporaryDrawer(props: Props) {
    const {
        status,
        handlerOpen,
        handlerClose,
        drawerHeader,
        children,
        subHeader,
        isForm = false,
        editFn = () => {},
        formData = {},
        deleteFn = () => {},
        block = false,
        groupFlag = false,
        type,
        alertType,
        trailAnalysis = false,
        activeStep,
        completed,
        handleStep = () => {},
        trailKey
    } = props;
    const [state, setState] = React.useState({
        right: false
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClose = () => {
        handlerClose();
        toggleDrawer('right', false);
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            className={!type ? 'standardBox' : type == 'model' ? 'standardBox modelWidth' : 'standardBox fullWidth'}
  
        >

            <div
                style={
                    type === 'chart'
                        ? {
                              // padding: '30px',
                              // borderBottom: '1px solid grey',
                              // display: 'flex',
                              // justifyContent: 'flex-start',
                              // alignItems: 'baseline'
                              padding: '20px',
                              paddingBottom: '0px',
                              borderBottom: '1px solid grey',
                              display: 'flex',
                              //justifyContent: 'space-between',
                              //width: 'calc(100% + 50%)'
                              width: '100%',
                          }
                        : {
                              // padding: '30px',
                              // borderBottom: '1px solid grey',
                              // display: 'flex',
                              // justifyContent: 'flex-start',
                              // alignItems: 'baseline'
                              padding: '20px',
                              paddingBottom: '0px',
                              borderBottom: '1px solid grey',
                              display: 'flex',
                              justifyContent: 'space-between'
                          }
                }
            >
                {/* <div
                    style={{
                        border: '1px solid blue',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '0px',
                        marginTop: '-2px'
                    }}
                >
                    <span
                        onClick={handleClose}
                        style={{ position: 'relative', fontSize: '12px', color: 'white', left: '35%' }}
                    >
                        X
                    </span>
                </div> */}
                <div
                    onClick={handleClose}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '20px',
                        top: '17px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#DADCEE',
                        fontSize: '16px',
                        fontFamily: 'Montserrat',
                        marginBottom: '15px',
                        marginLeft: '45px'
                    }}
                >
                    <h6>{drawerHeader}</h6>
                    {subHeader && <div style={{ fontSize: '12px' }}>{subHeader}</div>}
                </div>
                {groupFlag ? (
                    <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                        <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} />
                    </div>
                ) : null}
            </div>
            {isForm && (
                <div
                    style={{
                        backgroundColor: '#2D2F44',
                        width: '90%',
                        margin: '0 auto',
                        height: '70px',
                        marginTop: '20px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px',
                        color: 'white'
                    }}
                >
                    <div style={{ width: '49%', textAlign: 'center', color: '#98BCF8', padding: '8px' }}>
                        <p style={{ fontSize: '12px', marginBottom: '0rem', lineHeight: '15px' }}>
                            {formData?.MachineName}
                        </p>
                        <h6 style={{ fontSize: '10px', marginTop: '7px' }}>{formData?.PlantName}</h6>
                    </div>
                </div>
            )}
                               {trailAnalysis ?
            <Stepper nonLinear activeStep={activeStep} sx={{margin: '12px 12px'}} className='fontTxt'>
            {steps.map((label, index) => (
              //disabled={trailKey === '' || activeStep > index ? true : false}
              /**/
              <Step key={label} completed={completed[index]}>
                <StepButton onClick={handleStep(index)} disabled={trailKey === "" ? true : false} >
                  <Typography sx={{ color: '#ffffff', fontSize: '14px' }}>{label}</Typography>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          : null}
            {children}
        </Box>
    );
    const steps = ['New/Edit Trail', 'Create Raw Materials', 'Add Raw Materials Values', 'Raw Materials Review', 'CQA Entry', 'BPR Limit Entry'];
    
    return (
        <div>
            {/* {(["right"] as const).map((anchor) => ( */}
            {/* <React.Fragment key={"right"}> */}
            {/* <Button onClick={handlerOpen}>{"right"}</Button> */}

            <Drawer
                anchor={'right'}
                open={status}
                // onClose={handlerClose}
                onClose={handleClose}
                style= {{zIndex: '9999'}}
                className={alertType === "Custom Alerts" || alertType === "View FMEA" || alertType === "Preventive Maintenance Schedule" || alertType === "Work Order" || alertType === "Limit Config" ? 'drawerDivAlert' : 'drawerDiv'}
            >
                {list()}
            </Drawer>
            {/* </React.Fragment> */}
            {/* ))} */}
        </div>
    );
}
