import axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';

class TrailAnalysisService {

    getTrailAnalysisProcess(req:any) {
        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_PROCESS, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisCustomers(req:any) {
        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_CUSTOMERS, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisCpp(req:any) {
        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_CPP, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisProducts(req:any) {

        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_PRODUCTS, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisRecipie(req:any) {
        var data = {
            "productKey": req.productKey,
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_RECIPIE, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    createTrailAnalysis(req:any) {
        var data = {
            "userId": req.userId,
            "lotNumber": req.lotNumber,
            "batchSize": req.batchSize,
            "processKey": req.processKey,
            "startDate": req.startDate,
            "cppKeys": req.cppKeys,
            "bprType": req.bprType,
            "customerKey": req.customerKey,
            "productKey": req.productKey,
            "recipieKey": req.recipieKey,
            "endDate": req.endDate,
            "type": req.type
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_CREATE, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysis(req:any) {
        var data = {
            "userId": req.userId,
            "startDate": req.startDate,
            "endDate": req.endDate,
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_LIST, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisDetails(req:any) {
        var data = {
            "trailId": req.trailKey,
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_DETAILS, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisRawMaterials(req:any) {
        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_RAW_MATERIALS, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    createRawMaterials(req:any) {
        var data = {
            "userId": req.userId,
            "trialKey": req.trialKey,
            "formData": req.formData,
            "type": req.type
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_CREATE_RAW_MATERIALS, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisRawMaterialsInfo(req:any) {
        var data = {
            "trialKey": req.trialKey,
            "type": req.type
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_GET_RAW_MATERIALS_INFO, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    createRawMaterialsValues(req:any) {
        var data = {
            "userId": req.userId,
            "formData": req.formData,
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_CREATE_RAW_MATERIALS_VALUES, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisPSDData(req:any) {
        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_PSDDATA, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {

                return {
                    status: response.status,
                    data: response.data.message
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    createTrailAnalysisCQA(req:any) {
        var data = {
            "userId": req.userId,
            "trialKey": req.trialKey,
            "milledKeys": req.milledKeys,
            "unMilledKeys": req.unMilledKeys,
            "lubricatedKeys": req.lubricatedKeys
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_CREATE_CQA, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisCQA(req:any) {
        var data = {
            "trialKey": req.trialKey
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_CQA, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisBPRLimitOperators(req:any) {
        return axios
            .get(ACTION_CODES.API_TRAIL_ANALYSIS_BPR_OPERATORS, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                const result = response.data.message[0];
                return {
                    status: response.status,
                    data: result[0]
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    getTrailAnalysisBPRLimitInfo(req:any) {
        var data = {
            "trialKey": req.trialKey
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_BPR_INFO, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    createBPRLimitValues(req:any) {
        var data = {
            "userId": req.userId,
            "formData": req.formData,
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_CREATE_BPR_LIMIT_VALUES, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    downloadTrailAnalysisTemplate(req:any) {
        return axios({
            url: ACTION_CODES.API_TRIAL_ANALYSIS_DOWNLOAD_TEMPLATE,
            method: 'post',
            responseType: 'blob',
            headers: {
                Authorization: 'Bearer ' + req?.token
            },
            data: req,
            timeout: 500000
        })
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                };
            });
    }

    validateTrailAnalysisTemplate(req:any) {
        return axios({
            url: ACTION_CODES.API_TRIAL_ANALYSIS_VALIDATE_TEMPLATE,
            method: 'post',
          
            headers: {
                Authorization: 'Bearer ' + req.get("token")
            },
            data: req,
        
        })
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                };
            });
    }

    uploadTrailAnalysisTemplate(req:any) {
        return axios({
            url: ACTION_CODES.API_TRIAL_ANALYSIS_UPLOAD_TEMPLATE,
            method: 'post',
            responseType: 'blob',
            headers: {
                Authorization: 'Bearer ' + req.get("token")
            },
            data: req,
            timeout: 500000
        })
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                };
            });
    }

    deleteTrailAnalysisTrial(req:any) {
        var data = {
            "userId": req.userId,
            "type": "Trial",
            "trialKey" : req.trialKey
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_DELETE_TRIAL, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }

    downloadTrailAnalysisReport(req:any) {
        return axios({
            url: ACTION_CODES.API_TRIAL_ANALYSIS_DOWNLOAD_REPORT,
            method: 'post',
            responseType: 'blob',
            headers: {
                Authorization: 'Bearer ' + req?.token
            },
            data: req,
            timeout: 500000
        })
            .then((response) => {
                return {
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    status: error.response.status,
                };
            });
    }

    getTrailGraphData(req:any) {
        var data = {
            "productKey": req.productKey,
        };
        return axios
            .post(ACTION_CODES.API_TRAIL_ANALYSIS_RECIPIE, data, {
                headers: {
                Authorization: 'Bearer ' + req?.token
            },
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            })
            .catch(error => {

                return {
                    status: error.response.status
                }
            })
    }
    
}
export default new TrailAnalysisService();