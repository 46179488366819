import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box,
    TextField
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Attachments from '../../../../atoms/Attachments';
import OutlinedInput from '@mui/material/OutlinedInput';
import DownArrow from '../../../../../assets/down-arrow.svg';
import Edit from '../../../../../assets/images/edit.svg';
import { Formik, Form, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { MD_YUPSCHEMA } from './schema';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import { fetchBlobImage } from '../../../../utils/helper';
import { convertBlobFileToBase64 } from '../../../../utils/helper';
import dateFormat from 'dateformat';
import { UTCTimeFormat } from '../../../../utils/formatTime';
import { SnackBarOptions, Dateformat } from '../../../UserReport/DownloadableReport/CommonUtil';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import AcgTimePicker from '../../../../atoms/TimePicker';
import Axios from 'axios';
// import '../CreateReport/index.scss';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { key } from 'localforage';
import { ContactsOutlined } from '@material-ui/icons';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    assetList: any;
    categoryList: any;
    actionTypeList: any;
    breakdownKey: any;
    getBreakdownList: Function;
    fetchBreakdownOrderDetails: Function
};
type ModuleType = {
    startTimeBR: any;
    startDateBR: any;
    startDateMS: any;
    startTimeMS: any;
    startDateMC: any;
    startTimeMC: any;
    startDateMO: any;
    startTimeMO: any;
    scName: string | '';
    scValue: string | '';
    scDesc: string | '';
};

const CreateModule = (props: ComponentProps) => {
    const { breakdownKey, drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, assetList, categoryList, actionTypeList, getBreakdownList = () => { }, fetchBreakdownOrderDetails = () => { } } = props;
    const formRef = useRef<any>(null);
    const [machineKey, setMachineKey] = useState('');
    const [categoryKey, setCategoryKey] = useState('');
    const [breakdownDescription, setBreakdownDescription] = useState('');
    const [machineDetails, setMachineDetails] = useState<any[]>([]);
    const [componentList, setComponentList] = useState<any[]>([]);
    const [subComponentList, setSubComponentList] = useState<any[]>([]);
    const [componentKey, setComponentKey] = useState('');
    const [subComponentKey, setSubComponentKey] = useState('');
    const [maintenanceAction, setMaintenanceAction] = useState('');
    const [actionTypeKey, setActionTypeKey] = useState<number | string>('');
    const [partsNames, setPartsNames] = useState<any[]>([]);
    const [sparePartsList, setSparepartsList] = useState<any[]>([]);
    const [rootCauseList, setRootCauseList] = useState<any[]>([]);
    const [rootCauseKey, setRootCauseKey] = useState('');
    const [rootCauseSubList, setRootCauseSubList] = useState<any[]>([]);
    const [rootCauseSubKey, setRootCauseSubKey] = useState('');
    const [rootCauseDescription, setRootCauseDescription] = useState('');
    const [selectedModelImage, setSelectedModelImage] = useState<any>([]);
    const [formError, setFormError] = useState({ modelCode: '', modelName: '', paramsUpload: '', modelImage: '', hasError: false });
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('1');
    const [validationSchema, setValidationSchema] = useState({});
    const [changedBD, setChangedBD] = useState(false);
    const [changeMSD, setChangeMSD] = useState(false);
    const [changeMCD, setChangeMCD] = useState(false);
    const [changeMOD, setChangeMOD] = useState(false);
    const [initialValues, setInitialValues] = useState<any>({
        scName: '',
        scValue: '',
        scDesc: '',
        startDateBR: null,
        startDateMS: null,
        startDateMC: null,
        startDateMO: null,
    });
    const [editImage, setEditImage] = useState(false);
    const [fName, setFname] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Breakdown maintenance ${breakdownKey === "" ? 'created' : 'edited'} successfully!`,
            open: true,
            type: 'success'
        },
    };

    useEffect(() => {
        if (breakdownKey !== '') {
            const tmpTableData = state[STORE_KEYS.GET_BREAKDOWN_MAINTENANCE_LIST_DETAILS]?.data;
            if (tmpTableData && tmpTableData.length) {
                setMachineKey(tmpTableData[0].Machinekey);
                getMachineDetails(state[STORE_KEYS.USER_DTL]?.LoggedUserKey, tmpTableData[0].Machinekey);
                setCategoryKey(tmpTableData[0].MaintBreakdownCategoryKey);
                setComponentKey(tmpTableData[0].MaintComponentKey);
                getSubComponents(tmpTableData[0].MaintComponentKey);
                setSubComponentKey(tmpTableData[0].MaintSubComponentKey);
                setBreakdownDescription(tmpTableData[0].BreakdownDesc);
                setMaintenanceAction(tmpTableData[0].MaintActionDesc);
                setActionTypeKey(tmpTableData[0].MaintActionTypeKey);
                setSelectedModelImage(tmpTableData[0].BreakdownImagePath);
                setFname(tmpTableData[0].BreakdownImagePath);
                setRootCauseDescription(tmpTableData[0].MaintRootCauseDesc);
                const s = getSpareParts(tmpTableData[0].MaintSubComponentKey);
                const r = getRootCauseList(tmpTableData[0].MaintSubComponentKey);
                const reverseSpareKeys: SetStateAction<any[]> = [];
                const reversRootCauseListKeys: SetStateAction<any[]> = [];
                const reversRootCauseSubListKeys = [];
                if (tmpTableData[0].MaintSparePartKeys) {
                    const spareKeys = tmpTableData[0].MaintSparePartKeys.split(',');
                    s.then((res: any) =>
                        res?.map((i: any) => {
                            if (spareKeys.includes(i.code.toString())) {
                                reverseSpareKeys.push(i);
                            }
                        })
                    );
                    setPartsNames(reverseSpareKeys);
                } else setPartsNames([]);
                setRootCauseKey(tmpTableData[0].MaintRootCauseCategoryKey);
                const rs = getRootCauseSubList(tmpTableData[0].MaintRootCauseCategoryKey);
                setRootCauseSubKey(tmpTableData[0].MaintRootCauseSubCategoryKey);
                setInitialValues({
                    ...initialValues,
                    startDateBR: new Date(UTCTimeFormat(tmpTableData[0].ReportedTime)),
                    startDateMS: new Date(UTCTimeFormat(tmpTableData[0].MaintStartTime)),
                    startDateMC: new Date(UTCTimeFormat(tmpTableData[0].MaintCompletedTime)),
                    startDateMO: new Date(UTCTimeFormat(tmpTableData[0].MachBackInOperationTime)),
                })

            }
        }
    }, [breakdownKey])

    const isDisabled = initialValues.startDateMO < initialValues.startDateMC || initialValues.startDateMC < initialValues.startDateMS || initialValues.startDateMS < initialValues.startDateBR || initialValues.startDateBR === null || initialValues.startDateMS === null || initialValues.startDateMC === null || initialValues.startDateMO === null || breakdownDescription === '' || !/\S/.test(breakdownDescription) || machineKey === '' || categoryKey === '' || componentKey === '' || subComponentKey === '' || maintenanceAction === '' || !/\S/.test(maintenanceAction) || actionTypeKey === '' || rootCauseKey === '' || rootCauseSubKey === '' || rootCauseDescription === '' || !/\S/.test(rootCauseDescription) ? true : false;
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                />
            </div>
        );
    };
    useEffect(() => {
        setValidationSchema(Yup.object().shape(MD_YUPSCHEMA));

    }, []);
    const handleSubmit = async () => {
        const sp: any[] = [];
        partsNames.map(i => { sp.push(i.code) });
        let strImage;
        if (selectedModelImage.length > 0 && typeof (selectedModelImage) !== "string") {
            const base64Image = await convertBlobFileToBase64(selectedModelImage[0]);
            strImage = base64Image?.replace(/^data:image\/[a-z]+;base64,/, "");
        }
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_BREAKDOWN_MAINTENANCE,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    machineKey: machineKey,
                    category: categoryKey,
                    breakdownDescription: breakdownDescription,
                    action: maintenanceAction,
                    actionKey: actionTypeKey,
                    spareparts: sp.toString(),
                    rootCauseSubKey: rootCauseSubKey,
                    rootCauseDescription: rootCauseDescription,
                    image: strImage ? strImage : null,
                    BrDate: dateFormat(initialValues.startDateBR, Dateformat.YYYY_MM_DD_HH_MM_SS),
                    MsDate: dateFormat(initialValues.startDateMS, Dateformat.YYYY_MM_DD_HH_MM_SS),
                    McDate: dateFormat(initialValues.startDateMC, Dateformat.YYYY_MM_DD_HH_MM_SS),
                    MoDate: dateFormat(initialValues.startDateMO, Dateformat.YYYY_MM_DD_HH_MM_SS),
                    key: breakdownKey !== '' ? breakdownKey : null,
                    type: breakdownKey !== '' ? 'Edit' : 'Add',
                    fName: breakdownKey ? fName.replace(/'/g, "''") : null,
                    updateRequire: fName === selectedModelImage ? false : true
                }
            },
            uniqueScreenIdentifier: { maintmoduleAdded: true },
            storeKey: STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE]?.addMachineModelLoaded) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        maintmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE]?.maintmoduleAdded) {
            if (state?.[STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE]?.status === "Success") {
                setReload(true);
                setCurrTab(0);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                breakdownKey !== '' ? fetchBreakdownOrderDetails(breakdownKey) : undefined;
                getBreakdownList();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        maintmoduleAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_BREAKDOWN_MAINTENANCE]?.maintmoduleAdded]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const getMachineDetails = (userId: any, mKey: any) => {

        const payload = {
            userId: loggedInUser,
            machineKey: mKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_MACHINE_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    setMachineDetails(response.data.data);
                    getComponent(response.data.data[0].MachineModelKey);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });

    }

    const handleChangeAsset = (event: { target: { value: any; }; }) => {
        setMachineKey(event.target.value);
        getMachineDetails(state[STORE_KEYS.USER_DTL]?.LoggedUserKey, event.target.value);
    }

    const getComponent = (mmKey: Number) => {
        const payload = {
            userId: loggedInUser,
            machineModelKey: mmKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_COMPONENTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setComponentList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintComponentKey, name: item.MaintComponentName }))
                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }

    const getSubComponents = (cKey: number) => {
        const payload = {
            userId: loggedInUser,
            componentKey: cKey
        };
        Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_SUB_COMPONENTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setSubComponentList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintSubComponentKey, name: item.MaintSubComponentName }))
                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }

    const getSpareParts = async (sKey: number) => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
            subComponentKey: sKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_SPARE_PARTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setSparepartsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintSparePartKey, name: item.MaintSparePartName }))

                    ]);
                    finalResult = [...tmpTableData?.map((item: any) => ({ code: item.MaintSparePartKey, name: item.MaintSparePartName }))]
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }

    const getRootCauseList = async (sKey: number) => {
        let finalResult;
        const payload = {
            userId: loggedInUser,
            subComponentKey: sKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_ROOT_CAUSE,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setRootCauseList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintRootCauseCategoryKey, name: item.MaintRootCauseCategoryName }))

                    ]);
                    finalResult = [
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintRootCauseCategoryKey, name: item.MaintRootCauseCategoryName }))

                    ];
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
        return finalResult;
    }

    const getRootCauseSubList = async (rKey: number) => {
        const payload = {
            userId: loggedInUser,
            rootCauseKey: rKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.GET_BREAKDOWN_ROOT_CAUSE_SUB,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setRootCauseSubList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MaintRootCauseSubCategoryKey, name: item.MaintRootCauseSubCategoryName }))

                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }

    const handleChangeCategory = (event: { target: { value: any; }; }) => {
        setCategoryKey(event.target.value);
    }

    const handleChangeComponent = (event: { target: { value: any; }; }) => {
        setSubComponentKey('');
        setRootCauseKey('');
        setRootCauseSubKey('');
        setPartsNames([]);
        setSparepartsList([]);
        setComponentKey(event.target.value);
        getSubComponents(event.target.value);
    }

    const handleChangeSubComponent = (event: { target: { value: any; }; }) => {
        setRootCauseKey('');
        setRootCauseSubKey('');
        setSparepartsList([]);
        setPartsNames([]);
        setSubComponentKey(event.target.value);
        getSpareParts(event.target.value);
        getRootCauseList(event.target.value);
    }

    const handleChangeActionType = (event: { target: { value: any; }; }) => {
        setActionTypeKey(event.target.value);
    }

    const handleChangeSpareparts = (id: any) => {
        let newSelected: SetStateAction<any[]> = [];
        const selectedIndex = partsNames.findIndex((t) => t.code === id.code);
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(partsNames, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(partsNames.slice(1));
        } else if (selectedIndex === partsNames.length - 1) {
            newSelected = newSelected.concat(partsNames.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                partsNames.slice(0, selectedIndex),
                partsNames.slice(selectedIndex + 1)
            );
        }
        setPartsNames(newSelected);
    }

    const handleChangeRootCauseType = (event: { target: { value: any; }; }) => {
        setRootCauseSubKey('');
        setRootCauseKey(event.target.value);
        getRootCauseSubList(event.target.value);
    }
    const handleChangeRootCauseSubType = (event: { target: { value: any; }; }) => {
        setRootCauseSubKey(event.target.value);
    }

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    // handleSubmit(values);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (
                        <Form>
                            <Label label="Asset ID &nbsp;*" className={'labelDiv'} />
                            <DropDown
                                onChange={handleChangeAsset}
                                className="inputDiv"
                                name="dropdownModel"
                                items={assetList}
                                placeHolder="Select Asset ID"
                                value={machineKey}
                            // helperText={
                            //     errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                            // }
                            />
                            {machineKey !== '' ?
                                <>
                                    <Label label="Asset Details" className={'labelDiv'} />
                                    <img
                                        style={{
                                            padding: 10, maxHeight: "200px",
                                            objectFit: "contain",
                                            width: "100%"
                                        }}
                                        src={
                                            machineDetails[0]?.MachineModelName ? fetchBlobImage(machineDetails[0]?.MachineModelName + ".png").url : ""
                                        } alt="No image" />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Model: " + machineDetails[0]?.MachineModelName}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        maxLength={300}
                                    />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Year of purchase: " + machineDetails[0]?.YearOfPurchase}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                    />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Warranty info: " + machineDetails[0]?.WarrantyInfo}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                    />
                                    <TextInput
                                        variant="outlined"
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        name="scName"
                                        disabled={true}
                                        value={"Current AMC status: " + machineDetails[0]?.CurrentAMCStatus}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                    />
                                    <Label label="Breakdown Category &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeCategory}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={categoryList}
                                        placeHolder="Select Breakdown Category"
                                        value={categoryKey}
                                    // helperText={
                                    //     errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                                    // }
                                    />

                                    <Label label="Breakdown Description &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={breakdownDescription}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Breakdown Description'
                                        onChange={(e: any) => {
                                            setBreakdownDescription(e.target.value)
                                        }}
                                        maxLength={300}
                                    />

                                    <Label label="Select Component &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeComponent}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={componentList}
                                        placeHolder="Select Component"
                                        value={componentKey}
                                    />
                                    <Label label="Select Sub Component &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeSubComponent}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={subComponentList}
                                        placeHolder="Select Sub Component"
                                        value={subComponentKey}
                                    />
                                    <Label label="Maintenance Action &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={maintenanceAction}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Maintenance Action'
                                        onChange={(e: any) => {
                                            setMaintenanceAction(e.target.value)
                                        }}
                                        maxLength={300}
                                    />
                                    <Label label="Select Action Type &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeActionType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={actionTypeList}
                                        placeHolder="Select Action Type"
                                        value={actionTypeKey}
                                    />
                                    {actionTypeKey === 2 ?
                                        <>
                                            <Label label="Select Parts Used" className={'labelDiv'} />
                                            <FormControl sx={{ width: '100%' }}>
                                                {partsNames?.length > 0 ? null : (
                                                    <InputLabel
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '13px',
                                                            paddingTop: '5px',
                                                            paddingLeft: '34px',
                                                            color: '#b1c2df',
                                                            opacity: '0.7'
                                                        }}
                                                        id="demo-multiple-checkbox-label"
                                                    >
                                                        Select Parts Used
                                                    </InputLabel>
                                                )}
                                                <Select
                                                    IconComponent={DropDownIcon}
                                                    labelId="multiple-checkbox-label"
                                                    placeholder="Select Parameters"
                                                    id="multiple-checkbox"
                                                    multiple
                                                    displayEmpty
                                                    label=""
                                                    // style={{ backgroundColor: '#1d1e2c', color: '#b1c2df' }}
                                                    style={{
                                                        backgroundColor: '#1d1e2c',
                                                        color: '#b1c2df',
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '13px',
                                                        borderRadius: '10px',
                                                    }}
                                                    className="inputDiv"
                                                    name="dropdownMachineParams"
                                                    value={partsNames}
                                                    // input={<OutlinedInput />}
                                                    MenuProps={MenuProps}
                                                    renderValue={(selected) => selected.length > 0 && selected.map((x) => x.name).join(', ')}
                                                >
                                                    {sparePartsList?.map((machine: any) =>
                                                    (
                                                        <MenuItem key={machine.code} value={machine} onClick={() => handleChangeSpareparts(machine)}>
                                                            <Checkbox
                                                                checked={partsNames.findIndex(item => item.code === machine.code) >= 0}
                                                            />
                                                            <ListItemText primary={machine.name} />
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </>
                                        : null}
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="Breakdown Reported Date &nbsp; *" className={'labelDiv'} />
                                        <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={breakdownKey !== '' && !changedBD ? initialValues.startDateBR : values.startDateBR}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateBR: val })
                                                setFieldValue('startDateBR', val);
                                                setChangedBD(true);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="Maintenance Started Date &nbsp; *" className={'labelDiv'} />
                                        <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={breakdownKey !== '' && !changeMSD ? initialValues.startDateMS : values.startDateMS}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateMS: val })
                                                setFieldValue('startDateMS', val);
                                                setChangeMSD(true);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                            minDate={initialValues.startDateBR}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="Maintenance Completed Date &nbsp; *" className={'labelDiv'} />
                                        <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={breakdownKey !== '' && !changeMCD ? initialValues.startDateMC : values.startDateMC}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateMC: val })
                                                setFieldValue('startDateMC', val);
                                                setChangeMCD(true);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                            minDate={initialValues.startDateMS}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                        <Label label="Machine Back In Operation Date &nbsp; *" className={'labelDiv'} />
                                        <CustomDateTimePicker
                                            name="dateTimeFromSelect"
                                            value={breakdownKey !== '' && !changeMOD ? initialValues.startDateMO : values.startDateMO}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setInitialValues({ ...initialValues, startDateMO: val })
                                                setFieldValue('startDateMO', val);
                                                setChangeMOD(true);
                                            }}
                                            inputFormat="dd/MM/yyyy hh:mm aa"
                                            placeholder="Select From Date"
                                            minDate={initialValues.startDateMC}
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Label label="Root Cause Category &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeRootCauseType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={rootCauseList}
                                        placeHolder="Root Cause Category"
                                        value={rootCauseKey}
                                    />
                                    <Label label="Root Cause Sub Category &nbsp; *" className={'labelDiv'} />
                                    <DropDown
                                        onChange={handleChangeRootCauseSubType}
                                        className="inputDiv"
                                        name="dropdownModel"
                                        items={rootCauseSubList}
                                        placeHolder="Root Cause Sub Category"
                                        value={rootCauseSubKey}
                                    />

                                    <Label label="Root Cause Description &nbsp; *" className={'labelDiv'} />
                                    <TextInput
                                        // multiline
                                        // maxRows={4}
                                        // sx={{ margin: '0px', padding: '2px', height: "6rem" }}
                                        variant="outlined"
                                        fullWidth
                                        type="text"
                                        name="gtId"
                                        value={rootCauseDescription}
                                        autoComplete="off"
                                        formInput="inputDiv"
                                        placeholder='Root Cause Description'
                                        onChange={(e: any) => {
                                            setRootCauseDescription(e.target.value)
                                        }}
                                        inputProps={{ maxLength: 50, shrink: true }}
                                    />
                                    <Grid item xs={3} lg={1}>
                                        {breakdownKey === '' ?
                                            <>
                                                <Label label="Upload Image" className={'labelDiv'} />
                                                <Attachments
                                                    viewOnly={false}
                                                    setError={selectedModelImage}
                                                    isSetFile={setSelectedModelImage}
                                                />
                                                {selectedModelImage?.length === 0 ? <div>
                                                    {formError.modelImage &&
                                                        <Typography sx={{ mt: '2%', color: '#F06868', fontSize: '12px' }}> {formError.modelImage}</Typography>
                                                    }
                                                </div> : null}
                                            </>
                                            :
                                            editImage
                                                ?
                                                <Grid item xs={3} lg={1}>
                                                    <Label label="Upload Image" className={'labelDiv'} />
                                                    <Attachments
                                                        viewOnly={false}
                                                        setError={selectedModelImage}
                                                        isSetFile={setSelectedModelImage}
                                                    />
                                                </Grid>
                                                :
                                                <Box className="attachment_wrapper" style={{ marginTop: 30 }}>
                                                    {<img onClick={() => setEditImage(true)} src={Edit} alt="X" className="closeBtn" />}
                                                    <>
                                                        <img
                                                            src={selectedModelImage ? fetchBlobImage(selectedModelImage).url : undefined}
                                                            alt={'avtar'}
                                                        />

                                                    </>
                                                </Box>

                                        }
                                    </Grid>
                                    <Typography variant="body1" sx={{color:"#ffff", marginTop: 2}}>Note: * fields are mandatory! </Typography>
                                </>
                                : null}

                            {renderButtons()}
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;
