import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Axios from 'axios';
import { Typography } from '@mui/material';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import DownArrow from '../../../../../assets/down-arrow.svg';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import HelpIcon from '@mui/icons-material/Help';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import ACGButton from '../../../../atoms/Button';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import FMEAService from '../../fmeaServices';
import "../../cm.scss";
import "./style.css";
import QueryBuilder, { defaultOperators, RuleValidator, ValidationResult, formatQuery } from 'react-querybuilder';
import { Grid } from '@mui/material';
import { AnyARecord } from 'dns';
import { fontWeight } from '@mui/system';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const muiTheme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const initialQuery = { combinator: 'and', rules: [] };
export default function CreateForm(props: any) {

  const [editing, setEditing] = useState(false); 
  const [modelsData, setModelsData] = useState<any[]>([]);
  const [machineNameData, setMachineNameData] = useState([]);
  const [machineNames, setMachineNames] = useState<any[]>([]);
  const [machineModelKey, setMachineModelKey] = useState('');
  const [severityData, setSeverityData] = useState<any[]>([]);
  const [severityKey, setSeverityKey] = useState('');
  const [recommendedNotes, setRecommendedNotes] = useState('');
  const [potentialFailure, setPotentialFailure] = useState('');
  const [componentList, setComponentList] = useState<any[]>([]);
  const [subComponentList, setSubComponentList] = useState<any[]>([]);
  const [componentKey, setComponentKey] = useState('');
  const [subComponentKey, setSubComponentKey] = useState('');
  const [FMEAPresetKey, setFMEAPresetKey] =  useState('');
  const ref = useRef(null);
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  useEffect(() => {
    setEditing(false);
    getModels();
    FMEAService.getFMEASeverityList({ token: token })
      .then((response: any) => {
        const result = response?.data?.message[0];
        setSeverityData([
          ...result[0].map((item: any) => ({ code: item.SeverityKey, name: item.SeverityCriteria }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, []);

  useEffect(() => {
    if (props.editableCustomData[0] && props.customKey !== '') {
      (async function () {
        setEditing(true);
        setMachineNames([]);
        const reverseMKeys: any = [];
        const n = getModels();
        setMachineModelKey(props.editableCustomData[0]?.MachineModelKey);
        getComponent(props.editableCustomData[0]?.MachineModelKey);
        getSubComponents(props.editableCustomData[0]?.MaintComponentKey);
        setFMEAPresetKey(props.editableCustomData[0]?.FailureModeKey);
        const m = getMachineModelNameList(props.editableCustomData[0]?.MachineModelKey);
        if (props.editableCustomData[0]?.MachineKeys !== null) {
          const mKeys = (props.editableCustomData[0]?.MachineKeys).split(',');
          m.then((res: any) => res?.map(async(i: any, index: number) => {
            if (mKeys.includes(i.MachineKey.toString())) {
              await reverseMKeys.push(i);
            }
          }
          ));
          setMachineNames(reverseMKeys);
        }
        setPotentialFailure(props.editableCustomData[0]?.PotentialEffectsOfFailure);
        setRecommendedNotes(props.editableCustomData[0]?.FailureMode);
        setSeverityKey(props.editableCustomData[0]?.SeverityKey);
        setComponentKey(props.editableCustomData[0]?.MaintComponentKey);
        setSubComponentKey(props.editableCustomData[0]?.MaintSubComponentKey)
      })()
    }
  }, [props.customKey])

  const getModels = async () => {
    const body = { userId: loggedInUser,  token: token }
    FMEAService.getFMEACustomerModels(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setModelsData([
          ...result[0].map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    
  }

  const handleChangeSeverity = (event: any) => {
    setSeverityKey(event.target.value);
  };

  const handleChangeModel = (id: any) => {
    setFMEAPresetKey('');
    setMachineNameData([]);
    setMachineNames([]);
    setMachineModelKey(id.target.value);
    setComponentKey('');
    setSubComponentKey('');
    getMachineModelNameList(id.target.value);
    getComponent(id.target.value);
  };

  const getMachineModelNameList = async (args1: any) => {
    let finalResult;
    const body = { userId: loggedInUser, machineModelKey: args1, token: token }
    await FMEAService.getMachineList(body)
      .then(async (response: any) => {
        const result = await response?.data?.message[0];
        setMachineNameData(result[0]);
        finalResult = result[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return finalResult;
  }

  const handleChangeMachineNames = (id: any) => {
    let newSelected: any = [];
    if (props.customKey === '') {
      const selectedIndex = machineNames.indexOf(id);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(machineNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(machineNames.slice(1));
      } else if (selectedIndex === machineNames.length - 1) {
        newSelected = newSelected.concat(machineNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          machineNames.slice(0, selectedIndex),
          machineNames.slice(selectedIndex + 1)
        );
      }
    }
    else {
      const selectedIndex = machineNames.findIndex((t: any) => t.MachineKey === id.MachineKey);
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(machineNames, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(machineNames.slice(1));
      } else if (selectedIndex === machineNames.length - 1) {
        newSelected = newSelected.concat(machineNames.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          machineNames.slice(0, selectedIndex),
          machineNames.slice(selectedIndex + 1)
        );
      }
    }
    setMachineNames(newSelected)

  }

  const reset = async () => {
    props.setCustomKey('');
    setSeverityKey('');
    setModelsData([]);
    setMachineModelKey('');
    setMachineNameData([]);
    setMachineNames([]);
    setRecommendedNotes('');
    setFMEAPresetKey('');
  }

  const options = {
    AD_SUCCESS: {
      message: `Failure mode ${props.customKey === "" ? 'created' : 'edited'} successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Failure mode with the same name alredy exsist !`,
      open: true,
      type: 'remark'
    }
  };

  const createCustomAlert = () => {
    const mKeys: any = [];
    machineNames?.map(i => mKeys.push(i.MachineKey));
    const body = {
      userId: loggedInUser,
      subComponent: subComponentKey,
      presetRuleKey: props.customKey ? props.customKey : null,
      machineModelKey: machineModelKey,
      machineKeys: mKeys ? mKeys.toString() : null,
      severityKey: severityKey,
      recommendedNotes: recommendedNotes,
      potentialFailure: potentialFailure,
      type: props.customKey !== '' ? 'Edit' : "Add",
      token: token
    }
    FMEAService.createFMEACustomRule(body)
      .then((response: any) => {
        if (response.status === 200) {
          reset();
          props.setCurrTab(1);
          const result = response?.data?.message[0];
          const checkIfExsist = result[0];
          const isError = checkIfExsist[0];
          if (isError.Result === 'Error') {
            props.setSnackbarOpts(options.AD_FAIL);
          }
          else {
            props.setSnackbarOpts(options.AD_SUCCESS);
          }
          props.drawerClose();
          props.fetchCustomAlerts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const check = (object: any) => {
    for (const value of Object.values(object)) {
      if (value === "" || (typeof value === "object" && check(value))) {
        return true;
      }
    }

    return false;
  }

  const yesDisabled = (
    componentList.length === 0 ||
    subComponentList.length === 0 ||
    recommendedNotes === '' ||
    potentialFailure === '' ||
    machineModelKey === '' ||
    severityKey === '' 
 ) ? true : false;
  let optionalDisabled: boolean;

  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );

  const getComponent = (mmKey: Number) => {
    const payload = {
      userId: loggedInUser,
      machineModelKey: mmKey
    };
    Axios({
      url: BASEURL + '/' + ACTION_CODES.GET_FMEA_COMPONENTS,
      method: 'post',
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(async (response) => {
        if (response.status == 200 && response?.data) {
          const tmpTableData = response.data.data;
          setComponentList([
            ...tmpTableData?.map((item: any) => ({ code: item.MaintComponentKey, name: item.MaintComponentName }))
          ]);
        }
      })
      .catch((error) => {
        return {
          status: error.response.status
        };
      });
  }

  const getSubComponents = (cKey: number) => {
    const payload = {
      userId: loggedInUser,
      componentKey: cKey
    };
    Axios({
      url: BASEURL + '/' + ACTION_CODES.GET_FMEA_SUB_COMPONENTS,
      method: 'post',
      data: payload,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(async (response) => {
        if (response.status == 200 && response?.data) {
          const tmpTableData = response.data.data;
          setSubComponentList([
            ...tmpTableData?.map((item: any) => ({ code: item.MaintSubComponentKey, name: item.MaintSubComponentName }))
          ]);
        }
      })
      .catch((error) => {
        return {
          status: error.response.status
        };
      });
  }
  const handleChangeComponent = (event: { target: { value: any; }; }) => {
    setSubComponentKey('');
    setComponentKey(event.target.value);
    getSubComponents(event.target.value);
  }

  const handleChangeSubComponent = (event: { target: { value: any; }; }) => {
    setSubComponentKey(event.target.value);
  }

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", margin: "10px 0", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          style={{ height: '40px', marginRight: '10px' }}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { createCustomAlert() }}
          type="submit"
          className="second-btn"
          style={{ height: '40px', fontWeight: '500' }}
          variant="contained"
          disabled={yesDisabled || optionalDisabled ? true : false}
        />
      </div>
    );
  };

  return (
    <Container className="createReportt">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
              <Grid>
                <FormControl fullWidth>
                  <Label label="Model &nbsp; *" className={'CMlabelDiv'} />
                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={modelsData}
                    placeHolder="Select model"
                    value={machineModelKey}
                    onChange={handleChangeModel}
                    disabled={!editing ? false : true}
                  />
                </FormControl>
              </Grid>
              <Grid>
                <FormControl fullWidth>
                  <Label label="Failure Mode &nbsp; *" className={'CMlabelDiv multilineheight'} />
                  <Box
                    className='multilineheight'
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextInput
                      fullWidth
                      placeholder='Failure Mode'
                      className="multilinetxt"
                      variant="outlined"
                      maxRows={4}
                      sx={{ margin: '0px', padding: '2px' }}
                      multiline
                      value={recommendedNotes}
                      onChange={(e) => setRecommendedNotes(e.target.value)}
                      disabled={!editing ? false : true}
                    // size='500'

                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Potential Effects of Failure &nbsp; *" className={'CMlabelDiv multilineheight'} />
                  <Box
                    className='multilineheight'
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 0, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextInput
                      fullWidth
                      placeholder='Potential Effects of Failure'
                      className="multilinetxt"
                      variant="outlined"
                      maxRows={4}
                      sx={{ margin: '0px', padding: '2px' }}
                      multiline
                      value={potentialFailure}
                      onChange={(e) => setPotentialFailure(e.target.value)}
                      disabled={!editing ? false : true}
                    // size='500'

                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
              <div className='labelfixed marginspace'>
                <FormControl fullWidth>
                  <Label label="Machine Name (optional)" className={'CMlabelDiv'} />
                  <FormControl sx={{ width: '100%' }}>
                    {machineNames?.length > 0 ? null : (
                      <InputLabel
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          paddingTop: '0px',
                          marginBottom: '0px',
                          paddingLeft: '12px',
                          lineHeight: '1',
                          minHeight: '20px',
                          color: '#b1c2df',
                          opacity: '0.7'
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Machines
                      </InputLabel>
                    )}
                    <Select
                      IconComponent={DropDownIcon}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      className="multibox2"
                      label=""
                      multiple
                      value={machineNames}
                      sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                      placeholder='Please select machines'
                      // input={<OutlinedInput />}
                      renderValue={(selected) => selected.map((x: any) => x.LineMachineName).join(', ')}
                      MenuProps={MenuProps}
                    // onBlur={() => getReciepeList(customerKey, machineModelKey, machineNames)}

                    >
                      {machineNameData?.map((i: any) => (
                        <MenuItem value={i} onClick={() => handleChangeMachineNames(i)}>
                          <Checkbox
                            checked={
                              machineNames.findIndex((item: any) => item.MachineKey === i.MachineKey) >= 0
                            }

                          />
                          <ListItemText primary={i.LineMachineName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormControl>
                </div>
              </Grid>
             
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Select Component &nbsp; *" className={'CMlabelDiv'}  />
                  <DropDown
                    onChange={handleChangeComponent}
                    className="inputDiv"
                    name="dropdownModel"
                    items={componentList}
                    placeHolder="Select Component"
                    value={componentKey}
                    disabled={!editing ? false : true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Label label="Select Sub Component &nbsp; *" className={'CMlabelDiv'}  />
                  <DropDown
                    onChange={handleChangeSubComponent}
                    className="inputDiv"
                    name="dropdownModel"
                    items={subComponentList}
                    placeHolder="Select Sub Component"
                    value={subComponentKey}
                    disabled={!editing ? false : true}
                  />
                </  FormControl>
              </Grid>
             
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Label label="Severity &nbsp; *" className={'CMlabelDiv'} />
                    <Tooltip title={<Typography fontSize={12} className='tooltiptxt'>If condition is continuously true for a period of time -
                      Critical Alerts will be triggered once in every 5 minutes.
                      Major Alerts will be triggered once in every 10 minutes.
                      Minor Alerts will be triggered once in every 15 minutes.
                    </Typography>} arrow>
                      <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" }} />
                    </Tooltip>
                  </div>

                  <DropDown
                    className="inputDiv"
                    name="dropdownModel"
                    items={severityData}
                    placeHolder="Select severity"
                    value={severityKey}
                    style={{ marginBottom: "10px" }}
                    onChange={handleChangeSeverity}
                  />
                </FormControl>
            </Grid>
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
}