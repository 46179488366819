import { SetStateAction, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    Box
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DownArrow from '../../../../../assets/down-arrow.svg';
import useGetState from '../../../../utils/hooks/useGetState';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../../../store/slice';
import ACGButton from '../../../../atoms/Button';
import Label from '../../../../atoms/Label/index';
import TextInput from '../../../../atoms/TextInput';
import Axios from 'axios';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import { Draggable } from "react-drag-reorder";
import PlusIcon from '../../../../../assets/images/PlusIcon.svg';
import BinIcon from '../../../../../assets/images/bin.svg';
import { object } from 'yup';

type ComponentProps = {
    drawerClose: Function;
    setCurrTab: Function;
    setReload: Function;
    setSnackbarOpts: Function;
    dtlData: any;
    selectedRow: any;
    isEdit: boolean;
    setDtlData: Function;
    setSelectedRow: Function;
    preventiveMaintenanceKey: any;
    setPreventiveMaintenanceKey: Function;
};

const CreateModule = (props: ComponentProps) => {
    const { drawerClose, setCurrTab, setReload = () => { }, setSnackbarOpts = () => { }, dtlData, isEdit, setDtlData = () => { }, selectedRow, setSelectedRow = () => { }, preventiveMaintenanceKey, setPreventiveMaintenanceKey = () => {} } = props;
    const [checklistFields, setChecklistFields] = useState<any[]>([])
    const [rearrange, setRearrange] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const dispatch = useDispatch();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const options = {
        AD_SUCCESS: {
            message: `Inspection action checklist ${isEditing === true ? 'created' : 'edited'} successfully!`,
            open: true,
            type: 'success'
        },
    };

    const handleFormChange = (event: any, index: any) => {
        let data: any = [...checklistFields];
        const key = data[index]?.MaintPREVReqInspChkLstKey;
        if(data[index]?.MaintPREVReqInspChkLstKey === null || data[index]?.MaintPREVReqInspChkLstKey === undefined ) {
            data[index]["MaintPREVReqInspChkLstKey"]=null
            data[index]["TaskDesc"] = event.target.value
        } else {
            data.splice(index,1,{"MaintPREVReqInspChkLstKey":key, "TaskDesc": event.target.value})
            data.push()
        }
        setChecklistFields(data);
    }

    const removeFields = (index: any) => {
        if(index !== 0 ) {
        let data = [...checklistFields];
        data.splice(index, 1)
        setChecklistFields(data);
        }
    }
    const addFields = (index: any) => {
        if (checklistFields.length < 20) {
            let object = {
                TaskDesc: ''
            }
            setChecklistFields([...checklistFields, object]);
        }
    }

    const dataValidation = (data:any) => {
        let result;
        data.forEach(function (v:any, i:any) {

            if (
                Object.keys(v).some(function (k) {
                    return v[k] == "";
                })
            ) {
                result = true;
            }
            else {
                result = false
            }

        });
        return result;
    }
    const isDisabled = dataValidation(checklistFields) || !checklistFields.length  ? true : false;

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { handleSubmit() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={isDisabled ? true : false}
                />
            </div>
        );
    };

    const handleSubmit = async () => {
        checklistFields.forEach(function (element:any, index: number) {
            element.TaskId = index + 1;
          });
        const actionList = {
            "MaintPREVRequestKey": preventiveMaintenanceKey,
            "InspChecklist": checklistFields
        }
        const bundle = {
            payload: {
                urlPath: ACTION_CODES.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                requestType: 'POST',
                reqObj: {
                    userId: loggedInUser,
                    actionList: JSON.stringify(actionList)
                }
            },
            uniqueScreenIdentifier: { preventiveMaintmoduleInspectionListAdded: true },
            storeKey: STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE
        };
        dispatch(executeACGAction(bundle));
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.preventiveMaintmoduleInspectionListAdded) {
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleInspectionListAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.preventiveMaintmoduleInspectionListAdded) {
            if (state?.[STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.status === "Success") {
                setReload(true);
                setCurrTab(0);
                setSnackbarOpts(options.AD_SUCCESS);
                drawerClose();
                setPreventiveMaintenanceKey('');
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                    uniqueScreenIdentifier: {
                        preventiveMaintmoduleInspectionListAdded: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE]?.preventiveMaintmoduleInspectionListAdded]);

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    useEffect(()=>{
        if(preventiveMaintenanceKey){
        (async function() {
            const payload = {
                userId: loggedInUser,
                orderKey: preventiveMaintenanceKey
            }
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.GET_PREVENTIVE_INSPECTION_LIST_MAINTENANCE,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(async (response) => {
                    if (response.status == 200 && response?.data) {
                        const tmpTableData = response.data.data;
                        !tmpTableData?.length ? setChecklistFields([{"MaintPREVReqInspChkLstKey": null, "TaskDesc": '' }]) :setChecklistFields(tmpTableData)
                        !tmpTableData?.length ? setIsEditing(true) : setIsEditing(false)     
                    }
                })
                .catch((error) => {
                    return {
                        status: error.response.status
                    };
                });
        })();
    }
    },[preventiveMaintenanceKey])

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );
    const swapArrayLocs = (index1: number, index2: number) => {
        var temp = checklistFields[index1];
        checklistFields[index1] = checklistFields[index2];
        checklistFields[index2] = temp;
      }
    const getChangedPos = (currentPos:any, newPos:any) => {
        swapArrayLocs(currentPos, newPos);
      };
    
    return (
        <Container className="createReport">
            <Box>
                <div className='formwrap all-labelcls'>
                    <FormControl fullWidth>
                        <Label label="Create Inspection Checklist (Max-20 Tasks can be added for now)" className={'CMlabelDiv'} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                value={rearrange}
                                onChange={() => { setRearrange(!rearrange) }}
                                sx={{ color: "#ffff" }}
                            />
                            <Label label="Re-order inspection action checklist" className={'CMlabelDiv'} />
                        </div>
                        {rearrange ?
                            <Draggable onPosChange={getChangedPos}>
                                {
                                    checklistFields.map((form, index) => {
                                        return (

                                            <div key={index}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                                    <Label label={`Please enter task description`} className={'CMlabelDiv'} />
                                                    <Box sx={{ width: "100%", marginLeft: '10px' }}>
                                                        <TextInput
                                                            name='Checklist'
                                                            fullWidth={true}
                                                            placeholder={`Enter task description`}
                                                            key={index}
                                                            onChange={event => handleFormChange(event, index)}
                                                            value={form.TaskDesc}
                                                            disabled={rearrange ? true : false}
                                                        />
                                                    </Box>
                                                </Box>

                                            </div>

                                        )
                                    })
                                }
                            </Draggable> :

                            checklistFields.map((form, index) => {
                                return (

                                    <div key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                            <Label label={`Please enter task description`} className={'CMlabelDiv'} />
                                            <Box sx={{ width: "100%", marginLeft: '10px' }}>
                                                <TextInput
                                                    name='Checklist'
                                                    fullWidth={true}
                                                    placeholder={`Enter task description`}
                                                    key={index}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.TaskDesc}
                                                />
                                            </Box>
                                        </Box>
                                        <Stack spacing={2} direction="row" sx={{ display: 'flex' }} className='addremovcls'>
                                            {index === checklistFields.length - 1 ?
                                             <a style={{ cursor: "pointer" }} onClick={() => addFields(index)}><img src={PlusIcon} alt="PlusIcon" /></a> : null}
                                            <a style={{ cursor: "pointer" }} onClick={() => removeFields(index)}><img style={{ height: "30px", width: '30px' }} src={BinIcon} alt="BinIcon" /></a>
                                        </Stack>

                                    </div>

                                )
                            })
                        }

                    </FormControl>
                </div>
                <div className='butondrower'>
                    {renderButtons()}
                </div>
            </Box>
        </Container>
    );
};
export default CreateModule;
