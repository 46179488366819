import React, { useState, useEffect } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarChart = (props:any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [opdata4, setOPData4] = useState<any>([]);
  useEffect(()=> {
    const lab:any = [], d1:any = [], d2:any = [], d3:any = [], d4:any = [];
    props.apq.map((i:any)=> {
      lab.push(i.BatchNo);
      d1.push(i.Availability);
      d2.push(i.Quality);
      d3.push(i.Performance)
      d4.push(i.OEE)
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
    setOPData4(d4);
  },[props])

  const data = {
    labels: labelSet,
    datasets: [
      {
        label: 'OEE %',
        backgroundColor: '#00ffFF',
        borderColor: '#00ffFF',
        borderWidth: 2,
        hoverBackgroundColor: '#D0AE8B',
        hoverBorderColor: '#D0AE8B',
        data: opdata4,
        color: "#e0e0e0",
        type: 'line' as const,
      },
      {
        label: 'Availability %',
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 0,
        hoverBackgroundColor: '#ffc107',
        hoverBorderColor: '#ffc107',
        data: opdata1,
        color: "#ffffff",
      },
      {
        label: 'Performance %',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        borderWidth: 0,
        hoverBackgroundColor: '#0d6efd',
        hoverBorderColor: '#0d6efd',
        data: opdata3,
        color: "#e0e0e0",
      },
      {
        label: 'Quality %',
        backgroundColor: '#198754',
        borderColor: '#198754',
        borderWidth: 0,
        hoverBackgroundColor: '#198754',
        hoverBorderColor: '#198754',
        data: opdata2,
        color: "#e0e0e0",
      },
      
    ],
    color: '#ffffff',
  };

  const options = {
    maintainAspectRatio: false, 
    // barThickness: 55,
    responsive: true,
    plugins: {
      datalabels: {color: 'white', display: false},
      legend: {
        labels: {
          color: "#e0e0e0",
          font: {
            size: 10,
            family: "montserrat",
          }
        }
      }       
      },
      
    scales: {
      x: {
        grid: {
          color: 'transparent',
        },
        ticks: {
          color: 'white', 
        },
      },
      y: {
        title: {
          display: true,
          text: 'A P Q  ( % )',
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
        ticks: {
          beginAtZero: true,
          color: 'white', 
        },
      },
    },
  };
  return <Chart type='bar' data={data} options={options} plugins={[ChartDataLabels]} />;
};

export default BarChart;