import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import Axios from 'axios';
import RuleEngineService from './ruleEngineServices';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import '../Admin/ClientManagement/index.scss';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import { SCREEN_TITLES } from '../../../app/constants/StringConstants';
import CreatePresetAlert from './PresetAlerts/CreateConfig';
import PresetConfig from "./PresetAlerts/Config";
import CreateCustomAlert from "./CustomAlerts/CreateConfig";
import CustomConfig from './CustomAlerts/Config';
import SnackbarAlert from '../../atoms/Snackbar';

const tabs = ['Preset Alerts', 'Custom Alerts'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [selectedRow, setSelectedRow] = useState<any>();
    const [notificationData, setNotificationData] = useState([]);
    const [customerKey, setCustomerKey] = useState("");
    const [customersData, setCustomersData] = useState<any[]>([]);
    const [grpKey, setGrpKey] = useState('');
    const [presetAlertsData, setPresetAlertsData] = useState([]);
    const [customAlertsData, setCustomAlertsData] = useState([]);
    const [presetKey, setPresetKey] = useState('');
    const [customKey, setCustomKey] = useState('');
    const [view, setView] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteGrp, setDeleteGrp] = useState(true);
    const [editableData, setEditableData] = useState([]);
    const [editableCustomData, setEditableCustomData] = useState([]);
    const [deletePresetAlert] = useState(true);
    const [deleteKey, setDeleteKey] = useState('');
    const date = new Date();
    date.setDate(date.getDate() - 6)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date())
    const [errorDate, setErrorFalse] = useState(false);
    const [plantsData, setPlantsData] = useState([]);
    const [selectedPlantsName, setSelectedPlantsName] = useState<any>([]);
    const [plantName, setPlantName] = useState([]);
    const [plantKeys, setPlantKeys] = useState([]);
    const [alertsData, setAlertsData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState(false);
    const [isPresetLoading, setIsPresetLoading] = useState(false)
    const [isCustomLoading, setIsCustomLoading] = useState(false)

    const dispatch = useDispatch();
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const getCreateValue = (ele: string) => {
        setDrawerHeader('Create');
        setCreateValue(ele);
        setCreateDrawerOpen(true);
        setGrpKey('');
        setPresetKey('');
        setCustomKey('');
    };

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    useEffect(() => {
        const body = { userId: loggedInUser, token: token }
        RuleEngineService.getRuleEngineCustomers(body)
            .then((response: any) => {
                const result = response?.data.message[0];
                const removeAll: any = [];
                result[0].map((i: any) => {
                    if (i.CustomerName !== "All") {
                        removeAll.push(i);
                    }
                })
                setCustomersData([
                    ...removeAll.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const fetchPresetAlerts = () => {
        setIsPresetLoading(true)
        const body = { userId: loggedInUser, token: token }
        RuleEngineService.getAllPresetRuleConditions(body)
            .then((response: any) => {
                const result = response.data.message[0];
                setPresetAlertsData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setIsPresetLoading(false)
            })
    }

    const fetchCustomAlerts = () => {
        setIsCustomLoading(true)
        const body = { userId: loggedInUser, token: token }
        RuleEngineService.getAllCustomRuleConditions(body)
            .then((response: any) => {
                const result = response.data.message[0];
                setCustomAlertsData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setIsCustomLoading(false)
            })
    }

    useEffect(() => {
        fetchPresetAlerts();
        fetchCustomAlerts();
    }, []);

    const fetchPresetAlertDetails = async (id: any) => {
        const body = { userId: loggedInUser, token: token, ruleKey: id }
        await RuleEngineService.getRuleEnginePresetAlertDetails(body)
            .then(async (response: any) => {
                const result = await response.data?.message[0];
                setEditableData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchCustomAlertDetails = async (id: any) => {
        const body = { userId: loggedInUser, ruleKey: id, token: token }
        RuleEngineService.getRuleEngineCustomAlertDetails(body)
            .then((response: any) => {
                const result = response.data.message[0];
                setEditableCustomData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleChangePlant = (event: any) => {
        setPlantKeys([]);
        setPlantName([]);
        setSelectedPlantsName([]);
        setCustomerKey(event.target.value);
        const body = { userId: loggedInUser, customerKey: event.target.value, token: token }
        RuleEngineService.getRuleEnginePlants(body)
            .then((response: any) => {
                const result = response.data.message[0];
                const name: any = [], key: any = [];
                result[0]?.map((i: any) => { name.push(i.PlantName); key.push(i.PlantKey) });
                setPlantKeys(key);
                setPlantName(name);
                setPlantsData(result[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return (
                    <PresetConfig
                        presetAlertsData={presetAlertsData}
                        fetchPresetAlerts={fetchPresetAlerts}
                        presetKey={presetKey}
                        setPresetKey={setPresetKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={presetKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}
                        fetchPresetAlertDetails={(e: any) => fetchPresetAlertDetails(e)}
                        isLoading={isPresetLoading}
                    />
                )
            case 1:
                return (
                    <CustomConfig
                        customAlertsData={customAlertsData}
                        fetchCustomAlerts={fetchCustomAlerts}
                        customKey={customKey}
                        setCustomKey={setCustomKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={customKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}
                        fetchCustomAlertDetails={(e: any) => fetchCustomAlertDetails(e)}
                        isLoading={isCustomLoading}
                    />
                )
            default:
                return (
                    <PresetConfig
                        presetAlertsData={presetAlertsData}
                        fetchPresetAlerts={fetchPresetAlerts}
                        presetKey={presetKey}
                        setPresetKey={setPresetKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={presetKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}
                        fetchPresetAlertDetails={(e: any) => fetchPresetAlertDetails(e)}
                    />
                );
        }
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <div style={{ flex: '80%', display: 'flex' }}>
                        <div className="headinglabel" style={{ marginTop: '5px' }}>
                            {SCREEN_TITLES.RULES_AND_AlERTS}
                        </div>
                        <div
                            style={{
                                flex: '10%',
                                marginLeft: '20px',
                                // marginTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                            className="dashboardDropdownDiv"
                        >
                            <DrawerDropdown click={getCreateValue} items={['Preset Alerts', 'Custom Alerts']} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '35px', zIndex: '0' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                    // type="nonCurve"
                    />
                </div>
            </Container>
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); }}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                children={
                    drawerHeader === 'Create' || drawerHeader === 'Edit' ? (
                        (
                            createValue === "Preset Alerts" ?
                                <CreatePresetAlert
                                    presetKey={presetKey}
                                    drawerClose={() => { setCreateDrawerOpen(false); }}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setDtlData={setDtlData}
                                    dtlData={dtlData}
                                    setSnackbarOpts={setSnackbarOptions}
                                    isEdit={false}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                    customersData={customersData}
                                    setPresetKey={setPresetKey}
                                    fetchPresetAlerts={fetchPresetAlerts}
                                    editableData={editableData}
                                />

                                :
                                <CreateCustomAlert
                                    customKey={customKey}
                                    drawerClose={() => { setCreateDrawerOpen(false); }}
                                    setCurrTab={setCurrTab}
                                    setReload={setReload}
                                    setDtlData={setDtlData}
                                    dtlData={dtlData}
                                    setSnackbarOpts={setSnackbarOptions}
                                    isEdit={false}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                    customersData={customersData}
                                    setCustomKey={setCustomKey}
                                    fetchCustomAlerts={fetchCustomAlerts}
                                    editableCustomData={editableCustomData}
                                />
                        )
                    ) : (

                        null
                    )
                }
            />

        </div>
    );
};

export default SystemConfiguration;
