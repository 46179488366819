import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import Axios from 'axios';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../../utils/formatData';
import ContactDetailDrawer from '../../../../atoms/ContactDetailDrawer';

const TABLE_HEAD1 = [
    { id: 'PlantName', label: 'Plant Name', alignRight: false },
    { id: 'ProductName', label: 'Product Name', alignRight: false },
    { id: 'RecipeName', label: 'Recipe Name', alignRight: false },
    { id: 'BatchNo', label: 'Golden Batch Number', alignRight: false },
    { id: 'LimitSet', label: 'Limit Set?', alignRight: false },
    { id: 'LastUpdatedDateTime', label: 'Last Updated?', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const TABLE_HEAD2 = [
    { id: 'ProductName', label: 'Product Name', alignRight: false },
    { id: 'BatchNumber', label: 'Batch Number', alignRight: false },
    { id: 'SubProcessName', label: 'Sub Process Name', alignRight: false },
    { id: 'RecipeName', label: 'Recipe Name', alignRight: false },
    { id: 'GoldenLotNo', label: 'Golden Lot Number', alignRight: false },
    { id: 'MachineSerialNo', label: 'Machine Serial Number', alignRight: false },
    {id: 'PhasesCount', label: 'Phases Count', alignRight: false },
    {id: 'PhasesConfigured', label: 'Phases Configured', alignRight: false },
    {id: 'CPPCount', label: 'CPP Count', alignRight: false },
    {id: 'CPPLimitsConfigured', label: 'CPP Limits Configured', alignRight: false },
    // { id: 'LotStartDate', label: 'Lot Started At', alignRight: false },
    // { id: 'LotEndDate', label: 'Lot Ended At', alignRight: false },
    { id: 'LastUpdatedDateTime', label: 'Last Updated?', alignRight: false },
    { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Golden batch recipe deleted',
        open: true,
        type: 'success'
    },
};

type Anchor = 'right';

const QialityAttributes = (props: any) => {
    const {
        goldenBatchList,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        selectedRow,
        setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        setAlertType,
        setGoldenRecipeKey,
        setKey,
        machineModelName,
        plantKey,
        machineModelKey,
        setPhasesTab,
        setAutoFillProducts,
        setApplyListFilter,
        applyListFilter,
        setFilteredProcessKey,
        isLoading
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('TrialId');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const currentPath = useLocation();
    const accessData = state?.[STORE_KEYS.ROUTES_PRIVILEGES];
    const isAccess = (__data: any) => {
        return __data.filter((d: any) => d.path == currentPath?.pathname)?.[0]?.isEditable;
    };
    useEffect(() => {
        if (reload) {
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            setRmUser(false);
        }
    }, [rmUser]);

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = async (row: any) => {
        const r = await { ...row }
        setSelectedRow(r);
        setRNVKey(r?.MCLCWorkOrderKey);
        setRemoveBxOpen(true);
    };

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.API_DELETE_GOLDENBATCH_RECIPE,
                        reqObj: {
                            LoggedUserKey: state[STORE_KEYS.USER_DTL]?.LoggedUserKey,
                            TablePrimaryKey: selectedRow?.GBDtlKey

                        }
                    },
                    uniqueScreenIdentifier: {
                        rowDeleted: true
                    },
                    storeKey: STORE_KEYS.API_DELETE_GOLDENBATCH_RECIPE
                })
            );
            ;
        }
    }, [rmUser]);

    useEffect(() => {
        if (state?.[STORE_KEYS.API_DELETE_GOLDENBATCH_RECIPE]?.rowDeleted) {
            setSnackbarOptions(options.RM_SUCCESS);
            if(props.machineModelName.includes("BQS")) {
                 props.getGoldenBatchListBQS(-1, -1, -1);
                 props.getProducts(5, 11, 'BQS_RETROFIT') } else {
                    props.getGoldenBatchListFBE(machineModelKey, -1);
                 }
            setRmUser(false)
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.API_DELETE_GOLDENBATCH_RECIPE,
                    uniqueScreenIdentifier: {
                        rowDeleted: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.API_DELETE_GOLDENBATCH_RECIPE]?.rowDeleted]);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < goldenBatchList.length; i++) {
                newSelecteds.push(goldenBatchList[i].id);
            }
            const toRemove = goldenBatchList.slice(page, goldenBatchList.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const filteredList = applySortFilter(goldenBatchList, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('WorkOrderID');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            props.editTrail(row?.TrialKey);
        }
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const resetInput = () => {
        setFilterName('');
    };

    const getPhases = (key1: any, key2: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.API_GET_GOLDENBATCH_FBE_PHASES,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "PlantKey": plantKey,
                        "MachineModelKey": machineModelKey,
                        "CustProductKey": key1,
                        "RecipeKey": key2,
                    }
                },
                storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_PHASES
            })
        );
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_PHASES]?.body?.data) {
            const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_PHASES]?.body?.data?.map((item: any) => {
                return item;
            });
            setPhasesTab(list);
        }

    }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_PHASES]]);

    const getAutofillProducts = (key1: any, key2: any, key3: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "PlantKey": plantKey,
                        "MachineModelKey": machineModelKey,
                        "CustProductKey": key1,
                        "SubProcessKey": key2,
                        "RecipeKey": key3,
                    }
                },
                storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS
            })
        );
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS]?.body?.data) {
            const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS]?.body?.data?.map((item: any) => {
                return item;
            });
            setAutoFillProducts([
                ...list?.map((item: any) => ({ code: item.AutofillCustProductKey, name: item.ProductName }))

            ]);
        }

    }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS]]);

    return (
        <div className='goldenBatchesList'>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} className='alertmsgdisplay' />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => {
                                setAlertType("");
                                setEditDrawerOpen(true);
                                setDrawerHeader("");
                                setCreateValue("Golden Batches Filter");
                            }}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Golden Batch List',
                                subTitle: `${filteredList?.length} Golden Batch List Count`
                            }}
                            filterButton={machineModelName.includes("BQS") ? false : true}
                            customButton={false}
                            appliedFilters={applyListFilter}
                            clearFilter={() => {setApplyListFilter(false); setFilteredProcessKey(''); props.getGoldenBatchListFBE(machineModelKey, -1)}}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={machineModelName.includes('BQS') ? TABLE_HEAD1 : TABLE_HEAD2}
                    body={filteredList}
                    isLoading={isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    machineModelName={machineModelName}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Select Client to display users' }
                        : {})}
                    GBAdmin={true}
                    editFn={async (row: any, type: string) => {
                        props.setAlertType("");
                        setSelectedRow(row);
                        setDrawerHeader(type);
                        props.setCreateDrawerOpen(true);
                        setCreateValue('Golden Batch');
                    }}
                    loadConfig={async (row: any, type1: string, type2: string) => {
                        if (row.LimitSet === "N" && props.machineModelName.includes("BQS")) {
                            props.setAlertType("");
                            setSelectedRow(row);
                            props.setCreateDrawerOpen(true);
                            setCreateValue(type1);
                            setGoldenRecipeKey(row?.RecipeKey);

                        } else {
                            setAlertType("Custom Alerts")
                            setDrawerHeader(type2);
                            setEditDrawerOpen(true);
                            setCreateValue('Limit Config');
                            setSelectedRow(filteredList);
                            if (props.machineModelName.includes("BQS")) {
                                setGoldenRecipeKey(row?.RecipeKey);
                                props.getQualityAttributesBQS(row?.RecipeKey);
                                setSelectedRow(filteredList);
                                props.setFilteredGoldenRecipeKey(row?.RecipeKey);
                            } else {
                                getPhases(row?.CustProductKey, row?.RecipeKey);
                                getAutofillProducts(row?.CustProductKey, row?.SubProcessKey, row?.RecipeKey);
                                props.getQualityAttributesFBE(row?.CustProductKey, row?.SubProcessKey, row?.RecipeKey);
                                setGoldenRecipeKey(row?.GoldenLotNo);
                                dispatch(
                                    updateScreenIdentifiers({
                                        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS,
                                        uniqueScreenIdentifier: {
                                          body: {}
                                        },
                                
                                      })
                                );
                                dispatch(
                                    updateScreenIdentifiers({
                                        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS,
                                        uniqueScreenIdentifier: {
                                          body: {}
                                        },
                                
                                      })
                                );
                                dispatch(
                                    updateScreenIdentifiers({
                                        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES,
                                        uniqueScreenIdentifier: {
                                          body: {}
                                        },
                                
                                      })
                                );
                            }
                        }
                    }}
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete Golden Batch Recipe"
                modalContent="Are you sure you want to delete golden batch recipe?"
            />

        </div>
    );
};

export default QialityAttributes;
