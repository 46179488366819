import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import DownArrow from '../../../../../assets/down-arrow.svg';
import BarChart from './barchart';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const DropDownIcon = (prop: any) => (
  <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

const RadioSelectionComponent = (props: any) => {
  const [labelText, setLabelText] = useState('Operator Trends');
  const handleOptionChange = (event: any) => {
    props.changeOP(event.target.value)
    props.setSelectAllOP(true);
    setLabelText(event.target.value === 'option1' ? 'Operator Trends' : 'Product Trends');
  };

  let data = props.selectedOption === 'option2' ? props.products : props.operators;

  return (
    <div className='raioselectionBtn'>
      <div className='mainradiocls'>
        <div>
          <label>
            <input
              type="radio"
              value="option1"
              checked={props.selectedOption === 'option1'}
              onChange={handleOptionChange}
            />
            Operator
          </label>
        </div>

        <div>
          <label>
            <input
              type="radio"
              value="option2"
              checked={props.selectedOption === 'option2'}
              onChange={handleOptionChange}
            />
            Product
          </label>
        </div>
        <div className='dropmulti'>
          {/* <div className='lableTitle'>Select Filter</div> */}
          <FormControl sx={{ width: '100%' }}>
            {props.selectedOP?.length > 0 ? null : (
              <InputLabel
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '13px',
                  paddingTop: '0px',
                  paddingLeft: '12px',
                  color: '#b1c2df',
                  lineHeight: '1',
                  opacity: '0.7'
                }}
                id="demo-multiple-checkbox-label"
              >Select Filter
              </InputLabel>
            )}
            <Select
              IconComponent={DropDownIcon}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              className='chekboxmulti'
              //label=""
              multiple
              disabled={props.selectedMachine === "" ? true : false}
              value={props.selectedOP}
              sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
              placeholder='Please select machines'
              // input={<OutlinedInput />}
              renderValue={(selected) => selected.map((x: any) => props.selectedOption === 'option2' ? x.ProductName : x.OperatorName).join(', ')}
              MenuProps={MenuProps}

            >
              <MenuItem value={"all"} onClick={() => props.setSelectAllOP(!props.selectAllOP)}>
                <Checkbox
                  checked={
                    props.selectAllOP
                  }

                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {data?.map((i: any) => (
                <MenuItem value={i} onClick={() => props.handleChangeOP(i)}>
                  <Checkbox
                    checked={
                      props.selectedOption === 'option2' ? props.selectedOP.findIndex((item: any) => item.ProductKey === i.ProductKey) >= 0 : props.selectedOP.findIndex((item: any) => item.OperatorKey === i.OperatorKey) >= 0
                    }

                  />
                  <ListItemText primary={props.selectedOption === 'option2' ? i.ProductName : i.OperatorName} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className='filterMachineSr'>
        {/* <h3 className='titleGraph font14'>{labelText}</h3> */}
        <div>
          <div className='BarGraphOne'>
            <BarChart OPData={props.OPData} selectedOption={props.selectedOption} selectedMachineModel={props.selectedMachineModel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioSelectionComponent;
