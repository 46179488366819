import { useState, useEffect, useRef } from 'react';
import { MuiDateRangePicker } from '../../../../../atoms/DateRangePicker';
import { DateRange } from 'materialui-daterange-picker';
import DropDown from '../../../../../atoms/Dropdown';
import Label from '../../../../../atoms/Label/index';
import dateFormat from 'dateformat';
import './index.scss';
import { useDispatch } from 'react-redux';
import { executeACGAction, updateScreenIdentifiers } from '../../../../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../../../../constants/apiConstants';
import LineChart from './LineChart';
import useGetState from '../../../../../utils/hooks/useGetState';
import CustomDatePicker from '../../../../../atoms/CustomDateTimePicker';
import { Form, Formik, FormikProps } from 'formik';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import * as Yup from 'yup';
import { Dateformat } from '../../../../../utils/formatTime';
import ACGButton from '../../../../../atoms/Button';

type Props = { dtlData: any };
type DateParams = {
    dateFromSelect: string | '';
    dateToSelect: string | '';
};

const TimeSeriesDtl = (props: Props) => {
    const { dtlData } = props;
    const dispatch = useDispatch();
    const [timeRg, setTimeRg] = useState<any>(4);
    const [dateRange, setDateRange] = useState<DateRange>({});
    const stateObj = useGetState();
    const [chartData, setChartData] = useState<any>([]);
    const [dataPoints, setDataPoints] = useState<any>([]);
    const [setValue, setSetValue] = useState<any>([]);
    const [LSLValue, setLSLValue] = useState<any>([]);
    const [USLValue, setUSLValue] = useState<any>([]);
    const [dataLabels, setDataLabels] = useState<any>([]);
    const [noData, setNoData] = useState('');
    const [validationSchema, setValidationSchema] = useState({});
    const formRef = useRef<any>(null);
    const [dateParams, setDateParams] = useState<DateParams>({
        dateFromSelect: '',
        dateToSelect: ''
    });
    const [toDt, setToDt] = useState(dateFormat(new Date(), Dateformat.YYYY_MM_DD_HH_MM_SS));
    const rangeChng = (value: any) => {
        //   getKpis(dateFormat(value?.startDate, 'yyyy-mm-dd'), dateFormat(value?.endDate, 'yyyy-mm-dd'));
        setDateRange(value);
    };
    const getTsDtl = (timePeriodKey?: any, vals?: any) => {
        setNoData('');
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_TS_DTL,
                    reqObj: {
                        machineKey: dtlData?.machineKey,
                        mmParamKey: dtlData?.paramKey,
                        timePeriodKey: timePeriodKey ? timePeriodKey : timeRg,
                        customStartTime: vals?.dateFromSelect
                            ? dateFormat(vals?.dateFromSelect, 'yyyy-mm-dd HH:MM')
                            : '2017-01-31 00:00:00',
                        customEndTime: vals?.dateToSelect
                            ? dateFormat(vals?.dateToSelect, 'yyyy-mm-dd HH:MM')
                            : '2017-01-31 00:00:00'
                    }
                },
                uniqueScreenIdentifier: { tsDtlRecd: true },
                storeKey: STORE_KEYS.TS_DTL
            })
        );
    };
    // useEffect(() => {
    //     getTsDtl();
    //     setNoData('');
    // }, []);
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.TS_DTL]?.tsDtlRecd) {
            setChartData(stateObj?.[STORE_KEYS.TS_DTL]?.body?.data);
            if (stateObj?.[STORE_KEYS.TS_DTL]?.statusCode === 202) {
                setNoData('No Data Found');
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.TS_DTL,
                    uniqueScreenIdentifier: {
                        tsDtlRecd: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.TS_DTL]?.tsDtlRecd]);

    useEffect(() => {
        setNoData('Timed out, Please try again later');
    }, [stateObj?.err]);

    useEffect(() => {
        if (chartData?.length > 0) {
            const dPoints: any[] = [];
            // const SetValue: any[] = [];
            // const LSLBandValue: any[] = [];
            // const USLBandValue: any[] = [];
            const sPoints: any[] = [];
            const LSLPoints: any[] = [];
            const USLPoints: any[] = [];
            const dLabels: any[] = [];
            chartData?.map((ele: any) => {
                // const date = new Date(ele?.EventTime)?.toString();
                // const index = date.indexOf('GMT');
                // const newDate = date.slice(0, index);
                dPoints.push(ele?.ActualValue);
                sPoints.push(ele?.SetValue);
                LSLPoints.push(ele?.LSLBandValue);
                USLPoints.push(ele?.USLBandValue);
                dLabels.push(ele?.EventTime);
            });
            setDataPoints(dPoints);
            setSetValue(sPoints);
            setLSLValue(LSLPoints);
            setUSLValue(USLPoints);
            setDataLabels(dLabels);
        }
    }, [chartData?.length]);

    const changeTime = (event: any) => {
        setTimeRg(event.target.value);
    };

    useEffect(() => {
        setDataPoints([]);
        setDataLabels([]);
        setDateRange({});
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.TS_DTL,
                uniqueScreenIdentifier: {
                    body: {
                        data: []
                    }
                }
            })
        );
        if (timeRg !== 9) {
            getTsDtl(timeRg);
        }
        if (timeRg == 9) {
            setNoData('Please select a date range');
        }
    }, [timeRg]);

    useEffect(() => {
        setDataPoints([]);
        setDataLabels([]);
        dispatch(
            updateScreenIdentifiers({
                storeKey: STORE_KEYS.TS_DTL,
                uniqueScreenIdentifier: {
                    body: {
                        data: []
                    }
                }
            })
        );
        if (dateRange?.startDate) {
            getTsDtl(timeRg);
        }
    }, [dateRange]);

    useEffect(() => {
        setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
    }, []);

    const handleSubmit = async (values: any) => {
        setDataPoints([]);
        setDataLabels([]);
        getTsDtl(timeRg, values);
        setNoData('');
    };

    return (
        <div className="timeSeriesDtl">
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <div style={{ width: '300px' }}>
                    <Label label="Select Time Range" className={'nameDiv'} />
                    <DropDown
                        onChange={(event) => {
                            setTimeRg(event.target.value);
                            setNoData('');
                        }}
                        className="dpDiv"
                        name="dropdownTr"
                        items={dtlData?.tps}
                        value={timeRg}
                        placeHolder={'Select Time Range'}
                    />
                </div>
                {/* <div style={{ width: '300px' }} className="graphTimePicker">
                    {timeRg == 9 && (
                        <MuiDateRangePicker
                            TextFieldProps={{
                                variant: 'outlined',
                                placeholder: 'Select Date Range'
                            }}
                            dateRange={dateRange}
                            onDateRangeChange={rangeChng}
                        />
                    )}
                </div> */}
                {timeRg == 9 && (
                    <Formik
                        initialValues={dateParams}
                        validationSchema={validationSchema}
                        onSubmit={(values: any) => {
                            handleSubmit(values);
                        }}
                        validateOnBlur={true}
                        validateOnChange={true}
                        innerRef={formRef}
                        validator={() => ({})}
                    >
                        {(formikprops: FormikProps<any>) => {
                            const {
                                values,
                                handleChange,
                                handleBlur,
                                isValid,
                                errors,
                                touched,
                                setFieldValue,
                                resetForm
                            } = formikprops;
                            return (
                                <Form
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    name="graphForm" className='graphFormTS'
                                >
                                    <div style={{ marginTop: '27px', marginLeft: '10px' }}>
                                        <Label label="From Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={values.dateFromSelect}
                                            className="inputDiv selfPad"
                                            onChange={(val: any) => {
                                                setFieldValue('dateFromSelect', val);
                                                console.log(val);
                                            }}
                                            // closeOnSelect={false}
                                            inputFormat="dd/MM/yyyy, HH:mm:SS"
                                            placeholder="Select From Date"
                                            maxDate={new Date()}
                                        />
                                        {errors?.dateFromSelect && (
                                            <div style={{ marginTop: '00px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '1%',
                                                        position: 'absolute',
                                                        width: '200px'
                                                    }}
                                                >
                                                    {errors?.dateFromSelect}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ marginTop: '27px', marginLeft: '10px' }}>
                                        <Label label="To Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateToSelect"
                                            value={values.dateToSelect}
                                            className="inputDiv selfPad"
                                            onChange={(val: any) => {
                                                setFieldValue('dateToSelect', val);
                                                console.log(val);
                                                // handleSubmit(values, errors);
                                            }}
                                            // onClose={finishedEnd}
                                            // closeOnSelect={false}
                                            inputFormat="dd/MM/yyyy, HH:mm:SS"
                                            placeholder="dateToSelect"
                                            maxDate={new Date()}
                                        />
                                        {errors?.dateToSelect && (
                                            <div style={{ marginTop: '0px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '1%',
                                                        position: 'absolute'
                                                    }}
                                                >
                                                    {errors?.dateToSelect}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div style={{}}>
                                        <ACGButton
                                            name={'Submit'}
                                            handleClick={() => {}}
                                            type="submit"
                                            className="second-btn"
                                            variant="contained"
                                            style={{ height: '40px', marginLeft: '10px', marginTop: '50px' }}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}

                {/* <CustomDatePicker
                    name="dateFromSelect"
                    value={toDt}
                    className="inputDiv selfPad"
                    onChange={(val: any) => {
                        setToDt(val);
                    }}
                    onClose={finishedEnd}
                    inputFormat="dd-MM-yyyy, HH:mm:SS"
                    placeholder="Select From Date"
                    closeOnSelect={false}
                /> */}
            </div>
            <LineChart
                chartData={chartData}
                labels={dataLabels}
                points={dataPoints}
                setValue={setValue}
                LSLValue={LSLValue}
                USLValue={USLValue}
                noData={noData}
            />
        </div>
    );
};

export default TimeSeriesDtl;
