import CreatePassword from './CreatePassword/index';
import PasswordSuccess from './PasswordSuccess/index';
import SetSecurityQn from './SetSecurityQn/index';
import SimpleLoginTemplate from './SimpleLogin/index';
import ForgotPassword from './ForgotPassword/index';
import LockedAccount from './LockedAccount/index';
import ForgotPwdDtl from './ForgotPwdDtl';

const componentMap: any = {
    simpleLogin: SimpleLoginTemplate,
    setSecurityQn: SetSecurityQn,
    createPassword: CreatePassword,
    passwordSuccess: PasswordSuccess,
    forgotPassword: ForgotPassword,
    forgotPwdDtl: ForgotPwdDtl,
    lockAccount: LockedAccount
};

type ComponentProps = { data: { templateType: string; setTemplateType: Function; vals?: any } };

const LoginModuleTemplates = (props: ComponentProps) => {
    const { data } = props;
    const Component = componentMap[data?.templateType];

    return (
        <div>
            <Component data={data} />
        </div>
    );
};

export default LoginModuleTemplates;
