import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../atoms/ButtonTabs/MapCustomTabs';
import './index.scss';
import Alarms from './TabContent/Alarms';
import Events from './TabContent/Events';
import Parameters from './TabContent/Parameters';
import ConditionMonitoringLog from './TabContent/ConditionMonitoringLog';
import Overview from './TabContent/Overview';
import TimeSeries from './TabContent/TimeSeries';

type Props = { data: any; setSelectedRow?: any };
const Dashboard = (props: Props) => {
    const { data, setSelectedRow = () => {} } = props;
    const [currTab, setCurrTab] = useState(0);

    const tabs = ['Overview', 'Parameters', 'Time Series', 'Alarms', 'Events'];
    const navigateTab = () => {
        switch (currTab) {
            case 0:
                return <Overview data={data} setSelectedRow={setSelectedRow} />;
            case 1:
                return <Parameters data={data} />;
            case 2:
                return <TimeSeries data={data} />;
            case 3:
                return <Alarms data={data} />;
            // case 4:
            //     return <ConditionMonitoringLog />;
            case 4:
                return <Events data={data} />;
            default:
                return <Overview data={data} />;
        }
    };
    useEffect(() => {
        if (data?.reloadTabs) {
            setCurrTab(0);
        }
    }, [data?.reloadTabs]);
    return (
        <div>
            {/* <Container > */}
            <div style={{ zIndex: '0', borderRadius: '0px' }} className="drawerTabs">
                <MapCustomTabs
                    navigateTab={navigateTab}
                    currTab={currTab}
                    setCurrTab={setCurrTab}
                    tabsArray={tabs}
                    className="tabs"
                    position="relative"
                />
            </div>
            {/* </Container> */}
        </div>
    );
};

export default Dashboard;
