import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarChart = (props:any) => {

  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);

  useEffect(()=> {
    const lab:any = [], d1:any = [], d2:any = [];
    props?.alarmTrends.map((i:any)=> {
      lab.push(i.Alarmname);
      d1.push(i.AlarmCount);
    });
    setLabelSet(lab);
    setOPData1(d1);

  },[props])

  const data = {
    labels: labelSet,
    datasets: [
      {
        label: 'Alarm Count',
        backgroundColor: '#FF4747',
        borderColor: '#FF4747',
        borderWidth: 0,
        hoverBackgroundColor: '#FF4747',
        hoverBorderColor: '#FF4747',
        data: opdata1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, 
    responsive: true,
    plugins: {
      datalabels: {color: 'white', display: false},
      legend: {
        labels: {
          color: "#e0e0e0",
          font: {
            size: 10,
            family: "montserrat",
          }
        }
      }       
      },
    scales: {
      x: {
        grid: {
          color: 'transparent',
        },
        ticks: {
          callback: function(label:any) {
            if(labelSet[label].length < 10) {
              return labelSet[label];
            } else { 
              return labelSet[label].substr(0, 10) + "..."   
            }       
          },
          color: 'white'
        }
      },
      y: {
        grid: {
          color: '#878f99',
        },
        ticks: {
          beginAtZero: true,
          color: 'white', 
        },
        title: {
          display: true,
          text: 'Alarm Count',
          color: 'white',
          font: {
            size: 12
          }
        },
      },
    },
  };

  return (
    <div className='barchartPMEC noheight'>
      <Bar data={data} options={options}  plugins={[ChartDataLabels]} />
    </div>
  );
};

export default BarChart;