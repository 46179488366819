// feature/UserReport/DownloadableReport/BatchReport/index
import Drawer from '@mui/material/Drawer';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import ACGButton from '../../../../atoms/Button';
import { useEffect, useRef, useState } from 'react';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import { getLastSixMonths } from "../../../../utils/helper";
import './index.scss';
import { FormHelperText } from '@mui/material';
import DownArrow from '../../../../../assets/down-arrow.svg';
import DropDown from '../../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import * as Yup from 'yup';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import { executeACGAction, reset, resetStoreKey } from '../../../../store/slice';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import Box from '@mui/material/Box';
import { SnackBarOptions, Dateformat } from '../CommonUtil';
import SnackbarAlert from '../../../../atoms/Snackbar';
import moment from 'moment';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
};

type BatchParams = {
    dropdownMonth: string | '';
    dropdownPlant: string | '';
    dropdownModel: string | '';
    dropdownSerialNo: string | '';
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function AuditReportDrawer(props: Props) {
    const { status, handlerClose } = props;

    const [snackbarOptions, setSnackbarOptions] = useState(SnackBarOptions.DEFAULT);

    const [statusMessage, setStatusMessage] = useState<string>();

    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );

    const drawerClose = () => {
        handlerClose();
    };

    const [batchParams, setBatchParams] = useState<BatchParams>({
        dropdownMonth: '',
        dropdownPlant: '',
        dropdownModel: '',
        dropdownSerialNo: '',
    });

    const [monthList, setMonthList] = useState<any>();

    const [plantsList, setPlantsList] = useState<any>();
    //const [selectedPlant, setSelectedPlant] = useState<any>();

    const [machineModels, setMachineModels] = useState<any>();
    const [selectedModel, setSelectedModel] = useState<any>();

    const [machineParams, setMachineParams] = useState<any>([]);
    const [selectedMachineParams, setSelectedMachineParams] = useState<any>([]);

    const [machineSerialNos, setMachineSerialNos] = useState<any>();
    const [selectedMachineSerialNo, setSelectedMachineSerialNo] = useState<any>();

    const [batches, setBatches] = useState<any>();
    const [isSubmit, setIsSubmit] = useState<any>(false);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [reportResponse, setReportResponse] = useState<any>(null);

    const [isDownloadError, setIsDownloadError] = useState<boolean>(false);

    const [validationSchema, setValidationSchema] = useState({});

    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const REPORT_CODE = 'ALARMRPT';
    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const closeSnackbar = () => setSnackbarOptions(SnackBarOptions.DEFAULT);
    const [presetKey, setPresetKey] = useState('');
    const [noBatches, setNoBatches] = useState(true);
    const [isAllBatches, setIsAllBatches] = useState(false);

    useEffect(() => {
        const date = new Date();
        // dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        // dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        resetResult();

        setMachineSerialNos(null);
        setMachineParams([]);
        setMachineModels(null);

        setMachineModels(null);
        setSelectedModel(null);
        setMachineParams(null);
        setSelectedMachineSerialNo(null);
        setBatches(null);

        setBatchParams({
            ...batchParams,
            dropdownMonth: '',
            dropdownPlant: '',
            dropdownModel: '',
            dropdownSerialNo: ''
        });

        if (status) {
            setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.DN_ALARM_REPORT_MONTH_YEAR,
                    },
                    storeKey: STORE_KEYS.DN_ALARM_REPORT_MONTH_YEAR
                })
            );
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.DN_ALARM_REPORT_PLANTS,
                    },
                    storeKey: STORE_KEYS.DN_ALARM_REPORT_PLANTS
                })
            );
        }
    }, [status]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALARM_REPORT_MONTH_YEAR]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALARM_REPORT_MONTH_YEAR]?.body?.data;
            const months = data?.map((item: any) => {
                let monthData = {...item,MonthYearName:moment.utc(item.MonthYearName).format("MM/YYYY")};
                const newVal = renameObjKeys(monthData, { MonthYearKey: 'code', MonthYearName: 'name' });
                return newVal;
            });
            setMonthList(months);
            updateStatusMessage();
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALARM_REPORT_MONTH_YEAR }));
        }
    }, [state?.[STORE_KEYS.DN_ALARM_REPORT_MONTH_YEAR]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALARM_REPORT_PLANTS]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALARM_REPORT_PLANTS]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            });
            setPlantsList(plants);
            updateStatusMessage();
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALARM_REPORT_PLANTS }));
        }
    }, [state?.[STORE_KEYS.DN_ALARM_REPORT_PLANTS]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINE_MODELS]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINE_MODELS]?.body?.data;
            const models = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModels(models);
            updateStatusMessage();
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALARM_REPORT_MACHINE_MODELS }));
        }
    }, [state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINE_MODELS]?.body?.data]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINES]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINES]?.body?.data;
            let index = 0;
            const cats = data?.map((item: any) => {
                const item1 = Object.assign({}, item, { code: item.MachineKey });
                const newVal = renameObjKeys(item1, { MachineKey: 'code', LineMachineName: 'name' });
                index++;
                return newVal;
            });
            setMachineSerialNos(cats);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALARM_REPORT_MACHINES }));
        }
    }, [state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINES]?.body?.data]);

    useEffect(() => {
        batchParams.dropdownPlant && dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_ALARM_REPORT_MACHINE_MODELS,
                    reqObj: {
                        plantKey: batchParams.dropdownPlant
                    }
                },
                storeKey: STORE_KEYS.DN_ALARM_REPORT_MACHINE_MODELS
            })
        );

    }, [batchParams.dropdownPlant]);
    useEffect(() => {
        if (selectedModel) {
            batchParams.dropdownPlant && batchParams.dropdownMonth && dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_ALARM_REPORT_MACHINES,
                        reqObj: {
                            machineModelKey: selectedModel,
                            plantKey: batchParams.dropdownPlant,
                            monthYearKey: batchParams.dropdownMonth
                        }
                    },
                    storeKey: STORE_KEYS.DN_ALARM_REPORT_MACHINES
                })
            );
            setSelectedModel(null);
        }

    }, [selectedModel]);
    useEffect(() => {
        if (selectedMachineSerialNo) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_ALARM_REPORT_BATCHES,
                        reqObj: {
                            machineKey: selectedMachineSerialNo,
                            monthYearKey: batchParams.dropdownMonth
                        }
                    },
                    storeKey: STORE_KEYS.DN_ALARM_REPORT_BATCHES
                })
            );
        }

    }, [selectedMachineSerialNo]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALARM_REPORT_BATCHES]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALARM_REPORT_BATCHES]?.body?.data;
            const dpBatches = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
                return newVal;
            });

            setBatches(dpBatches);

            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALARM_REPORT_BATCHES }));
        }
    }, [
        state?.[STORE_KEYS.DN_ALARM_REPORT_BATCHES]?.body?.data,
        state?.[STORE_KEYS.DN_ALARM_REPORT_MACHINES]?.body?.data
    ]);

    const handleSubmit = async (values: any) => {
        console.log("selectedBatch.length", selectedBatch.length)
        if (selectedBatch.length === 0) {
            setNoBatches(true);
            return;
        } else setNoBatches(false);
        //alert("Submit");
        setIsSubmit(false)
        const batch: any = [], batchName: any = [];
        const plant = plantsList.find((item: any) => item.code == values.dropdownPlant);
        const model = machineModels.find((item: any) => item.code == values.dropdownModel);
        const serialNo = machineSerialNos.find((item: any) => item.code == values.dropdownSerialNo);
        selectedBatch.map((i: any) => { batch.push(i.code); batchName.push(i.name) })

        if (model && serialNo && plant) {

            const payload = {
                batchKey: batch,
            };

            const token = state?.[STORE_KEYS.USER_DTL].token;

            //resetResult();

            setIsGenerating(true);

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_ALARM_REPORT_DATA,
                method: 'post',
                responseType: 'blob',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        setIsDownloadError(false);
                        setIsGenerating(false);
                        setReportResponse(response.data);
                    }
                })
                .catch((error) => {
                    setIsGenerating(false);
                    setIsDownloadError(true);
                    return {
                        status: error.response.status
                    };
                });
        } else {
            alert('Please select all the fields or bad request');
        }
    };

    const sendEmail = () => {

        const token = state?.[STORE_KEYS.USER_DTL].token;
        const userEmail = state?.[STORE_KEYS.USER_DTL]?.EmailId;

        const month = monthList.find(
            (item: any) => item.code == batchParams.dropdownMonth
        );
        const serialNo = machineSerialNos.find(
            (item: any) => item.code == selectedMachineSerialNo
        );
        const fileName = `AlarmsReport_${serialNo.name}_${month.name}.xlsx`;

        if (reportResponse && token && userEmail && fileName) {

            setSnackbarOptions(SnackBarOptions.SENDING_EMAIL);

            const formData = new FormData();
            formData.append('file', reportResponse, 'file.xlsx');
            formData.append('filename', fileName);
            formData.append('userId', userEmail);

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_RPT_EMAIL_REPORT,
                method: 'post',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        setIsDownloadError(false);
                        setSnackbarOptions(SnackBarOptions.SENT_EMAIL);
                    }
                    else {
                        setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                    }
                })
                .catch((error) => {
                    setIsDownloadError(true);
                    setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                    return {
                        status: error.response.status
                    };
                });
        }
    };

    const updateStatusMessage = () => {
        const message = plantsList?.length <= 0 ? 'No plants available' :
            machineModels?.length <= 0 ? 'No models available' :
                machineSerialNos?.length <= 0 ? 'No machine serial number available' :
                    batches?.length <= 0 ? 'No batches available' : '';
        setStatusMessage(message);
    };

    const resetResult = () => {
        setSelectedBatch([]);
        setNoBatches(true);
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setIsSubmit(false);
    };
    const crossClick = () => {
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setIsSubmit(false);
    };
    const handleChangeBatch = (e: any, id: any) => {
        setNoBatches(false);
        console.log("selectedBatch", id)
        // if (selectedBatch.includes(id)) {
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        console.log("Helloo7", newSelected)
        setSelectedBatch(newSelected);
        // }
    }
    const handleSelectAllBatches = () => {
        if(isAllBatches){
            setSelectedBatch(batches)
            // console.log("Batches",batches)
        }else{
            setSelectedBatch([])
        }
    }

    useEffect(() => {
        handleSelectAllBatches()
    }, [isAllBatches])

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Drawer anchor={'right'} open={status} onClose={drawerClose} style={{ zIndex: '9999' }} className='allreports'>
                <Box role="presentation" className={'standardBox1'}>
                    <div className="baseDiv">
                        <div
                            onClick={() => {
                                resetResult();
                                drawerClose();
                            }}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '25px',
                                position: 'absolute',
                                left: '20px',
                                top: '16px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%'
                                // padding:'10px'
                            }}
                        >
                            <div style={{ display: 'block', marginLeft: '45px' }}>
                                <h6
                                    style={{
                                        color: '#DADCEE',
                                        fontSize: '16px',
                                        fontFamily: 'Montserrat'
                                        // marginTop: '-7px'
                                    }}
                                >
                                    Alarm Report
                                </h6>
                            </div>
                        </div>
                    </div>
                    <Container style={{ backgroundColor: '#2b2d42' }} className={'alm-dn-report-form1'}>
                        <Formik
                            initialValues={batchParams}
                            validationSchema={validationSchema}
                            onSubmit={(values: any) => {
                                handleSubmit(values);
                            }}
                            validateOnBlur={false}
                            validateOnChange={true}
                            innerRef={formRef}
                        >
                            {(formikprops: FormikProps<any>) => {
                                const {
                                    values,
                                    handleChange,
                                    handleBlur,
                                    isValid,
                                    errors,
                                    setTouched,
                                    touched,
                                    setFieldValue,
                                    resetForm
                                } = formikprops;
                                console.log("valuesvalues", values)

                                return (
                                    <Form className="detailForm">
                                        <Label label="Month" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(monthEvent) => {
                                                handleChange(monthEvent);
                                                const month = monthList.find(
                                                    (item: any) => item.code === monthEvent.target.value
                                                );
                                                if (month) {
                                                    setBatchParams({ ...batchParams, dropdownMonth: month.code.toString() });
                                                    setSelectedBatch([]);
                                                    setSelectedModel(null);
                                                    setSelectedModel(null);
                                                    setFieldValue('dropdownModel', '');

                                                    setMachineSerialNos(null);
                                                    setSelectedMachineSerialNo(null);
                                                    setFieldValue('dropdownSerialNo', '');

                                                    setBatches(null);
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownMonth"
                                            items={monthList}
                                            placeHolder="Select Month"
                                            value={values.dropdownMonth || []}
                                            helperText={
                                                errors.dropdownMonth && touched.dropdownMonth ? errors.dropdownMonth : ''
                                            }
                                        />
                                        <Label label="Plants" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(plantEvent) => {
                                                handleChange(plantEvent);
                                                const plant = plantsList.find(
                                                    (item: any) => item.code === plantEvent.target.value
                                                );
                                                if (plant) {

                                                    setBatchParams({ ...batchParams, dropdownPlant: plant.code.toString() });
                                                    console.log("Helloo3")
                                                    setSelectedBatch([]);
                                                    setSelectedModel(null);
                                                    setSelectedModel(null);
                                                    setFieldValue('dropdownModel', '');

                                                    setMachineSerialNos(null);
                                                    setSelectedMachineSerialNo(null);
                                                    setFieldValue('dropdownSerialNo', '');

                                                    setBatches(null);
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownPlant"
                                            items={plantsList}
                                            placeHolder="Select Plant"
                                            value={values.dropdownPlant || []}
                                            helperText={
                                                errors.dropdownPlant && touched.dropdownPlant ? errors.dropdownPlant : ''
                                            }
                                        />
                                        <Label label="Machine Model" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(modelEvent: any) => {
                                                handleChange(modelEvent);
                                                const model = machineModels.find(
                                                    (item: any) => item.code === modelEvent.target.value
                                                );
                                                if (model) {
                                                    console.log("Helloo4")
                                                    setSelectedBatch([]);
                                                    setMachineSerialNos(null);
                                                    setSelectedMachineSerialNo(null);
                                                    setFieldValue('dropdownSerialNo', '');

                                                    setBatches([]);

                                                    setSelectedModel(model.code.toString());

                                                    setBatchParams({ ...batchParams, dropdownModel: model.name })
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={machineModels}
                                            placeHolder="Select Machine Model"
                                            value={values.dropdownModel}
                                            helperText={
                                                errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                                            }
                                        />
                                        <div className='labelfixed'>

                                        </div>
                                        <Label label="Line / Serial Number" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(snEvent: any) => {
                                                console.log("Helloo6")
                                                setSelectedBatch([]);
                                                const serialNo = machineSerialNos.find(
                                                    (item: any) => item.code === snEvent.target.value
                                                );

                                                if (serialNo) {
                                                    setSelectedMachineSerialNo(serialNo.code.toString());
                                                }

                                                handleChange(snEvent);
                                                resetResult();
                                            }}
                                            className="inputDiv"
                                            name="dropdownSerialNo"
                                            items={machineSerialNos}
                                            placeHolder="Select Line / Serial Number"
                                            value={values.dropdownSerialNo}
                                            helperText={
                                                errors.dropdownSerialNo && touched.dropdownSerialNo
                                                    ? errors.dropdownSerialNo
                                                    : ''
                                            }
                                        />
                                        <div className='labelfixed'>
                                            <Label label="Select Batch" className={'labelDiv'} />
                                            <FormControl sx={{ width: '100%' }}>
                                                {selectedBatch?.length > 0 ? null : (
                                                    <InputLabel
                                                        style={{
                                                            fontFamily: 'Montserrat',
                                                            fontSize: '12px',
                                                            paddingTop: '0px',
                                                            paddingLeft: '20px',
                                                            color: '#b1c2df',
                                                            opacity: '0.7',
                                                            marginTop: '5px',
                                                            marginLeft: '-5px',
                                                        }}
                                                        id="demo-multiple-checkbox-label"
                                                    >
                                                        Select Batch
                                                    </InputLabel>
                                                )}
                                                <Select
                                                    IconComponent={DropDownIcon}
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    className='inputDivselectparam'
                                                    label=""
                                                    multiple
                                                    fullWidth
                                                    value={selectedBatch}
                                                    sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                    placeholder='Please select Batches'
                                                    // input={<OutlinedInput />}
                                                    renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                    MenuProps={MenuProps}
                                                    style={{
                                                        backgroundColor: '#1d1e2c',
                                                        color: '#b1c2df',
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        borderRadius: '10px',
                                                        height: '40px',
                                                        marginLeft: '15px',
                                                        marginRight: '15px',
                                                        width: '100%',
                                                    }}

                                                >
                                                    <MenuItem value={"all"} onClick={() => { setIsAllBatches(!isAllBatches) }}>
                                                        <Checkbox
                                                            checked={
                                                                isAllBatches
                                                            }

                                                        />
                                                        <ListItemText primary="Select All" />
                                                    </MenuItem>
                                                    {batches?.map((i: any) => (

                                                        <MenuItem value={i}
                                                            onClick={(e: any) => { handleChangeBatch(e, i); }}
                                                        // disabled={selectedBatch.length < 3 ? false : true}
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    selectedBatch.findIndex((item: any) => item.code === i.code) >= 0
                                                                }

                                                            />
                                                            <ListItemText primary={i.name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {isSubmit && noBatches === true ? <FormHelperText style={{
                                                    color: "#F06868",
                                                    fontSize: "11px",
                                                    fontFamily: "Montserrat",
                                                    fontWeight: 600,
                                                }}>Please select batch</FormHelperText> : null}
                                            </FormControl>
                                        </div>

                                        {!isGenerating && !reportResponse && (
                                            <div className={'btns noWrap'}>
                                                <ACGButton
                                                    name={'Reset'}
                                                    handleClick={() => {
                                                        setBatchParams({
                                                            ...batchParams,
                                                            dropdownPlant: '',
                                                            dropdownModel: '',
                                                            dropdownSerialNo: '',
                                                        });
                                                        resetForm();
                                                        resetResult();
                                                    }}
                                                    secondary={true}
                                                    className={'first-btn'}
                                                    type="button"
                                                    variant="contained"
                                                />
                                                <ACGButton
                                                    name={'Submit'}

                                                    handleClick={() => {
                                                        //alert("Hello"); 
                                                        setIsSubmit(true)
                                                    }}
                                                    type="submit"
                                                    className="second-btn"
                                                    variant="contained"
                                                />
                                            </div>
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>

                        {isGenerating && !reportResponse && <Label label="Generating Report ..." className={'labelDiv'} />}

                        {isDownloadError && (
                            <Label label="Report Generation Error, Please retry" className={'labelDiv'} />
                        )}

                        {reportResponse && (
                            <div className={'btns noWrap'} style={{ position: 'relative' }}>
                                <ACGButton
                                    name={'Download'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            const month = monthList.find(
                                                (item: any) => item.code == batchParams.dropdownMonth
                                            );
                                            const serialNo = machineSerialNos.find(
                                                (item: any) => item.code == selectedMachineSerialNo
                                            );
                                            FileDownload(reportResponse, `AlarmsReport_${serialNo.name}_${month.name}.xlsx`);
                                        }
                                    }}
                                    className={'first-btn'}
                                    type="button"
                                    variant="contained"
                                />
                                <ACGButton
                                    name={'Email'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            sendEmail();
                                        }
                                    }}
                                    className={'first-btn'}
                                    variant="contained"
                                />
                                <div
                                    onClick={() => {
                                        crossClick();
                                    }}
                                    style={{
                                        border: '1px solid #5D97F6',
                                        borderRadius: '50px',
                                        height: '25px',
                                        width: '25px',
                                        position: 'relative',
                                        right: '10px',
                                        top: '8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '12px',
                                            color: '#5D97F6',
                                            left: '35%',
                                            top: '-3%'
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            </div>
                        )}
                    </Container>
                </Box>
            </Drawer>
        </div>
    );
}