// import { Button } from "@material-ui/core"
import { Card, Button, Grid } from '@mui/material';
import { DateRange } from 'materialui-daterange-picker';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MuiDateRangePicker } from '../../../../../atoms/DateRangePicker';
import MachineOverviewCard from '../../../../../atoms/MachineOverviewCard';
import MachineOverviewContactCard from '../../../../../atoms/MachineOverviewContactCard';
import { ACTION_CODES, STORE_KEYS } from '../../../../../constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../../../store/slice';
import useGetState from '../../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../../Login/helper';
import RightDrawer from '../../../../../atoms/tempDrawer/indexAlt';
import { fetchBlobImage } from '../../../../../utils/helper';
import dateFormat from 'dateformat';
import './index.scss';
import AuditTrailForm from './AuditTrailForm';
import SPOCDetails from './SPOCDetails';
import { BlobServiceClient, BlobItem } from "@azure/storage-blob";
import { ClassNames } from '@emotion/react';

type Props = { data: any; setSelectedRow?: any };
const Overview = (props: Props) => {
    const { data, setSelectedRow } = props;
    const d1 = new Date();
    const d2 = new Date();
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate:new Date(d1.setDate(d1.getDate() - 15)),
        endDate: new Date(d2.setDate(d2.getDate() - 1))
    });
    const [formOpen, setFormOpen] = useState(false);
    const [configOpen, setConfigOpen] = useState(false);
    const [kpis, setKpis] = useState<any>([]);
    const [spocs, setSpocs] = useState<any>([]);
    const [reload, setReload] = useState(false);
    const state = useGetState();
    const dispatch = useDispatch();
    let machineModelName,blobClient;
    if(state?.[STORE_KEYS.KPI]?.body?.data?.[0].MachineModelImagePath === null || state?.[STORE_KEYS.KPI]?.body?.data?.[0].MachineModelImagePath === undefined) {
        machineModelName = null;
    } else {
        machineModelName =   state?.[STORE_KEYS.KPI]?.body?.data?.[0].MachineModelImagePath?.split('/')[state?.[STORE_KEYS.KPI]?.body?.data?.[0].MachineModelImagePath?.split('/').length - 1]; 
        blobClient = fetchBlobImage(machineModelName);
    } 
    const getKpis = (startDate?: any, endDate?: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_KPI,
                    reqObj: {
                        machineKey: data?.MachineKey,
                        startDate: startDate ? startDate : dateFormat(dateRange.startDate, 'yyyy-mm-dd'),
                        endDate: endDate ? endDate : dateFormat(dateRange.endDate, 'yyyy-mm-dd')
                    }
                },
                uniqueScreenIdentifier: { kpisRecd: true },
                storeKey: STORE_KEYS.KPI
            })
        );
    };
    const getSpocs = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_SPOC_DTL}/${data?.MachineKey}`
                },
                uniqueScreenIdentifier: { spocsRecd: true },
                storeKey: STORE_KEYS.SPOCS
            })
        );
    };
    useEffect(() => {
        getKpis();
        getSpocs();
    }, []);
    useEffect(() => {
        if (data?.reloadTabs) {
            getKpis();
            getSpocs();
            setSelectedRow({ ...data, reloadTabs: false });
        }
    }, [data?.reloadTabs]);
    useEffect(() => {
        if (reload) {
            getSpocs();
            setReload(false);
        }
    }, [reload]);
    useEffect(() => {
        if (state?.[STORE_KEYS.KPI]?.kpisRecd) {
            const kpiList = state?.[STORE_KEYS.KPI]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { KPIName: 'title', KPIValue: 'value' });
                return newVal;
            });
            setKpis(kpiList);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.KPI,
                    uniqueScreenIdentifier: {
                        kpisRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.KPI]?.kpisRecd]);
    useEffect(() => {
        if (state?.[STORE_KEYS.SPOCS]?.spocsRecd) {
            const spocList = state?.[STORE_KEYS.SPOCS]?.body?.data?.map((item: any) => {
                const newVal = { ...item };
                if (item?.SPOCType === 'ACG') {
                    newVal['title'] = 'ACG Spoc';
                } else {
                    newVal['title'] = 'Customer Spoc';
                }
                return newVal;
            });
            setSpocs(spocList);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.SPOCS,
                    uniqueScreenIdentifier: {
                        spocsRecd: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.SPOCS]?.spocsRecd]);

    const rangeChng = (value: any) => {
        getKpis(dateFormat(value?.startDate, 'yyyy-mm-dd'), dateFormat(value?.endDate, 'yyyy-mm-dd'));
        setDateRange(value);
    };
    const openForm = () => {
        setFormOpen(true);
    };
    return (
        <div className="overviewContainer">
            <div className="overviewContainerFlexMain">
                <div className="FlexOne">
                    <div className="topRow daterangeletr">
                        <div>
                            <h6 style={{ color: '#DADCEE', fontSize: '16px', marginBottom: '3px' }}>KPIs</h6>
                            <h6 style={{ color: 'rgb(156 194 255 / 70%)', fontSize: '12px', marginTop: '0px' }}>{`${
                                state?.[STORE_KEYS.KPI]?.body?.data?.length
                            } KPIs`}</h6>
                        </div>
                        <MuiDateRangePicker
                            TextFieldProps={{
                                variant: 'outlined',
                                placeholder: 'Select Date Range'
                            }}
                            dateRange={dateRange}
                            onDateRangeChange={rangeChng}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{
                                backgroundColor: 'rgba(93, 151, 246, 0.08)',
                                width: '240px',
                                fontSize: '11px',
                                height: '40px',
                                fontFamily: 'Montserrat',
                                opacity: '0'
                            }}
                            disabled={true}
                            onClick={openForm}
                        >
                            Download Audit Trail Report
                        </Button>
                    </div>
                    <br />
                    <Grid container spacing={2}>
                        {kpis?.map((ele: any) => {
                            return (
                                <Grid item xs={12} lg={4} md={3} sm={6} key={ele}>
                                    <MachineOverviewCard type="overview" data={ele} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
                <div className="FlexTwo">
                    <h6 style={{ color: '#DADCEE', fontSize: '16px', marginTop: '15px', paddingLeft: '10px' }}>
                        Machines
                    </h6>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                        <img
                            src={blobClient?blobClient.url:''}
                            alt="machine img"
                            style={{
                                // minHeight: '200px',
                                // height: '200px',
                                // minWidth: '100%',
                                // width: '100%',
                                // paddingRight: '10px',
                                // paddingLeft: '10px'
                                maxHeight: '25rem',
                                minWidth: '90%',
                                width: '100%',
                                //paddingRight: '10px',
                                //marginLeft: '7%'
                            }}
                        />
                    </div>
                    <div className="configureRowMainDiv">
                        <div className="configureRow">
                            <h4>SPOC Details</h4>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    height: '30px',
                                    width: '100px',
                                    fontFamily: 'montserrat',
                                    fontSize: '12px',
                                    borderRadius: '6px',
                                    textTransform: 'capitalize'
                                }}
                                onClick={() => setConfigOpen(true)}
                            >
                                Configure
                            </Button>
                        </div>
                        <br />
                        {/* <div className="configureRowChildMain"> */}
                        <Grid container spacing={2}>
                            {spocs?.map((ele: any) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                                        <div className="configureRowChild">
                                            <h5 style={{ color: '#9CC2FF', fontSize: '10px' }}>{ele?.title}</h5>
                                            <MachineOverviewContactCard data={ele} />
                                        </div>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {/* </div> */}
                    </div>
                    <div className="bottomRow">
                        <h5>Warranty</h5>
                        <p>{state?.[STORE_KEYS.KPI]?.body?.data?.[0]?.WarrantyTillDate}</p>
                    </div>
                </div>
            </div>
            {/* <RightDrawer
                handlerOpen={() => {}}
                handlerClose={() => setFormOpen(false)}
                status={formOpen}
                drawerHeader={'Audit Trail Report'}
                children={<AuditTrailForm />}
                subHeader={'Download Excel'}
                isForm={true}
                formData={{ MachineName: data?.MachineName, PlantName: data?.PlantName }}
            /> */}
            <RightDrawer
                handlerOpen={() => {}}
                handlerClose={() => setConfigOpen(false)}
                status={configOpen}
                drawerHeader={data?.MachineName}
                children={<SPOCDetails spocs={spocs} machineKey={data?.MachineKey} setReload={setReload} />}
                subHeader={'SPOC Details'}
            />
        </div>
    );
};

export default Overview;
