// feature/UserReport/DownloadableReport/AuditReport/index
import Drawer from '@mui/material/Drawer';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { FormHelperText} from '@mui/material';
import ACGButton from '../../../../atoms/Button';
import Select from '@mui/material/Select';
import DownArrow from '../../../../../assets/down-arrow.svg';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useRef, useState } from 'react';
import { getLastSixMonths } from "../../../../utils/helper";
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import './index.scss';
import Box from '@mui/material/Box';
import DropDown from '../../../../atoms/Dropdown';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import * as Yup from 'yup';
import { Container } from '@mui/material';
import { executeACGAction, resetStoreKey } from '../../../../store/slice';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import SnackbarAlert from '../../../../atoms/Snackbar';
import ErrorTextLabel from '../../../../atoms/ErrorTextLabel/Index';
import { SnackBarOptions, Dateformat } from '../CommonUtil';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
};

type AuditParams = {
    dateFromSelect: string | '';
    dateToSelect: string | '';
    dropdownPlant: string | '';
    dropdownModel: string | '';
    dropdownSerialNo: string | '';
    // dropdownBatch: any | [];
     modeName: string | '';
};

export default function AuditReportDrawer(props: Props) {

    const { status, handlerClose } = props;

    const [snackbarOptions, setSnackbarOptions] = useState(SnackBarOptions.DEFAULT);
    const closeSnackbar = () => setSnackbarOptions(SnackBarOptions.DEFAULT);

    const drawerClose = () => { handlerClose(); setBatches([]); setSelectedBatch([]), setselectedCategory([]); setNoBatches(false); setNoCategory(false); };

    const [statusMessage, setStatusMessage] = useState<string>();

    const [auditParams, setAuditParams] = useState<AuditParams>({
        dateFromSelect: dateFormat(new Date().setHours(-(24 * 7)), Dateformat.YYYY_MM_DD),
        dateToSelect: dateFormat(new Date().setHours(-24), Dateformat.YYYY_MM_DD),
        dropdownPlant: '',
        dropdownModel: '',
        dropdownSerialNo: '',
        // dropdownBatch: [],
        modeName: ''
    });

    const [plantsList, setPlantsList] = useState<any>();

    const [machineModels, setMachineModels] = useState<any>();
    const [selectedModel, setSelectedModel] = useState<any>();

    const [machineSerialNos, setMachineSerialNos] = useState<any>();
    const [selectedSerialKey, setSelectedSerialKey] = useState<any>();

    const [categories, setCategories] = useState<any>();
    const [batches, setBatches] = useState<any>();
    const [isSubmit, setIsSubmit] = useState<any>(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [isDownloadError, setIsDownloadError] = useState<boolean>(false);

    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    const REPORT_CODE = 'ADTRPT';
    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const [selectedCategory, setselectedCategory] = useState<any>([]);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [reportResponse, setReportResponse] = useState<any>(null);
    const [presetKey, setPresetKey] = useState('');
    const [noBatches, setNoBatches] = useState(true);
    const [noCategory, setNoCategory] = useState(true);
    useEffect(() => {
        const date = new Date();
        setStatusMessage('');
        dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_AUDIT_REPORTS_CATEGORY_DATA }));
        setAuditParams({
            ...auditParams,
            dateFromSelect: dateFormat(new Date(date.getFullYear(), date.getMonth(), 1), Dateformat.YYYY_MM_DD),
            dateToSelect: dateFormat(new Date(), Dateformat.YYYY_MM_DD),
            dropdownPlant: '',
            dropdownModel: '',
            dropdownSerialNo: '',
            // dropdownBatch: []
        });
        resetResult();
        if (status) {
            setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_PLANTS_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA
                })
            );
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'GET',
                        urlPath: ACTION_CODES.DN_AUDIT_REPORTS_CATEGORY_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE
                        }
                    },
                    storeKey: STORE_KEYS.DN_AUDIT_REPORTS_CATEGORY_DATA
                })
            );
        }
    }, [status]);

    useEffect(() => {

        if (state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            });
            setPlantsList(plants);

            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        }
        updateStatusMessage();
    }, [
        state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data
    ]);

    useEffect(() => {

        if (state?.[STORE_KEYS.DN_AUDIT_REPORTS_CATEGORY_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_AUDIT_REPORTS_CATEGORY_DATA]?.body?.data;
            const cats = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { AuditRptCatKey: 'code', CategoryName: 'name' });
                return newVal;
            });

            setCategories(cats);

            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_AUDIT_REPORTS_CATEGORY_DATA }));
        }

        updateStatusMessage();
    }, [
        state?.[STORE_KEYS.DN_AUDIT_REPORTS_CATEGORY_DATA]?.body?.data
    ]);

    const updateStatusMessage = () => {

        const message = plantsList?.length <= 0 ? 'No plants available' :
            machineModels?.length <= 0 ? 'No models available' :
                machineSerialNos?.length <= 0 ? 'No machine serial number available' :
                    categories?.length <= 0 ? 'No categories available' :
                        batches?.length <= 0 ? 'No batches available' : '';

        setStatusMessage(message);

        console.log(statusMessage);
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data;
            const serialNos = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
                return newVal;
            });
            setMachineSerialNos(serialNos);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA }));
        }
        updateStatusMessage();
    }, [
        state?.[STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA]?.body?.data
    ]);

    //---------------------Plant Change---------------------

    useEffect(() => {
        if (auditParams.dropdownPlant) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE,
                            plantKey: auditParams.dropdownPlant.toString()
                        }
                    },
                    storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA
                })
            );
        }
    }, [auditParams.dropdownPlant]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data;
            const models = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModels(models);
            updateStatusMessage();
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_ALMANLS_REPORT_MACHINE_MODEL_DATA]?.body?.data]);

    //---------------------Machine Model Change---------------------

    useEffect(() => {

        selectedSerialKey && auditParams.dateFromSelect && auditParams.dateToSelect && dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_REPORT_BATCH_TYPE,
                    reqObj: {
                        mKey: selectedSerialKey,
                        fDate: dateFormat(auditParams.dateFromSelect, Dateformat.YYYY_MM_DD),
                        tDate: dateFormat(auditParams.dateToSelect, Dateformat.YYYY_MM_DD)
                    }
                },
                storeKey: STORE_KEYS.DN_REPORT_BATCH_TYPE
            })
        );

        auditParams.dropdownPlant && selectedModel && dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_REPORT_LINE_SERIAL_DATA,
                    reqObj: {
                        machineModelKey: selectedModel,
                        plantKey: auditParams.dropdownPlant.toString(),
                        reportCode: REPORT_CODE
                    }
                },
                storeKey: STORE_KEYS.DN_REPORT_LINE_SERIAL_DATA
            })
        );
    }, [selectedSerialKey, auditParams.dropdownPlant, selectedModel, auditParams.dateToSelect, auditParams.dateFromSelect]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_BATCH_TYPE]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_BATCH_TYPE]?.body?.data;
            const dpBatches = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
                return newVal;
            });

            setBatches(dpBatches);

            updateStatusMessage();

            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_BATCH_TYPE }));
        }
    }, [state?.[STORE_KEYS.DN_REPORT_BATCH_TYPE]?.body?.data]);

    useEffect(()=>{
        if(selectedCategory.length === 0) {
            setNoCategory(true);
        } else setNoCategory(false);
        if(selectedBatch.length === 0) {
            setNoBatches(true);
        } else setNoBatches(false);
    },[selectedBatch,selectedCategory])

    const handleSubmit = async (values: any) => {
        if(selectedCategory.length === 0) {
            setNoCategory(true);
            return;
        } else setNoCategory(false);
        if(selectedBatch.length === 0) {
            setNoBatches(true);
            return;
        } else setNoBatches(false);
    
        setIsSubmit(false)
        const batch:any = [], batchName:any = [], cat:any = [], catName:any = [];
        const fromDate = dateFormat(values.dateFromSelect, Dateformat.YYYY_MM_DD);
        const toDate = dateFormat(values.dateToSelect, Dateformat.YYYY_MM_DD);
        const plant = plantsList.find((item: any) => item.code == values.dropdownPlant);
        const model = machineModels.find((item: any) => item.code == values.dropdownModel);
        const serialNo = machineSerialNos.find((item: any) => item.code == values.dropdownSerialNo);
        if(selectedBatch.length === 0) {
            batches.map((i:any)=> {batch.push(i.code); batchName.push(i.name)})
        }else{

            selectedBatch.map((i:any)=> {batch.push(i.code); batchName.push(i.name)})
        }

        if(selectedCategory.length === 0) {
            categories.map((i:any)=> {cat.push(i.code); catName.push(i.name)})
        }else{

            selectedCategory.map((i:any)=> {cat.push(i.code); catName.push(i.name)})
        }

        if (fromDate && toDate && model && cat && batch && serialNo && plant) {

            setIsGenerating(true);

            setStatusMessage('Generating Report, Please Wait...');

            const payload = {
                startDate: fromDate,
                endDate: toDate,
                plant: plant.name,
                machineModel: model.name,
                category: cat.toString(),
                batch: batch.name,
                serialNo: serialNo.name,
                msno: serialNo.name,
                machineKey: serialNo.code,
                batchKey: batch,
                batchNames: batchName,
            };

            const token = state?.[STORE_KEYS.USER_DTL].token;

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_AUDIT_REPORT_DATA,
                method: 'post',
                responseType: 'blob',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    setStatusMessage('');
                    if (response.status == 200 && response?.data) {
                        setReportResponse(response.data);

                    } else if (response.status == 202) {
                        setStatusMessage('No data available. Please try again with correct ranges');
                    }
                })
                .catch((error) => {
                    setStatusMessage('');
                    setStatusMessage('Report Generation Error, Please retry');
                    return {
                        status: error.response.status
                    };
                });
        }
        else {
            alert('Please select all the fields or bad request');
        }
    };

    const sendEmail = () => {

        setSnackbarOptions(SnackBarOptions.SENDING_EMAIL);

        const token = state?.[STORE_KEYS.USER_DTL].token;
        const userEmail = state?.[STORE_KEYS.USER_DTL]?.EmailId;
        const fileName = auditParams.modeName + '_Audit_report.xlsx';

        const formData = new FormData();
        formData.append('file', reportResponse);
        formData.append('filename', fileName);
        formData.append('userId', userEmail);

        setStatusMessage('Sending Email');

        Axios({
            url: BASEURL + '/' + ACTION_CODES.DN_RPT_EMAIL_REPORT,
            method: 'post',
            data: formData,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                setStatusMessage('');
                if (response.status == 200 && response?.data) {
                    setSnackbarOptions(SnackBarOptions.SENT_EMAIL);
                    setIsDownloadError(false);
                } else {
                    setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                }
            })
            .catch((error) => {
                setStatusMessage('');
                setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                return {
                    status: error.response.status
                };
            });
    };

    const resetResult = () => {
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setStatusMessage('');
        setNoBatches(true);
        setNoCategory(true);
        setIsSubmit(false);
        // setBatches([]);
        // setSelectedBatch([]);
    };
    const crossClick = () => {
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setIsSubmit(false);
    };
    const handleChangeBatch = (e:any, id: any) => {
        if(selectedBatch.includes(id) || selectedBatch.length < 3){
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        console.log(newSelected)
        setSelectedBatch(newSelected);
    }
    }

    const handleChangeCategory = (e:any, id: any) => {
        if(selectedCategory.includes(id) || selectedCategory.length < 3){
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedCategory.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedCategory, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedCategory.slice(1));
            } else if (selectedIndex === selectedCategory.length - 1) {
                newSelected = newSelected.concat(selectedCategory.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedCategory.slice(0, selectedIndex),
                    selectedCategory.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedCategory.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedCategory, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedCategory.slice(1));
            } else if (selectedIndex === selectedCategory.length - 1) {
                newSelected = newSelected.concat(selectedCategory.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedCategory.slice(0, selectedIndex),
                    selectedCategory.slice(selectedIndex + 1)
                );
            }
        }
        console.log(newSelected)
        setselectedCategory(newSelected);
    }
    }

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Drawer anchor={'right'} open={status} onClose={drawerClose} style={{ zIndex: '9999' }} className='allreports'>
                <Box role="presentation" className={'standardBox1'}>
                    <div className="baseDiv">
                        <div
                            onClick={() => {
                                drawerClose();
                            }}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '25px',
                                position: 'absolute',
                                left: '20px',
                                top: '16px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%'
                                // padding:'10px'
                            }}
                        >
                            <div style={{ display: 'block', marginLeft: '45px' }}>
                                <h6
                                    style={{
                                        color: '#DADCEE',
                                        fontSize: '16px',
                                        fontFamily: 'Montserrat'
                                        // marginTop: '-7px'
                                    }}
                                >
                                    Audit Report
                                </h6>
                            </div>
                        </div>
                    </div>
                    <Container style={{ backgroundColor: '#2b2d42' }} className={'alm-dn-report-form1'}>
                        {/* <div className={'dn-rp-heading-label'}>Audit Report</div> */}
                        <Formik
                            initialValues={auditParams}
                            validationSchema={validationSchema}
                            onSubmit={(values: any) => {
                                handleSubmit(values);
                            }}
                            validateOnBlur={false}
                            validateOnChange={true}
                            innerRef={formRef}
                        >
                            {(formikprops: FormikProps<any>) => {
                                const { values, handleChange, handleBlur, isValid, errors, touched, setTouched, setFieldValue, resetForm } =
                                    formikprops;
                                return (
                                    <Form className="detailForm">
                                        <Label label="From Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={values.dateFromSelect}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setSelectedBatch([]);
                                                setselectedCategory([]);
                                                setBatches(null);
                                                // setFieldValue('dropdownBatch', []);

                                                setFieldValue('dateFromSelect', val);

                                                setAuditParams({
                                                    ...auditParams,
                                                    dateFromSelect: val
                                                });
                                                resetResult();
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select From Date"
                                            minDate={getLastSixMonths()}
                                        />
                                        {errors?.dateFromSelect && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {errors?.dateFromSelect}
                                                </span>
                                            </div>
                                        )}
                                        <Label label="To Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateToSelect"
                                            value={values.dateToSelect}
                                            className="inputDiv"
                                            onChange={async (val: any) => {
                                                setSelectedBatch([]);
                                                setselectedCategory([]);
                                                setBatches(null);
                                                // setFieldValue('dropdownBatch', []);
                                                setTouched({}, false);
                                                await setFieldValue('dateToSelect', val);
                                                setAuditParams({
                                                    ...auditParams,
                                                    dateToSelect: val
                                                });

                                                resetResult();
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="dateToSelect"
                                            minDate={values.dateFromSelect}
                                        />
                                        {errors?.dateToSelect && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {errors?.dateToSelect}
                                                </span>
                                            </div>
                                        )}
                                        <Label label="Plants" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(plantEvent) => {
                                                handleChange(plantEvent);
                                                setSelectedBatch([]);
                                                setselectedCategory([]);
                                                const plant = plantsList.find(
                                                    (item: any) => item.code === plantEvent.target.value
                                                );

                                                if (plant) {

                                                    setSelectedModel(null);
                                                    setMachineModels(null);
                                                    setFieldValue('dropdownModel', '');

                                                    setMachineSerialNos(null);
                                                    setMachineSerialNos(null);
                                                    setFieldValue('dropdownSerialNo', '');

                                                    setAuditParams({ ...auditParams, dropdownPlant: plant.code })

                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownPlant"
                                            items={plantsList}
                                            placeHolder="Select Plant"
                                            value={values.dropdownPlant}
                                            helperText={
                                                errors.dropdownPlant && touched.dropdownPlant ? errors.dropdownPlant : ''
                                            }
                                        />

                                        <Label label="Machine Model" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(modelEvent: any) => {
                                                setSelectedBatch([]);
                                                setselectedCategory([]);
                                                handleChange(modelEvent);
                                                const model = machineModels.find(
                                                    (item: any) => item.code === modelEvent.target.value
                                                );
                                                if (model) {

                                                    setBatches(null);
                                                    // setFieldValue('dropdownBatch', []);

                                                    setSelectedModel(model.code.toString());
                                                    setAuditParams({ ...auditParams, modeName: model.name });
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={machineModels}
                                            placeHolder="Select Machine Model"
                                            value={values.dropdownModel}
                                            helperText={
                                                errors.dropdownModel && touched.dropdownModel ? errors.dropdownModel : ''
                                            }
                                        />

                                        <Label label="Line / Serial Number" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(snEvent: any) => {
                                                setSelectedBatch([]);
                                                setselectedCategory([]);
                                                handleChange(snEvent);
                                                const SerialKey = machineSerialNos.find(
                                                    (item: any) => item.code === snEvent.target.value
                                                );
                                                if (SerialKey) {
                                                    setSelectedSerialKey(SerialKey.code);
                                                }
                                                resetResult();
                                            }}
                                            className="inputDiv"
                                            name="dropdownSerialNo"
                                            items={machineSerialNos}
                                            placeHolder="Select Line / Serial Number"
                                            value={values.dropdownSerialNo}
                                            helperText={
                                                errors.dropdownSerialNo && touched.dropdownSerialNo ? errors.dropdownSerialNo : ''
                                            }
                                        />

                                         <div className='labelfixed'>
                                        <Label label="Category" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedCategory?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Select Category
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}
                                                value={selectedCategory}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select Batches'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}

                                            >
                                                {categories?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e:any) => { handleChangeCategory(e,i);  }}
                                                        // disabled={selectedBatch.length < 3 ? false : true}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedCategory.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {isSubmit && noCategory === true ? <FormHelperText style={{color:"#F06868",
                                            fontSize: "11px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                            }}>Please select category</FormHelperText>: null}
                                           
                                        </FormControl>
                                        </div>
                                        <div className='labelfixed'>
                                        <Label label="Select Batch (Max-3)" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedBatch?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Select Batch
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}
                                                value={selectedBatch}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select Batches'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}

                                            >
                                                {batches?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e:any) => { handleChangeBatch(e,i);  }}
                                                        // disabled={selectedBatch.length < 3 ? false : true}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedBatch.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {isSubmit && noBatches === true ? <FormHelperText style={{color:"#F06868",
                                            fontSize: "11px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 600,
                                            }}>Please select batch</FormHelperText>: null}
                                          
                                        </FormControl>
                                        </div>
                                        {!isGenerating && !reportResponse && (<div className={'btns noWrap'}>
                                            <ACGButton
                                                name={'Reset'}
                                                handleClick={() => {
                                                    setAuditParams({
                                                        ...auditParams,
                                                        dateFromSelect: dateFormat(new Date().setHours(-(24 * 7)), 'yyyy-mm-dd'),
                                                        dateToSelect: dateFormat(new Date().setHours(-24), 'yyyy-mm-dd'),
                                                        dropdownPlant: '',
                                                        dropdownModel: '',
                                                        dropdownSerialNo: '',
                                                        // dropdownBatch: []
                                                    });
                                                    resetForm();
                                                    resetResult();
                                                    setSelectedBatch([]);
                                                    setselectedCategory([]);
                                                    setNoBatches(false);
                                                    setNoCategory(false);
                                                }}
                                                secondary={true}
                                                className={'first-btn'}
                                                type="button"
                                                variant="contained"
                                            />
                                            <ACGButton
                                                name={'Submit'}
                                                handleClick={() => {
                                                setIsSubmit(true) }
                                            }
                                                type="submit"
                                                className="second-btn"
                                                variant="contained"
                                            />
                                        </div>)}
                                    </Form>
                                );
                            }}
                        </Formik>
                        {isGenerating && !reportResponse && <Label label="Generating Report ..." className={'labelDiv'} />}

                        {isDownloadError && (
                            <Label label="Report Generation Error, Please retry" className={'labelDiv'} />
                        )}

                        {reportResponse && (
                            <div className={'btns noWrap'} style={{ position: 'relative' }}>
                                <ACGButton
                                    name={'Download'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            FileDownload(reportResponse, auditParams.modeName + '_Audit_report.xlsx');
                                        }
                                    }}
                                    className={'first-btn'}
                                    type="button"
                                    variant="contained"
                                />
                                <ACGButton
                                    name={'Email'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            sendEmail();
                                        }
                                    }}
                                    className={'first-btn'}
                                    variant="contained"
                                />
                                <div
                                    onClick={() => {
                                        crossClick();
                                    }}
                                    style={{
                                        border: '1px solid #5D97F6',
                                        borderRadius: '50px',
                                        height: '25px',
                                        width: '25px',
                                        position: 'relative',
                                        right: '10px',
                                        top: '8px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '12px',
                                            color: '#5D97F6',
                                            left: '35%',
                                            top: '-3%'
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            </div>
                        )}
                    </Container>
                </Box>
            </Drawer>
        </div>
    );
}
