import React from 'react';
import { RouteProps, Redirect, Route, withRouter } from 'react-router-dom';
import { getFromLocalStorage } from '../../utilities/storageUtility';
import PageNotFound from '../atoms/PageNotFound';
import { STORE_KEYS } from '../constants/apiConstants';
import useGetState from '../utils/hooks/useGetState';
interface PrivateRouteProps extends RouteProps {
    component: React.FC<RouteProps>;
    path: string;
}

const PrivateRoute = ({ component: Component, path, ...rest }: PrivateRouteProps) => {
    const accessToken = getFromLocalStorage('token');
    const state = useGetState();
    const isAccess = state?.[STORE_KEYS.ROUTES_PRIVILEGES]?.some((item: any) => item?.path === path) || (accessToken && path === '/machine-monitoring/alarm-detail') ||
        (accessToken && path === '/machine-monitoring/event-detail') ||
        (accessToken && path === '/machine-monitoring/machine-detail');

    return (
        <Route
            {...rest}
            path={path}
            render={(props: any) =>
                accessToken && accessToken !== '' ? (
                    isAccess ? (
                        <Component {...props} />
                    ) : (
                        <PageNotFound />
                    )
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
};
export default PrivateRoute;
