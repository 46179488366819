export const BASEURL = '/api';

export const ACTION_CODES = {
    LOGIN: 'login',
    SUBMODULE_ACCESS: 'submodule-access',
    GETQA: 'getQA',
    CHANGEPWD: 'resetPwd',
    UPDATEPWD: 'changePwd',
    ADDQA: 'addQA',
    PWDVRFY: 'verifyPwd',
    FORGOTPWD: 'forgotPwd',
    GET_CLIENT_DROPDOWN: 'customers/get-customers-of-user',
    GET_CLIENTS: 'customers/get-customers-details',
    GET_AUDIT: 'auditTrail/get-userTracking-details',
    GET_SYSTEMCONFIG: 'systemConfig/get-systemConfig-details',
    GET_CLNTFEATURES: 'customers/get-customer-features-details',
    GET_CONTACT_DETAILS: 'remote-assistance/get-remoteCall-user-mapping',
    DELETE_CLIENT: 'customers/delete-customer',
    GET_CLIENT_SUMMARY: 'customers/get-customers-summary',
    GET_CLIENT_FEATURES: 'customers/get-all-customer-features',
    ADD_CLIENT: 'customers/add-customer',
    ADD_SYSCONFIG: 'systemConfig/add-systemConfig',
    EDIT_SYSCONFIG: 'systemConfig/edit-systemConfig',
    EDIT_CLIENT: 'customers/edit-customer',
    GET_PLANTS: 'plants/get-plants-details',
    GET_PLANT_LINES: 'plants/get-plant-lineNo-details',
    ADD_PLANT: 'plants/add-plant',
    EDIT_PLANT: 'plants/edit-plant',
    ADD_PLANTLINE: 'plants/add-plant-lineNo',
    DELETE_PLANT: 'plants/delete-plant',
    DELETE_SYSCONFIG: 'systemConfig/delete-systemConfig/',
    GET_FEATURE_SMRY: 'features/get-features-summary',
    ADD_REPORT: 'features/add-report',
    EDIT_REPORT: 'features/edit-report',
    GET_RPT: 'features/get-reports-details',
    GET_USER_SUMMARY: 'users/get-users-summary',
    GET_CONTACT_SUMMARY: 'remote-assistance/get-remoteCall-users',
    GET_USERS: 'users/get-users',
    DELETE_USER: 'users/delete-user/',
    UNLOCK_USER: 'unlock-user-account/',
    ACTIVATE_USER: 'users/activate-user/',
    INACTIVATE_USER: 'users/inactivate-user/',
    DELETE_CONTACT: 'remote-assistance/delete-remoteCall-user/',
    DELETE_MAP_CONTACT: 'remote-assistance/delete-remoteCall-user-mapping/',
    GET_ROLES: 'roles/get-role-details/',
    GET_CONTACTS: 'remote-assistance/get-remoteCall-users',
    DELETE_ROLE: 'roles/delete-cust-role/',
    DELETE_RPT: 'features/delete-report',
    GET_MODULES: 'features/get-modules-details',
    ADD_MODULE: 'features/add-module',
    EDIT_MODULE: 'features/edit-module',
    DELETE_MODULE: 'features/delete-module',
    GET_MENUS: 'features/get-menus-details',
    GET_COUNTRIES: 'plants/get-country-dropdown',
    GET_TIMEZONES: 'plants/get-timezone-dropdown',
    GET_LOCATIONS: 'plants/get-location-dropdown',
    GET_DASHBOARD: 'features/get-dashboards-details',
    ADD_MENU: 'features/add-menu',
    DELETE_MENU: 'features/delete-menu',
    EDIT_MENU: 'features/edit-menu',
    GET_MODULES_DN: 'features/get-modules-dropdown',
    ADD_FEATURES: 'customers/customer-features-mapping',
    MACHMON_SMRY: 'machine-monitoring/get-machine-summary',
    ACTIVE_ALARMS: 'machine-monitoring/get-active-alarms',
    GET_EVENTS: 'machine-monitoring/get-events',
    GET_CONDLOGS: 'machine-monitoring/get-cond-monitoring-log',
    GET_MACHINES: 'machine-monitoring/get-all-machines-details',
    MARK_FAV: 'machine-monitoring/mark-favouriteMachine',
    UNMARK_FAV: 'machine-monitoring/unMark-favouriteMachine',
    MARK_USER_FAV: 'remote-assistance/mark-favourite',
    UNMARK_USER_FAV: 'remote-assistance/unMark-favourite',
    GET_KPI: 'machine-monitoring/get-overview-kpi',
    GET_SPOC_DTL: 'machine-monitoring/get-spoc-details',
    GET_PARAMGRP: 'machine-monitoring/get-parameter-group',
    GET_PARAMS: 'machine-monitoring/get-machine-parameters-details',
    GET_TSGRP: 'machine-monitoring/get-time-series-group',
    GET_CALLHISTORY: 'remote-assistance/get-call-history',
    GET_TS: 'machine-monitoring/get-time-series-parameters-details',
    GET_COUNTRY_CODE: '/get-country-phone-code',
    ADD_ACCESS_TO_USER: 'users/assign-user-access',
    ADD_USER: 'users/add-user',
    EDIT_USER: 'users/edit-user',
    GET_USER_ACCESS_DETAILS: 'users/get-users-access-details',
    GET_ALL_PLANTS: 'customers/get-customers-and-plants',
    GET_PLANTS_OF_CUSTOMER: 'customers/get-customers-and-plants-based-on-customer',
    GET_ROLES_DETAILS: 'roles/get-role-features-details',
    GET_ROLE_FEATURES: 'roles/get-all-features',
    ADD_ROLE_FEATURES: 'roles/role-features-mapping',
    ADD_ROLE: 'roles/add-cust-role',
    EDIT_ROLE: 'roles/edit-cust-role',
    GET_ALL_ACTIVE_ALARMS: 'machine-monitoring/get-active-alarms',
    GET_ACTIVE_ALARMS_OF_MACHINE: 'machine-monitoring/get-active-alarms-of-machine',
    GET_ALL_ALARMS_OF_MACHINE: 'machine-monitoring/get-all-alarms-of-machine',
    GET_ALARM_DETAILS_OF_MACHINE: 'machine-monitoring/get-alarm-details-of-machine',
    GET_TIMER_PERIOD_DROPDWON: 'machine-monitoring/get-alarm-event-time-period-dropdown',
    GET_SEVERITY_DROPDOWN: 'machine-monitoring/get-alarm-event-severity-dropdown',
    GET_TP: 'machine-monitoring/get-time-series-time-periods',
    GET_TS_DTL: 'machine-monitoring/get-time-series-details',
    GET_ALL_ACTIVE_ALARMS_OF_ALARM: 'machine-monitoring/get-all-active-alarms',
    GET_ALL_ALARMS_OF_MODEL: 'machine-monitoring/get-all-alarms-of-model',
    GET_ALARM_DETAILS_OF_MODEL: 'machine-monitoring/get-alarm-details-of-model',
    GET_ALL_EVENTS_OF_MACHINE: 'machine-monitoring/get-all-events',
    GET_EVENTS_DETAILS_OF_MACHINE: 'machine-monitoring/get-event-details',
    GET_ALL_EVENTS__OF_MODEL: 'machine-monitoring/get-all-events-of-model',
    GET_EVENTS_DETAILS__OF_MODEL: 'machine-monitoring/get-event-details-of-model',
    GET_EVENTS_SUMMARY: 'machine-monitoring/get-events-summary',
    GET_ALARM_SUMMARY: 'machine-monitoring/get-alarms-summary',
    ADD_SPOC: 'machine-monitoring/add-spoc',
    EDIT_SPOC: 'machine-monitoring/edit-spoc',
    DELETE_SPOC: 'machine-monitoring/delete-spoc',
    GET_MACHINEMODEL_DROPDOWN: 'machines/get-machineModel-dropdown',
    GET_MACHINE_FAMILY: 'machines/get-machineFamily-dropdown',
    MACHINE_SMRY: 'machines/get-machineModel-summary',
    GET_MODELS: 'machines/get-machineModel-details',
    GET_MODEL_PARMS: 'machines/get-machineModelParameters',
    ADD_MACHINES: 'machines/add-machine',
    EDIT_MACHINES: 'machines/edit-machine',
    ADD_MACHINE_PARAMS: 'machines/add-machineParameters',
    GET_MACHINE_SEVERITY_DROPDOWN: 'machines/get-severity-dropdown',
    GET_MACHINE_DATA_TYPE_DROPDOWN: 'machines/get-datatype-dropdown',
    GET_MACHINE_CATEGORY_DROPDOWN: 'machines/get-category-dropdown',
    ADD_CONTACT: 'remote-assistance/add-remoteCall-user',
    EDIT_CONTACT: 'remote-assistance/edit-remoteCall-user',
    ADD_CONTACT_MAP: 'remote-assistance/remoteCall-user-mapping',
    GET_PLANT_DROPDOWN: 'plants/get-plants-dropdown',
    GET_MACHINE_DROPDOWN: 'remote-assistance/get-machines-dropdown',
    DELETE_MODEL: 'machines/delete-machineModel',
    GET_ALLMACHINES: 'machines/get-machine-details',
    DELETE_MACHINE: 'machines/delete-machine',
    MACH_FAMILY: 'machines/get-machineFamily-dropdown',
    RPTS_PWRBI_ACCESS_TOKEN: 'powerbi/accessToken',
    ADMIN_DOWNLOADABLE_REPORTS: 'features/get-downloadableReports-details',
    ADMIN_EDIT_DOWNLOADABLE_REPORTS: 'features/get-downloadableReports-details',
    ADMIN_DELETE_DOWNLOADABLE_REPORTS: 'features/delete-downloadableReports',
    GET_MACHINE_SUMMARY_DETAILS: 'machines/get-details-of-a-machine',
    GET_MACHINE_PARMS_DETAIL: 'machines/get-machineParameters',
    UNASSIGN_MACHINE: 'machines/unassign-machine',
    GET_PLANTS_FROMCLIENT: 'machines/get-plant-dropdown',
    GET_PLANTLINES_FROMPLANT: 'machines/get-plantLineNo-dropdown',
    MACHINE_ONBOARD: 'machines/machineOnBoarding',
    MACHINE_EDITONBOARD: 'machines/edit-machineOnBoarding',
    ADD_MACHINE_MODEL: 'machines/add-machineModel',
    ADD_MACHINE_MODEL_PARAMETER: 'machines/add-machineModelParameter',
    MACHINES_EDIT_MACHINEMODEL: 'machines/edit-machineModel',
    EDIT_MACHIN_EMODEL_PARAMETER: 'machines/edit-machineModelParameter',
    MACHINES_GET_PARAMGROUP_DETAILS: 'machines/get-paramGroup-details',
    ADD_PARAM_GROUP: 'machines/add-paramGroup',
    EDIT_PARAM_GROUP: 'machines/edit-paramGroup',
    MACHINES_GET_MM_PARAMETERS_NAME: 'machines/get-mmParameters-name',
    MACHINES_PARAMETERS_MAPPING: 'machines/paramGroup-parameters-mapping',
    GET_PARAM_GROUP_DETAILS: 'machines/get-paramGroup-parameters-details',
    GET_DNWDRPTS_ROLE_BASED: 'features/get-feature-downloadableReports-based-on-roles',

    DN_REPORT_PLANTS_DATA: 'report-plants-data',
    DN_ALMANLS_REPORT_MACHINE_MODEL_DATA: 'report-machine-model-data',
    DN_ALMANYS_BQS_MQR_SUMMARY: 'bqsMpr/reportPageAlarmAnalysisSummary',
    DN_ALMANYS_BQS_MQR_DESC_SUMMARY: 'bqsMpr/reportPageAlarmAnalysisAlarmDescWiseSummary',
    DN_ALMANYS_BQS_MQR_DATE_SUMMARY: 'bqsMpr/eReportPageAlarmAnalysisAlarmDateWiseSummary',

    //Alarm report
    DN_ALARM_REPORT_MONTH_YEAR: 'alarm-report-month-year',
    DN_ALARM_REPORT_PLANTS:'alarm-report-plants-data',
    DN_ALARM_REPORT_MACHINE_MODELS:'alarm-report-machine-models',
    DN_ALARM_REPORT_MACHINES:'alarm-report-machines',
    DN_ALARM_REPORT_BATCHES:'alarm-report-batches',

    DN_GET_REPORT_TYPES: 'report-types-data',
    DN_MPRBQS_MASTER_DATA: 'bqsMpr/filterParamAllMasterData',
    DN_LINE_SERIAL_DATA: 'report-line-serial-data',
    DN_MQR_USERINFO: 'bqsMpr/userInfo',
    DN_AUDIT_REPORTS_CATEGORY_DATA: 'audit-categories-data',
    DN_BATCH_REPORT_DATA: 'batch_report_data',
    DN_ALARM_REPORT_DATA: 'alarm_report_data',
    DN_AUDIT_REPORT_DATA: 'audit_report_data',
    DN_RAW_DATA_REPORT: 'raw-data-report',

    DN_REPORT_CATAGORY: 'machines/get-category-dropdown',
    DN_REPORT_BATCH_TYPE: 'batch_type',
    DN_REPORT_LINE_SERIAL_DATA: 'report-line-serial-data',
    DN_REPORT_BATCH_DETAILS: 'bqsMpr/FReportPageBQSBatchDetails',
    DN_REPORT_OPTIONAL_PARAMS: 'opt_parameter_data',
    DN_RPT_DOWNTIME_FILTER_DATA: 'zydus_filter',
    DN_RPT_DOWNTIME_REPORT_DATA: 'zydus_dt_report_data',
    GET_UC_BC: 'broadcast/get-broadcast-details',
    GET_BC_CLIENTS: 'machines/get-customer-dropdown',
    ADD_BC: 'broadcast/add-broadcast',
    EDIT_BC: 'broadcast/edit-broadcast',
    GET_BC_MAPPINGS_CLIENT: 'broadcast/get-broadcast-customer-mappings',
    DELETE_UC_BC: 'broadcast/delete-broadcast',
    BC_SUMMARY: 'broadcast/get-broadcast-summary',
    GET_SAS_TOKEN: 'machines/get-sas-token',
    GET_CRR_BRDCAST: 'broadcast/get-current-broadcast',
    DN_RPT_EMAIL_REPORT: 'email_report',
    ADD_CALL_HISTORY: 'remote-assistance/add-call-history',

    GET_RP_USER_REPORTS: 'features/get-feature-reports-based-on-roles',
    GET_RP_USER_DASHBOARDS: 'features/get-feature-dashboards-based-on-roles',
    //Maintenance Module end points
    GET_BREAKDOWN_MAINTENANCE_ASSETS: 'get-breakdown-maintenance-assets',
    GET_BREAKDOWN_MACHINE_DETAILS: 'get-breakdown-machine-details',
    GET_BREAKDOWN_MAINTENANCE_CATEGORY: 'get-breakdown-category',
    GET_BREAKDOWN_COMPONENTS: 'get-breakdown-components',
    GET_BREAKDOWN_SUB_COMPONENTS: 'get-breakdown-sub-components',
    GET_BREAKDOWN_MAINTENANCE_ACTION_TYPE: 'get-breakdown-action-type',
    GET_BREAKDOWN_SPARE_PARTS: 'get-breakdown-spare-parts',
    GET_BREAKDOWN_ROOT_CAUSE: 'get-breakdown-root-cause',
    GET_BREAKDOWN_ROOT_CAUSE_SUB: 'get-breakdown-root-cause-sub',
    ADD_BREAKDOWN_MAINTENANCE: 'add-breakdown-maintenance',
    GET_BREAKDOWN_MAINTENANCE_LIST: 'get-breakdown-maintenance-list',
    GET_BREAKDOWN_MAINTENANCE_LIST_DETAILS: 'get-breakdown-maintenance-list-details',
    DELETE_MAINTENANCE_MODULE: 'delete-maintenance-module',
    TOGGLE_MAINTENANCE_MODULE_STATUS: 'toggle-maintenance-module-status',
    GET_SERVICE_REQUEST_PRIORITIES: 'get-service-request-priorities',
    ADD_SERVICE_REQUEST: 'add-service-request',
    GET_SERVICE_REQUEST_LIST: 'get-service-request-list',
    GET_SERVICE_REQUEST_LIST_DETAILS: 'get-service-request-details',
    GET_SERVICE_REQUEST_USER_LIST: 'get-service-request-user-list',
    ADD_SERVICE_REQUEST_USER_ASSIGNMENT: 'add-service-request-user-assign',
    ADD_SERVICE_REQUEST_USER_ACTION: 'add-service-request-user-action',
    ADD_SERVICE_REQUEST_CLOSE: 'add-service-request-close',
    GET_PREVENTIVE_MAINT_CATEGORY: 'get-preventive-maint-category',
    GET_PREVENTIVE_MAINT_PERIOD: 'get-preventive-maint-period',
    ADD_PREVENTIVE_MAINTENANCE: 'add-preventive-maint',
    ADD_PREVENTIVE_MAINTENANCE_EXCEL: 'add-preventive-maint-excel',
    ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE: 'add-inspection-list-preventive-maint',
    GET_PREVENTIVE_INSPECTION_LIST_MAINTENANCE: 'get-inspection-list-preventive-maint',
    GET_PREVENTIVE_UPDATED_INSPECTION_LIST_MAINTENANCE: 'get-updated-inspection-list-preventive-maint',
    UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE: 'update-inspection-list-preventive-maint',
    GET_PREVENTIVE_MAINTENANCE_LIST: 'get-preventive-maint-list',
    GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS: 'get-preventive-maint-list-details',
    GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST: 'get-preventive-maint-workorder-list',
    GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS: 'get-preventive-maint-workorder-list-details',
    ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER: 'add-preventive-maint-close-order',
    GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER_LIST: 'get-preventive-maint-workorder-user-list',
    GET_PREVENTIVE_MAINTENANCE_USER_LIST: 'get-preventive-maint-user-list',
    ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER: 'assign-preventive-maint-workorder-user',
    ASSIGN_PREVENTIVE_MAINTENANCE_USER: 'assign-preventive-maint-user',
    GET_PREVENTIVE_MAINTENANCE_ACTION_TYPE: 'get-preventive-maint-action-type',
    API_RULE_ENGINE_CUSTOMERS: "/api/get_rule_engine_customers",
    API_RULE_ENGINE_CUSTOMER_MODELS: "/api/get_rule_engine_customer_models",
    API_RULE_ENGINE_NOTIFICATION_GROUPS: "/api/get_notification_groups",
    API_RULE_ENGINE_CREATE_NOTIFICATION_GROUP: "/api/create_notification_group",
    API_RULE_ENGINE_PORTAL_USERS: "/api/portal_user_list",
    API_RULE_ENGINE_DELETE_NMGROUP: "/api/delete_notification_member_group",
    API_RULE_ENGINE_ADD_MEMBER: "/api/add_group_member",
    API_RULE_ENGINE_LIST_MEMBERS: "/api/list_group_members",
    API_RULE_ENGINE_PLANTS: "/api/get_rule_engine_plants",
    API_RULE_ENGINE_ALERT_LIST: "/api/get_alerts_list",
    API_RULE_ENGINE_PRESET_ALERT_LIST: "/api/get_preset_alerts_list",
    API_RULE_ENGINE_MACHINE_NAME_LIST: "/api/get_machine_name_list",
    API_RULE_ENGINE_RECIPE_LIST: "/api/get_recipe_list",
    API_RULE_ENGINE_SEVERITY_LIST: "/api/get_severity_list",
    API_RULE_ENGINE_TIME_DURATION: "/api/get_time_duration",
    API_RULE_ENGINE_CUSTOMER_NGROUPS: "/api/get_customer_ngroups",
    API_RULE_ENGINE_CREATE_PRESET_ALERT: "/api/create_preset_alert",
    API_RULE_ENGINE_PRESET_RULE_CONDITIONS: "/api/get_preset_alert_conditions",
    API_RULE_ENGINE_PRESET_RULE_DETAILS: "/api/get_preset_alert_details",
    API_RULE_ENGINE_CUSTOM_RULE_CONDITIONS: "/api/get_custom_alert_conditions",
    API_RULE_ENGINE_TOGGLE_ALERT_STATUS: "/api/toggleRuleAlertStatus",
    API_RULE_ENGINE_CUSTOM_GROUP_NAMES: "/api/get_custom_group_names",
    API_RULE_ENGINE_CUSTOM_PARAMETERS: "/api/get_custom_parameters",
    API_RULE_ENGINE_CUSTOM_OPERATORS: "/api/get_custom_operators",
    API_RULE_ENGINE_PARAMETER_VALUE_TYPES: "/api/get_custom_param_vtypes",
    API_RULE_ENGINE_CREATE_CUSTOM_ALERT: "/api/create_custom_alert",
    API_RULE_ENGINE_CUSTOM_RULE_DETAILS: "/api/get_custom_alert_details1",
    API_RULE_ENGINE_NOTIFICATIONS: "/api/get_rule_engine_notifications",
    API_RULE_ENGINE_NOTIFICATION_DETAILS: "/api/get_rule_engine_notification_details",
    API_RULE_ENGINE_RANGE_DETAILS: "/api/get_custom_alert_range_limit",
    API_TRAIL_ANALYSIS_PROCESS: "/api/get_trail_analysis_process",
    API_TRAIL_ANALYSIS_CPP: "/api/get_trail_analysis_cpp",
    API_TRAIL_ANALYSIS_CUSTOMERS: "/api/get_trail_analysis_customers",
    API_TRAIL_ANALYSIS_PRODUCTS: "/api/get_trail_analysis_products",
    API_TRAIL_ANALYSIS_RECIPIE: "/api/get_trail_analysis_recipie",
    API_TRAIL_ANALYSIS_CREATE: "/api/create_trail_analysis",
    API_TRAIL_ANALYSIS_LIST: "/api/get_trail_analysis_list",
    API_TRAIL_ANALYSIS_DETAILS: "/api/get_trail_analysis_details",
    API_TRAIL_ANALYSIS_RAW_MATERIALS: "/api/get_trail_analysis_raw_materials",
    API_TRAIL_ANALYSIS_CREATE_RAW_MATERIALS: "/api/create_trail_analysis_raw_materials",
    API_TRAIL_ANALYSIS_GET_RAW_MATERIALS_INFO: "/api/get_trail_analysis_raw_materials_info",
    API_TRAIL_ANALYSIS_CREATE_RAW_MATERIALS_VALUES: "/api/create_trail_analysis_raw_materials_values",
    API_TRAIL_ANALYSIS_PSDDATA: "/api/get_trail_analysis_psd_data",
    API_TRAIL_ANALYSIS_CREATE_CQA: "/api/create_trail_analysis_cqa",
    API_TRAIL_ANALYSIS_CQA: "/api/get_trail_analysis_cqa",
    API_TRAIL_ANALYSIS_BPR_OPERATORS: "/api/get_trail_analysis_bpr_operators",
    API_TRAIL_ANALYSIS_BPR_INFO: "/api/get_trail_analysis_bpr_info",
    API_TRAIL_ANALYSIS_CREATE_BPR_LIMIT_VALUES: "/api/create_trail_analysis_bpr_limit_values",
    API_TRIAL_ANALYSIS_DOWNLOAD_TEMPLATE: "/api/download_trial_analysis_template",
    API_TRIAL_ANALYSIS_VALIDATE_TEMPLATE : "/api/validate_trial_analysis_template",
    API_TRIAL_ANALYSIS_UPLOAD_TEMPLATE: "/api/upload_trial_analysis_template",
    API_TRAIL_ANALYSIS_DELETE_TRIAL : "/api/delete_trial_analysis_trial",
    API_TRIAL_ANALYSIS_DOWNLOAD_REPORT: "/api/download_trial_analysis_report",
    API_FMEA_CUSTOMERS: "/api/get_fmea_customers",
    API_FMEA_CUSTOMER_MODELS: "/api/get_fmea_customer_models",
    API_FMEA_NOTIFICATION_GROUPS: "/api/get_fmea_notification_groups",
    API_FMEA_CREATE_NOTIFICATION_GROUP: "/api/create_fmea_notification_group",
    API_FMEA_PORTAL_USERS: "/api/portal_fmea_user_list",
    API_FMEA_DELETE_NMGROUP: "/api/delete_fmea_notification_member_group",
    API_FMEA_ADD_MEMBER: "/api/add_fmea_group_member",
    API_FMEA_LIST_MEMBERS: "/api/list_fmea_group_members",
    API_FMEA_PLANTS: "/api/get_fmea_plants",
    API_FMEA_ALERT_LIST: "/api/get_fmea_alerts_list",
    API_FMEA_PRESET_ALERT_LIST: "/api/get_fmea_preset_alerts_list",
    API_FMEA_MACHINE_NAME_LIST: "/api/get_fmea_machine_name_list",
    API_FMEA_RECIPE_LIST: "/api/get_fmea_recipe_list",
    API_FMEA_SEVERITY_LIST: "/api/get_fmea_severity_list",
    API_FMEA_TIME_DURATION: "/api/get_fmea_time_duration",
    API_FMEA_CUSTOMER_NGROUPS: "/api/get_fmea_customer_ngroups",
    API_FMEA_CREATE_PRESET_ALERT: "/api/create_fmea_preset_alert",
    API_FMEA_PRESET_RULE_CONDITIONS: "/api/get_fmea_preset_alert_conditions",
    API_FMEA_PRESET_RULE_DETAILS: "/api/get_fmea_preset_alert_details",
    API_FMEA_CUSTOM_RULE_CONDITIONS: "/api/get_fmea_custom_alert_conditions",
    API_FMEA_TOGGLE_CURRENT_STATUS: "/api/toggleCurrentFEMAStatus",
    API_FMEA_CUSTOM_GROUP_NAMES: "/api/get_fmea_custom_group_names",
    API_FMEA_CUSTOM_PARAMETERS: "/api/get_fmea_custom_parameters",
    API_FMEA_CUSTOM_OPERATORS: "/api/get_fmea_custom_operators",
    API_FMEA_PARAMETER_VALUE_TYPES: "/api/get_fmea_custom_param_vtypes",
    API_FMEA_CREATE_CUSTOM_ALERT: "/api/create_fmea_custom_alert",
    API_FMEA_CUSTOM_RULE_DETAILS: "/api/get_fmea_custom_alert_details1",
    API_FMEA_NOTIFICATIONS: "/api/get_fmea_notifications",
    API_FMEA_NOTIFICATION_DETAILS: "/api/get_fmea_notification_details",
    GET_FMEA_COMPONENTS: 'get_fmea_components',
    API_FMEA_CREATE_FAILURE_INDICATOR: "/api/create_fmea_failure_indicator",
    API_FMEA_EXSISTING_FAILURE_INDICATOR: "/api/get_exsisting_failure_indicator",
    API_FMEA_DETECTION_CRITERIA : "/api/get_fmea_detection_criteria",
    API_FMEA_OCCURANCE_CRITERIA: "/api/get_fmea_occurance_criteria",
    API_FMEA_CREATE_POTENTIAL_CAUSES: "/api/create_fmea_potential_causes",
    API_FMEA_ASSIGN_NOTIFY_GROUPS: "/api/assign_fmea_notify_groups",
    API_FMEA_GET_FAILURE_NOTIFICATION_GROUPS: "/api/get_fmea_failure_notification_groups",
    GET_FMEA_SUB_COMPONENTS: 'get_fmea_sub_components',
    API_FMEA_GET_POTENTIAL_CAUSES: "/api/get_exsisting_potential_causes",
    API_FMEA_GET_POTENTIAL_CAUSE_DETAILS: "/api/get_exsisting_potential_cause_details",
    API_FMEA_DELETE_FAILURE_MODE: "/api/delete_fmea_failure_mode",
    API_FMEA_GET_POTENTIAL_CAUSE_RECOMM: "/api/get_exsisting_potential_cause_recomm",
    API_FMEA_DELETE_CASUAL_PARAMETER: "/api/delete_fmea_casual_parameter",
    API_PMEC_GET_MACHINE_MODEL: "/pmec_machine_model",
    API_PMEC_GET_PLANTS: "/pmec_plants",
    API_PMEC_GET_MACHINES: "/pmec_machines",
    API_PMEC_GET_PRODUCTS: "/pmec_products",
    API_PMEC_GET_OPERATORS: "/pmec_operators",
    API_PMEC_GET_OEE_MASTER: "/pmec_get_oee_master",
    API_PMEC_GET_OEE_DETAILS: "/pmec_get_oee_details",
    API_PMEC_GET_OPERATORS_SUMMARY: "/pmec_get_oee_operator_summary",
    API_PMEC_GET_PRODUCTS_SUMMARY: "/pmec_get_oee_product_summary",
    API_PMEC_GET_DASHBOARD_PRODUCTS: "/pmec_dashboard_products",
    API_PMEC_GET_BATCHES: "/pmec_batches",
    API_PMEC_GET_SHIFTS: "/pmec_shifts",
    API_PMEC_ALARM_TREND: "/pmec_get_alarm_trend",
    API_PMEC_GET_APQ: "/pmec_get_apq",
    API_PMEC_GET_OEE_SHIFT_DETAILS: "/pmec_get_oee_shift_details",
    API_PMEC_GET_AVL_METRICS: "/pmec_get_avl_metrics",
    API_PMEC_GET_DT_ANALYSIS: "/pmec_get_dt_analysis",
    API_PMEC_GET_DT_DETAILS: "/pmec_get_dt_details",
//these are for custom alerts
    GET_CUSTOM_PARAMETERS: 'customAlerts/getParametersList',
    GET_CUSTOM_PARAMETER_VALUE: 'customAlerts/getParameterValue',
    GET_CUSTOM_OPERATORS: 'customAlerts/getOperatorsList',
    GET_CUSTOM_SEVERITY :'customAlerts/getSeverityList',
    SUBMIT_CUSTOM_ADD_ALERT:'customAlerts/addAlert',
    GET_CUSTOM_EXISTING_ALERTS:'customAlerts/getExistingAlerts',
    DELETE_CUSTOM_ALERT:'customAlerts/deleteAlert',
    GET_CUSTOM_ALERT_LOGS:'customAlerts/getAlertsLogs',
    API_GET_CUSTOMERS: '/api/api_get_customers',
    API_GET_FILTER_CUSTOMERS: '/api/api_get_filter_customers',
    API_GET_MODULES: '/api/api_get_modules',
    API_CREATE_NOTIFICATION_GROUP: '/api/create_notification_groups',
    API_GET_ALL_NOTIFICATTIONS: "/api/get_all_notification_groups",
    API_DELETE_NOTIFICATION: "/api/delete_notification",
    API_PORTAL_USERS: "/api/portal_users_list",
    API_GET_GROUP_USERS: "/api/list_group_users",
    API_GROUP_ADD_MEMBER: "/api/group_add_member",
    //these are for notification screen
    GET_UNACKNOWLEDGED_NOTIFICATION_COUNT: 'notification/getUnackowledgedNotificationCount',
    API_GET_ALL_NOTIFICATIONS: "notification/getUnackowledgedNotificationDetails",
    API_GET_HISTORICAL_NOTIFICATIONS: "/notification/getUnackowledgedHistory",
    API_GET_RULE_TYPES: "/notification/getRuleTypes",
    API_GET_NOTIFICATION_SEVERITY: "/notification/getSeverity",
    API_GET_NOTIFICATION_MACHINES: "/notification/getMachines",
    API_ACK_NOTIFICATION: "/notification/ackNotification",
    API_GET_NOTIFICATION_DETAILS: "/notification/notificationDetails",
    API_GET_NOTIFICATION_GROUP_PLANT_LIST: "/notification/getNotificationsGroupPlantList",
    API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST: "/notification/getNotificationGroupFilterPlantList",
    API_GET_NOTIFICATION_FILTER_PLANT_LIST: "/notification/getNotificationFilterPlantList",
    //these are for sop screens
    GET_EXISTING_SOP_LIST:"sop/getExistingSopList",
    GET_EXISTING_SOP_DETAILS:"sop/getExistingSopDetails",
    GET_SOP_MACHINE_MODEL:"sop/getMachineModelList",
    GET_SOP_MACHINE_COM:"sop/getMachineComponentList",
    GET_SOP_TYPE:"sop/getSopType",
    SUBMIT_SOP_DETAILS:"/sop/postSopDetails",
    DELETE_SOP:"sop/deleteSop",
    //These are for component life cycle
    GET_CLC_MACHINE_MODELS_LIST: "/clc/getCLCMachineModels",
    GET_CLC_MACHINE_MODELS: "clc/getMachineModels",
    POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS: "clc/postComponentLifeCycleDetails",
    DELETE_CLC_ENTRY: "clc/deletetComponentLifeCycleDetails",
    DELETE_WO_ENTRY: "clc/deletetComponentLifeCycleWorkOrder",
    GET_CLC_LIST: "clc/getComponentLifeCycleDetails",
    GET_CLC_MACHINE_SERIALS: "clc/getMachineSerials",
    POST_CLC_REPLACE_COMPONENT_LIFE_CYCLE: "clc/replaceComponentLifeCycleDetails",
    GET_WORK_ODER_LIST: "clc/getWorkorderList",
    GET_WORK_ORDER_STATUS: "clc/getWorkorderStatus",
    POST_CLOSE_WORK_ORDER: "clc/closeWorkOrder",
    GET_CLC_MAPPING_LIST: "clc/getComponentLifeCycleMappingList",
    POST_CLC_MAPPING_LIST: "clc/postComponentLifeCycleMappingList",
    GET_CLC_ONBOARD_LIST: "clc/getOnBoardedComponentLifeCycleList",
    CLC_STATUS_UPDATE: "clc/postComponentLifeCycleStatus",
      //These are for golden batch dashboard
    GOLDEN_BATCH_DATA: "gb/getGoldenBatchTrend",
    GET_GB_SERIAL_DATA: "gb/getGoldenBatchSerial",
    GET_GB_PRODUCT_DATA: "gb/getGoldenBatchProducts",
    GET_GB_RECIPE_DATA: "gb/getGoldenBatchRecipe",
    GET_GB_BATCH_FILTER_DATA: "gb/getGoldenBatchFilterData",
    GET_GB_PARAMAETER_FILTER_DATA: "gb/getGoldenBatchParameters",
    GET_GB_ALARMS_DATA: "gb/getGoldenBatchAlarms",
    GET_GB_ALERTS_DATA: "gb/getGoldenBatchAlerts",
    API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST: "gb/getGoldenBatchPlantsConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST: "gb/getGoldenBatchMachineModelConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST: "gb/getGoldenBatchMachineConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST: "gb/getGoldenBatchrecipesConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST: "gb/getGoldenBatchFBErecipesConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST: "gb/getGoldenBatchBatchesConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST: "gb/getGoldenBatchBatchesLotConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST: "gb/getGoldenBatchFBEMasterBatchesConfig",
    API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS: "gb/getGoldenBatchProductListBQS",
    API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE: "gb/getGoldenBatchProductListFBE",
    API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE: "gb/getGoldenBatchProcessListFBE",
    API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE: "gb/getGoldenBatchSubProcessListFBE",
    API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST: "gb/getGoldenBatchFBEMachineConfig",
    API_POST_GOLDENBATCH_CONFIG_LOAD: "gb/postGoldenBatchLoadConfig",
    API_POST_GOLDENBATCH_CONFIG_FBE_LOAD: "gb/postGoldenBatchLoadFBEConfig",
    API_GET_GOLDENBATCH_CONFIG_LIST_BQS: "gb/getGoldenBatchConfigListBQS",
    API_GET_GOLDENBATCH_CONFIG_LIST_FBE: "gb/getGoldenBatchConfigListFBE",
    API_GET_GOLDENBATCH_RECIPE_BQS: "gb/getGoldenBatchRecipeBQS",
    API_GET_GOLDENBATCH_RECIPE_FBE: "gb/getGoldenBatchRecipeFBE",
    API_GET_GOLDENBATCH_QA_BQS: "gb/getGoldenBatchQABQS",
    API_GET_GOLDENBATCH_QA_FBE: "gb/getGoldenBatchQAFBE",
    API_POST_GOLDENBATCH_QA_BQS: 'gb/postGoldenBatchQABQS',
    API_POST_GOLDENBATCH_QA_FBE: 'gb/postGoldenBatchQAFBE',
    API_DELETE_GOLDENBATCH_RECIPE: 'gb/deleteGoldenBatchRecipe',
    API_GET_GOLDENBATCH_RECIPE_MM: 'gb/getGoldenBatchRecipeMM',
    API_GET_GOLDENBATCH_RECIPE_LIST: 'gb/getGoldenBatchRecipeList',
    API_GET_GOLDENBATCH_LIMIT_PARAMETERS: 'gb/getGoldenBatchLimitParameters',
    API_GET_GOLDENBATCH_FBE_PHASES: 'gb/getGoldenBatchFBEPhases',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS: 'gb/getGoldenBatchFBEAutofillProducts',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS: 'gb/getGoldenBatchFBEAutofillProcess',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS: 'gb/getGoldenBatchFBEAutofillSubProcess',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES: 'gb/getGoldenBatchFBEAutofillRecipes',
};

export const STORE_KEYS = {
    LOGIN_DATA: 'loginData',
    SUBMODULE_ACCESS: 'submodule-access',
    SECURITY_QA: 'securityQa',
    CHANGE_PWD: 'changePwd',
    UPDATE_PWD: 'updatePwd',
    GET_QA: 'getQa',
    ADD_QA: 'addQa',
    DELETE: 'deleteRow',
    UNLOCK: 'unlockUser',
    CLIENT_SUMMARY: 'clientSummary',
    CLIENT_DROPDOWN: 'clientDropdown',
    PLANT_DROPDOWN: 'plantDropdown',
    ROLE_DROPDOWN: 'roleDropdown',
    MACHINE_DROPDOWN: 'machineDropdown',
    COUNTRY_CODE_DROPDOWN: 'countryCodeDropdown',
    CLIENT_FEATURES: 'clientAssignFeatures',
    CLIENT_LIST: 'clientList',
    AUDIT_LIST: 'auditlist',
    SYSTEMCONFIG_LIST: 'systemConfig',
    CLIENT_FTRS: 'clientFeatures',
    ADD_CLIENT: 'addClient',
    PLANT_LIST: 'plantList',
    PLANT_LINES: 'plantLines',
    FEATURE_SMRY: 'featureSmry',
    ADD_RPT: 'addRpt',
    ADD_DN_RPT: 'add-dn-Rpt',
    RPT_LIST: 'rpts',
    USER_SUMMARY: 'userSummary',
    USER_LIST: 'userList',
    ROLE_LIST: 'roleList',
    CONTACT_LIST: 'contactList',
    DELETE_RPT: 'deleteRpt',
    DELETE_MENU: 'deleteMenu',
    USER_DTL: 'userDtl',
    ROUTES_PRIVILEGES: 'routesPrivileges',
    DEFAULT_PAGE: 'defaultPage',
    MODULES: 'modules',
    MENUS: 'menus',
    DASHBOARDS: 'dashboard',
    ADD_MENU: 'addMenu',
    MODULES_DN: 'modulesDn',
    COUNTRIES: 'countries',
    TIMEZONES: 'timezones',
    STATES: 'states',
    PWD_VERIFY: 'pwdVrfy',
    ADD_LINE: 'addLine',
    MACMON_SMRY: 'machineSmry',
    ACT_ALARMS: 'activeAlarms',
    EVENTS: 'events',
    CONDN_LOGS: 'condnLogs',
    MACHINES: 'machines',
    KPI: 'kpis',
    SPOCS: 'spocs',
    PARAMGRPS: 'paramGrps',
    PARAMS: 'params',
    TSGRPS: 'tsGrps',
    TS: 'timeSeries',
    USER_ACCESS_DETAILS: 'userAccessDetails',
    ALL_PLANTS: 'allPlants',
    ROLE_DETAIL: 'roleDetails',
    ROLE_FEATURES: 'roleFeatures',
    ALL_SELECTED_PLANTS: 'selecteduserFeatures',
    ADD_ROLEPT: 'rolePT',
    ALL_ACTIVE_ALARMS: 'allActiveAlarms',
    ACTIVE_ALARMS_OF_MACHINE: 'activeAlarmsofMachine',
    ACTIVE_ALARMS_OF_ALARM: 'activeAlarmsofAlarm',
    ALARMS_OF_MACHINE: 'alarmsOfMachine',
    ADD_TIMEPERIOD_DROPDOWN: 'timeperiodDropdown',
    ADD_SEVERITY_DROPDOWN: 'severitydDropdown',
    ADD_DETAILS_ACTIVE_ALARM: 'detailActiveAlarm',
    ADD_DETAILS_ACTIVE_ALARM_OF_MODEL: 'detailActiveAlarmofModel',
    ADD_ALL_EVENTS_OF_MACHINE: 'allEventsofmachine',
    ADD_EVENT_DETAIL_OF_MACHINE: 'eventDetailsOfMachine',
    ADD_EVENT_DETAILS_OF_MODEL: 'eventDetailsofModel',
    ADD_ALL_EVENT_OF_MODEL: 'eventEventofModel',
    ADD_EVENT_SUMMARY: 'eventsTotalSummary',
    ADD_ALARM_SUMMARY: 'alarmTotalSummary',
    ADD_SYS_CONFIG: 'addSysConfig',
    TP: 'timePeriods',
    TS_DTL: 'tsDtl',
    ADD_MACHINEMODEL_DROPDOWN: 'machineModelDropdown',
    ADD_MACHINE_FAMILY: 'machineFamilyDropdown',
    MACHMODEL_SMRY: 'machineModelSmry',
    MODELS: 'models',
    MODEL_PARMS: 'modelParams',
    ALL_MACHINES: 'allMachines',
    MACHFAMILY: 'machFamily',
    POWERBI_ACCESS_TOKEN: 'PowerBI Access Token',
    RPTS_PWRBI_ACCESS_TOKEN: 'rpts_pwrbi_access_token',
    ADMIN_DOWNLOADABLE_REPORTS: 'downloadableReports-details',
    CALLHISTORY_LIST: 'callHistoryList',
    // MODEL_PARMS: 'machineModelParams',
    PLANTS_OFCLIENT: 'plantsOfClient',
    PLANTLINES_OFPLANT: 'plantLinesOfPlant',
    ADD_DATATYPE: 'dataType',
    ADD_CATEGORY: 'category',
    ADD_CNT: 'addCnt',
    MAP_CNT: 'mapCnt',
    CONTACT_DETAILS: 'contactDetails',
    MACHINE_DETAIL_SUMMARY: 'machineDetailSummary',
    MACHINE_PARMS: 'machineParms',
    ADD_MACHINE_MODEL: 'addMachineModel',
    ADD_MACHINE_MODEL_PARAMETER: 'addMachineModelParameter',
    MACHINES_EDIT_MACHINEMODEL_DATA: 'machinesEditMachineModel',
    EDIT_MACHIN_EMODEL_PARAMETER_DATA: 'editMachineModelParameter',
    MACHINES_EDIT_MACHINEMODEL: 'editMachineModel',
    MACHINES_GET_PARAMGROUP_DETAILS_DATA: 'machinesGetParamGroupDetails',
    ADD_PARAM_GROUP_DATA: 'addParamGroup',
    MACHINES_GET_MM_PARAMETERS_NAME_DATA: 'machinesGetMMParametersName',
    MACHINES_PARAMETERS_MAPPING_DATA: 'paramGroupMapping',
    GET_PARAM_GROUP_DETAILS_DATA: 'getParamGroupDetails',
    GET_DNWDRPTS_ROLE_BASED: 'DownaloadabeReportsRoleBased',

    DN_REPORT_PLANTS_DATA: 'report-plants-data',
    DN_ALMANLS_REPORT_MACHINE_MODEL_DATA: 'report-machine-model-data',

    //alarm report
    DN_ALARM_REPORT_MONTH_YEAR:'alarm-report-month-year',
    DN_ALARM_REPORT_PLANTS:'alarm-report-plants-data',
    DN_ALARM_REPORT_MACHINE_MODELS:'alarm-report-machine-models',
    DN_ALARM_REPORT_MACHINES:'alarm-report-machines',
    DN_ALARM_REPORT_BATCHES:'alarm-report-batches',

    DN_GET_REPORT_TYPES: 'report-types-data',
    DN_MPRBQS_MASTER_DATA: 'bqsMpr-filterParamAllMasterData',
    DN_LINE_SERIAL_DATA: 'report-line-serial-data',
    DN_MQR_USERINFO: 'bqsMpr-userInfo',
    DN_AUDIT_REPORTS_CATEGORY_DATA: 'audit-categories-data',
    DN_REPORT_LINE_SERIAL_DATA: 'report-line-serial-data',
    DN_RAW_DATA_REPORT: 'raw-data-report',
    DN_ALMANYS_BQS_MQR_SUMMARY: 'bqsMpr-reportPageAlarmAnalysisSummary',
    DN_ALMANYS_BQS_MQR_DESC_SUMMARY: 'bqsMpr-eportPageAlarmAnalysisAlarmDescWiseSummary',
    DN_ALMANYS_BQS_MQR_DATE_SUMMARY: 'bqsMpr-eReportPageAlarmAnalysisAlarmDateWiseSummary',
    DN_REPORT_CATAGORY: 'machines-get-category-dropdown',
    DN_REPORT_BATCH_DETAILS: 'bqsMpr-FReportPageBQSBatchDetails',
    DN_REPORT_BATCH_TYPE: 'batch_type',
    DN_REPORT_OPTIONAL_PARAMS: 'opt_parameter_data',
    DN_BATCH_REPORT_DATA: 'batch_report_data',
    DN_RPT_DOWNTIME_FILTER_DATA: 'zydus_filter',
    DN_RPT_DOWNTIME_FILTER_MACHINE_DATA: 'zydus_filter_MachineData',
    DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA: 'zydus_filter_ProductData',
    DN_RPT_DOWNTIME_FILTER_BATCH_DATA: 'zydus_filter_BatchData',
    DN_RPT_DOWNTIME_FILTER_SHIFT_DATA: 'zydus_filter_ShiftData',
    UC_BC: 'ucBcs',
    BC_CLIENTS: 'bcClients',
    BC_MAPDCLIENTS: 'bcMapdClients',
    BC_SMRY: 'bcSmry',
    CURRENT_BROADCAST: 'currBroadcast',
    GLOBAL_DRAWER: 'globalDrawer',
    ADD_CALL_HISTORY: 'remote-assistance-add-call-history',
    GET_RP_USER_REPORTS: 'reports-based-on-roles',
    GET_RP_USER_DASHBOARDS: 'dashboards-based-on-roles',
    DELETE_MAP_CONTACT: 'dltMap',
    GET_PLANTS_OF_CUSTOMER: 'get-customers-and-plants-based-on-customer',
    GET_BREAKDOWN_MAINTENANCE_ASSETS: 'get-breakdown-maintenance-assets',
    GET_BREAKDOWN_MACHINE_DETAILS: 'get-breakdown-machine-details',
    GET_BREAKDOWN_MAINTENANCE_CATEGORY: 'get-breakdown-category',
    GET_BREAKDOWN_COMPONENTS: 'get-breakdown-components',
    GET_BREAKDOWN_SUB_COMPONENTS: 'get-breakdown-sub-components',
    GET_BREAKDOWN_MAINTENANCE_ACTION_TYPE: 'get-breakdown-action-type',
    GET_BREAKDOWN_SPARE_PARTS: 'get-breakdown-spare-parts',
    GET_BREAKDOWN_ROOT_CAUSE: 'get-breakdown-root-cause',
    GET_BREAKDOWN_ROOT_CAUSE_SUB: 'get-breakdown-root-cause-sub',
    ADD_BREAKDOWN_MAINTENANCE: 'add-breakdown-maintenance',
    GET_BREAKDOWN_MAINTENANCE_LIST: 'get-breakdown-maintenance-list',
    GET_BREAKDOWN_MAINTENANCE_LIST_DETAILS: 'get-breakdown-maintenance-list-details',
    DELETE_MAINTENANCE_MODULE: 'delete-maintenance-module',
    TOGGLE_MAINTENANCE_MODULE_STATUS: 'toggle-maintenance-module-status',
    GET_SERVICE_REQUEST_PRIORITIES: 'get-service-request-priorities',
    ADD_SERVICE_REQUEST: 'add-service-request',
    GET_SERVICE_REQUEST_LIST: 'get-service-request-list',
    GET_SERVICE_REQUEST_LIST_DETAILS: 'get-service-request-details',
    GET_SERVICE_REQUEST_USER_LIST: 'get-service-request-user-list',
    ADD_SERVICE_REQUEST_USER_ASSIGNMENT: 'add-service-request-user-assign',
    ADD_SERVICE_REQUEST_USER_ACTION: 'add-service-request-user-action',
    ADD_SERVICE_REQUEST_CLOSE: 'add-service-request-close',
    GET_PREVENTIVE_MAINT_CATEGORY: 'get-preventive-maint-category',
    GET_PREVENTIVE_MAINT_PERIOD: 'get-preventive-maint-period',
    ADD_PREVENTIVE_MAINTENANCE: 'add-preventive-maint',
    ADD_PREVENTIVE_MAINTENANCE_EXCEL: 'add-preventive-maint-excel',
    ADD_PREVENTIVE_INSPECTION_LIST_MAINTENANCE: 'add-inspection-list-preventive-maint',
    GET_PREVENTIVE_INSPECTION_LIST_MAINTENANCE: 'get-inspection-list-preventive-maint',
    GET_PREVENTIVE_UPDATED_INSPECTION_LIST_MAINTENANCE: 'get-updated-inspection-list-preventive-maint',
    UPDATE_PREVENTIVE_INSPECTION_LIST_MAINTENANCE: 'update-inspection-list-preventive-maint',
    GET_PREVENTIVE_MAINTENANCE_LIST: 'get-preventive-maint-list',
    GET_PREVENTIVE_MAINTENANCE_LIST_DETAILS: 'get-preventive-maint-list-details',
    GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST: 'get-preventive-maint-workorder-list',
    GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_LIST_DETAILS: 'get-preventive-maint-workorder-list-details',
    ADD_PREVENTIVE_MAINTENANCE_CLOSE_ORDER: 'add-preventive-maint-close-order',
    GET_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER_LIST: 'get-preventive-maint-workorder-user-list',
    GET_PREVENTIVE_MAINTENANCE_USER_LIST: 'get-preventive-maint-user-list',
    ASSIGN_PREVENTIVE_MAINTENANCE_WORK_ORDER_USER: 'assign-preventive-maint-workorder-user',
    ASSIGN_PREVENTIVE_MAINTENANCE_USER: 'assign-preventive-maint-user',
    GET_PREVENTIVE_MAINTENANCE_ACTION_TYPE: 'get-preventive-maint-action-type',
    API_RULE_ENGINE_CUSTOMERS: "/api/get_rule_engine_customers",
    API_RULE_ENGINE_CUSTOMER_MODELS: "/api/get_rule_engine_customer_models",
    API_RULE_ENGINE_NOTIFICATION_GROUPS: "/api/get_notification_groups",
    API_RULE_ENGINE_CREATE_NOTIFICATION_GROUP: "/api/create_notification_group",
    // API_RULE_ENGINE_PORTAL_USERS: "/api/portal_user_list",
    API_RULE_ENGINE_DELETE_NMGROUP: "/api/delete_notification_member_group",
    API_RULE_ENGINE_ADD_MEMBER: "/api/add_group_member",
    // API_RULE_ENGINE_LIST_MEMBERS: "/api/list_group_members",
    API_RULE_ENGINE_PLANTS: "/api/get_rule_engine_plants",
    API_RULE_ENGINE_ALERT_LIST: "/api/get_alerts_list",
    API_RULE_ENGINE_PRESET_ALERT_LIST: "/api/get_preset_alerts_list",
    API_RULE_ENGINE_MACHINE_NAME_LIST: "/api/get_machine_name_list",
    API_RULE_ENGINE_RECIPE_LIST: "/api/get_recipe_list",
    API_RULE_ENGINE_SEVERITY_LIST: "/api/get_severity_list",
    API_RULE_ENGINE_TIME_DURATION: "/api/get_time_duration",
    API_RULE_ENGINE_CUSTOMER_NGROUPS: "/api/get_customer_ngroups",
    API_RULE_ENGINE_CREATE_PRESET_ALERT: "/api/create_preset_alert",
    API_RULE_ENGINE_PRESET_RULE_CONDITIONS: "/api/get_preset_alert_conditions",
    API_RULE_ENGINE_PRESET_RULE_DETAILS: "/api/get_preset_alert_details",
    API_RULE_ENGINE_CUSTOM_RULE_CONDITIONS: "/api/get_custom_alert_conditions",
    API_RULE_ENGINE_TOGGLE_ALERT_STATUS: "/api/toggleRuleAlertStatus",
    API_RULE_ENGINE_CUSTOM_GROUP_NAMES: "/api/get_custom_group_names",
    API_RULE_ENGINE_CUSTOM_PARAMETERS: "/api/get_custom_parameters",
    API_RULE_ENGINE_CUSTOM_OPERATORS: "/api/get_custom_operators",
    API_RULE_ENGINE_PARAMETER_VALUE_TYPES: "/api/get_custom_param_vtypes",
    API_RULE_ENGINE_CREATE_CUSTOM_ALERT: "/api/create_custom_alert",
    API_RULE_ENGINE_CUSTOM_RULE_DETAILS: "/api/get_custom_alert_details1",
    API_RULE_ENGINE_NOTIFICATIONS: "/api/get_rule_engine_notifications",
    API_RULE_ENGINE_NOTIFICATION_DETAILS: "/api/get_rule_engine_notification_details",
    API_RULE_ENGINE_RANGE_DETAILS: "/api/get_custom_alert_range_limit",
    API_FMEA_CUSTOMERS: "/api/get_fmea_customers",
    API_FMEA_CUSTOMER_MODELS: "/api/get_fmea_customer_models",
    API_FMEA_NOTIFICATION_GROUPS: "/api/get_fmea_notification_groups",
    API_FMEA_CREATE_NOTIFICATION_GROUP: "/api/create_fmea_notification_group",
    API_FMEA_PORTAL_USERS: "/api/portal_fmea_user_list",
    API_FMEA_DELETE_NMGROUP: "/api/delete_fmea_notification_member_group",
    API_FMEA_ADD_MEMBER: "/api/add_fmea_group_member",
    API_FMEA_LIST_MEMBERS: "/api/list_fmea_group_members",
    API_FMEA_PLANTS: "/api/get_fmea_plants",
    API_FMEA_ALERT_LIST: "/api/get_fmea_alerts_list",
    API_FMEA_PRESET_ALERT_LIST: "/api/get_fmea_preset_alerts_list",
    API_FMEA_MACHINE_NAME_LIST: "/api/get_fmea_machine_name_list",
    API_FMEA_RECIPE_LIST: "/api/get_fmea_recipe_list",
    API_FMEA_SEVERITY_LIST: "/api/get_fmea_severity_list",
    API_FMEA_TIME_DURATION: "/api/get_fmea_time_duration",
    API_FMEA_CUSTOMER_NGROUPS: "/api/get_fmea_customer_ngroups",
    API_FMEA_CREATE_PRESET_ALERT: "/api/create_fmea_preset_alert",
    API_FMEA_PRESET_RULE_CONDITIONS: "/api/get_fmea_preset_alert_conditions",
    API_FMEA_PRESET_RULE_DETAILS: "/api/get_fmea_preset_alert_details",
    API_FMEA_CUSTOM_RULE_CONDITIONS: "/api/get_fmea_custom_alert_conditions",
    API_FMEA_TOGGLE_CURRENT_STATUS: "/api/toggleCurrentFEMAStatus",
    API_FMEA_CUSTOM_GROUP_NAMES: "/api/get_fmea_custom_group_names",
    API_FMEA_CUSTOM_PARAMETERS: "/api/get_fmea_custom_parameters",
    API_FMEA_CUSTOM_OPERATORS: "/api/get_fmea_custom_operators",
    API_FMEA_PARAMETER_VALUE_TYPES: "/api/get_fmea_custom_param_vtypes",
    API_FMEA_CREATE_CUSTOM_ALERT: "/api/create_fmea_custom_alert",
    API_FMEA_CUSTOM_RULE_DETAILS: "/api/get_fmea_custom_alert_details1",
    API_FMEA_NOTIFICATIONS: "/api/get_fmea_notifications",
    API_FMEA_NOTIFICATION_DETAILS: "/api/get_fmea_notification_details",
    GET_FMEA_COMPONENTS: 'get_fmea_components',
    GET_FMEA_SUB_COMPONENTS: 'get_fmea_sub_components',
    API_FMEA_CREATE_FAILURE_INDICATOR: "/api/create_fmea_failure_indicator",
    API_FMEA_EXSISTING_FAILURE_INDICATOR: "/api/get_exsisting_failure_indicator",
    API_FMEA_DETECTION_CRITERIA : "/api/get_fmea_detection_criteria",
    API_FMEA_OCCURANCE_CRITERIA: "/api/get_fmea_occurance_criteria",
    API_FMEA_CREATE_POTENTIAL_CAUSES: "/api/create_fmea_potential_causes",
    API_FMEA_ASSIGN_NOTIFY_GROUPS: "/api/assign_fmea_notify_groups",
    API_FMEA_GET_FAILURE_NOTIFICATION_GROUPS: "/api/get_fmea_failure_notification_groups",
    API_FMEA_GET_POTENTIAL_CAUSES: "/api/get_exsisting_potential_causes",
    API_FMEA_GET_POTENTIAL_CAUSE_DETAILS: "/api/get_exsisting_potential_cause_details",
    API_FMEA_GET_POTENTIAL_CAUSE_RECOMM: "/api/get_exsisting_potential_cause_recomm",
    API_FMEA_DELETE_CASUAL_PARAMETER: "/api/delete_fmea_casual_parameter",
    API_FMEA_DELETE_FAILURE_MODE: "/api/delete_fmea_failure_mode",
    API_PMEC_GET_MACHINE_MODEL: "pmec_machine_model",
    API_PMEC_GET_MACHINES: "/pmec_machines",
    API_PMEC_GET_PRODUCTS: "/pmec_products",
    API_PMEC_GET_OPERATORS: "/pmec_operators",
    API_PMEC_GET_OEE_MASTER: "/pmec_get_oee_master",
    API_PMEC_GET_OEE_DETAILS: "/pmec_get_oee_details",
    API_PMEC_GET_OPERATORS_SUMMARY: "/pmec_get_oee_operator_summary",
    API_PMEC_GET_PRODUCTS_SUMMARY: "/pmec_get_oee_product_summary",
    API_PMEC_GET_DASHBOARD_PRODUCTS: "/pmec_dashboard_products",
    API_PMEC_GET_BATCHES: "/pmec_batches",
    API_PMEC_GET_SHIFTS: "/pmec_shifts",
    API_PMEC_ALARM_TREND: "/pmec_get_alarm_trend",
    API_PMEC_GET_APQ: "/pmec_get_apq",
    API_PMEC_GET_OEE_SHIFT_DETAILS: "/pmec_get_oee_shift_details",
    API_PMEC_GET_AVL_METRICS: "/pmec_get_avl_metrics", 
    API_PMEC_GET_DT_ANALYSIS: "/pmec_get_dt_analysis",
    API_PMEC_GET_DT_DETAILS: "/pmec_get_dt_details",
    API_PMEC_GET_PLANTS: "/pmec_plants",

    //these are for custom alerts
    CUSTOM_ALERTS_PARAMETERS:'custom_alerts_parameters',
    CUSTOM_ALERTS_PARAMETER_VALUE:'custom_alerts_parameter_value',
    CUSTOM_ALERTS_OPERATORS:'custom_alerts_operators',
    CUSTOM_ALERTS_SEVERITY:'custom_alerts_severity',
    CUSTOM_ALERTS_SUCCESS:'custom_alerts_success',
    CUSTOM_ALERTS_EXISTING_ALERTS:'custom_alerts_existing_alerts',
    CUSTOM_ALERTS_DELETE_SUCCESS:'custom_alerts_delete_success',
    CUSTOM_ALERTS_LOGS:'custom_alerts_logs',
    CUSTOM_ALERTS_EDIT_SUCCESS:'custom_alerts_edit_success',

    //these are for notifications
    UNACKNOWLEDGED_NOTIFICATION_COUNT:'unacknowledged_notification_count',
    API_GET_ALL_NOTIFICATIONS: "notification/getUnackowledgedNotificationDetails",
    API_GET_HISTORICAL_NOTIFICATIONS: "/notification/getUnackowledgedHistory",
    API_GET_RULE_TYPES: "/notification/getRuleTypes",
    API_GET_NOTIFICATION_SEVERITY: "/notification/getSeverity",
    API_GET_NOTIFICATION_MACHINES: "/notification/getMachines",
    API_ACK_NOTIFICATION: "/notification/ackNotification",
    API_GET_NOTIFICATION_DETAILS: "/notification/notificationDetails",
    API_GET_NOTIFICATION_GROUP_PLANT_LIST: "/notification/getNotificationsGroupPlantList",
    API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST: "/notification/getNotificationGroupFilterPlantList",
    API_GET_NOTIFICATION_FILTER_PLANT_LIST: "/notification/getNotificationFilterPlantList",
    //these are for sop
    EXISTING_SOP_LIST:"existing_sop_list",
    EXISTING_SOP_DETAILS:"existing_sop_details",
    SOP_MACHINE_MODEL_LIST:"sop_machine_model_list",
    SOP_MACHINE_COM_LIST:"sop_machine_com_list",
    SOP_TYPE_LIST:"sop_type_list",
    SUBMIT_SOP_DETAILS_RESPONSE:"sop_submit_response",
    DELETE_SOP_RESPONSE:"delete_sop_response",
    //these are for compoenent life cycle
    GET_CLC_MACHINE_MODELS_LIST: "/clc/getCLCMachineModels",
    GET_CLC_MACHINE_MODELS: "clc/getMachineModels",
    POST_CLC_COMPONENT_LIFE_CYCLE_DETAILS: "clc/postComponentLifeCycleDetails",
    DELETE_CLC_ENTRY: "clc/deletetComponentLifeCycleDetails",
    DELETE_WO_ENTRY: "clc/deletetComponentLifeCycleWorkOrder",
    GET_CLC_LIST: "clc/getComponentLifeCycleDetails",
    GET_CLC_MACHINE_SERIALS: "clc/getMachineSerials",
    POST_CLC_REPLACE_COMPONENT_LIFE_CYCLE: "clc/replaceComponentLifeCycleDetails",
    GET_WORK_ODER_LIST: "clc/getWorkorderList",
    GET_WORK_ORDER_STATUS: "clc/getWorkorderStatus",
    POST_CLOSE_WORK_ORDER: "clc/closeWorkOrder",
    GET_CLC_MAPPING_LIST: "clc/getComponentLifeCycleMappingList",
    POST_CLC_MAPPING_LIST: "clc/postComponentLifeCycleMappingList",
    GET_CLC_ONBOARD_LIST: "clc/getOnBoardedComponentLifeCycleList",
    CLC_STATUS_UPDATE: "clc/postComponentLifeCycleStatus",
   //These are for golden batch dashboard
    GOLDEN_BATCH_DATA: "golden_batch_data",
    GET_GB_SERIAL_DATA: "gb/getGoldenBatchSerial",
    GET_GB_PRODUCT_DATA: "gb/getGoldenBatchProducts",
    GET_GB_RECIPE_DATA: "gb/getGoldenBatchRecipe",
    GET_GB_BATCH_FILTER_DATA: "gb/getGoldenBatchFilterData",
    GET_GB_PARAMAETER_FILTER_DATA: "gb/getGoldenBatchParameters",
    GET_GB_ALARMS_DATA: "gb/getGoldenBatchAlarms",
    GET_GB_ALERTS_DATA: "gb/getGoldenBatchAlerts",
    API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST: "gb/getGoldenBatchPlantsConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST: "gb/getGoldenBatchMachineModelConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST: "gb/getGoldenBatchMachineConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST: "gb/getGoldenBatchrecipesConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST: "gb/getGoldenBatchFBErecipesConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST: "gb/getGoldenBatchBatchesConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST: "gb/getGoldenBatchBatchesLotConfig",
    API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST: "gb/getGoldenBatchFBEMasterBatchesConfig",
    API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS: "gb/getGoldenBatchProductListBQS",
    API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE: "gb/getGoldenBatchProductListFBE",
    API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE: "gb/getGoldenBatchProcessListFBE",
    API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE: "gb/getGoldenBatchSubProcessListFBE",
    API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST: "gb/getGoldenBatchFBEMachineConfig",
    API_POST_GOLDENBATCH_CONFIG_LOAD: "gb/postGoldenBatchLoadConfig",
    API_POST_GOLDENBATCH_CONFIG_FBE_LOAD: "gb/postGoldenBatchLoadFBEConfig",
    API_GET_GOLDENBATCH_CONFIG_LIST_BQS: "gb/getGoldenBatchConfigListBQS",
    API_GET_GOLDENBATCH_CONFIG_LIST_FBE: "gb/getGoldenBatchConfigListFBE",
    API_GET_GOLDENBATCH_RECIPE_BQS: "gb/getGoldenBatchRecipeBQS",
    API_GET_GOLDENBATCH_RECIPE_FBE: "gb/getGoldenBatchRecipeFBE",
    API_GET_GOLDENBATCH_QA_BQS: "gb/getGoldenBatchQABQS",
    API_GET_GOLDENBATCH_QA_FBE: "gb/getGoldenBatchQAFBE",
    API_POST_GOLDENBATCH_QA_BQS: 'gb/postGoldenBatchQABQS',
    API_POST_GOLDENBATCH_QA_FBE: 'gb/postGoldenBatchQAFBE',
    API_DELETE_GOLDENBATCH_RECIPE: 'gb/deleteGoldenBatchRecipe',
    API_GET_GOLDENBATCH_RECIPE_MM: 'gb/getGoldenBatchRecipeMM',
    API_GET_GOLDENBATCH_RECIPE_LIST: 'gb/getGoldenBatchRecipeList',
    API_GET_GOLDENBATCH_LIMIT_PARAMETERS: 'gb/getGoldenBatchLimitParameters',
    API_GET_GOLDENBATCH_FBE_PHASES: 'gb/getGoldenBatchFBEPhases',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_PRODUCTS: 'gb/getGoldenBatchFBEAutofillProducts',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS: 'gb/getGoldenBatchFBEAutofillProcess',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS: 'gb/getGoldenBatchFBEAutofillSubProcess',
    API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES: 'gb/getGoldenBatchFBEAutofillRecipes',
};
