import React, { useEffect } from 'react';
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import AcgButton from '../Button/index';
import Bin from '../../../assets/images/bin.svg';
import './index.scss';

type ComponentProps = {
    handleClose: React.MouseEventHandler<HTMLElement>;
    isOpen: boolean;
    leftBtnPress?: React.MouseEventHandler<HTMLButtonElement>;
    rightBtnPress?: React.MouseEventHandler<HTMLButtonElement>;
    entityDetails?: EntityDetails;
    cvsAlertBoxProps?: any;
    modalContent?: any;
    modalHeader?: any;
    remark?: boolean;
    validateRemark?: any;
    cvsAlertBox?: any;
    isAlertImg?: boolean;
    isforceMajuere?: boolean;
    fmProps?: any;
    openOptions?: boolean;
    isProfPicUpload?: boolean;
    profPicProps?: any;
    custombutton?: any;
};
type EntityDetails = {
    entityName: string;
    entityOld: string;
    entityNew: string;
};

type DialogTitleType = {
    id: string;
    children?: any;
    classes?: any;
    onClose?: React.MouseEventHandler<HTMLElement>;
    isAlertImg?: boolean;
};
const DialogTitleComp = (props: DialogTitleType) => {
    const { children, isAlertImg } = props;

    return (
        <DialogTitle id={''}>
            <Typography>{children}</Typography>
            {isAlertImg ? <img src={Bin} /> : null}
        </DialogTitle>
    );
};

const AlertBox = (props: ComponentProps) => {
    const {
        handleClose,
        isOpen,
        leftBtnPress,
        rightBtnPress,
        entityDetails,
        modalContent,
        modalHeader,
        custombutton,
        isAlertImg = true
    } = props;
    return (
        <Dialog
            PaperProps={{
                style: { width: '500px', borderRadius: '0px', backgroundColor: '#24263A' }
            }}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            className={'acgAlertbox'}
        >
            <div className="cancel">
                <DialogTitleComp id="customized-dialog-title" onClose={handleClose} isAlertImg={isAlertImg} />
            </div>

            {modalContent && (
                <DialogContent dividers>
                    <Typography component={'span'} gutterBottom>
                        <div className="changedText">
                            <span className="textHeader" style={{ marginLeft: '0px !important', marginBottom: '20px' }}>
                                {modalHeader}
                            </span>
                            <br />
                            <span className="textValue">{modalContent}</span>
                        </div>
                    </Typography>
                </DialogContent>
            )}
            <DialogActions className="buttons">
                <AcgButton
                    name={'Cancel'}
                    handleClick={leftBtnPress}
                    secondary={true}
                    type="button"
                    className={'leftButton'}
                    variant="contained"
                />
                <AcgButton name={custombutton ? 'Unlock' : modalHeader?.includes('Inactivate') == true ? 'Inactivate' : modalHeader?.includes('Activate') == true ? 'Activate' : 'Delete'} handleClick={rightBtnPress} type="button" className={'rightButton'} />
            </DialogActions>
        </Dialog>
    );
};

export default AlertBox;
