import { FC } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Router from './router/index';
import * as Sentry from '@sentry/react';
import ErrorPage from './utils/errorPage';

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat'].join(',')
    }
});

const App: FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Sentry.ErrorBoundary fallback={ErrorPage} showDialog={false}>
            <div className="app-container">
                <Router />
            </div>
            </Sentry.ErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
