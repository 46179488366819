import { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer
} from "recharts";
import { executeACGAction, updateScreenIdentifiers } from '../../../../store/slice';
import { batch, useDispatch } from 'react-redux';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import dateFormat from 'dateformat';
import moment from 'moment';
const Dateformat = {
  YYYY_MM_DD: 'yyyy-mm-dd',
  YYYY_MM_DD_HH_MM_SS: 'yyyy-mm-dd hh:mm:ss',
  YYYY_MM_DD_HH_MM_SS_L: 'yyyy-mm-ddTHH:MM:ss.l',
}

export default function Graph(props: any) {
  const state = useGetState();
  const bottomRange = props.vSliderB;
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedSliderData, setSelectedSliderData] = useState<any>([]);
  const [focusBar, setFocusBar] = useState(null);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      setSelectedTime(payload[0]?.payload?.date);
      return (
        <div style={{ background: "#1d1e2c", color: "#ffffff", padding: "10px", cursor: "pointer", borderRadius: '8px' }} >
          <p className="label mtbP">Event Date : {(payload[0]?.payload?.date)}
          {/* {moment().utc(payload[0]?.payload?.date).format("DD/MM/YYYY hh:mm:ss A")} */}
          </p>
          <p className="label mtbP">Actual : {payload[1]?.payload?.actual}</p>
          <p className="label mtbP">Upper Limit : {payload[2]?.payload?.upperLimit}</p>
          <p className="label mtbP">Lower Limit : {payload[3]?.payload?.lowerLimit}</p>
        </div>
      );
    }

    return null;
  };

  const fetchAlertsAndAlarms = (val:any) => {
    console.log(val)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_ALARMS_DATA,
          reqObj: {
            "EventTime": val,
            "MachineKey": props.selectedSerial
          }
        },
        uniqueScreenIdentifier: { gbAlarms: true },
        storeKey: STORE_KEYS.GET_GB_ALARMS_DATA
      })
    );

    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_ALERTS_DATA,
          reqObj: {
            "EventTime": val,
            "MachineKey": props.selectedSerial
          }
        },
        uniqueScreenIdentifier: { gbAlerts: true },
        storeKey: STORE_KEYS.GET_GB_ALERTS_DATA
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_ALERTS_DATA]?.gbAlerts) {
      const rptsData = state?.[STORE_KEYS.GET_GB_ALERTS_DATA]?.body?.data;
      props.setAlertsData(rptsData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_ALERTS_DATA,
          uniqueScreenIdentifier: {
            gbAlerts: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_ALERTS_DATA]?.gbAlerts]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_ALARMS_DATA]?.gbAlarms) {
      const rptsData = state?.[STORE_KEYS.GET_GB_ALARMS_DATA]?.body?.data;
      props.setAlarmsData(rptsData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_ALARMS_DATA,
          uniqueScreenIdentifier: {
            gbAlarms: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_ALARMS_DATA]?.gbAlarms]);
  const CustomizedDot = (props: any) => {
    const { cx, cy, value } = props;
    if(value != "" ) {
    return (
      <svg
        x={cx - 0}
        y={cy - 0}
        width={10}
        height={10}
        fill={props?.payload?.isAlarm ? "yellow" :"white"}
        viewBox="0 0 1024 1024"
        style={{ cursor: "pointer", zIndex: 999 }}
        onClick={()=>fetchAlertsAndAlarms(props?.payload?.date)}
      >
        <path d="M512,1009.984 c-274.912,0,-497.76,-222.848,-497.76,-497.76 s222.848,-497.76,497.76,-497.76 c274.912,0,497.76,222.848,497.76,497.76 s-222.848,497.76,-497.76,497.76 M340.768,295.936" />
      </svg>
    )
    } else {
      return <></>
    }
  }

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <div style={{ display: "inline-flex", width: "100%", justifyContent: "center", marginBottom: "0px", position: "relative", bottom: "10px" }}>
        {
          payload.map((entry: any, index: number) => {
            if (entry.value !== "alarmAndAlerts")
              return (
                <div style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ width: "30px", height: "8px", background: entry?.color, marginRight: "5px" }}></div>
                  <div>
                    <label style={{ color: "#ffff", fontSize: "10px", marginRight: "10px", textTransform: "capitalize" }} key={`item-${index}`}>{entry.value.replace(/([A-Z])/g, ' $1').trim()}</label>
                  </div>
                </div>
              )
          })
        }
      </div>
    );
  }
  
  return (
    <ResponsiveContainer height={300} width="100%" className="gblegends">
    <LineChart
      height={300}
      data={props.appliedFilters ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.data?.slice(props.hSliderT[0], props.hSliderT[1]) : null}
      margin={{
        top: 0,
        right: 10,
        left: 20,
        bottom: 0
      }}
      // className={`${props?.appliedFilters && !props.rightSlider ? "abcd": "xyzz"}`}
      width={((props?.appliedFilters && !props.rightSlider)) ? 1000 : 1250}
    >
      <CartesianGrid strokeDasharray="0 0" stroke="grey" />
      <XAxis dataKey="date"
        style={{
          fontSize: '10px',
          color: "#ffffff !important"
        }}
        tick={{ fill: 'white' }}
      />
      <YAxis tick={{ fill: 'white' }} domain={[Math.round(props.vSliderB - 15), Math.round(props.vSliderT + 15)]} />
      <YAxis yAxisId="right" orientation="right" domain={[0, 10]} tick={{ fill: 'white' }} />
      <Tooltip 
      trigger={"hover"}
      content={<CustomTooltip/>}
      offset={0}
      coordinate={{ x: 0, y: 0 }}
       />
      <Legend content={renderLegend} layout="horizontal" verticalAlign="bottom" align="center"  className="legendGB"/>
      <Line
        type="monotone"
        dataKey="lowerLimit"
        stroke="tomato"
        dot={false}
        strokeWidth={2}
        style={{ cursor: "pointer" }}
        activeDot={true}
        onClick={fetchAlertsAndAlarms}
      />
      <Line type="monotone" dataKey="actual" stroke="rgba(255, 99, 132, 0.5)" strokeWidth={2} dot={false}
      
      />
      <Line type="monotone" dataKey="alarmAndAlerts" stroke="#FF4747" strokeWidth={0} dot={<CustomizedDot />}    
      />
      <Line type="monotone" dataKey="upperLimit" stroke="cyan" strokeWidth={2} dot={false}
      />
      {/* <Line yAxisId="right" type="monotone" dataKey="batchStatus" stroke="#e0a909"
        strokeWidth={3} dot={false}
      /> */}
      <Line yAxisId="right" type="monotone" dataKey="machineRunStatus" stroke="#198754" strokeWidth={2}
        dot={false} />
    </LineChart>
    </ResponsiveContainer>
  );
}
