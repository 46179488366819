import { useState, useEffect, useCallback, useMemo } from 'react';
import TextInput from '../../../../atoms/TextInput';
import DropDown from '../../../../atoms/Dropdown';
import MapCustomTabs from '../../../../atoms/ButtonTabs/MapCustomTabs';
import { useDispatch } from "react-redux";
import Table from "../../../../atoms/StickyHeaderTable";
import { applySortFilter, reGroup } from "../../../../utils/helper";
import CustomEditableTable from '../../../../atoms/CustomEditMapTable';
import { executeACGAction, reset, resetErr, resetStoreKey, updateScreenIdentifiers } from "../../../../store/slice";
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import ACGButton from '../../../../atoms/Button'
import Attachments from '../../../../atoms/ExcelAttachments';
import useGetState from '../../../../utils/hooks/useGetState';
import Box from '@mui/material/Box';
import {
    Button
} from '@mui/material';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Label from '../../../../atoms/Label/index';
import Grid from '@mui/material/Grid';
import "../../gb.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const TABLE_HEAD1 = [
    { id: "ParamName", label: "ParameterName", alignRight: false },
    { id: "GBLimitTypesCodes", label: "Parameter Value Type *", alignRight: false },
    { id: "OptimumMin", label: "Optimum Minimum", alignRight: false },
    { id: "OptimumMax", label: "Optimum Maximum", alignRight: false },
];

const TABLE_HEAD2 = [
    { id: "ParamName", label: "ParameterName", alignRight: false },
    { id: "GBLimitTypes", label: "Parameter Value Type ", alignRight: false },
    { id: "OptimumMin", label: "Optimum Minimum", alignRight: false },
    { id: "OptimumMax", label: "Optimum Maximum", alignRight: false },
    { id: "UpperLimit", label: "Upper Limit", alignRight: false },
    { id: "LowerLimit", label: "Lower Limit", alignRight: false },
    { id: "SetValue", label: "Reference Lot Actual  Values", alignRight: false },
    { id: "PhaseDuration", label: "Phase Duration(Min)", alignRight: false },
];

const rowsPerPage = 10;
export default function CreateForm(props: any) {
    const [currTab, setTabChange] = useState(0);
    const dispatch = useDispatch();
    const stateObj = useGetState();
    const [order, setOrder] = useState('desc');
    const [parameters, setParameters] = useState<any[]>([]);
    const [searchedText, setSearchedText] = useState<string>("");
    const [orderBy, setOrderBy] = useState('ParamName');
    const [page, setPage] = useState<number>(0);
    const [disable, setDisable] = useState(true);
    const [selectedData, setSelectedData] = useState<any>({});
    const [autofillProductKey, setAutofillProductKey] = useState('');
    const [autofillProcessKey, setAutofillProcessKey] = useState('');
    const [autofillSubProcessKey, setAutofillSubProcessKey] = useState('');
    const [autofillRecipeKey, setAutofillRecipeKey] = useState('');
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [updatedReplaceDate, setupdatedReplaceDate] = useState<any>([]);

    const options = {
        AD_SUCCESS: {
            message: "Successfully configured limts.",
            open: true,
            type: 'success'
        },
        AD_FAIL: {
            message: "Failed to edit",
            open: true,
            type: 'remark'
        },
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        props.setSnackbarOpts(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const addConfigBQS = async (type: string) => {
        const batchKey = Number(props.qualityAttributeList[0]?.BatchKey);
        const sheetData: any = [];
        props.qualityAttributeList?.map((item: any) => {
            sheetData.push({
                ParamName: item.ParamName,
                OptimumMin: Number(item.OptimumMin),
                OptimumMax: Number(item.OptimumMax),
                LimitType: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.name,
            })
        });
        try {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.API_POST_GOLDENBATCH_QA_BQS,
                        reqObj: {
                            "LoggedUserKey": loggedInUser,
                            "RecipeKey": props.filteredGoldenRecipeKey ? props.filteredGoldenRecipeKey : props.qualityAttributeList[0]?.RecipeKey,
                            "BatchKey": batchKey ? batchKey : props.qualityAttributeList[0]?.BatchKey,
                            "GBParamConfigJason": sheetData,
                            "AddEditActivity": type
                        }
                    },
                    uniqueScreenIdentifier: { limitsBQS: true },
                    storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS
                })
            );
        }
        catch (err: any) {
            handleSnackbarError(err);
            props.drawerClose();
            props.setCurrTab(1);
        }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS]?.limitsBQS) {
            props.setCurrTab(2);
            props.getQualityAttributesBQS(props.filteredGoldenRecipeKey ? props.filteredGoldenRecipeKey : props.qualityAttributeList[0]?.RecipeKey)
            props.setSnackbarOpts(options.AD_SUCCESS);
            props.drawerClose();
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS,
                    uniqueScreenIdentifier: {
                        limitsBQS: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_BQS]?.limitsBQS]);

    const addConfigFB = async () => {
        try {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.API_POST_GOLDENBATCH_QA_FBE,
                        reqObj: {
                            "LoggedUserKey": loggedInUser,
                            "CustProductKey": selectedData?.CustProductKey,
                            "RecipeKey": selectedData?.RecipeKey,
                            "GBFBEParamConfigJason": Object.values(props.qualityAttributeList).flat().map((item: any) => {
                                if (item.OptimumMin === "" && item.OptimumMax === "") {
                                    return {
                                        PhaseKey: item.PhaseKey,
                                        MMParamKey: item.MMParamKey,
                                        OptimumMax: null,
                                        OptimumMin: null,
                                        GBLimitTypesKey: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.key,
                                    }
                                }
                                else if (item.OptimumMin === "") {
                                    return {
                                        PhaseKey: item.PhaseKey,
                                        MMParamKey: item.MMParamKey,
                                        OptimumMax: Number(item.OptimumMax),
                                        OptimumMin: null,
                                        GBLimitTypesKey: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.key,
                                    }
                                }
                                else if (item.OptimumMax === "") {
                                    return {
                                        PhaseKey: item.PhaseKey,
                                        MMParamKey: item.MMParamKey,
                                        OptimumMax: null,
                                        OptimumMin: Number(item.OptimumMin),
                                        GBLimitTypesKey: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.key,
                                    }
                                }
                                else if (item.OptimumMin && item.OptimumMax) {
                                    return {
                                        PhaseKey: item.PhaseKey,
                                        MMParamKey: item.MMParamKey,
                                        OptimumMax: Number(item.OptimumMax),
                                        OptimumMin: Number(item.OptimumMin),
                                        GBLimitTypesKey: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.key,
                                    }
                                }
                                else if (item.OptimumMin && !item.OptimumMax) {
                                    return {
                                        PhaseKey: item.PhaseKey,
                                        MMParamKey: item.MMParamKey,
                                        OptimumMin: Number(item.OptimumMin),
                                        GBLimitTypesKey: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.key,
                                    }
                                } else if (item.OptimumMax && item.OptimumMin) {
                                    return {
                                        PhaseKey: item.PhaseKey,
                                        MMParamKey: item.MMParamKey,
                                        OptimumMax: Number(item.OptimumMax),
                                        GBLimitTypesKey: props.limitParameters.find((i: any) => i.code === item.GBLimitTypesCodes)?.key,
                                    }
                                }

                            }).filter(function (el) {
                                return el != null;
                            })
                            ,
                            "AddEditActivity": "Edit"
                        }
                    },
                    uniqueScreenIdentifier: { limitsFB: true },
                    storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE
                })
            );
        }
        catch (err: any) {
            handleSnackbarError(err);
            props.drawerClose();
            props.setCurrTab(1);
        }
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE]?.limitsFB) {
            props.setSnackbarOpts(options.AD_SUCCESS);
            props.getQualityAttributesFBE(selectedData?.CustProductKey, selectedData?.SubProcessKey, selectedData?.RecipeKey);
            props.setDrawerHeader("View");
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE,
                    uniqueScreenIdentifier: {
                        limitsFB: false
                    }
                })
            );
        }
    }, [state?.[STORE_KEYS.API_POST_GOLDENBATCH_QA_FBE]?.limitsFB]);

    useEffect(() => {
        if (props.machineModelName.includes("FB")) {
            setDisable(false);
        } else {
            let check1 = check(props.qualityAttributeList);
            setDisable(check1);
        }
    }, [props.qualityAttributeList])

    useEffect(() => {
        if (props.machineModelName.includes("FB")) {
            const data = props.selectedRow.find((i: any) => i.GoldenLotNo === props.goldenRecipeKey);
            setSelectedData(data);
        }
    }, []);

    function check(object: any) {
        let count = 0;
        let value: any;
        for (value of Object.values(object)) {
            if (value !== null) {
                if (value.OptimumMin === null || value.OptimumMin === "" || value.OptimumMin === "NaN" || value.OptimumMax === null || value.OptimumMax === "" || value.OptimumMax === "NaN" || (typeof value === "object" && check(value))) {
                    return true;
                }
                count = count + 1
            }
        }

        return false;
    }

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={() => { props.drawerClose(); }}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { props.machineModelName.includes("BQS") ? addConfigBQS("Edit") : addConfigFB() }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={disable ? true : false}
                />
            </div>
        );
    };

    const filteredList = applySortFilter(parameters, order, searchedText, orderBy);
    const parametersCount = filteredList?.length;

    const handleChangePage = useCallback((event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    }, [])

    const setCurrTab = (val: number) => {
        setTabChange(val);
    }

    const getAutofillProcesss = (key1: any, key2: any, key3: any, key4:any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "PlantKey": props.plantKey,
                        "MachineModelKey": props.machineModelKey,
                        "CustProductKey": key4,
                        "SubProcessKey": key1,
                        "RecipeKey": key2,
                        "AutofillCustProductKey": key3
                    }
                },
                storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS
            })
        );
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS]?.body?.data) {
            const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS]?.body?.data?.map((item: any) => {
                return item;
            });
            props.setAutoFillProcess([
                ...list?.map((item: any) => ({ code: item.AutofillProcessKey, name: item.ProcessName }))

            ]);
        } else {
            props.setAutoFillProcess([]);
        }

    }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_PROCESS]]);

    const getAutofillSubProcess = (key1: any, key2: any, key3: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "PlantKey": props.plantKey,
                        "MachineModelKey": props.machineModelKey,
                        "AutofillCustProductKey": autofillProductKey,
                        "AutofillProcessKey": key1,
                        "CustProductKey": key2,
                        "RecipeKey": key3
                    }
                },
                storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS
            })
        );
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS]?.body?.data) {
            const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS]?.body?.data?.map((item: any) => {
                return item;
            });
            props.setAutoFillSubProcess([
                ...list?.map((item: any) => ({ code: item.AutofillSubProcessKey, name: item.SubProcessName }))

            ]);
        } else {
            props.setAutoFillSubProcess([]);
        }

    }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_SUBPROCESS]]);

    const getAutofillRecipes = (key1: any, key2: any, key3: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES,
                    reqObj: {
                        "LoggedUserKey": loggedInUser,
                        "PlantKey": props.plantKey,
                        "MachineModelKey": props.machineModelKey,
                        "RecipeKey": key3,
                        "AutofillCustProductKey": autofillProductKey,
                        "AutofillProcessKey": autofillProcessKey,
                        "AutofillSubProcessKey": key1,
                        "CustProductKey": key2
                    }
                },
                storeKey: STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES
            })
        );
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES]?.body?.data) {
            const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES]?.body?.data?.map((item: any) => {
                return item;
            });
            props.setAutoFillRecipes([
                ...list?.map((item: any) => ({ code: item.AutofillRecipeKey, name: item.RecipeName }))

            ]);
        } else {
            props.setAutoFillRecipes([]);
        }

    }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_FBE_AUTOFILL_RECIPES]]);

    const navigateTab = () => {
        switch (currTab) {
            default:
                return (
                    <CustomEditableTable
                        headers={props.drawerHeader === "Edit" ? TABLE_HEAD1 : TABLE_HEAD2}
                        filteredUsers={props.qualityAttributeList[Object.keys(props.qualityAttributeList)[currTab]]}
                        getUpdatedRowData={props.qualityAttributeList[Object.keys(props.qualityAttributeList)[currTab]]}
                        parametersCount={parametersCount}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        setPageNumber={setPage}
                        updatedReplaceDate={updatedReplaceDate}
                        setCLCMappingList={props.setCLCMappingList}
                        qualityAttributeList={props.qualityAttributeList}
                        setQualityAttributeList={props.setQualityAttributeList}
                        limitParameters={props.limitParameters}
                        tabIndex={props.phasesTab[currTab]?.PhaseKey}
                        drawerHeader={props.drawerHeader}
                    />
                )
        }
    }

    return (
        <Container className="createReport clc-cls">
            <Box>
                <div className='formwrap all-labelcls clclabel'>
                    <FormControl fullWidth>
                        <Grid container spacing={2}>

                            {props.machineModelName.includes("BQS") ?
                                <>
                                    <Grid item md={4}>
                                        <Label label="Golden Batch Recipe" className={'CMlabelDiv'} />
                                        <TextInput
                                            fullWidth
                                            id="outlined-basic"
                                            sx={{ margin: '0px', padding: '2px' }}
                                            value={props.qualityAttributeList[0]?.RecipeName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={12} className='tablemappingLimit'>
                                        <CustomEditableTable
                                            headers={TABLE_HEAD1}
                                            filteredUsers={props.qualityAttributeList}
                                            getUpdatedRowData={props.qualityAttributeList}
                                            parametersCount={parametersCount}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            handleChangePage={handleChangePage}
                                            setPageNumber={setPage}
                                            updatedReplaceDate={updatedReplaceDate}
                                            setCLCMappingList={props.setCLCMappingList}
                                            qualityAttributeList={props.qualityAttributeList}
                                            setQualityAttributeList={props.setQualityAttributeList}
                                            limitParameters={props.limitParameters}
                                        />
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item md={2.5}>
                                        <Label label="Product Name" className={'CMlabelDiv'} />
                                        <TextInput
                                            fullWidth
                                            id="outlined-basic"
                                            sx={{ margin: '0px', padding: '2px' }}
                                            value={selectedData?.ProductName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={2.5}>
                                        <Label label="Master Process" className={'CMlabelDiv'} />
                                        <TextInput
                                            fullWidth
                                            id="outlined-basic"
                                            sx={{ margin: '0px', padding: '2px' }}
                                            value={selectedData?.ProcessName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={2.5}>
                                        <Label label="Sub Process" className={'CMlabelDiv'} />
                                        <TextInput
                                            fullWidth
                                            id="outlined-basic"
                                            sx={{ margin: '0px', padding: '2px' }}
                                            value={selectedData?.SubProcessName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item md={2.5}>
                                        <Label label="Recipe Name" className={'CMlabelDiv'} />
                                        <TextInput
                                            fullWidth
                                            id="outlined-basic"
                                            sx={{ margin: '0px', padding: '2px' }}
                                            value={selectedData?.RecipeName}
                                            disabled
                                        />
                                    </Grid>
                                    {props.drawerHeader === "Edit" ?
                                        <>
                                            <Grid item md={2.5}>
                                                <Label label="Select Existing Products *" className={'CMlabelDiv'} />
                                                <div>
                                                    <div>
                                                        <DropDown
                                                            onChange={(e: any) => {
                                                                setAutofillProductKey(e.target.value);
                                                                getAutofillProcesss(selectedData?.SubProcessKey, selectedData?.RecipeKey, e.target.value, selectedData?.CustProductKey);
                                                                setAutofillProcessKey("");
                                                                setAutofillSubProcessKey("");
                                                                setAutofillRecipeKey("");
                                                            }}
                                                            className="inputDiv"
                                                            name="dropdownModel"
                                                            items={props.autoFillProducts}
                                                            placeHolder="Select Existing Products"
                                                            value={autofillProductKey}
                                                        />
                                                    </div>
                                                   
                                                </div>
                                            </Grid>
                                            <Grid item md={2.5}>
                                                <Label label="Select Existing Process *" className={'CMlabelDiv'} />
                                                <div>
                                                    <div>
                                                        <DropDown
                                                            onChange={(e: any) => {
                                                                setAutofillProcessKey(e.target.value);
                                                                getAutofillSubProcess(e.target.value, selectedData?.CustProductKey, selectedData?.RecipeKey);
                                                                setAutofillSubProcessKey("");
                                                                setAutofillRecipeKey("");
                                                            }}
                                                            className="inputDiv"
                                                            name="dropdownModel"
                                                            items={props.autoFillProcess}
                                                            placeHolder="Select Existing Process"
                                                            value={autofillProcessKey}
                                                        />
                                                    </div>

                                                </div>
                                            </Grid>
                                            <Grid item md={2.5}>
                                                <Label label="Select Existing Sub Process *" className={'CMlabelDiv'} />
                                                <div>
                                                    <div>
                                                        <DropDown
                                                            onChange={(e: any) => {
                                                                setAutofillSubProcessKey(e.target.value);
                                                                getAutofillRecipes(e.target.value, selectedData?.CustProductKey, selectedData?.RecipeKey);
                                                                setAutofillRecipeKey("");
                                                            }}
                                                            className="inputDiv"
                                                            name="dropdownModel"
                                                            items={props.autoFillSubProcess}
                                                            placeHolder="Select Existing Sub Process"
                                                            value={autofillSubProcessKey}
                                                        />
                                                    </div>

                                                </div>
                                            </Grid>
                                            <Grid item md={2.5}>
                                                <Label label="Select Existing Recipes *" className={'CMlabelDiv'} />
                                                <div>
                                                    <div>
                                                        <DropDown
                                                            onChange={(e: any) => {
                                                                setAutofillRecipeKey(e.target.value);
                                                            }}
                                                            className="inputDiv"
                                                            name="dropdownModel"
                                                            items={props.autoFillRecipes}
                                                            placeHolder="Select Existing Recipes"
                                                            value={autofillRecipeKey}
                                                        />
                                                    </div>

                                                </div>
                                            </Grid>
                                            <Grid item md={2} style={{margin: "auto"}}>
                                                <Button style={{ textTransform: "capitalize", marginTop: 34 }} 
                                                onClick={() => props.getQualityAttributesFBE(selectedData?.CustProductKey, selectedData?.SubProcessKey, selectedData?.RecipeKey, autofillProcessKey, autofillProductKey, autofillSubProcessKey, autofillRecipeKey)}
                                                disabled={autofillRecipeKey === "" ? true : false}
                                                >Copy</Button>
                                            </Grid>
                                        </>
                                        : null}
                                    <Grid item md={12} style={{marginTop: 14}}>
                                        <MapCustomTabs
                                            navigateTab={navigateTab}
                                            currTab={currTab}
                                            setCurrTab={setCurrTab}
                                            tabsArray={props?.phasesTab.map((value: any) => value.PhaseName)}
                                            className="tabs"
                                            position="relative"
                                        // type="nonCurve"
                                        />
                                    </Grid>
                                </>
                            }

                        </Grid>
                    </FormControl>
                </div>
                {props.drawerHeader === "Edit" ?
                    <div className='butondrower'>
                        {renderButtons()}
                    </div>
                    : null}
            </Box>
        </Container>
    );
}