import { Grid, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../../../atoms/Dropdown';
import MachineOverviewCard from '../../../../../atoms/MachineOverviewCard';
import SearchBar from '../../../../../atoms/SearchBar';
import { ACTION_CODES, STORE_KEYS } from '../../../../../constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../../../store/slice';
import useGetState from '../../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../../Login/helper';
import RightDrawer from '../../../../../atoms/tempDrawer/indexAlt';
import TimeSeriesDtl from './TimeSeriesDtl';
import LogLogo from '../../../../../../assets/images/CondnMonLog.svg';
import ViewReportLogo from '../../../../../../assets/images/ViewReport.svg';

import CreateAlertDrawer from '../../../../../atoms/CreateAlertDrawer/CreateAlertDrawer';
import ViewAlertDrawer from '../../../../../atoms/ViewAlertDrawer/ViewAlertDrawer';
import AlertLogDrawer from '../../../../../atoms/AlertLogDrawer/AlertLogDrawer';
import MenuDropDown from '../../../../../atoms/MenuDropDown/MenuDropDown';
import CustomAlertBtn from '../../../../../atoms/CustomAlertBtn/CustomAlertBtn';

const num = [0, 1, 2, 3, 4, 5, 6, 7, 8];
type Props = { data: any };
const TimeSeries = (props: Props) => {
    const dispatch = useDispatch();
    const { data } = props;
    const [grps, setGrps] = useState<any>([]);
    const [grp, setGrp] = useState<any>(-1);
    const [ts, setTs] = useState<any>([]);
    const [tsData, setTsData] = useState<any>([]);
    const [searchTxt, setSearchTxt] = useState<any>('');
    const [dtlOpen, setDtlOpen] = useState(false);
    const [drawerHeader, setDrawerHeader] = useState('');
    const [dtlData, setDtlData] = useState<any>({});
    //drawer states
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [createValue, setCreateValue] = useState('');
    const [AlertType, setALertType] = useState('');
    const [editDetails,setEditDetails]=useState({isEdit:false,values:null})
    const [totalAlertCounts,setTotalAlertCounts]=useState<number>(0)

    const stateVal = useGetState();
    const getGrps = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_TSGRP}/${data?.MachineKey}`
                },
                uniqueScreenIdentifier: { grpsRecd: true },
                storeKey: STORE_KEYS.TSGRPS
            })
        );
    };
    const getTs = (grpKey?: any) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_TS,
                    reqObj: {
                        machineKey: data?.MachineKey,
                        machMdlParamGrpKey: grpKey ? grpKey : -1
                    }
                },
                uniqueScreenIdentifier: { tsRecd: true },
                storeKey: STORE_KEYS.TS
            })
        );
    };
    useEffect(() => {
        getGrps();
        getTs();
    }, []);
    useEffect(() => {
        if (stateVal?.[STORE_KEYS.TSGRPS]?.grpsRecd) {
            const grpsList = stateVal?.[STORE_KEYS.TSGRPS]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachMdlParamGrpKey: 'code', GroupName: 'name' });
                return newVal;
            });
            setGrps(grpsList);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.TSGRPS,
                    uniqueScreenIdentifier: {
                        grpsRecd: false
                    }
                })
            );
        }
    }, [stateVal?.[STORE_KEYS.TSGRPS]?.grpsRecd]);
    useEffect(() => {
        if (stateVal?.[STORE_KEYS.TS]?.tsRecd) {
            setTsData(stateVal?.[STORE_KEYS.TS]?.body?.data);
            if (searchTxt !== '') {
                const tsList = stateVal?.[STORE_KEYS.TS]?.body?.data?.filter((item: any) =>
                    item?.ParamName?.toLowerCase()?.includes(searchTxt?.toLowerCase())
                );
                setTs(tsList);
            } else {
                setTs(stateVal?.[STORE_KEYS.TS]?.body?.data);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.TS,
                    uniqueScreenIdentifier: {
                        tsRecd: false
                    }
                })
            );
        }
    }, [stateVal?.[STORE_KEYS.TS]?.tsRecd]);
    const handleSearch = (event: any) => {
        setSearchTxt(event.target.value);
        if (searchTxt !== '') {
            const list = tsData?.filter((parm: any) =>
                parm?.ParamName?.toLowerCase()?.includes(event.target.value.toLowerCase())
            );
            setTs(list);
        }
    };
    const resetInput = () => {
        setSearchTxt('');
        setTs(stateVal?.[STORE_KEYS.TS]?.body?.data);
    };
    const getTp = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_TP
                },
                uniqueScreenIdentifier: { tpRecd: true },
                storeKey: STORE_KEYS.TP
            })
        );
    };
    const clickParam = (val: any) => {
        setDrawerHeader(val?.ParamName);
        setDtlData({ machineKey: val?.MachineKey, paramKey: val?.MMParamKey });
        getTp();
    };
    useEffect(() => {
        if (stateVal?.[STORE_KEYS.TP]?.tpRecd) {
            const tpsList = stateVal?.[STORE_KEYS.TP]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { TimePeriodKey: 'code', TimePeriodName: 'name' });
                return newVal;
            });
            setDtlData({ ...dtlData, tps: tpsList });
            setDtlOpen(true);
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.TP,
                    uniqueScreenIdentifier: {
                        tpRecd: false
                    }
                })
            );
        }
    }, [stateVal?.[STORE_KEYS.TP]?.tpRecd]);

    const handleMenuClick = (drawerStatus: any, CreateValue: any, ALertType: any) => {
        setCreateDrawerOpen(drawerStatus);
        setCreateValue(CreateValue)
        setALertType(ALertType)
    }

    const DropdownOptions = [
        { label: "Create Alert", click: () => { handleMenuClick(true, "", "Create Alert") } },
        { label: "View Alerts", click: () => { handleMenuClick(true, "Custom Alerts", "View Alerts") } },
    ]
    
    const handleEdit=(values:any)=>{
        setEditDetails({isEdit:true,values:values})
        setCreateDrawerOpen(false)
        handleMenuClick(true, "", "Create Alert")
    }
    return (
        <div className="overviewContainer">
            <div className="overviewContainerFlexMain">
                <div className="FlexOne">
                    <div className="topRow">
                        <div>
                            <h6 style={{ color: '#DADCEE', fontSize: '16px', marginBottom: '3px' }}>
                                Time Series List
                            </h6>
                            <h6 style={{ color: 'rgb(156 194 255 / 70%)', fontSize: '12px', marginTop: '0px' }}>
                                {`${ts?.length} Time Series`}
                            </h6>
                        </div>

                        <div style={{ width: '200px', marginRight: '15%' }}>
                            <Dropdown
                                onChange={(event) => {
                                    setGrp(event.target.value);
                                    getTs(event.target.value);
                                }}
                                className="inputDiv"
                                name="dropdownClient"
                                items={grps}
                                value={grp}
                            />
                        </div>

                        <div className="searchDiv margintp">
                            <SearchBar
                                searchText={searchTxt}
                                setSearchText={handleSearch}
                                resetSearchText={resetInput}
                                searchLabel={'Search for Time Series'}
                            />
                        </div>
                         <CustomAlertBtn DropdownOptions={DropdownOptions}/>
                        <div>
                            {/* <img style={{ marginRight: '20px' }} src={LogLogo} alt="condnLogo" />
                            <img src={ViewReportLogo} alt="reportLogo" /> */}
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <Grid container spacing={2}>
                {ts?.length > 0 ? (
                    ts?.map((ele: any, idx: any) => {
                        const index = idx + 1;
                        return (
                            <Grid item xs={12} lg={2.3} md={3} sm={6} key={index}>
                                <MachineOverviewCard data={ele} onClick={clickParam} clickable={true} type="graph" />
                            </Grid>
                        );
                    })
                ) : (
                    <div style={{ fontSize: '14px', marginLeft: '20px', color: '#B1C2DF' }}>
                        {'Time series not available'}
                    </div>
                )}
            </Grid>
            <RightDrawer
                handlerOpen={() => { }}
                handlerClose={() => setDtlOpen(false)}
                status={dtlOpen}
                drawerHeader={drawerHeader}
                type="chart"
                children={<TimeSeriesDtl dtlData={dtlData} />}
            />
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); }}
                status={createDrawerOpen}
                subHeader={AlertType=='View Alerts' ? `${totalAlertCounts} Counts`:undefined}
                drawerHeader={AlertType.includes("Create")?editDetails.isEdit ?   "Edit Alert" : "Create Alert" : AlertType}
                children={
                    AlertType.includes("Create") ? 
                    <CreateAlertDrawer MachineKey={data?.MachineKey} TabName="TimeSeries" editDetails={editDetails} handlerClose={() => { setCreateDrawerOpen(false); }}/> : AlertType.includes("View") 
                    ? <ViewAlertDrawer setTotalAlertCounts={setTotalAlertCounts} MachineKey={data?.MachineKey} handleEdit={handleEdit} TabName="TimeSeries" /> 
                    : <AlertLogDrawer MachineKey={data?.MachineKey} />
                }
            />
        </div>
    );
};

export default TimeSeries;
