import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import './index.scss';
import useGetState from '../../utils/hooks/useGetState';
import { STORE_KEYS } from '../../constants/apiConstants';
interface Props {
    click: any;
    remove?: any;
    userUnlockFn?: any;
    activeFn?: any;
    block?: boolean;
    dtlMode?: boolean;
    onlyDeleteMode?: Boolean;
    onlyDeleteModeInner?: boolean;
    onlyEditMode?: Boolean;
    data?: any;
    alarmFlag?: boolean;
    userFlag?: boolean;
    workflow?: any;
    editFn?: any;
    loadConfig?: any;
    hideEdit?: boolean;
    loadMode?: boolean;
    rules?: boolean;
    trial?: boolean;
    onClickUpload?: any;
    onClickDownloadReport?: any;
    onClickDownload?: any;
    setInspectionAction?: any;
    setAssign?: any;
    updateInspectionAction?: any;
    pmec?: boolean;
    fmea?: boolean;
    failureIndicatorsData?: any;
    newEntry?: boolean;
    notificationHistory?: boolean;
    clc?: boolean;
    clcWO?: boolean;
    clcOnboard?: boolean;
    GBAdmin?: boolean;
    GBLimit?: boolean;
    machineModelName?: any;
    toggleStatus?: any;
    type?:any
}
export default function UserMoreMenu(props: Props) {
    const { click, remove = () => { }, userUnlockFn = () => { }, activeFn = () => { }, block, dtlMode = false, onlyDeleteMode = false, alarmFlag, userFlag, onlyEditMode = false, onlyDeleteModeInner = false, data, workflow, editFn = () => { }, loadConfig = () => { }, hideEdit = false, loadMode = false, rules = false, trial = false, onClickUpload = () => { }, onClickDownloadReport = () => { }, onClickDownload = () => { }, setInspectionAction = null, setAssign = null, updateInspectionAction = null, pmec = false, fmea = false, failureIndicatorsData, newEntry = true, notificationHistory, clc = false, clcWO = false, clcOnboard = false, GBAdmin = false, GBLimit = false, machineModelName, toggleStatus } = props;
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const currentPath = useLocation();
    const state = useGetState();
    const accessData = state?.[STORE_KEYS.ROUTES_PRIVILEGES];
    const isAccess = (__data: any) => {
        return __data.filter((d: any) => d.path == currentPath?.pathname)?.[0]?.isEditable;
    };
    const viewExsist = data && Object.keys(data)?.includes('IsEnableViewOption');
    const assignTo = data && Object.keys(data)?.includes('IsEnableAssignToOption');
    const deleteExsist = data && Object.keys(data)?.includes('IsEnableDeleteOption');
    const closeExsist = data && Object.keys(data)?.includes('IsEnableCloseoption');
    const updateChecklist = data && Object.keys(data)?.includes('IsEnableChecklistUpdate');
    const deletePRExsist = data && Object.keys(data)?.includes('IsEnableDeletePR');
    const deletPWRExsist = data && Object.keys(data)?.includes('IsEnableDeletePRWO');
    const editPM = data && Object.keys(data)?.includes('IsEnableEditScheduleDtls');
    const actionHandler = () => {
        if (notificationHistory && data.TobeAcknowledged === "N") return
        rules || fmea && newEntry || clc || clcWO ? click() : undefined;
        if (!viewExsist && !deleteExsist && !closeExsist && !updateChecklist && !assignTo) {
            setIsOpen(true);
        } else if (data?.IsEnableViewOption === "Y" || data?.IsEnableDeleteOption === "Y" || data?.IsEnableCloseoption === "Y" || data?.IsEnableChecklistUpdate === "Y" || data?.IsEnableAssignToOption === "Y") {
            setIsOpen(true);
        }
    }

    return (
        <>
            <IconButton ref={ref} onClick={actionHandler}>
                <Icon className="customTableMenuAction" icon={moreVerticalFill} width={20} height={20} />
            </IconButton>
            {/* ,marginTop:'30px' */}
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 'auto', minWidth: '118px', maxWidth: '100%', borderRadius: '10px' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={() => setIsOpen(false)}
                style={{ zIndex: '99999' }}
                className="userMenu"
            >
                {/* component={RouterLink} to="#" */}
                {block ? (
                    isAccess(accessData) ? (
                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                            {data?.status === "Active" ?
                                <ListItemText
                                    primary="Inactivate"
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    onClick={activeFn}
                                />
                                :
                                <ListItemText
                                    primary="Activate"
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    onClick={activeFn}
                                />
                            }

                        </MenuItem>
                    ) : null
                ) : null}
                {dtlMode ? (
                    isAccess(accessData) ? (
                        <div>
                            {!viewExsist && workflow || (viewExsist && data.IsEnableViewOption === "Y" && workflow) ?

                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary="View"
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={click}
                                    />
                                </MenuItem>
                                : null}

                            {workflow === null || workflow === undefined || (workflow && data?.IsEnableEditSR === "Y") || (workflow && data?.IsEnableEditScheduleDtls === "Y") ?
                                !hideEdit ?
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Edit"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={data?.IsEnableEditScheduleDtls === "Y" ? () => editFn(data, "Edit") : click}
                                        />
                                    </MenuItem> : null : null}

                            {workflow && data.IsEnableChangePMStatus === "Y" ?
                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary={data.CurrentStatus == "E" ? "Disable" : "Enable"}
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={() => { toggleStatus(data?.MaintPREVRequestKey, data.CurrentStatus == "E" ? "D" : "E") }}
                                    />
                                </MenuItem> : null}

                            {workflow && data.IsEnableAssignToOptionPM === "Y" ?
                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary="Assign"
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={() => { setAssign(data?.MaintPREVRequestKey) }} />
                                </MenuItem>
                                : null}

                            {workflow && setInspectionAction !== null && data.IsEnableAddChkLst === "Y" ?
                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary="Action Checklist"
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={() => { setInspectionAction(data?.MaintPREVRequestKey) }}
                                    />
                                </MenuItem>
                                : null}

                            {workflow && data.IsEnableDeletePR === "Y" ?
                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary="Delete"
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={remove}
                                    />
                                </MenuItem>
                                : null}

                        </div>
                    ) :
                        (
                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                <ListItemText primary="No Access" primaryTypographyProps={{ variant: 'body2' }} />
                            </MenuItem>
                        )
                ) : onlyDeleteMode ? (
                    isAccess(accessData) ? (
                        <div>
                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                <ListItemText
                                    primary="Delete"
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    onClick={click}
                                />
                            </MenuItem>
                        </div>
                    ) : (
                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                            <ListItemText primary="No Access" primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>
                    )
                ) : onlyEditMode ? (
                    isAccess(accessData) ? (
                        <div>
                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                <ListItemText
                                    primary="Edit"
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    onClick={remove}
                                />
                            </MenuItem>
                        </div>
                    ) : (
                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                            <ListItemText primary="No Access" primaryTypographyProps={{ variant: 'body2' }} />
                        </MenuItem>
                    )

                ) : onlyDeleteModeInner ? (isAccess(accessData) ? (
                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                        <ListItemText
                            primary="Delete"
                            primaryTypographyProps={{ variant: 'body2' }}
                            onClick={remove}
                        />
                    </MenuItem>
                ) : null)
                    :
                    !workflow && loadMode ?

                        rules ?
                            <>
                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary={data.EnableStatus=="E"?"Disable":"Enable"}
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={() => toggleStatus(data.RuleEngKey,  data.EnableStatus == "E" ? "D" : "E")}
                                    />
                                </MenuItem>
                                < MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary="Edit"
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={() => editFn(data, "Edit")}
                                    />
                                </MenuItem>
                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                    <ListItemText
                                        primary="Delete"
                                        primaryTypographyProps={{ variant: 'body2' }}
                                        onClick={remove}
                                    />
                                </MenuItem>
                            </>
                            : fmea ?
                                <>
                                    < MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="View"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => editFn(data, "View FMEA")}
                                        />
                                    </MenuItem>
                                    < MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary={data.EnableStatus == "E" ? "Disable" : "Enable"}
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => toggleStatus(data.FailureModeKey, data.EnableStatus == "E" ? "D" : "E")}
                                        />
                                    </MenuItem>

                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Failure indicators"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => editFn(data, "Failure Indicator")}
                                        />
                                    </MenuItem>
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Add Potential Causes"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => editFn(data, "Causal Parameter")}
                                        />
                                    </MenuItem>
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Assign Notification"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => editFn(data, "Assign Notification")}
                                        />
                                    </MenuItem>
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Delete"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={remove}
                                        />
                                    </MenuItem>
                                </>

                                : notificationHistory && !rules && !trial ?
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Edit"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => editFn(data, "Edit")}
                                        />
                                    </MenuItem>
                                    : !rules && !trial ?
                                        <>
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Edit"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => editFn(data, "Edit")}
                                                />
                                            </MenuItem>
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Delete"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={remove}
                                                />
                                            </MenuItem>

                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Add Member"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => editFn(data, "Add Member To")}
                                                />
                                            </MenuItem>
                                        </>
                                        : <>
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Edit"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => editFn(data, "Edit")}
                                                />
                                            </MenuItem>
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Delete"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={remove}
                                                />
                                            </MenuItem>
                                            {data.IsEnableUploadDowloadTrial === "Y" ?
                                                <>
                                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                        <ListItemText
                                                            primary="Download Template"
                                                            primaryTypographyProps={{ variant: 'body2' }}
                                                            onClick={() => onClickDownload(data.TrialKey, data.TrialFileName)}
                                                        />
                                                    </MenuItem>
                                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                        <ListItemText
                                                            primary="Upload Template"
                                                            primaryTypographyProps={{ variant: 'body2' }}
                                                            onClick={() => onClickUpload(data.TrialKey, data.TrialFileName)}
                                                        />
                                                    </MenuItem>
                                                </>
                                                : null}
                                            {data.ISEnableReport === "Y" ?
                                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                    <ListItemText
                                                        primary="Report"
                                                        primaryTypographyProps={{ variant: 'body2' }}
                                                        onClick={() => onClickDownloadReport(data.TrialKey, data.TrialFileName)}
                                                    />
                                                </MenuItem>
                                                : null}
                                        </>
                        :
                        (
                            <div>
                                {
                                    clcWO ? <>
                                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="View"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={() => editFn(data, "View Work Order")}
                                            />
                                        </MenuItem>
                                        {data.MCLCWorkOrderStatusName == "Open" ? <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="Close Work Order"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={() => editFn(data, "Close")}
                                            />
                                        </MenuItem> : null}
                                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="Delete"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={remove}
                                            />
                                        </MenuItem>
                                    </>
                                        : clcOnboard ?
                                            <>
                                                {/* <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="View"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => editFn(data, "View")}
                                        />
                                        </MenuItem> */}
                                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                    <ListItemText
                                                        primary="Edit"
                                                        primaryTypographyProps={{ variant: 'body2' }}
                                                        onClick={() => editFn(data, "Create")}
                                                    />
                                                </MenuItem>
                                                <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                    <ListItemText
                                                        primary="Update Status"
                                                        primaryTypographyProps={{ variant: 'body2' }}
                                                        onClick={() => editFn(data, "Update Status")}
                                                    />
                                                </MenuItem>
                                                {/* <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Delete"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={remove}
                                        />
                                    </MenuItem> */}
                                            </>
                                            : clc ?
                                                <>
                                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                        <ListItemText
                                                            primary="View"
                                                            primaryTypographyProps={{ variant: 'body2' }}
                                                            onClick={() => editFn(data, "View")}
                                                        />
                                                    </MenuItem>
                                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                        <ListItemText
                                                            primary="Edit"
                                                            primaryTypographyProps={{ variant: 'body2' }}
                                                            onClick={() => editFn(data, "Edit")}
                                                        />
                                                    </MenuItem>

                                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                        <ListItemText
                                                            primary="Delete"
                                                            primaryTypographyProps={{ variant: 'body2' }}
                                                            onClick={remove}
                                                        />
                                                    </MenuItem>
                                                </>
                                                : !viewExsist && !GBAdmin && !GBLimit || (viewExsist && data.IsEnableViewOption === "Y") ?

                                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                        <ListItemText
                                                            primary="View"
                                                            primaryTypographyProps={{ variant: 'body2' }}
                                                            onClick={click}
                                                        />
                                                    </MenuItem>
                                                    : null}
                                {workflow && data.IsEnableAssignToOption === "Y" ?
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Assign"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => { setAssign(data?.MaintPREVWorkOrderKey) }}
                                        />
                                    </MenuItem>
                                    : null}
                                {workflow && setInspectionAction !== null && data.IsEnableAddChkLst === "Y" ?
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Action Checklist"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={() => { setInspectionAction(data?.MaintPREVRequestKey) }}
                                        />
                                    </MenuItem>
                                    : null}

                                {(workflow && !deletePRExsist && !deletPWRExsist) || (workflow && deletePRExsist && data?.IsEnableDeletePR === "Y") || (workflow && deletPWRExsist && data?.IsEnableDeletePRWO === "Y") ?
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Delete"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={remove}
                                        />
                                    </MenuItem>
                                    : null}
                                {(isAccess(accessData) && GBAdmin) ? (
                                    <>

                                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="Edit"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={() => editFn(data, "Edit")}
                                            />
                                        </MenuItem>
                                        {machineModelName && machineModelName.includes("FB") ?
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="View Config Limit"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => loadConfig(data, "Limit Config", "View")}
                                                />
                                            </MenuItem>
                                            : null}
                                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="Configure /Edit Limit"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={() => loadConfig(data, data?.LimitSet === "N" ? "Config Limit" : "Limit Config", "Edit")}
                                            />
                                        </MenuItem>

                                    </>
                                ) : null}
                                {(isAccess(accessData) && GBLimit) ? (
                                    <>
                                        <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="Edit Config"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={() => editFn(data, "Limit Config")}
                                            />
                                        </MenuItem>

                                    </>
                                ) : null}
                                {/* {(workflow && !deletePRExsist && !deletPWRExsist) || (workflow && deletePRExsist && data?.IsEnableDeletePR === "Y") || (workflow && deletPWRExsist && data?.IsEnableDeletePRWO === "Y") ?
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Delete"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={remove}
                                        />
                                    </MenuItem>
                                    : null} */}

                                {(isAccess(accessData) && !GBLimit && !alarmFlag && !deleteExsist && !clc && !clcWO && !clcOnboard && !workflow) || (isAccess(accessData) && !GBLimit && !alarmFlag && deleteExsist && data.IsEnableDeleteOption == "Y" && !clc && !clcWO && !clcOnboard && !workflow) ? (
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Delete"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={remove}
                                        />
                                    </MenuItem>
                                ) : null}

                                {isAccess(accessData) && userFlag && data.IsLocked === "Y" ? (
                                    <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                        <ListItemText
                                            primary="Unlock"
                                            primaryTypographyProps={{ variant: 'body2' }}
                                            onClick={userUnlockFn}
                                        />
                                    </MenuItem>
                                ) : null}

                                {workflow ?
                                    <>
                                        {data?.IsEnableAssignButton === "Y" ? <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                            <ListItemText
                                                primary="Assign"
                                                primaryTypographyProps={{ variant: 'body2' }}
                                                onClick={() => editFn(data, "Assign")}
                                            />
                                        </MenuItem>
                                            : null}
                                        {data?.IsEnableActionTakenUpdateButton === "Y" ?
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Action"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => editFn(data, "Action")}
                                                />
                                            </MenuItem>
                                            : null}
                                        {data?.IsEnableReviewCloseButton === "Y" || (closeExsist && data?.IsEnableCloseoption === "Y") ?
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Close"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => editFn(data, closeExsist ? "Preventive Maintenance Close" : "Close")}
                                                />
                                            </MenuItem>
                                            : null}
                                        {updateChecklist && data?.IsEnableChecklistUpdate === "Y" && updateInspectionAction !== null ?
                                            <MenuItem sx={{ color: 'text.secondary' }} className="table_actionmenu">
                                                <ListItemText
                                                    primary="Update Inspection List"
                                                    primaryTypographyProps={{ variant: 'body2' }}
                                                    onClick={() => updateInspectionAction(data?.MaintPREVWorkOrderKey)}
                                                />
                                            </MenuItem>
                                            : null}

                                    </>
                                    : null}

                            </div>
                        )
                }
            </Menu >
        </>
    );
}
