import Drawer from '@mui/material/Drawer';
import * as Yup from 'yup';
import { Container } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import ACGButton from '../../../../atoms/Button';
import { useEffect, useRef, useState } from 'react';
import CustomDateTimePicker from '../../../../atoms/CustomDateTimePicker';
import { getLastSixMonths } from "../../../../utils/helper";
import './index.scss';
import DropDown from '../../../../atoms/Dropdown';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import { executeACGAction, resetStoreKey } from '../../../../store/slice';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import dateFormat from 'dateformat';
import Axios from 'axios';
import Box from '@mui/material/Box';
import FileDownload from 'js-file-download';

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
};

type CSVParams = {
    dropdownMachine: string | '';
    dropdownPlant: string | '';
    dateTimeFromSelect: string | '';
    dateTimeToSelect: string | '';
    machineName: string | '';
};

type SummaryParams = {
    machineKey: String;
    fromDateTime: String;
    toDateTime: String;
};

export default function CSVDrawer(props: Props) {

    const { status, handlerClose } = props;

    const drawerClose = () => { handlerClose(); };

    const [csvParams, setCSVParams] = useState<CSVParams>({
        dateTimeFromSelect: dateFormat(new Date().setHours(-(24 * 2)), 'yyyy-mm-dd hh:mm:ss'),
        dateTimeToSelect: dateFormat(new Date().setHours(-24), 'yyyy-mm-dd hh:mm:ss'),
        dropdownMachine: '',
        dropdownPlant: '',
        machineName: ''
    });

    const [validationSchema, setValidationSchema] = useState({});

    const [plantsList, setPlantsList] = useState<any>();
    const [selectedPlant, setSelectedPlant] = useState<any>();

    const [machineMachineSerialNos, setMachineMachineSerialNos] = useState<any>();

    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [reportResponse, setReportResponse] = useState<any>(null);

    const [isDownloadError, setIsDownloadError] = useState<boolean>(false);

    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);

    const REPORT_CODE = 'RAW';

    const resetResult = () => {
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
    };

    const handleSubmit = async (values: any) => {
        const { dropdownMachine, dropdownPlant, dateTimeToSelect, dateTimeFromSelect } = values;

        if (dropdownMachine && dateTimeFromSelect && dateTimeToSelect && dropdownPlant) {

            const fromDate = `${dateFormat(dateTimeFromSelect, 'yyyy-mm-dd HH:MM')}`;
            const toDate = `${dateFormat(dateTimeToSelect, 'yyyy-mm-dd HH:MM')}`;
            const model = machineMachineSerialNos.find((item: any) => item.code == dropdownMachine);
            const payload = { fromDateTime: fromDate, toDateTime: toDate, machineKey: model.code };
            const token = state?.[STORE_KEYS.USER_DTL].token;

            resetResult();

            setIsGenerating(true);

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_RAW_DATA_REPORT,
                method: 'post',
                responseType: 'blob',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        setIsGenerating(false);
                        setIsDownloadError(false);
                        setReportResponse(response.data);
                    } else {
                        setIsDownloadError(true);
                        setIsGenerating(false);
                        setReportResponse(null);
                    }
                })
                .catch((error) => {
                    setIsDownloadError(true);
                    return {
                        status: error.response.status
                    };
                });
        } else {
            alert('Please select all the fields or bad request');
        }
    };

    const getLineSerislData = (plantKey: String) => {
        setSelectedPlant(plantKey);
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_LINE_SERIAL_DATA,
                    reqObj: {
                        reportCode: REPORT_CODE,
                        plantKey: plantKey,
                        machineModelKey: 'ALL'
                    }
                },
                storeKey: STORE_KEYS.DN_LINE_SERIAL_DATA
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_LINE_SERIAL_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_LINE_SERIAL_DATA]?.body?.data;
            const models = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
                return newVal;
            });
            setMachineMachineSerialNos(models);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_LINE_SERIAL_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_LINE_SERIAL_DATA]]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
                return newVal;
            });
            setPlantsList(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_REPORT_PLANTS_DATA]?.body?.data]);

    useEffect(() => {

        setIsGenerating(false);
        setReportResponse(null);
        setIsDownloadError(false);
        setPlantsList(null);
        setSelectedPlant(null);
        setMachineMachineSerialNos(null);
        setCSVParams({
            ...csvParams,
            dateTimeFromSelect: dateFormat(new Date().setHours(-(24 * 2)), 'yyyy-mm-dd hh:mm:ss'),
            dateTimeToSelect: dateFormat(new Date().setHours(-24), 'yyyy-mm-dd hh:mm:ss'),
            dropdownMachine: '',
            dropdownPlant: '',
            machineName: ''
        });

        if (status) {
            setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.DN_REPORT_PLANTS_DATA,
                        reqObj: {
                            reportCode: REPORT_CODE
                        }
                    },
                    storeKey: STORE_KEYS.DN_REPORT_PLANTS_DATA
                })
            );
        }
    }, [status]);

    return (
        <Drawer anchor={'right'} open={status} onClose={drawerClose} style={{ zIndex: '9999' }} className='allreports'>
            <Box role="presentation" className={'standardBox1'} sx={{ height: "100%" }}>
                <div className="baseDiv">
                    {/* <div className={'dn-rp-heading-label'}>CSV Report</div> */}
                    <div
                        onClick={() => {
                            drawerClose();
                        }}
                        style={{
                            border: '1px solid #5D97F6',
                            borderRadius: '50px',
                            height: '25px',
                            width: '25px',
                            position: 'absolute',
                            left: '20px',
                            top: '16px',
                            cursor: 'pointer'
                        }}
                    >
                        <span
                            style={{
                                position: 'relative',
                                fontSize: '12px',
                                color: '#5D97F6',
                                left: '35%',
                                top: '-3%'
                            }}
                        >
                            X
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            width: '100%'
                            // padding:'10px'
                        }}
                    >
                        <div style={{ display: 'block', marginLeft: '45px' }}>
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat'
                                    // marginTop: '-7px'
                                }}
                            >
                                CSV Report
                            </h6>
                        </div>
                    </div>
                </div>
                <Container style={{ backgroundColor: '#2b2d42' }} className={'alm-dn-report-form1'}>
                    {/* <hr /> */}
                    <Formik
                        initialValues={csvParams}
                        validationSchema={validationSchema}
                        onSubmit={(values: any) => {
                            handleSubmit(values);
                        }}
                        validateOnBlur={false}
                        validateOnChange={true}
                        innerRef={formRef}
                    >
                        {(formikprops: FormikProps<any>) => {
                            const {
                                values,
                                handleChange,
                                handleBlur,
                                isValid,
                                errors,
                                touched,
                                setFieldValue,
                                resetForm
                            } = formikprops;
                            return (
                                <Form className="detailForm">
                                    <Label label="From Date" className={'labelDiv'} />
                                    <CustomDateTimePicker
                                        name="dateTimeFromSelect"
                                        value={values.dateTimeFromSelect}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('dateTimeFromSelect', val);
                                            resetResult();
                                        }}
                                        inputFormat="dd/MM/yyyy hh:mm aa"
                                        placeholder="Select From Date"
                                        minDate={getLastSixMonths()}
                                        helperText={
                                            errors.dateTimeFromSelect && touched.dateTimeFromSelect
                                                ? errors.dateTimeFromSelect
                                                : ''
                                        }
                                    />
                                    {errors?.dateTimeFromSelect && (
                                        <div style={{ marginTop: '10px' }}>
                                            <span
                                                style={{
                                                    color: '#F06868',
                                                    fontSize: '11px',
                                                    fontWeight: 600,
                                                    letterSpacing: 0,
                                                    marginTop: '20px',
                                                    marginBottom: '15px',
                                                    fontFamily: 'Montserrat',
                                                    marginLeft: '5%'
                                                }}
                                            >
                                                {errors?.dateTimeFromSelect}
                                            </span>
                                        </div>
                                    )}

                                    <Label label="To Date" className={'labelDiv'} />
                                    <CustomDateTimePicker
                                        name="dateTimeToSelect"
                                        value={values.dateTimeToSelect}
                                        className="inputDiv"
                                        onChange={(val: any) => {
                                            setFieldValue('dateTimeToSelect', val);
                                            console.log('dateTimeToSelect', val);
                                            resetResult();
                                        }}
                                        inputFormat="dd/MM/yyyy hh:mm aa"
                                        placeholder="Select To Date"
                                        minDate={getLastSixMonths()}
                                        helperText={
                                            errors.dateTimeToSelect && touched.dateTimeToSelect
                                                ? errors.dateTimeToSelect
                                                : ''
                                        }
                                    />
                                    {errors?.dateTimeToSelect && (
                                        <div style={{ marginTop: '10px' }}>
                                            <span
                                                style={{
                                                    color: '#F06868',
                                                    fontSize: '11px',
                                                    fontWeight: 600,
                                                    letterSpacing: 0,
                                                    marginTop: '20px',
                                                    marginBottom: '15px',
                                                    fontFamily: 'Montserrat',
                                                    marginLeft: '5%'
                                                }}
                                            >
                                                {errors?.dateTimeToSelect}
                                            </span>
                                        </div>
                                    )}
                                    <Label label="Plants" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(plantEvent: any) => {
                                            handleChange(plantEvent);
                                            const plant = plantsList.find(
                                                (item: any) => item.code === plantEvent.target.value
                                            );
                                            if (plant) {
                                                getLineSerislData(plant.code.toString());
                                                setFieldValue('dropdownMachine', '');
                                            }

                                            resetResult();
                                        }}
                                        className="inputDiv"
                                        name="dropdownPlant"
                                        items={plantsList}
                                        placeHolder="Select Plants"
                                        value={values.dropdownPlant}
                                        helperText={
                                            errors.dropdownPlant && touched.dropdownPlant ? errors.dropdownPlant : ''
                                        }
                                    />

                                    <Label label="Machine" className={'labelDiv'} />
                                    <DropDown
                                        onChange={(modelEvent: any) => {
                                            handleChange(modelEvent);
                                            const machine = machineMachineSerialNos.find(
                                                (item: any) => item.code === modelEvent.target.value
                                            );
                                            if (machine) {
                                                setCSVParams({ ...csvParams, machineName: machine.name });
                                            }
                                            resetResult();
                                        }}
                                        className="inputDiv"
                                        name="dropdownMachine"
                                        items={machineMachineSerialNos}
                                        placeHolder="Select Machine"
                                        value={values.dropdownMachine}
                                        helperText={
                                            errors.dropdownMachine && touched.dropdownMachine
                                                ? errors.dropdownMachine
                                                : ''
                                        }
                                    />
                                    {!isGenerating && !reportResponse && (
                                        <div className={'btns noWrap'}>
                                            <ACGButton
                                                name={'Reset'}
                                                handleClick={() => {
                                                    setCSVParams({
                                                        ...csvParams,
                                                        dateTimeFromSelect: dateFormat(
                                                            new Date().setHours(-(24 * 2)),
                                                            'yyyy-mm-dd hh:mm:ss aa'
                                                        ),
                                                        dateTimeToSelect: dateFormat(
                                                            new Date().setHours(-24),
                                                            'yyyy-mm-dd hh:mm:ss aa'
                                                        )
                                                    });
                                                    resetForm();
                                                    resetResult();
                                                }}
                                                secondary={true}
                                                className={'first-btn'}
                                                type="button"
                                                variant="contained"
                                            />
                                            <ACGButton
                                                name={'Submit'}
                                                handleClick={() => { }}
                                                type="submit"
                                                className="second-btn"
                                                variant="contained"
                                            />
                                        </div>
                                    )}
                                </Form>
                            );
                        }}
                    </Formik>

                    {isGenerating && !reportResponse && <Label label="Generating Report ..." className={'labelDiv'} />}

                    {isDownloadError && (
                        <Label label="Report Generation Error, Please retry" className={'labelDiv'} />
                    )}

                    {reportResponse && (
                        <div className={'btns noWrap'} style={{ position: 'relative' }}>
                            <div
                                onClick={() => {
                                    resetResult();
                                }}
                                style={{
                                    border: '1px solid #5D97F6',
                                    borderRadius: '50px',
                                    height: '25px',
                                    width: '25px',
                                    position: 'absolute',
                                    right: '-15px',
                                    top: '-15px',
                                    cursor: 'pointer'
                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',
                                        fontSize: '12px',
                                        color: '#5D97F6',
                                        left: '35%',
                                        top: '-3%'
                                    }}
                                >
                                    X
                                </span>
                            </div>
                            <ACGButton
                                name={'Download'}
                                handleClick={() => {
                                    if (reportResponse) {
                                        FileDownload(reportResponse, csvParams.machineName + '_raw_report.csv');
                                    }
                                }}
                                className={'first-btn'}
                                type="button"
                                variant="contained"
                            />
                        </div>
                    )}
                </Container>
            </Box>
        </Drawer>
    );
}
