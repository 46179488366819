import { useState, useEffect } from 'react';
import './tableRow.scss';
import TextInput from '../../TextInput';
import { STORE_KEYS } from '../../../constants/apiConstants';
import { reGroup } from '../../../utils/helper';
import useGetState from '../../../utils/hooks/useGetState';
import { TableRow, TableCell, Button } from '@mui/material';
import CustomDatePicker from '../../CustomDatePicker';
import DropDown from '../../Dropdown';
import useDebounce from '../../../hooks/useDebounce';
import moment from 'moment';
interface Props {
    header: any;
    row: any;
    isTableImageReqd?: boolean;
    isRadio?: boolean;
    isPermission?: boolean;
    checkDrodownSelection?: any;
    getUpdatedRowData: any;
    updatedReplaceDate?: any;
    setCLCMappingList?: any;
    clcMappingList?: any;
    qualityAttributeList?: any;
    setQualityAttributeList?: any;
    limitParameters?: any;
    tabIndex?: any;
    drawerHeader?: any;
}
const TableBody = (props: Props) => {

    const { row, header, getUpdatedRowData, updatedReplaceDate, setCLCMappingList, clcMappingList, qualityAttributeList, setQualityAttributeList, limitParameters, tabIndex = null, drawerHeader } = props;
    const { MMParamKey } = row;
    const [rowFormData, setRowFormData] = useState<any>({});
    const [changedRow, setChangedRow] = useState<any>(null);
    const debouncedMachineModelName = useDebounce<string>(changedRow, 800);
    const state = useGetState();
    const onChangeHandler = (e: any, id: string) => {
        const updatedRow = { ...rowFormData };
        updatedRow[id] = e.target.value;
        setRowFormData(updatedRow);
        setChangedRow(updatedRow);
    }

    useEffect(() => {
        setRowFormData(row);
        setChangedRow(null);
    }, [row, setRowFormData, setChangedRow])
    // For updating parameters state
    useEffect(() => {
        if (debouncedMachineModelName)
            getUpdatedRowData(debouncedMachineModelName);
    }, [debouncedMachineModelName]);

    const orderList = header.map((ele: any) => ele.id);

    const checkValidation = (ele : any, data: any) => {
        if(ele === 'OptimumMin' || ele === 'OptimumMax') {
            if(data === "" || data == null) return true;
            else return false;
        } else {
            return false;
        }
    }

    const displayArray = updatedReplaceDate && orderList.map((ele: any, index: number) => {
        const cellKey = index + 1;
        return <TableCell key={cellKey} style={ele === 'ParamCode' ? { position: 'sticky', left: 0, zIndex: 800 } : {}}>
            {drawerHeader === "View" && tabIndex ?
            <div style={{padding: '4px 0px'}}>{rowFormData[ele]}</div>
            :
            <div style={ele === 'ParamCode' ? { width: '79px' } : { width: 'max-content' }}>
                {ele === 'LastReplacedDate' ?

                    <CustomDatePicker
                        name="dateTimeFromSelect"
                        value={rowFormData[ele]}
                        className="inputDiv"
                        onChange={(val: any) => {

                            let data: any = [...clcMappingList];
                            const ind = clcMappingList.findIndex(function (item: any) {
                                return item.MCLCModelComponentKey === rowFormData["MCLCModelComponentKey"]
                            });
                            const tempData = data[ind];
                            data.splice(ind, 1)
                            data.splice(ind, 0, { AssemblyName: tempData.AssemblyName, AlertsBasisOperatingHours: tempData.AlertsBasisOperatingHours, ComponentName: tempData.ComponentName, LastReplacedDate: moment(val).format("DD MMM, yyyy"), CalendarLifeInHours: tempData.CalendarLifeInHours, OperatingLifeInHours: tempData.OperatingLifeInHours, MCLCModelComponentKey: tempData.MCLCModelComponentKey, ReplacementAlertInHours: tempData.ReplacementAlertInHours, MachineKey: tempData.MachineKey, MachineModelKey: tempData.MachineModelKey, PostDueReminderInHours: tempData.PostDueReminderInHours })

                            setCLCMappingList(data);
                        }}
                        inputFormat="dd/MM/yyyy"
                        placeholder="Select From Date"
                        maxDate={new Date()}
                        minDate={"01/01/2021"}
                    />

                    : ele === 'GBLimitTypesCodes' ?
                        <DropDown
                            onChange={(e) => {
                                if (tabIndex != null) {
                                    const data:any = Object.values(qualityAttributeList).flat()
                                        const ind = data.findIndex(function (item: any) {
                                            return item.MMParamKey === rowFormData["MMParamKey"] && item.PhaseKey === tabIndex
                                        });

                                const tempData = data[ind];
                                data.splice(ind, 1)
                                data.splice(ind, 0, { ParamName: tempData.ParamName, OptimumMin: tempData.OptimumMin, OptimumMax: tempData.OptimumMax, GBLimitTypesCodes: e.target.value, BatchKey: tempData.BatchKey, GBLimitTypes: tempData.GBLimitTypes, MachineModelName: tempData.MachineModelName, ProductName: tempData.ProductName, RecipeName: tempData.RecipeName, BatchNo: tempData.BatchNo, MMParamKey: tempData.MMParamKey, PhaseKey: tempData.PhaseKey })
                                setQualityAttributeList(reGroup(data, 'PhaseKey'));
                                }
                                else {
                                let data: any = [...qualityAttributeList];
                                const ind = qualityAttributeList.findIndex(function (item: any) {
                                    return item.MMParamKey === rowFormData["MMParamKey"]
                                });

                                const tempData = data[ind];
                                data.splice(ind, 1)
                                data.splice(ind, 0, { ParamName: tempData.ParamName, OptimumMin: tempData.OptimumMin, OptimumMax: tempData.OptimumMax, GBLimitTypesCodes: e.target.value, BatchKey: tempData.BatchKey, GBLimitTypes: tempData.GBLimitTypes, MachineModelName: tempData.MachineModelName, ProductName: tempData.ProductName, RecipeName: tempData.RecipeName, BatchNo: tempData.BatchNo, MMParamKey: tempData.MMParamKey })
                                setQualityAttributeList(data);
                            }}
                            }
                            className="inputDiv"
                            name="dropdownModel"
                            items={limitParameters}
                            placeHolder="Select Golden Batch Recipe"
                            value={String(rowFormData[ele])}
                        />
                        : <TextInput
                            readOnly={ele === 'OptimumMin' || ele === 'OptimumMax' ? false : true}
                            disabled={checkValidation(ele, rowFormData['GBLimitTypesCodes'])}
                            variant="outlined"
                            fullWidth
                            type={ele === 'OptimumMin' || ele === 'OptimumMax' ? 'number' : 'text'}
                            name="modelCode"
                            maxLength={4}
                            value={ele == "AlertsBasisOperatingHours" ? rowFormData[ele] == "Y" ? "Yes" : "No" : rowFormData[ele] ?? ''}
                            autoComplete="off"
                            formInput="inputDiv"
                            onChange={(e) => {
                                if (ele === "OptimumMin") {
                                    let value = e.target.value = Math.max(0, parseFloat(e.target.value)).toString().slice(0, 4)
                                    if (tabIndex != null) {
                                        const data:any = Object.values(qualityAttributeList).flat()
                                        const ind = data.findIndex(function (item: any) {
                                            return item.MMParamKey === rowFormData["MMParamKey"] && item.PhaseKey === tabIndex
                                        });
                                        
                                        const tempData = data[ind];
                                        data.splice(ind, 1)
                                        data.splice(ind, 0, { ParamName: tempData.ParamName,
                                             OptimumMin: value,
                                             OptimumMax: tempData.OptimumMax,
                                             GBLimitTypesCodes: tempData.GBLimitTypesCodes,
                                             BatchKey: tempData.BatchKey,
                                             GBLimitTypes: tempData.GBLimitTypes,
                                             MachineModelName: tempData.MachineModelName,
                                             ProductName: tempData.ProductName,
                                             RecipeName: tempData.RecipeName,
                                             BatchNo: tempData.BatchNo,
                                             MMParamKey: tempData.MMParamKey,
                                             PhaseKey: tempData.PhaseKey
                                             })
                                        setQualityAttributeList(reGroup(data, 'PhaseKey'));
                                    } else {
                                        let data: any = [...qualityAttributeList];
                                        const ind = qualityAttributeList.findIndex(function (item: any) {
                                            return item.MMParamKey === rowFormData["MMParamKey"]
                                        });
                                        const tempData = data[ind];
                                        data.splice(ind, 1)
                                        data.splice(ind, 0, { ParamName: tempData.ParamName, OptimumMin: value, OptimumMax: tempData.OptimumMax, GBLimitTypesCodes: tempData.GBLimitTypesCodes, BatchKey: tempData.BatchKey, GBLimitTypes: tempData.GBLimitTypes, MachineModelName: tempData.MachineModelName, ProductName: tempData.ProductName, RecipeName: tempData.RecipeName, BatchNo: tempData.BatchNo, MMParamKey: tempData.MMParamKey })
                                        setQualityAttributeList(data);
                                    }
                                } else if (ele === "OptimumMax") {
                                    let value = e.target.value =  Math.max(0, parseFloat(e.target.value)).toString().slice(0, 4)

                                    if (tabIndex != null) {
                                        const data:any = Object.values(qualityAttributeList).flat()
                                        const ind = data.findIndex(function (item: any) {
                                            return item.MMParamKey === rowFormData["MMParamKey"] && item.PhaseKey == tabIndex
                                        });
                                        const tempData = data[ind];
                                        data.splice(ind, 1)
                                        data.splice(ind, 0, { ParamName: tempData.ParamName, OptimumMin: tempData.OptimumMin, OptimumMax: value, GBLimitTypesCodes: tempData.GBLimitTypesCodes, BatchKey: tempData.BatchKey, GBLimitTypes: tempData.GBLimitTypes, MachineModelName: tempData.MachineModelName, ProductName: tempData.ProductName, RecipeName: tempData.RecipeName, BatchNo: tempData.BatchNo, MMParamKey: tempData.MMParamKey, PhaseKey: tempData.PhaseKey })
                                        setQualityAttributeList(reGroup(data, 'PhaseKey'));
                                    }
                                    else {
                                    let data: any = [...qualityAttributeList];
                                    const ind = qualityAttributeList.findIndex(function (item: any) {
                                        return item.MMParamKey === rowFormData["MMParamKey"]
                                    });
                                    const tempData = data[ind];
                                    data.splice(ind, 1)
                                    data.splice(ind, 0, { ParamName: tempData.ParamName, OptimumMin: tempData.OptimumMin, OptimumMax: value, GBLimitTypesCodes: tempData.GBLimitTypesCodes, BatchKey: tempData.BatchKey, GBLimitTypes: tempData.GBLimitTypes, MachineModelName: tempData.MachineModelName, ProductName: tempData.ProductName, RecipeName: tempData.RecipeName, BatchNo: tempData.BatchNo, MMParamKey: tempData.MMParamKey })
                                    setQualityAttributeList(data);
                                }
                                }
                                else {
                                    onChangeHandler(e, ele)
                                }
                            }
                            }
                            helperText={''}
                        />}
            </div>
            }
        </TableCell>
    });
    return (
        <TableRow
            hover
            key={MMParamKey}
            tabIndex={-1}
            role="checkbox"
            className="tableRow"
        >
            {displayArray}
        </TableRow>
    );
};

export default TableBody;
