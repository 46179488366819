import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { Dialog, DialogTitle, DialogContent, Popover, Button, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/styles'
import TextInput from '../../../atoms/TextInput';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  popover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300px', // Adjust the width as needed
    //padding: theme.spacing(2),
  },
}));
const NotificationCard = (props: any) => {
  const [openn, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    props.getNotificationDetails(props.index, props.type);
  };

  const handleClose = () => {
    setOpen(false);
    props.setNotificationDetails([]);
  };
  const [selectedOption, setSelectedOption] = useState('1');
  const handleRadioChange = (event: any, index: any) => {
    if (props.ackError.includes(index)) {
      const pos = props.ackError.indexOf(index);
      const data = [...props.ackError]
      data.splice(pos, 1);
      props.setAckError(data);
    }
    setSelectedOption(event.target.value);
    props.setAckSelectedNotification(props.ackSelectedNotification.filter(function (obj: any) {
      return obj?.id !== index;
    }));

  };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const [showDiv, setShowDiv] = useState(false);

  const handleButtonClick1 = () => {
    setShowDiv(!showDiv);
  };

  const handleButtonClick2 = () => {
    setShowDiv(!showDiv);
  };

  useEffect(() => {
    setSelectedOption('1');
  }, [props.data])
  const getClassName = (severity: string): string => {
    let obj: Record<string, string> = {
      "Major": "cardnotify majorCls",
      "Minor": "cardnotify minorCls",
      "Critical": "cardnotify criticalCls",
      "Very High": "cardnotify veryHighCls",
      "High": "cardnotify highCls",
      "Medium": "cardnotify mediumCls",
      "Low": "cardnotify lowCls",
      "Very Low": "cardnotify veryLowCls"
    };

    return obj?.[severity] ?? "";
  };
  return (
    <>
      <div className={getClassName(props?.data?.Severity)}>
        {console.log("dateformat", props?.data?.TriggeredTime)}
        <div className='alertcls alert_date'><span>Trigger Date:</span>{moment.utc(props?.data?.TriggeredTime,"DD-MMM-YYYY hh:mm:ss A").format("DD/MM/YYYY | hh:mm:ss A")}</div>
        <div className='alertcls alert_title'><span>Title :</span>{props?.data?.AlertName}</div>
        <div className='alertcls alert_source'><span>Source :</span>{props?.data?.ModuleName}</div>
        <div className='alertcls machine_serialno'><span>Machine Serial No :</span>{props?.data?.MachineSerialNo}</div>
        <div className='alertcls alert_severity'>
          <span>Severity Status :</span>
          <span className={props?.data?.Severity === "Very High" ? "majorsevirity" : props?.data?.Severity === "Low" ? "minorsevirity" : props?.data?.Severity === "High" ? "majorsevirity" : props?.data?.Severity === "Medium" ? "minorsevirity" : "criticalsevirity"} >{props?.data?.Severity}</span>
        </div>
        <div className='alertcls dtei_link'><span>Details :</span><span className='detaillinks' onClick={handleOpen}>Click here</span></div>
        <div className="recom">
        {props?.data?.RecommendedActions !== null ?
          <div className='alertcls tigerdatetime'><span>Recommended Action</span>
            <Tooltip title={<Typography fontSize={12} className='tooltiptxt'>
              <ul className='recomendedOption'>
                {console.log(JSON.parse(props?.data?.RecommendedActions))}
              {JSON.parse(props?.data?.RecommendedActions).map((item:any) => {
                return(<li>{item.RecommendedActions}</li>)
              })}
              </ul>
            </Typography>} arrow>
              <HelpIcon sx={{ justifyContent: 'center', color: '#ffffff', marginLeft: "2px" }} />
            </Tooltip>
          </div>
          : null
        }
        </div>
        <div className='radiobtnnotify'>
          <form>
            <label>
              <input
                type="radio"
                value="1"
                checked={selectedOption === '1'}
                onChange={(e) => handleRadioChange(e, props.index)}
              />
              Accept
            </label>
            <label>
              <input
                type="radio"
                value="2"
                checked={selectedOption === '2'}
                onChange={(e) => handleRadioChange(e, props.index)}
              />
              Ignore
            </label>
          </form>

          {selectedOption === '1' && (
            <div className='textboxcol optone'>
              <form>
                <TextInput
                autoComplete="off"
                  sx={{ marginBottom: "0px", height: "32px" }}
                  id="outlined-basic"
                  key={props.index}
                  variant="outlined"
                  fullWidth
                  placeholder='Enter Action Taken *'
                  value={props.ackSelectedNotification.find((i: any) => props.index === i.id) ? props.ackSelectedNotification.find((i: any) => props.index === i.id)?.value : ""}
                  onChange={(e: any) => props.handleChangeAck(e, props.index, selectedOption)}
                />
                <span className='errorsown' style={{ color: "#F06868" }}>{props.ackError.includes(props.index) ? "Please enter action" : null}</span>
              </form>
            </div>
          )}
          {selectedOption === '2' && (
            <div className='textboxcol opttwo'>
              <form>
                <TextInput
                  sx={{ marginBottom: "0px", height: "32px" }}
                  id="outlined-basic"
                  key={props.index}
                  variant="outlined"
                  fullWidth
                  placeholder='Enter Remarks'
                  value={props.ackSelectedNotification.find((i: any) => props.index === i.id) ? props.ackSelectedNotification.find((i: any) => props.index === i.id)?.value : ""}
                  onChange={(e: any) => props.handleChangeAck(e, props.index, selectedOption)}

                />

              </form>
            </div>
          )}
          <div className='alertcls actionbutons'>
            <Button variant="outlined" className='rejectbtn' onClick={() => props.handleCancelAck(props.index)}>Cancel</Button>
            <Button variant="contained" className='acceptbtn' onClick={() => props.handleSaveAck(props.index, props.type, selectedOption)} >Save</Button>
          </div>
        </div>

      </div>

      <Dialog open={openn} onClose={handleClose} className='notifyDetails' style={{zIndex:'9999'}}>
        <DialogContent className='customtopcls alertnot'>
          <div className="base-Div">
            <div
              onClick={handleClose}
              style={{
                border: '1px solid #5D97F6',
                borderRadius: '50px',
                height: '25px',
                width: '25px',
                position: 'absolute',
                right: '12px',
                top: '12px',
                cursor: 'pointer'
              }}
            >
              <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                X
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                borderBottom: '1px solid gray',
                padding: '0 10px'
              }}
            >
              <div className='titlemodel'>
                <h6 style={{ padding: '15px 20px', color: '#DADCEE', fontSize: '16px', fontFamily: 'Montserrat', marginBottom: '0' }}>
                  Alert Details
                </h6>
              </div>

            </div>
            <div className='contentmodel'>
              <div
                style={{
                  color: '#DADCEE',
                  fontSize: '16px',
                  fontFamily: 'Montserrat',
                  padding: '0px'
                }}
              >
                <div className='popupcontents'>
                  <div className='alertcls alert_cname'><span>Customer Name </span>: &nbsp; {props?.data?.CustomerName}</div>
                  <div className='alertcls alert_mcname'><span>Machine Name </span>: &nbsp; {props.notificationDetails[0]?.MachineName}</div>
                  <div className='alertcls alert_modelname'><span>Machine Model </span>: &nbsp; {props?.data?.MachineModelName}</div>
                  <div className='alertcls alert_plantname'><span>Plant Name </span>: &nbsp; {props?.data?.PlantName}</div>
                  <div className='alertcls alert_formula'><span>Formula </span>: &nbsp; {props.notificationDetails[0]?.ConditionFormula}</div>
                  {/* <div className='alertcls alert_trigTime'><span>Trigger Time </span>: &nbsp; {props.notificationDetails[0]?.TriggedTime}</div> */}
                  <div className='alertcls tigerdatetime'><span>Trigger Date </span>: &nbsp; {props?.data?.TriggeredTime}</div>
                </div>
                <TableContainer component={Paper}>
                  <Table>
                    {props.notificationDetails[0]?.ConditionFormula !== null ?
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell>Parameter Name</TableCell>
                            <TableCell>Parameter Value</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {props.notificationDetails[0]?.ParamName.split(',').map((item: any, index: number) => {
                            const value = props.notificationDetails[0]?.ParamValue.split(',');
                            return (
                              <TableRow>
                                <TableCell>{item}</TableCell>
                                <TableCell>{value[index]}</TableCell>
                              </TableRow>
                            )
                          })
                          }
                        </TableBody>
                      </>
                      :
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell>Alert name</TableCell>
                            <TableCell>Parameter Name</TableCell>
                            <TableCell>Parameter Value</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {props.notificationDetails.map((item: any, index: number) => {

                            return (
                              <TableRow>
                                <TableCell>{item.AlertName}</TableCell>
                                <TableCell>{item.ParamName}</TableCell>
                                <TableCell>{item.ParamValue}</TableCell>
                              </TableRow>
                            )
                          })
                          }
                        </TableBody>
                      </>

                    }
                  </Table>
                </TableContainer>
              </div>

            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NotificationCard;