import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Label from '../../../../../atoms/Label/index';
import DownArrow from '../../../../../../assets/down-arrow.svg';
import DropDown from '../../../../../atoms/Dropdown';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import CustomDatePicker from '../../../../../atoms/CustomDatePicker';
import ACGButton from '../../../../../atoms/Button';
import "../../cm.scss";
import { Grid } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function CreateForm(props: any) {
  const [reset, setReset] = useState(true);

  const handleReset = () => {
    var date = new Date();
    date.setDate(date.getDate() - 7)
    date.setHours(12, 0, 0, 0);
    props.setInDate(date)
    var d = new Date();
    d.setHours(12, 0, 0, 0);
    props.setEndDate(new Date(d.setDate(d.getDate() - 1)));
    props.setErrorFalse(false);
    props.setSelectedPlantsName([]);
    props.setPlantsData([]);
    props.setCustomerKey("");
    props.setPlantName([]);
    props.setPlantKeys([]);
    setReset(!reset);
  };

  const yesDisabled = props.selectedMachineModel.length === 0 || props.errorDate ? true : false;
  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );
  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { props.drawerClose(); props.ApplyFilters(); props.setAppliedFilters(true) }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled={yesDisabled ? true : false}
        />
      </div>
    );
  };
  return (
    <Container className="createReport">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <Label label="Start Date" className={'CMlabelDiv'} />
            <CustomDatePicker
              name="dateFromSelect"
              value={props.inDate}
              maxDate={new Date()}
              className="inputDiv"
              onChange={(val: any) => {
                props.setInDate(val);
              }}
              inputFormat="dd/MM/yyyy"
              placeholder="Select From Date"
            />

            <Label label="End Date" className={'CMlabelDiv'} />
            <CustomDatePicker
              name="dateFromSelect"
              value={props.endDate}
              minDate={props.inDate}
              maxDate={new Date()}
              className="inputDiv"
              onChange={(val: any) => {
                props.setEndDate(val);
              }}
              inputFormat="dd/MM/yyyy"
              placeholder="Select End Date"
            />
            {/* <Label label="Select Machine Model" className={'CMlabelDiv'} />
            <FormControl sx={{ width: '100%' }}>
              {props.machineModel?.length > 0 ? null : (
                <InputLabel
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '13px',
                    paddingTop: '0px',
                    paddingLeft: '12px',
                    color: '#b1c2df',
                    lineHeight: '1',
                    opacity: '0.7'
                  }}
                  id="demo-multiple-checkbox-label"
                >
                  Select Machine Model
                </InputLabel>
              )}
              <Select
                IconComponent={DropDownIcon}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                label=""
                multiple
                value={props.selectedMachineModel}
                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                placeholder='Please select machines'
                // input={<OutlinedInput />}
                renderValue={(selected) => selected.map((x:any) => x.MachineModelName).join(', ')}
                MenuProps={MenuProps}

              >
                {props.machineModel?.map((i: any) => (
                  <MenuItem value={i} onClick={() => props.handleMachineModel(i)}>
                  <Checkbox
                    checked={
                      props.selectedMachineModel.findIndex((item: any) => item.MachineModelKey === i.MachineModelKey) >= 0
                    }

                  />
                  <ListItemText primary={i.MachineModelName} />
                </MenuItem>
                ))}
              </Select>
            </FormControl> */}
       <Label label="Select Machine Model" className={'CMlabelDiv'} />
            <DropDown
              onChange={props.handleMachineModel}
              className="inputDiv dropdownbx"
              name="dropdownModel"
              items={props.machineModel}
              placeHolder="Select Machine Serial No."
              value={props.selectedMachineModel}
            />
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
};
