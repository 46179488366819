import { useState, useEffect } from "react";
import * as React from 'react';
import CustomTable from "../../../atoms/CustomTable/index";
import CustomTableToolbar from "../../../atoms/CustomTableToolbar";
import { Container, Card } from "@mui/material";
import useGetState from "../../../utils/hooks/useGetState";
import { useDispatch } from "react-redux";
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../../store/slice";
import { ACTION_CODES, STORE_KEYS } from "../../../constants/apiConstants";
import DarkTheme from '../../../../themes/Dark.json';
import Axios from 'axios'
import SnackbarAlert from "../../../atoms/Snackbar";
import { report } from "process";

export interface LocaleSettings {
  language: string;
  formatLocale: string;
}

export interface Settings {
  filterPaneEnabled: boolean;
  navContentPaneEnabled: boolean;
  localeSettings: LocaleSettings;
}

const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  },
  RM_SUCCESS: {
    message: 'Plant deleted successfully!',
    open: true,
    type: 'success'
  }
};

const UserReports = (props:any) => {

  const [page, setPage] = useState<number>(0);
  const [filterName, setFilterName] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [masterTableData, setMasterTableData] = useState<any[]>([]);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [filteredReportType, setFilteredReportType] = useState (-1);
  const [filteredSeverity, setFilteredSeverity] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [machines, setMachines] = useState([]);

  const dispatch = useDispatch();

  const stateObj = useGetState();

  const token = stateObj?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = stateObj[STORE_KEYS.USER_DTL]?.LoggedUserKey;

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setTableData(props.data);
    setMasterTableData([...props.data]);
  },[props.data])

  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

  const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {

    setPage(0);
    if (event.target.value === undefined) //for clear button action
    {
      setFilterName("");
      setTableData([...props.data]);
    }
    else {
      setFilterName(event.target.value);
      const filteredArray = event.target.value.length > 0 ?
      props.data.filter((obj:any) => obj.AlertName.toLowerCase().includes(String(event.target.value).toLowerCase()) || obj.Severity.toLowerCase().includes(String(event.target.value).toLowerCase()) || obj.ModuleName.toLowerCase().includes(String(event.target.value).toLowerCase()) || obj.AlertType.toLowerCase().includes(String(event.target.value).toLowerCase()))  :
      props.data;
      setTableData(filteredArray);
    }
  };

  const resetInput = (e:any) => {
    setFilterName('');
    handleFilterByName(e)
};

  return (
    <div>
      <div>
        <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
        <Container
          maxWidth="xl"
          style={{
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          <Card
            style={{
              borderRadius: "0px",
              backgroundColor: "#2b2d42",
              overflow: "inherit",
            }}
          >
            <CustomTableToolbar
              drawerOpen={()=>{
                props.setCreateDrawerOpen(true);
                props.setDrawerHeader("Filter");
                props.setCreateValue("Notifications")
            }}
              numSelected={0}
              filterName={filterName}
              onFilterName={handleFilterByName}
              resetInput={resetInput}
              displayInput={true}
              text={{
                title: "Total Notifications",
                subTitle: `${tableData?.length} Notifications`
              }}
              filterButton={true}
              customButton={false}
              appliedFilters={props.appliedFilters}
              clearFilter={props.clearFilter}
              placeholder="Search by Title, Source and Status"
            />
          </Card>
        </Container>
        <CustomTable
          selectable={false}
          headers={[]}
          body={tableData}
          filteredUsers={tableData}
          filterName={""}
          selected={(obj: any) => { }}
          order={""}
          orderBy={""}
          onRequestSort={() => { }}
          onSelectAllClick={() => { }}
          rowChange={() => { }}
          rowCount={tableData.length}
          onPageChange={handleChangePage}
          page={page}
          setPageNumber={setPage}
          selectRow={(rowIndex: any) => {
            setSelectedIndex(rowIndex);
            setSelectedRow(tableData[rowIndex]);
          }
          }
          route={[]}
          isRequestPage={true}
          link={true}
          type="Notification_List"
          setAckSelectedNotification={props.setAckSelectedNotification}
          handleCancelAck={props.handleCancelAck}
          handleSaveAck={props.handleSaveAck}
          ackSelectedNotification={props.ackSelectedNotification}
          setSelectedNotification={props.setSelectedNotification}
          selectedNotification={props.selectedNotification}
          handleChangeAck={props.handleChangeAck}
          notificationDetails={props.notificationDetails}
          getNotificationDetails={props.getNotificationDetails}
          setNotificationDetails={props.setNotificationDetails}
          setAckError={props.setAckError}
          ackError={props.ackError}
          isLoading={props.isLoading}
        />
     
      </div>
    </div>
  );
};

export default UserReports;