import React, { useState, useEffect } from 'react';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const Stacked = (props:any) => {
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);
  const [opdata4, setOPData4] = useState<any>([]);

  useEffect(()=> {
    const lab:any = [], d1:any = [], d2:any = [], d3:any = [], d4:any = [];

    props?.oeeDetails.map((i:any)=> {
      if( !lab.includes(i.TimeName)) lab.push(i.TimeName);
      d1.push(i.Availability);
      d2.push(i.OEE);
      d3.push(i.Performance);
      d4.push(i.Quality);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
    setOPData4(d4);
  },[props])
  const options = {
    plugins: {
      datalabels: {color: 'white', display: false},
      legend: {
        labels: {
          color: "#e0e0e0",
          font: {
            size: 12
          }
        }
      }       
      },
      responsive: true,
      scales: {
        x:{
          stacked: true,
          ticks: {
            color: 'white', 
         },
         grid: {
          color: 'transparent',
        },
        },
        y: {
          type: 'linear' as const,
          display: true,
          position: 'left' as const,
          ticks: {
                  color: 'white', 
               },
               grid: {
                color: '#878f99',
              },
               title: {
                display: true,
                text: 'Percentage %',
                color: 'white',
                font: {
                  size: 12
                }
              },
        },
      },
    };
  
  const labels =  labelSet;
  
 const data = {
    labels,
    datasets: [
      {
        label: 'Availability %',
        data: opdata1,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y',
      },
      {
        label: 'OEE %',
        data: opdata2,
        backgroundColor: '#0dcaf0',
        borderColor: '#0dcaf0',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y',
      },
      {
        label: 'Performance %',
        data: opdata3,
        backgroundColor: '#198754',
        borderColor: '#198754',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y',
      },
      {
        label: 'Quality %',
        data: opdata4,
        backgroundColor: '#FF4747',
        borderColor: '#FF4747',
        borderWidth: 2,
        type: 'line' as const,
        yAxisID: 'y',
      },
      
    ],
  };

  return <Chart type='bar' data={data} options={options} plugins={[ChartDataLabels]} />;
}

export default Stacked;
