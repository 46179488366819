// feature/UserReport/DownloadableReport/DowntimeReport/index
import Drawer from '@mui/material/Drawer';
import { Form, Formik, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import ACGButton from '../../../../atoms/Button';
import { useEffect, useRef, useState } from 'react';
import { FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import DownArrow from '../../../../../assets/down-arrow.svg';
import {
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
import './index.scss';
import Box from '@mui/material/Box';
import DropDown from '../../../../atoms/Dropdown';
import { VALIDATION_YUPSCHEMA } from './ValidationSchema';
import * as Yup from 'yup';
import { Container } from '@mui/material';
import { executeACGAction, resetStoreKey } from '../../../../store/slice';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../constants/apiConstants';
import useGetState from '../../../../utils/hooks/useGetState';
import { renameObjKeys } from '../../../Login/helper';
import { useDispatch } from 'react-redux';
import FileDownload from 'js-file-download';
import dateFormat from 'dateformat';
import { formatOnlyDate } from '../../../../utils/formatTime';
import Axios from 'axios';
import { SnackBarOptions, Dateformat } from '../CommonUtil';
import SnackbarAlert from '../../../../atoms/Snackbar';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
};

type DowntimeParams = {
    dateFromSelect: string | '';
    dateToSelect: string | '';
    dropdownMachineName: string | '';
    dropdownProduct: string | '';
    dropdownBatchNo: string | '';
    dropdownShiftName: string | '';
    modelName: string | '';
};

export default function DowntimeReportDrawer(props: Props) {
    const { status, handlerClose } = props;

    const drawerClose = () => {
        handlerClose();
    };

    const [snackbarOptions, setSnackbarOptions] = useState(SnackBarOptions.DEFAULT);
    const closeSnackbar = () => setSnackbarOptions(SnackBarOptions.DEFAULT);

    const [downtimeParams, setDowntimeParams] = useState<DowntimeParams>({
        dateFromSelect: dateFormat(new Date().setHours(-(24 * 7)), 'yyyy-mm-dd'),
        dateToSelect: dateFormat(new Date(), 'yyyy-mm-dd'),
        dropdownMachineName: '',
        dropdownProduct: '',
        dropdownBatchNo: '',
        dropdownShiftName: '',
        modelName: ''
    });
    const date = new Date();
    const [startDate, setStartDate] = useState<any>(new Date(new Date(date.getFullYear(), date.getMonth(), 1)));
    const [endDate, setEndDate] = useState<any>(new Date());

    const [validationSchema, setValidationSchema] = useState({});

    const [machineNames, setMachineNames] = useState<any>([]);
    const [selectedMachineName, setSelectedMachineName] = useState<any>([]);

    const [products, setProducts] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);

    const [batches, setBatches] = useState<any>([]);
    const [selectedBatch, setSelectedBatch] = useState<any>([]);

    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>([]);
    const [noBatches, setNoBatches] = useState(false);
    const [noShifts, setNoShifts] = useState(false);
    const [noMachines, setNoMachines] = useState(false);
    const [noProducts, setNoProducts] = useState(false);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [reportResponse, setReportResponse] = useState<any>(null);
    const [isDownloadError, setIsDownloadError] = useState<boolean>(false);
    const [presetKey, setPresetKey] = useState('');
    const state = useGetState();
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const REPORT_CODE = 'DTRPT';

    const getDowntimeFilters = (obj: any, pStoreKey: string) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.DN_RPT_DOWNTIME_FILTER_DATA,
                    reqObj: obj
                },
                storeKey: pStoreKey
            })
        );
    };

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_SHIFT_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_SHIFT_DATA]?.body?.data;
            let index = 0;
            const plants = data?.map((item: any) => {
                const item1 = Object.assign({}, item, { code: index });
                const newVal = renameObjKeys(item1, { code: 'code', ShiftName: 'name' });
                index++;
                return newVal;
            });
            setShifts(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_SHIFT_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_SHIFT_DATA]?.body?.data]);

    useEffect(() => {
        const date = new Date();
        if (selectedMachineName && selectedProduct && selectedBatch.length) {
            const keys: any = [];
            const mkeys: any = [];
            const batchName: any = [];
            selectedMachineName.map((i: any) => mkeys.push(i.code));
            selectedProduct.map((i: any) => keys.push(i.code));
            selectedBatch.map((i: any) => batchName.push(i.name));
            const shiftPayload = {
                params: {
                    type: 'shift',
                    machineKey: mkeys.toString(),
                    batch: batchName.toString(),
                    zproductkey: keys.toString(),
                    startDate: dateFormat(
                        startDate,
                        Dateformat.YYYY_MM_DD
                    ),
                    endDate: dateFormat(
                        endDate,
                        Dateformat.YYYY_MM_DD
                    ),
                }
            };
            getDowntimeFilters(shiftPayload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_SHIFT_DATA);
        }
    }, [selectedBatch, startDate, endDate]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA]?.body?.data;
            let index = 0;
            const plants = data?.map((item: any) => {
                const item1 = Object.assign({}, item, { code: index });
                const newVal = renameObjKeys(item1, { code: 'code', BatchNo: 'name' });
                index++;
                return newVal;
            });
            setBatches(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA]?.body?.data]);

    useEffect(() => {
        const date = new Date();
        if (selectedProduct) {
            const keys: any = [];
            const mkeys: any = [];
            selectedMachineName.map((i: any) => mkeys.push(i.code));
            selectedProduct.map((i: any) => keys.push(i.code));
            const batchPayload = {
                params: {
                    type: 'batchNo',
                    machineKey: mkeys.toString(),
                    zproductkey: keys.toString(),
                    startDate: dateFormat(
                        startDate,
                        Dateformat.YYYY_MM_DD
                    ),
                    endDate: dateFormat(
                        endDate,
                        Dateformat.YYYY_MM_DD
                    ),
                }
            };
            getDowntimeFilters(batchPayload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_BATCH_DATA);
        }
    }, [selectedProduct, startDate, endDate]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { ZProductKey: 'code', ProductName: 'name' });
                return newVal;
            });
            setProducts(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA]?.body?.data]);

    useEffect(() => {
        if (selectedMachineName) {
            const keys: any = [];
            selectedMachineName.map((i: any) => keys.push(i.code));
            const payload = {
                params: {
                    type: 'productKey', machineKey: keys.toString(),
                    startDate: dateFormat(
                        startDate,
                        Dateformat.YYYY_MM_DD
                    ),
                    endDate: dateFormat(
                        endDate,
                        Dateformat.YYYY_MM_DD
                    ),
                }
            };
            getDowntimeFilters(payload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_PRODUCT_DATA);
        }
    }, [selectedMachineName]);

    useEffect(() => {
        if (state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA]?.body?.data) {
            const data = state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA]?.body?.data;
            const plants = data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineKey: 'code', MachineName: 'name' });
                return newVal;
            });
            setMachineNames(plants);
            dispatch(resetStoreKey({ storeKey: STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA }));
        }
    }, [state?.[STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA]?.body?.data]);

    useEffect(() => {
        const date = new Date();
        setValidationSchema(Yup.object().shape(VALIDATION_YUPSCHEMA));
        resetResult();
        setMachineNames([]);
        setSelectedMachineName([]);
        setProducts([]);
        setSelectedProduct([]);
        setBatches([]);
        setSelectedBatch([]);
        setShifts([]);
        setSelectedShift([]);
        setDowntimeParams({
            dateFromSelect: dateFormat(new Date(date.getFullYear(), date.getMonth(), 1), Dateformat.YYYY_MM_DD),
            dateToSelect: dateFormat(new Date(), Dateformat.YYYY_MM_DD),
            dropdownMachineName: '',
            dropdownProduct: '',
            dropdownBatchNo: '',
            dropdownShiftName: '',
            modelName: ''
        });

        if (status) {
            if (startDate && endDate) {
                const fromDate = dateFormat(startDate, Dateformat.YYYY_MM_DD);
                const toDate = dateFormat(
                    endDate,
                    Dateformat.YYYY_MM_DD
                );

                const payload = {
                    params: {
                        type: 'machineName',
                        startDate: fromDate,
                        endDate: toDate
                    }
                };
                getDowntimeFilters(payload, STORE_KEYS.DN_RPT_DOWNTIME_FILTER_MACHINE_DATA);
            }
        }
    }, [status, startDate, endDate]);

    const handleSubmit = async (values: any) => {
        if (!selectedMachineName.length || !selectedProduct.length || !selectedBatch.length || !selectedShift.length) {
            setNoMachines(!selectedMachineName.length);
            setNoProducts(!selectedProduct.length);
            setNoBatches(!selectedBatch.length);
            setNoShifts(!selectedShift.length);
            return;
        } else {
            setNoMachines(false);
            setNoProducts(false);
            setNoBatches(false);
            setNoShifts(false);
        }
        const fromDate = dateFormat(values.dateFromSelect, Dateformat.YYYY_MM_DD);
        const toDate = dateFormat(values.dateToSelect, Dateformat.YYYY_MM_DD);
        const mKeys: any = [], mNames: any = [], pKeys: any = [], pNames: any = [], bKeys: any = [], bNames: any = [], sKeys: any = [], sNames: any = [];
        selectedMachineName.map((i: any) => { mKeys.push(i.code); mNames.push(i.name) });
        selectedProduct.map((i: any) => { pKeys.push(i.code); pNames.push(i.name) });
        selectedBatch.map((i: any) => { bKeys.push(i.code); bNames.push(i.name) });
        selectedShift.map((i: any) => { sKeys.push(i.code); sNames.push(i.name) })
        const payload = {
            machineKey: mKeys.toString(),
            productName: pKeys.toString(),
            shiftName: sNames.toString(),
            batchNo: bNames.toString(),
            startDate: fromDate,
            endDate: toDate,
            fMachineName: mNames.toString(),
            fProductName: pNames.toString(),
            fShiftName: sNames.toString(),
            fBatchNo: bNames.toString()
        };

        const token = state?.[STORE_KEYS.USER_DTL].token;

        // resetResult();
        setIsGenerating(true);

        Axios({
            url: BASEURL + '/' + ACTION_CODES.DN_RPT_DOWNTIME_REPORT_DATA,
            method: 'post',
            responseType: 'blob',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    setIsGenerating(false);
                    setReportResponse(response.data);
                }
            })
            .catch((error) => {
                setIsGenerating(false);
                setIsDownloadError(true);
                return {
                    status: error.response.status
                };
            });
    };

    const sendEmail = () => {
        const token = state?.[STORE_KEYS.USER_DTL].token;
        const userEmail = state?.[STORE_KEYS.USER_DTL]?.EmailId;

        const machine = machineNames.find((item: any) => item.code === selectedMachineName);

        if (machine) {
            setDowntimeParams({ ...downtimeParams, modelName: machine.name });
        }

        const fileName = downtimeParams.modelName + 'downtime_report.xlsx';

        if (reportResponse && token && userEmail && fileName) {
            setSnackbarOptions(SnackBarOptions.SENDING_EMAIL);

            let formDatta = new FormData();
            formDatta.append('file', reportResponse);
            formDatta.append('filename', fileName);
            formDatta.append('userId', userEmail);

            Axios({
                url: BASEURL + '/' + ACTION_CODES.DN_RPT_EMAIL_REPORT,
                method: 'post',
                data: formDatta,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        setReportResponse(response.data);
                        setSnackbarOptions(SnackBarOptions.SENT_EMAIL);
                    } else {
                        setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                    }
                })
                .catch((error) => {
                    setIsDownloadError(true);
                    setSnackbarOptions(SnackBarOptions.ERROR_SENDING_EMAIL);
                    return {
                        status: error.response.status
                    };
                });
        }
    };

    const resetResult = () => {
        setIsDownloadError(false);
        setIsGenerating(false);
        setReportResponse(null);
        setSelectedBatch([]);
        setSelectedMachineName([]);
        setSelectedProduct([]);
        setSelectedShift([]);
        setShifts([]);
        setBatches([]);
        setProducts([]);
        setNoBatches(false);
        setNoMachines(false);
        setNoProducts(false);
        setNoShifts(false);
    };
    const resetNewMachineResult = () => {

        setSelectedBatch([]);
        setSelectedProduct([]);
        setSelectedShift([]);

        setNoBatches(false);
        setNoProducts(false);
        setNoShifts(false);
    };
    const DropDownIcon = (prop: any) => (
        <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
    );
    const handleChangeMachine = (e: any, id: any) => {
        setNoMachines(false);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedMachineName.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedMachineName, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedMachineName.slice(1));
            } else if (selectedIndex === selectedMachineName.length - 1) {
                newSelected = newSelected.concat(selectedMachineName.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedMachineName.slice(0, selectedIndex),
                    selectedMachineName.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedMachineName.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedMachineName, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedMachineName.slice(1));
            } else if (selectedIndex === selectedMachineName.length - 1) {
                newSelected = newSelected.concat(selectedMachineName.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedMachineName.slice(0, selectedIndex),
                    selectedMachineName.slice(selectedIndex + 1)
                );
            }
        }
        setSelectedMachineName(newSelected);
        resetNewMachineResult()
    }

    const handleChangeProduct = (e: any, id: any) => {
        setNoProducts(false);
            let newSelected: any = [];
            if (presetKey === '') {
                const selectedIndex = selectedProduct.indexOf(id);
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selectedProduct, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selectedProduct.slice(1));
                } else if (selectedIndex === selectedProduct.length - 1) {
                    newSelected = newSelected.concat(selectedProduct.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        selectedProduct.slice(0, selectedIndex),
                        selectedProduct.slice(selectedIndex + 1)
                    );
                }
            }
            else {
                const selectedIndex = selectedProduct.findIndex((t: any) => t.BatchNo === id.BatchNo);
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selectedProduct, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selectedProduct.slice(1));
                } else if (selectedIndex === selectedProduct.length - 1) {
                    newSelected = newSelected.concat(selectedProduct.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        selectedProduct.slice(0, selectedIndex),
                        selectedProduct.slice(selectedIndex + 1)
                    );
                }
            }
            setSelectedProduct(newSelected);
        setSelectedBatch([]);
        setSelectedShift([]);

        setNoBatches(false);
        setNoShifts(false);
    }

    const handleChangeBatch = (e: any, id: any) => {
        setNoBatches(false);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        setSelectedBatch(newSelected);

        setSelectedShift([]);

        setNoShifts(false);
    }

    const handleChangeShifts = (e: any, id: any) => {
        setNoShifts(false);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedShift.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedShift.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }
        setSelectedShift(newSelected);
    }

    return (
        <div>
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Drawer anchor={'right'} open={status} onClose={drawerClose} style={{ zIndex: '9999' }} className='allreports'>
                <Box role="presentation" className={'standardBox1'}>
                    <div className="baseDiv">
                        <div
                            onClick={() => {
                                resetResult();
                                drawerClose();
                            }}
                            style={{
                                border: '1px solid #5D97F6',
                                borderRadius: '50px',
                                height: '25px',
                                width: '25px',
                                position: 'absolute',
                                left: '20px',
                                top: '16px',
                                cursor: 'pointer'
                            }}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    fontSize: '12px',
                                    color: '#5D97F6',
                                    left: '35%',
                                    top: '-3%'
                                }}
                            >
                                X
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: '100%'
                                // padding:'10px'
                            }}
                        >
                            <div style={{ display: 'block', marginLeft: '45px' }}>
                                <h6
                                    style={{
                                        color: '#DADCEE',
                                        fontSize: '16px',
                                        fontFamily: 'Montserrat'
                                        // marginTop: '-7px'
                                    }}
                                >
                                    Downtime Report
                                </h6>
                            </div>
                        </div>
                    </div>
                    <Container style={{ backgroundColor: '#2b2d42' }} className={'alm-dn-report-form1'}>
                        {/* <div className={'dn-rp-heading-label'}>Downtime Report</div> */}
                        <Formik
                            initialValues={downtimeParams}
                            validationSchema={validationSchema}
                            onSubmit={(values: any, { }) => {
                                handleSubmit(values);
                            }}
                            validateOnBlur={false}
                            validateOnChange={true}
                            innerRef={formRef}
                        >
                            {(formikprops: FormikProps<any>) => {
                                const {
                                    values,
                                    handleChange,
                                    handleBlur,
                                    isValid,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    resetForm
                                } = formikprops;
                                return (
                                    <Form className="detailForm">
                                        <Label label="From Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateFromSelect"
                                            value={values.dateFromSelect}
                                            className="inputDiv"
                                            onChange={(val: any) => {

                                                setMachineNames([]);
                                                setSelectedMachineName([]);
                                                setFieldValue('dropdownMachineName', '');

                                                setProducts([]);
                                                setSelectedProduct([]);
                                                setFieldValue('dropdownProduct', '');

                                                setBatches([]);
                                                setSelectedBatch([]);
                                                setFieldValue('dropdownBatchNo', '');

                                                setShifts([]);
                                                setSelectedShift([]);
                                                setFieldValue('dropdownShiftName', '');

                                                setFieldValue('dateFromSelect', val);
                                                setStartDate(val);
                                                resetResult();
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select From Date"
                                            minDate={new Date("11-01-2022")}
                                        //helperText={errors.dateFromSelect && touched.dateFromSelect ? errors.dateFromSelect : ''}
                                        />
                                        {errors?.dateFromSelect && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {errors?.dateFromSelect}
                                                </span>
                                            </div>
                                        )}

                                        <Label label="To Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="dateToSelect"
                                            value={values.dateToSelect}
                                            className="inputDiv"
                                            onChange={(val: any) => {

                                                setMachineNames([]);
                                                setSelectedMachineName([]);
                                                setFieldValue('dropdownMachineName', '');

                                                setProducts([]);
                                                setSelectedProduct([]);
                                                setFieldValue('dropdownProduct', '');

                                                setBatches([]);
                                                setSelectedBatch([]);
                                                setFieldValue('dropdownBatchNo', '');

                                                setShifts([]);
                                                setSelectedShift([]);
                                                setFieldValue('dropdownShiftName', '');

                                                setFieldValue('dateToSelect', val);
                                                setEndDate(val);
                                                resetResult();
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="dateToSelect"
                                            minDate={new Date("11-01-2022")}
                                        // helperText={errors.dateToSelect && touched.dateToSelect ? errors.dateToSelect : ''}
                                        />
                                        {errors?.dateToSelect && (
                                            <div style={{ marginTop: '10px' }}>
                                                <span
                                                    style={{
                                                        color: '#F06868',
                                                        fontSize: '11px',
                                                        fontWeight: 600,
                                                        letterSpacing: 0,
                                                        marginTop: '20px',
                                                        marginBottom: '15px',
                                                        fontFamily: 'Montserrat',
                                                        marginLeft: '5%'
                                                    }}
                                                >
                                                    {errors?.dateToSelect}
                                                </span>
                                            </div>
                                        )}
                                        <div className='labelfixed'>
                                        <Label label="Select Machines" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedMachineName?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Machine Name
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}
                                                value={selectedMachineName}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select machines'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}

                                            >
                                                {machineNames?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e: any) => { handleChangeMachine(e, i); }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedMachineName.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {noMachines === true ? <FormHelperText style={{
                                                color: "#F06868",
                                                fontSize: "11px",
                                                fontFamily: "Montserrat",
                                                fontWeight: 600,
                                            }}>Please select machine</FormHelperText> : null}
                                        </FormControl>
                                        </div>
                                        {/* <Label label="Machine Name" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(machineNameEvent) => {
                                                handleChange(machineNameEvent);
                                                const machine = machineNames.find(
                                                    (item: any) => item.code === machineNameEvent.target.value
                                                );
                                                if (machine) {
                                                
                                                    setProducts(null);
                                                    setSelectedProduct(null);
                                                    setFieldValue('dropdownProduct', '');

                                                    setBatches(null);
                                                    setSelectedBatch(null);
                                                    setFieldValue('dropdownBatchNo', '');

                                                    setShifts(null);
                                                    setSelectedShift(null);
                                                    setFieldValue('dropdownShiftName', '');

                                                    setSelectedMachineName(machine.code);

                                                    setDowntimeParams({ ...downtimeParams, modelName: machine.name });
                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownMachineName"
                                            items={machineNames}
                                            placeHolder="Select Machine"
                                            value={values.dropdownMachineName}
                                            helperText={
                                                errors.dropdownMachineName && touched.dropdownMachineName
                                                    ? errors.dropdownMachineName
                                                    : ''
                                            }
                                        /> */}
                                        {/* <Label label="Product Name" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(prodNameEvent: any) => {
                                                handleChange(prodNameEvent);
                                                const model = products.find(
                                                    (item: any) => item.code === prodNameEvent.target.value
                                                );
                                                if (model) {

                                                    setBatches(null);
                                                    setSelectedBatch(null);
                                                    setFieldValue('dropdownBatchNo', '');

                                                    setShifts(null);
                                                    setSelectedShift(null);
                                                    setFieldValue('dropdownShiftName', '');

                                                    setSelectedProduct(model.code.toString());

                                                    resetResult();
                                                }
                                            }}
                                            className="inputDiv"
                                            name="dropdownProduct"
                                            items={products}
                                            placeHolder="Select Product"
                                            value={values.dropdownProduct}
                                            helperText={
                                                errors.dropdownProduct && touched.dropdownProduct
                                                    ? errors.dropdownProduct
                                                    : ''
                                            }
                                        /> */}
                                        <div className='labelfixed'>
                                        <Label label="Select Products" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedProduct?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Products
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}
                                                value={selectedProduct}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select products'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}

                                            >
                                                {products?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e: any) => { handleChangeProduct(e, i); }}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedProduct.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {noProducts === true ? <FormHelperText style={{
                                                color: "#F06868",
                                                fontSize: "11px",
                                                fontFamily: "Montserrat",
                                                fontWeight: 600,
                                            }}>Please select product</FormHelperText> : null}
                                        </FormControl>
                                        </div>
                                        {/* <Label label="Batch No" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(batchEvent) => {
                                                handleChange(batchEvent);
                                                const model = batches.find(
                                                    (item: any) => item.code === batchEvent.target.value
                                                );
                                                if (model) {

                                                    setShifts(null);
                                                    setSelectedShift(null);
                                                    setFieldValue('dropdownShiftName', '');

                                                    setSelectedBatch(model.code);
                                                }

                                                resetResult();
                                            }}
                                            className="inputDiv"
                                            name="dropdownBatchNo"
                                            items={batches}
                                            placeHolder="Select Batch"
                                            value={values.dropdownBatchNo}
                                            helperText={
                                                errors.dropdownBatchNo && touched.dropdownBatchNo
                                                    ? errors.dropdownBatchNo
                                                    : ''
                                            }
                                        /> */}
                                        <div className='labelfixed'>
                                        <Label label="Select Batch" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedBatch?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Select Batch
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}
                                                value={selectedBatch}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select Batches'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}

                                            >
                                                {batches?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e: any) => { handleChangeBatch(e, i); }}
                                                    // disabled={selectedBatch.length < 3 ? false : true}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedBatch.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {noBatches === true ? <FormHelperText style={{
                                                color: "#F06868",
                                                fontSize: "11px",
                                                fontFamily: "Montserrat",
                                                fontWeight: 600,
                                            }}>Please select Batch No</FormHelperText> : null}
                                        </FormControl>
                                        </div>
                                        {/* <Label label="Shift Name" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(shiftEvent) => {
                                                handleChange(shiftEvent);
                                                const model = shifts.find(
                                                    (item: any) => item.code === shiftEvent.target.value
                                                );
                                                if (model) {
                                                    setSelectedShift(model.code);
                                                }
                                                resetResult();
                                            }}
                                            className="inputDiv"
                                            name="dropdownShiftName"
                                            items={shifts}
                                            placeHolder="Select Shift"
                                            value={values.dropdownShiftName}
                                            helperText={
                                                errors.dropdownShiftName && touched.dropdownShiftName
                                                    ? errors.dropdownShiftName
                                                    : ''
                                            }
                                        /> */}
                                        <div className='labelfixed'>
                                        <Label label="Select Shift" className={'labelDiv'} />
                                        <FormControl sx={{ width: '100%' }}>
                                            {selectedShift?.length > 0 ? null : (
                                                <InputLabel
                                                    style={{
                                                        fontFamily: 'Montserrat',
                                                        fontSize: '12px',
                                                        paddingTop: '0px',
                                                        paddingLeft: '20px',
                                                        color: '#b1c2df',
                                                        opacity: '0.7',
                                                        marginTop: '5px',
                                                        marginLeft: '-5px',
                                                    }}
                                                    id="demo-multiple-checkbox-label"
                                                >
                                                    Select Shift
                                                </InputLabel>
                                            )}
                                            <Select
                                                IconComponent={DropDownIcon}
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                className='inputDivselectparam'
                                                label=""
                                                multiple
                                                fullWidth
                                                style={{backgroundColor: '#1d1e2c',
                                                color: '#b1c2df',
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                borderRadius: '10px',
                                                height: '40px',
                                                marginLeft: '15px',
                                                marginRight: '15px',
                                                width: '100%',}}
                                                value={selectedShift}
                                                sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                                                placeholder='Please select Batches'
                                                // input={<OutlinedInput />}
                                                renderValue={(selected) => selected?.map((x: any) => x.name).join(', ')}
                                                MenuProps={MenuProps}

                                            >
                                                {shifts?.map((i: any) => (

                                                    <MenuItem value={i}
                                                        onClick={(e: any) => { handleChangeShifts(e, i); }}
                                                    // disabled={selectedBatch.length < 3 ? false : true}
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                selectedShift.findIndex((item: any) => item.code === i.code) >= 0
                                                            }

                                                        />
                                                        <ListItemText primary={i.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {noShifts === true ? <FormHelperText style={{
                                                color: "#F06868",
                                                fontSize: "11px",
                                                fontFamily: "Montserrat",
                                                fontWeight: 600,
                                            }}>Please select Shift</FormHelperText> : null}
                                        </FormControl>
                                        </div>
                                        {!isGenerating && !reportResponse && (
                                            <div className={'btns noWrap'}>
                                                <ACGButton
                                                    name={'Reset'}
                                                    handleClick={() => {
                                                        setDowntimeParams({
                                                            ...downtimeParams,
                                                            dateFromSelect: dateFormat(
                                                                new Date().setHours(-(24 * 7)),
                                                                'yyyy-mm-dd'
                                                            ),
                                                            dateToSelect: dateFormat(
                                                                new Date(),
                                                                'yyyy-mm-dd'
                                                            ),
                                                            dropdownMachineName: '',
                                                            dropdownProduct: '',
                                                            dropdownBatchNo: '',
                                                            dropdownShiftName: ''
                                                        });
                                                        resetForm();
                                                        resetResult();
                                                    }}
                                                    secondary={true}
                                                    className={'first-btn'}
                                                    type="button"
                                                    variant="contained"
                                                />
                                                <ACGButton
                                                    name={'Submit'}
                                                    handleClick={() => { }}
                                                    type="submit"
                                                    className="second-btn"
                                                    variant="contained"
                                                />
                                            </div>
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>

                        {isGenerating && !reportResponse && (
                            <Label label="Generating Report ..." className={'labelDiv'} />
                        )}

                        {isDownloadError && (
                            <Label label="Report Generation Error, Please retry" className={'labelDiv'} />
                        )}

                        {reportResponse && (
                            <div className={'btns noWrap'} style={{ position: 'relative' }}>
                                <div
                                    onClick={() => {
                                        resetResult();
                                    }}
                                    style={{
                                        border: '1px solid #5D97F6',
                                        borderRadius: '50px',
                                        height: '25px',
                                        width: '25px',
                                        position: 'absolute',
                                        right: '-15px',
                                        top: '-15px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            fontSize: '12px',
                                            color: '#5D97F6',
                                            left: '35%',
                                            top: '-3%'
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                                <ACGButton
                                    name={'Download'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            const machine = machineNames.find(
                                                (item: any) => item.code === selectedMachineName
                                            );
                                            if (machine) {
                                                setDowntimeParams({ ...downtimeParams, modelName: machine.name });
                                            }
                                            FileDownload(
                                                reportResponse,
                                                downtimeParams.modelName + 'downtime_report.xlsx'
                                            );
                                        }
                                    }}
                                    className={'first-btn'}
                                    type="button"
                                    variant="contained"
                                />
                                <ACGButton
                                    name={'Email'}
                                    handleClick={() => {
                                        if (reportResponse) {
                                            sendEmail();
                                        }
                                    }}
                                    className={'first-btn'}
                                    variant="contained"
                                />
                            </div>
                        )}
                    </Container>
                </Box>
            </Drawer>
        </div>
    );
}
