import { Card, Grid } from '@mui/material';
import Label from '../../atoms/Label';
import { sentenceCase } from 'change-case';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import Rating from '../../atoms/Rating';
import { useHistory } from 'react-router-dom';
import { paths } from '../../constants/paths';
import './index.scss';

type Props = {
    data?: any;
    selectedRow?: any;
    favClick?: Function;
};

const MachineListCard = (props: Props) => {
    const {
        MachineName,
        ConnectivityStatus,
        PlantName,
        ConnectivityStatusTime,
        MachineMode,
        MachineStatus,
        ActiveAlarmCount,
        FavoriteMachineFlag,
        MachineKey,
        UsrFavMachineKey
    } = props?.data;
    const { selectedRow, favClick = () => { } } = props;
    const history = useHistory();
    return (
        <Card className="MachineListCard">
            <div className="machineBlock">
                <div>
                    <h5 className="title" onClick={selectedRow}>
                        {MachineName}
                    </h5>
                    <p className="paraGraph">{PlantName}</p>
                </div>
                <Rating
                    value={FavoriteMachineFlag === 'Y' ? 1 : 0}
                    markKey={MachineKey}
                    unMarkKey={UsrFavMachineKey}
                    handleChange={favClick}
                />
            </div>
            {ConnectivityStatus !== 'Offline' && (
                <div>
                    <p className="onlineDiv">
                        <CloudDoneOutlinedIcon className="online" />
                        {ConnectivityStatusTime}
                    </p>
                </div>
            )}
            {ConnectivityStatus === 'Offline' && (
                <div className="offlineMainDiv">
                    <p className="offlineDiv">
                        <CloudOffOutlinedIcon className="offline" />
                        {ConnectivityStatusTime}
                    </p>
                </div>
            )}
            {ConnectivityStatus !== 'Offline' && (
                <>
                    {' '}
                    <div className="labelDiv">
                        <Label
                            variant="ghost"
                            color={
                                ConnectivityStatus === 'Critical'
                                    ? 'critical'
                                    : ConnectivityStatus === 'Online'
                                        ? 'major'
                                        : ConnectivityStatus === 'Offline'
                                            ? 'minor'
                                            : ConnectivityStatus === 'Failure'
                                                ? 'failure'
                                                : ConnectivityStatus === 'Maintenance'
                                                    ? 'maintenance'
                                                    : ConnectivityStatus === 'General'
                                                        ? 'general'
                                                        : 'warning'
                            }
                        >
                            {sentenceCase(ConnectivityStatus)}
                        </Label>
                    </div>
                    <Grid container className="machineMainGrid">
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <h5 className="heading">Mode</h5>
                            <p className="para">{MachineMode}</p>
                            <div className="borderRight"></div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <h5 className="heading">Status</h5>
                            <p className="para">{MachineStatus}</p>
                            <div className="borderRight"></div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <h5 className="heading">Active Alarms</h5>
                            <p style={{ cursor: 'pointer' }} onClick={() => {localStorage.setItem('selectedAlaramMachineKey', MachineKey); history.push('/machine-monitoring/alarm-detail');}} className="para">{ActiveAlarmCount}</p>
                        </Grid>
                    </Grid>
                </>
            )}
        </Card>
    );
};
export default MachineListCard;
