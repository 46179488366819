import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import MapCustomTabs from '../../../../app/atoms/ButtonTabs/MapCustomTabs';
import { applySortFilter } from '../../../utils/helper';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../app/constants/apiConstants';
import Axios from 'axios';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import useGetState from '../../../../app/utils/hooks/useGetState';
import { _noop } from '../../../../app/utils/common';
import '../../Admin/ClientManagement/index.scss';
import RightDrawer from '../../../../app/atoms/tempDrawer/indexAlt';
import Breadcrumb from '../../../atoms/BreadCrumb';
import Logs from './Logs/Config';
import PMECFilter from './Logs/CreateConfig';
import SnackbarAlert from '../../../atoms/Snackbar';
import DropDown from '../../../atoms/Dropdown';
import RadioSelectionComponent from './GraphOEE/radioselection';
import { Chart, registerables, ArcElement } from "chart.js";
import DoughnutChart from './GraphOEE/donutchart';
import TrendLine from './GraphOEE/trendLine';
import moment from 'moment';
Chart.register(...registerables);
Chart.register(ArcElement);
const tabs = ['Machine List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = () => {
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [drawerHeader, setDrawerHeader] = useState('Create');
    const [clientList, setClientList] = useState<any[]>([]);
    const [createValue, setCreateValue] = useState('');
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [reload, setReload] = useState(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [selectedRow, setSelectedRow] = useState<any>();
    const [customerKey, setCustomerKey] = useState("");
    const [customersData, setCustomersData] = useState<any[]>([]);
    const [presetKey, setPresetKey] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteGrp, setDeleteGrp] = useState(true);
    const [deletePresetAlert] = useState(true);
    const date = new Date();
    date.setDate(date.getDate() - 14)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [defaultStartDate, setDefultStartDate] = useState(date);
    const [defaultEndDate, setDefaultEndDate] = useState(new Date());
    const [errorDate, setErrorFalse] = useState(false);
    const [plantsData, setPlantsData] = useState<any[]>([]);
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>(11);
    const [plantKeys, setPlantKeys] = useState([]);
    const [alertsData, setAlertsData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState(false);
    const [machineModel, setMachineModel] = useState<any>([]);
    const [machines, setMachines] = useState<any>([]);
    const [selectedMachine, setSelectedMachine] = useState('');
    const dispatch = useDispatch();
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [tempParam1, setTempParam1] = useState([]);
    const [products, setProducts] = useState<any[]>([]);
    const [operators, setOperators] = useState<any[]>([]);
    const [selectedOP, setSelectedOP] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [oeeMaster, setOEEMaster] = useState<any>([]);
    const [oeeDetails, setOEEDetails] = useState<any>([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
    const [OPData, setOPData] = useState<any>([]);
    const [selectAllOP, setSelectAllOP] = useState<boolean>(false);
    const [selectedPlant, setSelectedPlant] = useState<any>(5);

    useEffect(() => {
        if (oeeMaster.length > 0) {
            const filteredList = applySortFilter(oeeMaster, "asc", "", "OEEPer", true);
            setSelectedMachine(filteredList[0]?.MachineKey);
        }
    }, [oeeMaster])

    useEffect(() => {
        if(selectedMachine !== "") {
        getOEEDetails(selectedMachine);
        fetchOP(selectedMachine)
        }

    },[selectedMachine])

    const changeOP = (type: any) => {
        setSelectedOP([]);
        setSelectedOption(type);
        setOPData([]);
        fetchOP(selectedMachine)
    }

    const handleChange = async (data: any) => {
        setSelectedOP([]);
        setOPData([]);
        setSelectedMachine(data);
    };

    useEffect(() => {
        if (!selectAllOP) {
            setSelectedOP([]);
            setOPData([]);
        }
        else {
            const sb: any = [];
            let data: any = [];
            if (selectedOption === "option2") {
                data = products
            } else { data = operators }
            data.map((i: any) => sb.push(i));
            setSelectedOP(sb);
            if(selectedMachine != "") getOPSummary(sb, selectedOption);
        }
    }, [selectAllOP])

    useEffect(()=> {
        if(operators.length > 0 || products.length > 0) {
            const sb: any = [];
            let data: any = [];
            if (selectedOption === "option2") {
                data = products
            } else { data = operators }
            data.map((i: any) => sb.push(i));
            handleChangeOP(sb);
            setSelectAllOP(true)
        }
    },[operators])

    const getOEEDetails = async (mk: any) => {
        const payload = {
            machineKey: mk,
            from: inDate,
            to: endDate
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OEE_DETAILS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOEEDetails(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    const handleChangeOP = (id: any) => {
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedOP.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedOP, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedOP.slice(1));
            } else if (selectedIndex === selectedOP.length - 1) {
                newSelected = newSelected.concat(selectedOP.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedOP.slice(0, selectedIndex),
                    selectedOP.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedOP.findIndex((t: any) => t.PlantKey === id.PlantKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedOP, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedOP.slice(1));
            } else if (selectedIndex === selectedOP.length - 1) {
                newSelected = newSelected.concat(selectedOP.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedOP.slice(0, selectedIndex),
                    selectedOP.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedOP(newSelected);
        if(newSelected.length > 0 || selectedMachine != "") getOPSummary(newSelected, selectedOption);

    }
    const getOPSummary = async (op: any, optype: any) => {
        const opURL = optype === "option1" ? ACTION_CODES.API_PMEC_GET_OPERATORS_SUMMARY : ACTION_CODES.API_PMEC_GET_PRODUCTS_SUMMARY;
        const opData: any = [];
        op.map((i: any) => opData.push(optype === "option1" ? i.OperatorKey : i.ProductKey))
        const payload = {
            machineKey: selectedMachine,
            from: inDate,
            to: endDate,
            op: opData.toString()
        };
        await Axios({
            url: BASEURL + '/' + opURL,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOPData(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    const setCurrTab = (val: number) => {
        setTabChange(val);
        if (radioValue == 'All' && currTab === 1) {
            setRadioValue('All');
        }
    };

    useEffect(() => {
        fetchMachines(machineModel);
        fetchOEEMaster(inDate, endDate, selectedMachineModel);
    }, [inDate, endDate, selectedMachineModel, selectedPlant])

    useEffect(() => {
        ApplyFilters(true);
        (async () => {
            const payload = {
                userId: loggedInUser,
            };
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINE_MODEL,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setMachineModel([
                            ...tmpTableData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))])
                    }
                })
                .catch((error) => {
                    return {
                        status: error.response.status
                    };
                });
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_PLANTS,
                method: 'post',
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then((response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = result[0][0];
                        setPlantsData([
                            ...tmpTableData?.map((item: any) => ({ code: item.PlantKey, name: item.PlantName }))])
                    }
                })
                .catch((error) => {
                    return {
                        status: error.response.status
                    };
                });

        })();
        fetchOEEMaster(inDate, endDate, selectedMachineModel)
    }, []);

    const handleChangePlant = (event: any) => {
        setSelectedPlant(event.target.value);
    };

    const fetchOEEMaster = async (sd: any, ed: any, mdl: any) => {
        const payload = {
            userId: loggedInUser,
            model: mdl,
            from: sd,
            to: ed,
            plantKey: selectedPlant
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OEE_MASTER,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setOEEMaster(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    const fetchMachines = async (model: any) => {
        const payload = {
            userId: loggedInUser,
            model: selectedMachineModel,
            plantKey: selectedPlant
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineName }))])
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    const fetchOP = async (mKey: any) => {
        const payload = {
            machineKey: mKey,
            from: inDate,
            to: endDate
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_PRODUCTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = await result[0][0];
                    setProducts(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_OPERATORS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then(async (response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = await result[0][0];
                    setOperators(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }

    const ApplyFilters = (flag: boolean) => {
        fetchMachines(machineModel);
        fetchOEEMaster(inDate, endDate, selectedMachineModel);
    }

    const clearFilter: any = async () => {
        setAppliedFilters(false);
        const date = new Date();
        date.setDate(date.getDate() - 6)
        setInDate(date);
        setEndDate(new Date());
        setMachines([]);
        setSelectedMachineModel('11');
        setSelectedMachine('');
        setSelectedOP([]);
        setOEEDetails([{ OEE: 0, Avaialability: 0, Performance: 0, Quality: 0 }]);
        setPlantKeys([]);
        setCustomerKey('');
        ApplyFilters(false);
        fetchOEEMaster(date, new Date(), 11);
    }

    const navigateTab = () => {
        switch (currTab) {

            case 0:
                return (
                    <Logs
                        oeeMaster={oeeMaster}
                        selectedMachineModel={selectedMachineModel}
                        presetKey={presetKey}
                        setPresetKey={setPresetKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={presetKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}

                        appliedFilters={appliedFilters}
                        clearFilter={clearFilter}
                        setSelectedMachineModel={setSelectedMachineModel}
                        setCustomerKey={setCustomerKey}
                        setInDate={setInDate}
                        setEndDate={setEndDate}
                        inDate={inDate}
                        endDate={endDate}
                    />
                )

            default:
                return (
                    <Logs
                        alertsData={alertsData}

                        presetKey={presetKey}
                        setPresetKey={setPresetKey}
                        deleteModalOpen={deleteModalOpen}
                        deleteGrp={deleteGrp}
                        deleteKey={presetKey}
                        deletePresetAlert={deletePresetAlert}
                        setDtlData={setDtlData}
                        dtlData={dtlData}
                        selectedRow={selectedRow}
                        setSelectedRow={() => setSelectedRow}
                        setCreateDrawerOpen={setCreateDrawerOpen}
                        setCreateValue={setCreateValue}
                        setDrawerHeader={setDrawerHeader}
                        setSnackbarOpts={setSnackbarOptions}

                        appliedFilters={appliedFilters}
                        clearFilter={clearFilter}
                        setSelectedMachineModel={setSelectedMachineModel}
                        setCustomerKey={setCustomerKey}
                        setInDate={setInDate}
                        setEndDate={setEndDate}
                    />
                );
        }
    };

    const handleMachineModel = (event: any) => {
        setSelectedMachineModel(event.target.value);
        setSelectAllOP(false);
        setOPData([]);
        setSelectedOP([]);
        setSelectedMachine('');
        setOEEDetails([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
    }
    const [plantOption, setPlantOption] = useState<any>([
        { 'name': "PMEC", "code": 1 },
        { 'name': "Vantage", "code": 2 },
        { 'name': "ACG Lab", "code": 3 }
    ]);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    return (
        <div className="dashboard PMEC-Dash">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
                    OEE Dashboard
                </div>
                <Breadcrumb
                    disabled={oeeMaster.length === 0 ? true : false}
                    data={
                        [{ label: "Home", url: "/pmec-dashboard" },
                        { label: "Production Dashboard", url: `/pmec-dashboard/kpi?mdk=${selectedMachineModel}&mk=${oeeMaster[0]?.MachineKey}&pk=${selectedPlant}&sd=${moment.utc(defaultStartDate).format("MM/DD/YYYY")}&ed=${moment.utc(defaultEndDate).format("MM/DD/YYYY")}` },
                        { label: "OEE Downtime", url: `/pmec-dashboard-downtime/kpi?mdk=${selectedMachineModel}&mk=${oeeMaster[0]?.MachineKey}&pk=${selectedPlant}&sd=${moment.utc(defaultStartDate).format("MM/DD/YYYY")}&ed=${moment.utc(defaultEndDate).format("MM/DD/YYYY")}` }]} />
                <div className='filterdiv'>
                    <div className='width_filter'>
                        <div className='lableTitle'>From</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setInDate(val);
                                setOEEDetails([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
                                setSelectedMachine('');
                                setSelectedOP([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}

                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>To</div>
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={endDate}
                            minDate={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setEndDate(val);
                                setOEEDetails([{ OEE: 0, Availability: 0, Performance: 0, Quality: 0 }]);
                                setSelectedMachine('')
                                setSelectedOP([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Plant</div>
                        <DropDown
                            onChange={handleChangePlant}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={plantsData}
                            placeHolder="Select Plant"
                            value={selectedPlant}
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Machine Model</div>
                        <DropDown
                            onChange={handleMachineModel}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={machineModel}
                            placeHolder="Select Machine Model"
                            value={selectedMachineModel}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '10px', zIndex: '0', position: 'relative' }}>
                    <MapCustomTabs
                        navigateTab={navigateTab}
                        currTab={currTab}
                        setCurrTab={setCurrTab}
                        tabsArray={tabs}
                        className="tabs"
                        position="relative"
                    // type="nonCurve"
                    />
                </div>
                <div className='filterdiv mt10'>
                    <div className='width_filter'>
                        <DropDown
                            onChange={(e: any) => handleChange(e.target.value)}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={machines}
                            placeHolder="Select Machine Serial No."
                            value={selectedMachine}
                        />
                    </div>
                    <div className='width_filter'>

                    </div>
                    <div className='width_filter'>

                    </div>
                    <div className='width_filter'>

                    </div>
                </div>
                <div className='graphcontainer pmec_dashgraph'>
                    <div className='leftgraph'>
                        <div className='comnclsMachineSr machine-srfiler'>
                            <div className='titleGraph'>OEE Parameters</div>
                        </div>
                        <div>
                            <TrendLine oeeDetails={oeeDetails} style={{width: "100%"}} />
                        </div>
                        {/* <div className='leftgraph-one comnclsgraph'>
                            <h3 className='titleGraph font14'>OEE %</h3>
                            <DoughnutChart oeeDetails={oeeDetails} type={"oee"} />
                        </div>
                        <div className='leftgraph-two comnclsgraph'>
                            <h3 className='titleGraph font14'>Availability %</h3>
                            <DoughnutChart oeeDetails={oeeDetails} type={"avl"} />
                        </div>
                        <div className='leftgraph-three comnclsgraph'>
                            <h3 className='titleGraph font14'>Performance %</h3>
                            <DoughnutChart oeeDetails={oeeDetails} type={"per"} />
                        </div>
                        <div className='leftgraph-four comnclsgraph'>
                            <h3 className='titleGraph font14'>Quality %</h3>
                            <DoughnutChart oeeDetails={oeeDetails} type={"qty"} />
                        </div> */}
                    </div>
                    <div className='rightgraph'>
                        <div className='titleGraph'>Production</div>
                        <RadioSelectionComponent
                            selectedOption={selectedOption}
                            changeOP={(t: any) => changeOP(t)}
                            handleChangeOP={(id: any) => handleChangeOP(id)}
                            operators={operators}
                            products={products}
                            selectedOP={selectedOP}
                            selectedMachine={selectedMachine}
                            OPData={OPData}
                            selectAllOP={selectAllOP}
                            setSelectAllOP={setSelectAllOP}
                            setOPData={setOPData}
                            selectedMachineModel={selectedMachineModel}
                        />
                    </div>
                </div>
            </Container>
            <RightDrawer
                alertType={createValue}
                handlerOpen={() => { }}
                handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); }}
                status={createDrawerOpen}
                drawerHeader={`${drawerHeader} ${createValue}`}
                children={
                    drawerHeader === 'Create' || drawerHeader === 'Edit' ? (
                        createValue === 'Notification Group' ?
                            (null)
                            : (
                                createValue === "Preset Alerts" ?
                                    null

                                    :
                                    null
                            )
                    ) : (

                        <>
                            {
                                drawerHeader === "Add" ?
                                    null
                                    : drawerHeader === "Filter" ?
                                        (
                                            <PMECFilter
                                                customersData={customersData}
                                                plantsData={plantsData}
                                                selectedMachineModel={selectedMachineModel}
                                                errorDate={errorDate}
                                                setCurrTab={setCurrTab}
                                                drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                                                setReload={setReload}
                                                setDtlData={setDtlData}
                                                inDate={inDate}
                                                endDate={endDate}
                                                setInDate={setInDate}
                                                setEndDate={setEndDate}
                                                handleChangePlant={(e: any) => handleChangePlant(e)}
                                                customerKey={customerKey}
                                                ApplyFilters={ApplyFilters}
                                                setAppliedFilters={setAppliedFilters}
                                                machineModel={machineModel}
                                                handleMachineModel={(e: any) => handleMachineModel(e)}
                                                clearFilter={clearFilter}
                                            />)

                                        : null
                            }
                        </>
                    )
                }
            />
        </div>
    );
};

export default SystemConfiguration;
