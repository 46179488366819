import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Label from '../Label';
import UserMoreMenu from '../CustomTableMenu/index';
import { sentenceCase } from 'change-case';
import Accordion from '../Accordion';
import SearchBar from '../SearchBar';
import { fetchBlobImage } from '../../utils/helper';
import './index.scss';
import BadgeTable from './BadgeTables';
import CustomDatePicker from '../../atoms/CustomDatePicker';
import {
    Divider
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import Loader from '../Loader';
type Anchor = 'right';
const TABLE_HEAD = [{ id: 'CustomerName', label: 'Client', alignRight: false }];
type Props = {
    status: any;
    handlerOpen: any;
    handlerClose: any;
    // children: any;
    data?: any;
    drawerHeader?: any;
    block?: boolean;
    type?: string;
    editFn?: Function;
    deleteFn?: Function;
    isRole?: boolean;
    activeFn?: Function;
    hasImage: boolean;
    workflow?: any;
    actionRequired?: boolean;
    hideEdit?: boolean;
    showActionList?: boolean;
    alertType?: any;
    isLoading?:boolean
};

export default function TemporaryDrawer(props: Props) {
    const {
        status,
        handlerOpen,
        handlerClose = () => { },
        drawerHeader,
        data,
        block = false,
        type,
        editFn = () => { },
        deleteFn = () => { },
        activeFn = () => { },
        isRole = false,
        hasImage = false,
        workflow = false,
        actionRequired = true,
        hideEdit = false,
        showActionList = false,
        alertType,
        isLoading=false
    } = props;
    const [isErrorImage, setIsErrorImage] = useState(false);
    let filteredLines = data?.details?.lines;
    if (data?.details?.plntText !== '') {
        const newLines = data?.details?.lines?.filter(
            (item: any) =>
                item?.PlantLineNoCode?.includes(data?.details?.plntText) ||
                item?.PlantLineNoName?.includes(data?.details?.plntText)
        );
        filteredLines = newLines;
    }
    const handleClose = () => {
        handlerClose();
        if (type === 'plant') {
            data?.details?.resetPlntText();
        }
    };
    const arr = [
        { LineName: 'Machines', LineCode: '145' },
        { LineName: 'Machines', LineCode: '146' },
        { LineName: 'Machines', LineCode: '147' },
        { LineName: 'Machines', LineCode: '148' }
    ];
    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" className={'standardBox'}>
            <div className="baseDiv" style={!actionRequired ? {paddingBottom: "20px"} : {}}>
                <div
                    onClick={handleClose}
                    style={{
                        border: '1px solid #5D97F6',
                        borderRadius: '50px',
                        height: '25px',
                        width: '25px',
                        position: 'absolute',
                        left: '20px',
                        top:
                            type === 'Role'
                                ? '30px'
                                : type === 'Feature' || type === "MaintMod"
                                    ? '17px'
                                    : type === 'Feature-dnldRpts'
                                        ? '17px'
                                        : type === 'Client'
                                            ? '18px'
                                            : type === 'Client noTop'
                                                ? '17px'
                                                : type === 'Plant'
                                                    ? '21px'
                                                    : type === 'broadcast'
                                                        ? '21px'
                                                        : '25px',
                        cursor: 'pointer'
                    }}
                >
                    <span style={{ position: 'relative', fontSize: '12px', color: '#5D97F6', left: '35%', top: '-3%' }}>
                        X
                    </span>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        width: '100%'
                        // padding:'10px'
                    }}
                >
                    <div style={ workflow ? { display: 'flex', marginLeft: '45px' }:{ display: 'block', marginLeft: '45px' }}>
                    {workflow ? <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat',
                                    // marginTop: '-7px'
                                }}
                            >
                               View Details
                            </h6> : null}
                        {type === 'Client' ? (
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat',
                                    marginTop: '-7px'
                                }}
                            >
                                {data?.name}
                            </h6>
                        ) : type === 'Client noTop' ? (
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat'
                                }}
                            >
                                {data?.name}
                            </h6>
                        ) : type === 'Plant' ? (
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat',
                                    marginTop: '-5px'
                                }}
                            >
                                {data?.name}
                            </h6>
                        ) : type === 'broadcast' ? (
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat',
                                    marginTop: '-5px'
                                }}
                            >
                                {data?.name}
                            </h6>
                        ) : (
                            <h6
                                style={{
                                    color: '#DADCEE',
                                    fontSize: '16px',
                                    fontFamily: 'Montserrat',
                                    marginTop: '-2px'
                                }}
                            >
                                {workflow ? "" : data?.name}
                            </h6>
                        )}

                        {type === 'Role' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.EmailId} <span>&nbsp;</span>| {data?.PhoneNo?.substring(0, 3)}{' '}
                                {data?.PhoneNo?.slice(-10)}
                            </p>
                        )}

                        {type === 'Contact' && (
                            <>
                                <p style={{ color: '#DADCEE', fontSize: '16px', fontFamily: 'Montserrat' }}>
                                    {data?.UserName} <span>&nbsp;</span>
                                </p>
                                <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                    SPOC {data?.SPOCType}
                                </p>
                            </>
                        )}
                        {type === 'Client' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.name}
                            </p>
                        )}
                        {type === 'Client noTop' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.name}
                            </p>
                        )}
                        {type === 'Plant' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {data?.details?.PlantName}
                            </p>
                        )}
                        {type === 'broadcast' && (
                            <p style={{ color: '#DADCEE', fontSize: '13px', fontFamily: 'Montserrat' }}>
                                {/* {data?.message} */}
                                Broadcast
                            </p>
                        )}
                    </div>{' '}
                    {type === 'Role' && (
                        <div style={{ marginTop: '7px' }}>
                            {data?.status === "Active" ?
                                <Label variant="outlined" color="success">
                                    {sentenceCase('Active')}
                                </Label>
                                :
                                <Label variant="outlined" color="failure">
                                    {sentenceCase('Inactive')}
                                </Label>
                            }

                        </div>
                    )}
                    {type === 'Feature' ||  type === 'MaintMod' ? (
                        <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                            {actionRequired ?
                            <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} workflow={type === 'Feature' ? null : workflow} data={data} hideEdit={hideEdit}/>
                            : null}
                            </div>
                    ) : type === 'Role' ? (
                        <div style={{ marginTop: '0px', marginBottom: '6px' }}>
                            <UserMoreMenu data={data} activeFn={activeFn} click={editFn} remove={deleteFn} block={block} dtlMode={true} workflow={null}/>
                        </div>
                    ) : (
                        <div style={{ marginTop: '-7px', marginBottom: '6px' }}>
                            <UserMoreMenu click={editFn} remove={deleteFn} block={block} dtlMode={true} workflow={null}/>
                        </div>
                    )}
                </div>
            </div>
            {/* {children} */}
            {isLoading?<Loader/>:
            <>{type === 'Role' || (isRole && type !== 'Plant' && type !== 'Feature') ? (
                <div className="userDetailDiv">
                    <div className="detailDivChild firstChild">
                        <p>User Type</p>
                        <h6>{data?.IsACGUser === 'N' ? 'Client' : 'ACG'}</h6>
                    </div>
                    <div className={'detailDivChild firstChild'}>
                        <p>Client</p>
                        <h6>{data?.CustomerName}</h6>
                    </div>
                    <div className={'detailDivChild'}>
                        <p>Role</p>
                        <h6>{data?.RoleName}</h6>
                    </div>
                </div>
            ) : type === 'Client' ||
                type === 'Client noTop' ||
                (!isRole &&
                    type !== 'Plant' &&
                    type !== 'broadcast' &&
                    type !== 'Feature' &&
                    type !== 'MaintMod' &&
                    type !== 'Feature-dnldRpts') ? (
                <div>
                    <div className="userDetailDiv clientDivTop" style={{ borderRadius: "16px" }}>
                        <div className={'detailDivChild firstChild'}>
                            <p>Users</p>
                            <h6>{data?.details?.TotalActiveUsers}</h6>
                        </div>

                        <div className={'detailDivChild '}>
                            <p>End Date</p>
                            <h6>{data?.details?.CustomerEndDate}</h6>
                        </div>
                    </div>
                    <div className="userDetailDiv clientDivBottom" style={{ height: '0px', marginTop: '-11px' }}></div>
                </div>
            ) : type === 'Plant' ? (
                <div>
                    <div className="userDetailDiv clientDivTop">
                        <div className={'detailDivChild firstChild'}>
                            <p>Client</p>
                            <h6>{data?.details?.CustomerName}</h6>
                        </div>
                        <div className={'detailDivChild firstChild'}>
                            <p>Plant Code</p>
                            <h6>{data?.details?.PlantCode}</h6>
                        </div>
                        <div className={'detailDivChild firstChild'}>
                            <p>Time Zone</p>
                            <h6>{data?.details?.TimeZoneName}</h6>
                        </div>
                        <div className={'detailDivChild'}>
                            <p>Shift Start Time</p>
                            <h6>{data?.details?.ShiftStartTime}</h6>
                        </div>
                    </div>
                    <div className="userDetailDiv clientDivBottom">
                        <div className={'detailDivChild'}>
                            <p>Address</p>
                            <h6>{data?.details?.PlantAddress}</h6>
                        </div>
                    </div>
                </div>
            ) : type === 'Contact' ? (
                <></>
            ) : type === 'broadcast' ? (
                <div style={{ marginTop: '0px' }}>
                    <div
                        className="userDetailDiv clientDivBottom noRadius"
                        style={{ borderRadius: '0px !important', width: '100%', height: 'auto', display: 'block' }}
                    >
                        <div className={'detailDivChild'} style={{ marginLeft: '0px', paddingLeft: '10px' }}>
                            <p>Message</p>
                            <h6>{data?.BroadcastMessage}</h6>
                        </div>
                        <div className={'detailDivChild'} style={{ marginLeft: '0px', paddingLeft: '10px' }}>
                            <p>Date</p>
                            <h6>{moment.utc(data?.DateTimeRange,"DD MMM YYYY | hh:mm A - DD MMM YYYY | hh:mm A").format("DD/MM/YYYY | hh:mm A - DD/MM/YYYY | hh:mm A")}</h6>
                        </div>
                        <div className={'detailDivChild'} style={{ marginLeft: '0px', paddingLeft: '10px' }}>
                            <p>Broadcast type</p>
                            <h6>{data?.BroadcastType}</h6>
                        </div>
                    </div>
                    {data?.BroadcastType != 'Global' && (
                        <div
                            style={{
                                padding: '10px',
                                width: '100%'
                            }}
                        >
                            {data?.broadcastChildren}
                        </div>
                    )}
                </div>
            ) : null}

            {type === 'Role' ? (
                <div className="accordionDiv">
                    <h5>Plant Access ({data?.count})</h5>
                    <Accordion data={data?.plants} edit={false} onSelecteChange={() => { }} />
                </div>
            ) : null}

            {type === 'Plant' ? (
                <SearchBar
                    searchLabel={'Search line'}
                    searchText={data?.details?.plntText}
                    setSearchText={data?.details?.setPlntText}
                    resetSearchText={data?.details?.resetPlntText}
                />
            ) : null}
            {type === 'Plant' ? (
                <div className="clientList">
                    <h5 className="listHeader">{`Lines (${filteredLines?.length})`}</h5>
                    {filteredLines?.map((line: any, idx: any) => {
                        const index = idx + 1;
                        return (
                            <div key={index} className="listDiv">
                                <div className="listItem">
                                    <p className="lineNameText">Line Name</p>
                                    <p className="lineNameText">{line?.PlantLineNoName}</p>
                                </div>
                                <div className="listItem">
                                    <p className="lineCodeText">Line Code</p>
                                    <p className="lineCodeText">{line?.PlantLineNoCode}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            {type === 'Feature' ? (
                <div className="clientList">
                    <h5 className="listHeader">Details</h5>
                    {data?.details?.map((elem: any, idx: any) => {
                        if(elem?.code !== "imageName"){
                        const index = idx + 1;
                        return (
                            <div key={index} className="listDiv">
                                {/* <div className="listItem">
                                    <p className="lineNameText">Line Name</p>
                                    <p className="lineNameText">{elem.LineName}</p>
                                </div> */}
                                <div className="listItem">
                                    <p className="lineCodeText" style={{ width: '100%' }}>{elem?.code}</p>
                                    <p className="lineCodeText" style={{ textAlign: 'right' }}>{elem?.name}</p>
                                </div>
                            </div>
                        );
                            }
                    })}
                    {hasImage && data.details.length > 0 && status ? <img
                    alt="No image"
                    src={data?.details[data.details.length -1].name ? fetchBlobImage(data?.details[data.details.length -1].name).url: undefined}
                    width={"100%"}
                    style={{marginTop: 10}}
                    /> : null}
                </div>
            ) : null}
               {type === "MaintMod"? (
                <div className="clientList">
                    <h5 className="listHeader" style={{fontSize: "14px", fontWeight: "bold"}}>Details</h5>
                    {data?.details?.map((elem: any, idx: any) => {
                        if(elem?.code !== "imageName" && elem?.code !== "checkListDetails"){
                        const index = idx + 1;
                        return (
                            <div key={index} className="listDivMM">
                                <div className="listItem">
                                    <div className="lineNameText" style={{ width: '100%' }}>{elem?.code}&nbsp;:&nbsp; 
                                    <span className="lineCodeText">{elem?.code?.includes("Time") && elem?.name != "-" ? moment.utc(elem?.name).format("DD/MM/YYYY") : elem?.name}</span></div>
                                </div>
                            </div>
                        );
                            }
                    })}
                    {data?.details.length === 6 && data?.details[5]?.name.length > 0? 
                    <div className='inspectionConfig'>
                          <p className="lineNameText" style={{ width: '100%', color: "#9cc2ff", fontSize: "12px", fontWeight: "bold", marginTop: "10px" }}>Inspection Check List Details</p>
                        <div style={{ display: "flex", marginTop: "20px" }} className='inspectionConfigHead'>
                            <span className='taskHeaders'>Task Id</span>
                            <span className='taskHeaders'>Task description</span>
                            <span className='taskHeaders'>Inspection Date</span>
                            <span className='taskHeaders'>Inspection Remarks</span>
                           
                        </div>
                        <Divider style={{backgroundColor: "rgba(58, 68, 86, 0.6)"}} />
                        {data?.details[5]?.name?.map((form:any, index:number) => {
                            return (

                                <div key={index} className='inspectionConfigBody'>                     
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                        <span className="taskLabel"> Task Id- {index + 1}</span> 
                                        <span className="taskLabel">{form?.TaskDesc ? form?.TaskDesc : "-"}</span>     
                                        <span className="taskLabel">{form?.InspectionDate ? moment.utc(form?.InspectionDate).format("DD/MM/YYYY") : "-"}</span> 
                                        <span className="taskLabel">{form?.InspRemark ? form?.InspRemark : "-"}</span>       
                                    </Box>

                                </div>

                            )
                        })
                        }

                </div>
                : null }
                    {hasImage && data.details.length > 0 && data.details[4]?.name !== null && hasImage && data?.details[4]?.name!== undefined && status ?
                     <>{isErrorImage ? <div style={{color: "#e0e0e0", fontSize: "12px"}}>
                        {/* Image not Uploaded */}
                        </div>:<img
                        alt="No image"
                        src={(data?.details[data.details.length -1]?.name &&  data?.details[4]?.name !== null && data?.details[4]?.name !== undefined) ? fetchBlobImage(data?.details[4]?.name).url: undefined}
                        width={"100%"}
                        style={{marginTop: 10, color: "#ffffff"}}
                        onError={()=>{setIsErrorImage(true)}}
                        />}</> : null}
                    {showActionList ? 
                    <div className='inspectionConfig'>
                        <div style={{ display: "flex", marginTop: "20px" }} className='inspectionConfigHead'>
                            <span className='taskHeaders'>Task Id</span>
                            <span className='taskHeaders'>Task description</span>
                        </div>
                        <Divider style={{backgroundColor: "rgba(58, 68, 86, 0.6)"}} />
                        {data?.actionData?.map((form:any, index:number) => {
                            return (

                                <div key={index} className='inspectionConfigBody'>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
                                        <span className="taskLabel"> Task Id- {index + 1}</span> 
                                        <span className="taskLabel">{form?.TaskDesc}</span>           
                                    </Box>

                                </div>

                            )
                        })
                        }

                </div>
                : null }
                </div>
            ) : null}
            {type === 'Feature-dnldRpts' ? (
                <div className="clientList">
                    <h5 className="listHeader">Details</h5>
                    {data?.details?.map((elem: any, idx: any) => {
                        const index = idx + 1;
                        return (
                            <div key={index} className="listDiv">
                                <div className="listItem">
                                    <p className="lineCodeText">{elem?.code}</p>
                                    <p className="lineCodeText">{elem?.name}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
            {(type === 'Client' || type === 'Client noTop') && (
                <div style={{ marginTop: '40px', padding: '20px' }}>
                    <BadgeTable data={data?.badgeTableData || {}} />
                </div>
            )}</>
            }
        </Box>
    );

    return (
        <div>
            <Drawer
                anchor={'right'}
                open={status}
                // onClose={handlerClose}
                onClose={handleClose}
                style={{ zIndex: '9999' }}
                className={alertType === "Custom Alerts" ? 'detailDrawerCustomDiv' : 'detailDrawerDiv'}

            >
                {list()}
            </Drawer>
        </div>
    );
}
