import { Card, Grid, Paper, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import './index.scss';
import MachinesIcon from '../../../assets/images/MachinesIcon.svg';
import AllAlarmsIcon from '../../../assets/images/AllAlarms.svg';
import AlarmsActiveIcon from '../../../assets/images/AlarmsActive.svg';
import CriticalAlarmsIcon from '../../../assets/images/CriticalAlarms.svg';
import MajorAlarmsIcon from '../../../assets/images/MajorAlarms.svg';
import MinorAlarmsIcon from '../../../assets/images/MinorAlarms.svg';
import AllEventsIcon from '../../../assets/images/AllEvent.svg';
import ACGButton from '../Button';

type Props = {
    data?: any;
    type?: string;
};
const MachineMonitoringTopCard = (props: Props) => {
    const { data, type } = props;
    const history = useHistory();
    const clicked = () => {
        history.push('/machine-monitoring/machine-detail');
    };
    return (
        <Card
            style={{
                width: '100%',
                backgroundColor: '#24263A',
                border: '1px solid #2B2D42'
            }}
            className="machineMonitoringTopCard"
        >
            <Grid container style={{ position: 'relative' }}>
                {data?.map((ele: any, index: number) => {
                    const Icon = ele.icon;
                    return (
                        <Grid
                            item
                            xs={index === 0 ? 12 : 6}
                            sm={2}
                            md={4}
                            lg={2}
                            xl={2}
                            className={type ? 'responsiveFirstGridItem' : ''}
                        >
                            <Paper
                                className={
                                    index === 0 && ele.title === 'Total Machines'
                                        ? 'paper machinePaper'
                                        : index === 0 && (ele.title === 'Total Alarms' || ele.title === 'Total Events')
                                            ? 'paper alarmPaper'
                                            : 'paper'
                                }
                            >
                                <div>
                                    {ele.title === 'Total Machines' ? (
                                        <img src={MachinesIcon} alt="machines" className={'totalMachines'} />
                                    ) : type === 'alarms' || type === 'events' ? (
                                        <img
                                            src={
                                                ele.title === 'Total Alarms'
                                                    ? AllAlarmsIcon
                                                    : ele.title === 'Total Events'
                                                        ? AllEventsIcon
                                                        : ele.title === 'Active'
                                                            ? AlarmsActiveIcon
                                                            : ele.title === 'Critical'
                                                                ? CriticalAlarmsIcon
                                                                : ele.title === 'Major'
                                                                    ? MajorAlarmsIcon
                                                                    : ele.title === 'Minor'
                                                                        ? MinorAlarmsIcon
                                                                        : AllAlarmsIcon
                                            }
                                            alt="alarms"
                                        //className={'totalMachines'}
                                        />
                                    ) : (
                                        <Icon
                                            fontSize="large"
                                            className={
                                                ele.title === 'Total Alarms' || ele.title === 'Total Events'
                                                    ? 'totalAlarms'
                                                    : ele.title === 'Major'
                                                        ? 'major'
                                                        : ele.title === 'Minor'
                                                            ? 'minor'
                                                            : ele.title === 'Critical'
                                                                ? 'critical'
                                                                : ele.title === 'Failure'
                                                                    ? 'failure'
                                                                    : ele.title === 'Online' || ele.title === 'Active'
                                                                        ? 'online'
                                                                        : ele.title === 'Offline'
                                                                            ? 'offline'
                                                                            : null
                                            }
                                        />
                                    )}
                                </div>
                                <div className="infoDiv">
                                    {ele.title}
                                    <p
                                        className={
                                            ele.title === 'Total Machines'
                                                ? 'totalMachines'
                                                : ele.title === 'Total Alarms' || ele.title === 'Total Events'
                                                    ? 'totalAlarms'
                                                    : ele.title === 'Major'
                                                        ? 'major'
                                                        : ele.title === 'Minor'
                                                            ? 'minor'
                                                            : ele.title === 'Critical'
                                                                ? 'critical'
                                                                : ele.title === 'Failure'
                                                                    ? 'failure'
                                                                    : ele.title === 'Online' || ele.title === 'Active'
                                                                        ? 'online'
                                                                        : ele.title === 'Offline'
                                                                            ? 'offline'
                                                                            : ''
                                        }
                                    >
                                        {ele.count}
                                    </p>
                                </div>
                            </Paper>
                            {index !== 0 && index !== data?.length - 1 ? <div className="borderRight"></div> : null}
                        </Grid>
                    );
                })}

                {!type ? (
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Button variant="outlined" color="primary" className="machineDetailButton" onClick={clicked}>
                            View Recent Activities
                        </Button>
                        {/* <ACGButton
                            // disabled={stateObj?.isLoading}
                            name={'View Details'}
                            handleClick={clicked}
                            secondary={true}
                            className={'machineDetailButton'}
                            type="button"
                            variant="contained"
                            style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                        /> */}
                    </Grid>
                ) : null}
            </Grid>
        </Card>
    );
};

export default MachineMonitoringTopCard;
