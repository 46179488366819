import { read, utils } from 'xlsx';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import NoData from '../../../../assets/nodata.svg';
import ACGButton from '../../../atoms/Button';
import Attachments from '../../../atoms/Attachments';
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import useGetState from "../../../utils/hooks/useGetState";
import { ACTION_CODES, BASEURL, STORE_KEYS } from "../../../constants/apiConstants";
import { executeACGAction, resetErr, updateScreenIdentifiers } from "../../../store/slice";
import { convertBlobFileToBase64 } from '../../../utils/helper';
import { boolean, string } from 'yup';
import DropDown from '../../../atoms/Dropdown';
import { renameObjKeys } from '../../Login/helper';
import "./index.scss"
import axios from 'axios';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    ADD_SUCCESS: {
        message: 'Model created successfully!',
        open: true,
        type: 'success'
    }
};
type Props = {
    closeDrawer?: any;
    setSnackbarOptions?: any;
    setToogleSopList?: any;
    sopEditData?: {
        edit: boolean, values: any
    }
}
const AddModel = (props: Props) => {
    const { closeDrawer, setSnackbarOptions, sopEditData, setToogleSopList } = props;
    const dispatch = useDispatch()
    const stateVal: any = useGetState();
    const token = stateVal?.[STORE_KEYS.USER_DTL].token;
    //these are the options data
    const [machineModelOptions, setMachineModelOptions] = useState<any[]>([]);
    const [machineComOptions, setMachineComOptions] = useState<any[]>([]);
    const [sopTypeOptions, setSopTypeOptions] = useState<any[]>([]);

    //these are for values
    const [machineModelValue, setMachineModelValue] = useState<any>("")
    const [machineComValue, setMachineComValue] = useState<any>("")
    const [sopNameValue, setSopNameValue] = useState<any>("")
    const [sopTypeValue, setSopTypeValue] = useState<any>("")
    const [file, setFile] = useState<any>([]);
    const [timeState, setTimeState] = useState(0)
    console.log("🚀 ~ AddModel ~ timeState:", timeState)
    useEffect(() => {
        setTimeState(new Date().getTime())
    }, [])

    //these are for other use
    const [isDisabled, setIsDisabled] = useState(true);

    const renderButtons = () => {
        return (
            <div className={'btns noWrap'} style={{ display: 'flex', float: 'right', marginTop: '15px' }}>
                <ACGButton
                    name={'Cancel'}
                    // disabled={stateObj?.isLoading}
                    handleClick={closeDrawer}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
                <ACGButton
                    name='Submit'
                    disabled={isDisabled}
                    handleClick={closeDrawer}
                    type="submit"
                    onClick={!sopEditData?.edit ? handleSubmit : handleEdit}
                    className="second-btn"
                    variant="contained"
                    style={{ marginRight: '25px', padding: '5px', width: '100px', height: '35px' }}
                />
            </div>
        );
    };
    const getMachineModelList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SOP_MACHINE_MODEL,
                    reqObj: {
                    }
                },
                storeKey: STORE_KEYS.SOP_MACHINE_MODEL_LIST
            })
        );
    };
    const getMachineComponentList = (MachineModelKey: string) => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'POST',
                    urlPath: ACTION_CODES.GET_SOP_MACHINE_COM,
                    reqObj: {
                        MachineModalKey: MachineModelKey
                    }
                },
                storeKey: STORE_KEYS.SOP_MACHINE_COM_LIST
            })
        );
    };
    const getSopTypeList = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.GET_SOP_TYPE,
                    reqObj: {

                    }
                },
                storeKey: STORE_KEYS.SOP_TYPE_LIST
            })
        );
    };

    const handleSubmit = async () => {
        let time = timeState
        let tempFileName = file[0].name.split(".pdf")
        let fileName = tempFileName + time + ".pdf"
        const formData = new FormData();
        formData.append('pdf', file[0]);
        formData.append('MaintComponentKey', machineComValue);
        formData.append('SOPTypeKey', sopTypeValue);
        formData.append('SOPName', sopNameValue);
        formData.append('AddEditActivity', "Add");
        formData.append('fileName', fileName);
        formData.append('isNewPdf', "true");
        await axios.post(`${BASEURL}${ACTION_CODES.SUBMIT_SOP_DETAILS}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token
            },
        }).then((result) => {
            if (result?.data?.body?.data?.[0]?.Result == "Success") {
                const snackbarError = {
                    message: result?.data?.body?.data?.[0]?.ResultMessage,
                    type: 'success',
                    open: true
                };
                setSnackbarOptions(snackbarError);
                setToogleSopList((prev: any) => !prev)
            }
            if (result?.data?.body?.data?.[0]?.Result == "Error") {
                const errorMsg = result?.data?.body?.data?.[0]?.ResultMessage || 'Internal Server error';
                const snackbarError = {
                    message: errorMsg,
                    type: 'reject',
                    open: true
                };
                setSnackbarOptions(snackbarError);
            }
        })
    }
    const handleEdit = async () => {
        let fileName;
        let fileObject;

        if (typeof file == "object") {
            let time = timeState
            let tempFileName = file[0].name.split(".pdf")
            fileName = tempFileName + time + ".pdf"
            fileObject = file[0]
        } else {
            fileName = file
            fileObject = file
        }

        const formData = new FormData();
        formData.append('pdf', fileObject);
        formData.append('MaintComponentKey', machineComValue);
        formData.append('SOPDetailsKey', sopEditData?.values.SOPDetailsKey);
        formData.append('SOPTypeKey', sopTypeValue);
        formData.append('SOPName', sopNameValue);
        formData.append('AddEditActivity', "Edit");
        formData.append('fileName', fileName);
        formData.append('isNewPdf', typeof file == "object" ? "true" : "false");
        if (typeof file == "object") {
            formData.append('oldFileName', sopEditData?.values?.SOPFilePath);
        }

        await axios.post(`${BASEURL}${ACTION_CODES.SUBMIT_SOP_DETAILS}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token
            },
        }).then((result) => {
            if (result?.data?.body?.data?.[0]?.Result == "Success") {
                const snackbarError = {
                    message: result?.data?.body?.data?.[0]?.ResultMessage,
                    type: 'success',
                    open: true
                };
                setSnackbarOptions(snackbarError);
                setToogleSopList((prev: any) => !prev)
            }
            if (result?.data?.body?.data?.[0]?.Result == "Error") {
                const errorMsg = result?.data?.body?.data?.[0]?.ResultMessage || 'Internal Server error';
                const snackbarError = {
                    message: errorMsg,
                    type: 'reject',
                    open: true
                };
                setSnackbarOptions(snackbarError);
            }
        })
    }

    useEffect(() => {
        getMachineModelList()
        getSopTypeList()
    }, [])

    useEffect(() => {
        if (machineModelValue) getMachineComponentList(machineModelValue)
    }, [machineModelValue])

    useEffect(() => {
        if (stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]?.body?.data) {
            const grpsList = stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name' });
                return newVal;
            });
            setMachineModelOptions(grpsList)
        }
    }, [stateVal[STORE_KEYS.SOP_MACHINE_MODEL_LIST]])
    useEffect(() => {
        if (stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]?.body?.data) {
            const grpsList = stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { MaintComponentKey: 'code', MaintComponentName: 'name' });
                return newVal;
            });
            setMachineComOptions(grpsList)
        }
    }, [stateVal[STORE_KEYS.SOP_MACHINE_COM_LIST]])
    useEffect(() => {
        if (stateVal[STORE_KEYS.SOP_TYPE_LIST]?.body?.data) {
            const grpsList = stateVal[STORE_KEYS.SOP_TYPE_LIST]?.body?.data?.map((item: any) => {
                const newVal = renameObjKeys(item, { SOPTypeKey: 'code', SOPType: 'name' });
                return newVal;
            });
            setSopTypeOptions(grpsList)
        }
    }, [stateVal[STORE_KEYS.SOP_TYPE_LIST]])
    useEffect(() => {
        if (machineModelValue && machineComValue && sopNameValue && sopTypeValue && file.length) {
            setIsDisabled(false)
        } else if (!sopNameValue.length || !file.length) {
            setIsDisabled(true)
        }
    }, [machineModelValue, machineComValue, sopNameValue, sopTypeValue, file]);

    useEffect(() => {
        if (sopEditData?.edit) {
            setMachineModelValue(sopEditData.values.MachineModelKey)
            setMachineComValue(sopEditData.values.MaintComponentKey)
            setSopNameValue(sopEditData.values.SOPName)
            setSopTypeValue(sopEditData.values.SOPTypeKey)
            setFile(sopEditData.values.SOPFilePath)
        }
    }, [sopEditData])
    return (
        <div>
            <div>
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: "3px",
                        paddingRight: "3px",
                    }}
                >
                    <div style={{ padding: '0 24px 24px 24px', borderBottom: '1px solid rgb(43 45 66 / 100%)', marginBottom: '10px' }}>
                        <Grid marginTop="10px">
                            <Label label="Machine Modal &nbsp; *" className={'CMlabelDiv'} />
                            <DropDown
                                disabled={sopEditData?.edit}
                                // className="inputDiv"
                                name="dropdownModel"
                                items={machineModelOptions}
                                placeHolder="Select Machine Modal"
                                value={machineModelValue}
                                onChange={(event) => {
                                    setMachineModelValue(event.target.value)
                                }}
                            />

                            {/* {isSubmit && errors.parameter ? <div className={styles.errorss}>Please Select Parameter</div> : null} */}
                        </Grid>
                        <Grid marginTop="10px">
                            <Label label="Machine Sub Component &nbsp; *" className={'CMlabelDiv'} />
                            <DropDown
                                // className="inputDiv"
                                name="dropdownModel"
                                items={machineComOptions}
                                placeHolder="Select Machine Sub Component"
                                value={machineComValue}
                                onChange={(event) => {
                                    setMachineComValue(event.target.value)
                                }}
                            />

                            {/* {isSubmit && errors.parameter ? <div className={styles.errorss}>Please Select Parameter</div> : null} */}
                        </Grid>
                        <Grid marginTop="10px">
                            <Label label="SOP Name &nbsp; *" className={'CMlabelDiv'} />
                            <TextInput
                                variant="outlined"
                                fullWidth
                                type="text"
                                name="modelCode"
                                value={sopNameValue}
                                autoComplete="off"
                                formInput="inputDiv"
                                onChange={(event) => {
                                    const alphanumericRegex = /^[a-zA-Z\s]*$/;
                                    if (event.target.value.match(alphanumericRegex)) {
                                        setSopNameValue(event.target.value);
                                    }
                                }}
                                onBlur={() => { }}
                                placeholder="Enter SOP Name"
                            // helperText={formError.modelCode}
                            />
                        </Grid>
                        <Grid marginTop="10px">
                            <Label label="SOP Type &nbsp; *" className={'CMlabelDiv'} />
                            <DropDown
                                disabled={sopEditData?.edit}
                                // className="inputDiv"
                                name="dropdownModel"
                                items={sopTypeOptions}
                                placeHolder="Select SOP Type"
                                value={sopTypeValue}
                                onChange={(event) => {
                                    setSopTypeValue(event.target.value)
                                }}
                            />

                            {/* {isSubmit && errors.parameter ? <div className={styles.errorss}>Please Select Parameter</div> : null} */}
                        </Grid>
                        <Grid marginTop="10px" className='sop-attachment-upload'>

                            <Label label="Upload Document &nbsp; *" className={'CMlabelDiv'} />
                            <Attachments
                                placeholder="Upload Documents"
                                type={[".pdf"]}
                                viewOnly={false}
                                setError={() => { }}
                                isSetFile={setFile}
                                isEdit={sopEditData?.edit ? sopEditData?.values.SOPFilePath : null}
                                isSopFile={true}
                                timeState={timeState}
                            />
                            {/* {selectedModelImage?.length === 0 ? <div>
                                {formError.modelImage &&
                                    <Typography sx={{ mt: '2%', color: '#F06868', fontSize: '12px' }}> {formError.modelImage}</Typography>
                                }
                                
                            </div> : null} */}
                        </Grid>
                    </div>
                </Container>
                {renderButtons()}
            </div>
        </div>
    );
};

export default AddModel;
