import { Card } from '@mui/material';
import RunningTime from '../../../assets/images/runningTime.svg';
import Speed from '../../../assets/images/speedometer.svg';
import Downtime from '../../../assets/images/downtime.svg';
import Asset from '../../../assets/images/assetutilisation.svg';
import Alarm from '../../../assets/images/alarm.svg';
import Vector from '../../../assets/images/Vector.svg';
import Clock from '../../../assets/images/clock.svg';
import './index.scss';
import moment from 'moment';
type Props = {
    type?: string;
    data?: any;
    clickable?: Boolean;
    onClick?: any;
};
const MachineOverviewCard = (props: Props) => {
    const { type, data, clickable = false, onClick = () => {} } = props;
    return (
        <Card
            className={
                type
                    ? 'machineOverviewCard'
                    : clickable
                    ? 'machineOverviewCard noPadding clickable'
                    : 'machineOverviewCard noPadding'
            }
            onClick={() => onClick(data)}
        >
            <div className="machineOverviewCardRoot">
                {!type ? null : data?.title?.includes('running time') ? (
                    <div className="imageDiv">
                        <img src={RunningTime} alt="speed" height="32px" width="32px" />
                    </div>
                ) : data?.title?.includes('speed') ? (
                    <div className="imageDiv">
                        <img src={Speed} alt="speed" height="32px" width="32px" />
                    </div>
                ) : data?.title?.includes('downtime') ? (
                    <div className="imageDiv">
                        <img src={Downtime} alt="speed" height="32px" width="32px" />
                    </div>
                ) : data?.title?.includes('utilisation') ? (
                    <div className="imageDiv">
                        <img src={Asset} alt="speed" height="32px" width="32px" />
                    </div>
                ) : data?.title?.includes('alarm') ? (
                    <div className="imageDiv">
                        <img src={Alarm} alt="speed" height="32px" width="32px" />
                    </div>
                ) : null}

                <div
                    style={{ textAlign: !type ? 'center' : type === 'graph' ? 'center' : 'start' }}
                    className={type ? 'contentDiv' : 'contentDiv noType'}
                >
                    <h5 className={type === "overview" ? 'hfivetitle no_Type' : 'hfivetitle'}>{data?.title ? data?.title : data?.ParamName}</h5>
                    <p style={{ fontWeight: '600', color: '#DADCEE' }}>
                        {data?.value ? data?.value : data?.ParamValue}
                    </p>
                    {!type ? (
                        <div className="machineBlock">
                            <p className="paraGraph">
                                <img className="time" src={Clock} alt="" />
                                {data?.LastEventTime}
                                 </p>
                        </div>
                    ) : null}
                    {data?.title?.includes('utilisation') || data?.title?.includes('running time') ? (
                        <img src={Vector} alt="speed" height="19px" width="19px" style={{ float: 'right', cursor: 'pointer' }} />
                    ) : null}

                    {type == 'graph' ? (
                        <div className='wraptime'>
                            <p className="paraGraph timediv">
                                <img className="time" src={Clock} alt="" />     
                            {data?.LastEventTime}
                            </p>
                            <img src={Vector} alt="speed" height="19px" width="19px" style={{ float: 'right', cursor: 'pointer' }} /></div>
                    ) : null}
                </div>
            </div>
        </Card>
    );
};

export default MachineOverviewCard;
