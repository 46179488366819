import { useState } from 'react';
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    TablePagination,
    Grid,
    Typography
} from '@mui/material';
import { formatOnlyDate } from "../../utils/formatTime";
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import TableColumns from './TableColumns/index';
import { makeStyles } from '@material-ui/core/styles';
import TablePaginator from './TablePagination/index';
import TableRows from './TableRows/index';
import theme from '../../../utilities/theme/theme.module.scss';
import './index.scss';
import MachineListCard from '../MachineListCard';
import ModalTableCard from '../ModelTableCard';
import { ModalCardType } from '../ModelTableCard';
import NotificationCard from '../../features/Notifications/Report/NotificationCard'

import MachineMonitoringCard from '../MachineMonitoringCard';
import NoData from '../../../assets/nodata.svg';
import Loader from '../Loader';
const useStyles = makeStyles({
    root: {
        paddingLeft: '3px !important',
        paddingRight: '3px !important',
        '& .simplebar-placeholder': {
            minHeight: '400px !imortant',
            height: 'auto !important'
        },
        // '& .simplebar-content .MuiTableContainer-root': {
        //     overflowX: 'clip',
        //     minWidth: 'max-content'
        // },
        '& .MuiPaper-root': {
            // marginTop: '20px',
            borderRadius: '0px 0px 15px 15px'
        },
        '& .MuiTableCell-head .MuiButtonBase-root': {
            // fontSize: '14px',
            fontWeight: 500
        },
        '& *,.MuiTableCell-root, .MuiTypography-root,.MuiButtonBase-root': {
            // fontFamily: 'poppins'
            // fontSize: '13px'
        },

        '&  .MuiTableRow-root.Mui-selected': {
            // backgroundColor: 'rgba(25, 118, 210, 0.20) !important'
        },
        '& .MuiTableRow-root:nth-child(odd)': {
            // backgroundColor: `${theme.s050Bg}`,
        },
        '& .MuiTableRow-head': {
            // backgroundColor: "white !important",
        },
        '& .MuiTablePagination-root .MuiToolbar-root ': {
            // backgroundColor: "#E0E9EF",
            backgroundColor: '#2b2d42'
        },
        '& .MuiToolbar-root': {
            backgroundColor: `${theme.s050Bg}`
        },
        '& .MuiTablePagination-spacer': {
            display: 'none'
        },
        '& .MuiTablePagination-displayedRows': {
            marginTop: '1rem',
            fontFamily: 'PoppinsSemiBold',
            fontSize: '12px'
        }
    }
});

interface Props {
    selectable: boolean;
    headers: any;
    body: any;
    filteredUsers: any;
    secondaryData?: any;
    filterName: string;
    orderBy: any;
    order: any;
    onRequestSort: any;
    onSelectAllClick: any;
    rowCount: number;
    page: number;
    setPageNumber: Function;
    selected: any;
    machineSelected?: any;
    onPageChange: any;
    rowChange: Function;
    machineRowChange?: any;
    selectRow?: any;
    route?: any;
    drawerTable?: boolean;
    editMenu?: boolean;
    link?: boolean;
    icon?: boolean;
    isRequestPage?: boolean;
    isTableImageReqd?: boolean;
    removeFn?: any;
    userUnlockFn?: any;
    isLoading?: boolean;
    noDataText?: string;
    type?: string;
    tableDataObj?: any;
    dtlMode?: boolean;
    idKey?: string;
    favClick?: Function;
    isRadio?: boolean;
    isPermission?: any;
    checkDrodownSelection?: any;
    isPagination?: boolean;
    isSelectAll?: boolean;
    clip?: boolean;
    noAction?: Boolean;
    overflow?: boolean;
    alarmFlag?: boolean;
    userFlag?: boolean;
    onlyDeleteModeInner?: boolean;
    workflow?: boolean;
    editFn?: any;
    loadConfig?: any;
    loadMode?: boolean;
    rules?: boolean;
    trial?: boolean;
    onClickUpload?: any;
    onClickDownloadReport?: any;
    onClickDownload?: any;
    setInspectionAction?: any;
    setAssign?: any;
    updateInspectionAction?: any;
    alreadyAdded?: any;
    addParams?: boolean;
    pmec?: boolean;
    endDate?: any;
    inDate?: any;
    fmea?: any;
    failureIndicatorsData?: any;
    setPotentialCauses?: any;
    setNewEntry?: any;
    newEntry?: boolean;
    setMachineKey?: any;
    handleCancelAck?: any;
    handleSaveAck?: any;
    setAckSelectedNotification?: any,
    ackSelectedNotification?: any;
    setSelectedNotification?: any;
    selectedNotification?: any;
    handleChangeAck?: any;
    notificationDetails?: any;
    getNotificationDetails?: any;
    setNotificationDetails?: any;
    notificationHistory?: any;
    setAckError?: any;
    ackError?: any;
    clc?: boolean;
    clcWO?: boolean;
    clcOnboard?: boolean;
    GBAdmin?: boolean;
    GBLimit?: boolean;
    machineModelName?: any;
    toggleStatus?: any;
    isPM?: boolean
}

export default function User(props: Props) {
    const history = useHistory();
    const {
        selectable,
        headers,
        body,
        filteredUsers,
        filterName,
        orderBy,
        order,
        onRequestSort,
        onSelectAllClick,
        rowCount,
        page,
        setPageNumber,
        selected,
        onPageChange,
        rowChange,
        selectRow,
        route,
        drawerTable,
        editMenu,
        link,
        icon,
        isRequestPage,
        isTableImageReqd = true,
        removeFn = () => { },
        userUnlockFn = () => { },
        isLoading = false,
        noDataText = 'No data to show',
        type,
        secondaryData,
        machineRowChange,
        machineSelected,
        tableDataObj,
        dtlMode = false,
        idKey = 'id',
        favClick = () => { },
        isRadio,
        isPermission,
        checkDrodownSelection,
        isPagination = true,
        isSelectAll,
        clip,
        noAction,
        overflow = true,
        alarmFlag,
        userFlag,
        onlyDeleteModeInner,
        workflow = false,
        editFn = () => { },
        loadConfig = () => { },
        loadMode = false,
        rules = false,
        trial = false,
        onClickUpload = () => { },
        onClickDownloadReport = () => { },
        onClickDownload = () => { },
        setInspectionAction = null,
        setAssign = null,
        updateInspectionAction = null,
        alreadyAdded = [],
        addParams = false,
        pmec = false,
        endDate,
        inDate,
        fmea = false,
        failureIndicatorsData,
        setPotentialCauses,
        setNewEntry,
        newEntry,
        setMachineKey,
        handleCancelAck = () => { },
        handleSaveAck = () => { },
        setAckSelectedNotification,
        ackSelectedNotification,
        setSelectedNotification,
        selectedNotification,
        handleChangeAck = () => { },
        notificationDetails,
        getNotificationDetails = () => { },
        setNotificationDetails,
        notificationHistory,
        setAckError,
        ackError,
        clc = false,
        clcWO = false,
        clcOnboard = false,
        GBAdmin = false,
        GBLimit = false,
        machineModelName,
        toggleStatus,
        isPM
    } = props;
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(12);
    const [tableData, setTableData] = useState([]);
    const [machineRowsPerPage, setMachineRowsPerPage] = useState(16);
    const notificationItemPerPage = 18;
    let addedList: any = [];
    alreadyAdded.map((i: any) => {
        addedList.push({
            "Category": i.Category,
            "machineName": i.ParamName,
            "id": i.MMParamkey
        })
    })
    useEffect(() => {
        setTableData(body);
    }, []);
    const emptyRowDisplay = [];
    if (
        filteredUsers &&
        filteredUsers.length > 0 &&
        filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length < 10
    ) {
        for (let i = 0; i < filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length; i++) {
            emptyRowDisplay.push(i);
        }
    }

    // const showRow = (id:any) => {
    //     // alert(id)
    //     console.log(id);
    // };
    // console.log(selected, 'sdsd');
    const scrollCSS = {
        '&::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.3em'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#2b2d42',
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: '1px solid slategrey'
        }
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
    const isUserNotFound = filteredUsers?.length === 0;
    return (
        <Container
            maxWidth="xl"
            className={
                !drawerTable && !clip
                    ? classes.root + ' TableBase'
                    : !drawerTable && clip
                        ? classes.root + ' TableBase clipped'
                        : classes.root + ' DrawerTableBase'
            }
        >
            <Card
                style={{
                    borderRadius: '0px 0px 10px 10px',
                    backgroundColor: '#2e304a'
                }}
            >
                <Scrollbar>
                    <TableContainer
                        sx={
                            !drawerTable
                                ? {
                                    minWidth: 500,
                                    maxHeight: 400,
                                    ...scrollCSS
                                }
                                : {
                                    minWidth: 300,
                                    maxHeight: 300,
                                    ...scrollCSS
                                }
                        }
                        style={{ backgroundColor: '#2e304a' }}
                    >
                        <Table className="actualTable">
                            <TableColumns
                                order={order}
                                orderBy={orderBy}
                                headLabel={headers}
                                rowCount={rowCount}
                                numSelected={selected.length}
                                onRequestSort={onRequestSort}
                                onSelectAllClick={onSelectAllClick}
                                selectable={selectable}
                                isSelectAll={isSelectAll}
                                pmec={pmec}
                            />
                            {isLoading ? <TableBody>
                                <TableRow className="emptyTable">
                                    <TableCell colSpan={14} style={{ width: '100%' }}>
                                        <Loader />
                                    </TableCell>
                                </TableRow>
                            </TableBody> : <>
                                {filteredUsers && type == 'double' && filteredUsers.length > 0 && filteredUsers ? (
                                    <TableBody>
                                        <TableRow style={{ backgroundColor: '#383B5E' }}>
                                            <TableCell colSpan={12} style={{ borderBottom: '0px', color: '#C0C2D8' }}>
                                                Machine Parameters
                                            </TableCell>
                                        </TableRow>

                                        {secondaryData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: any) => {
                                                const { id, name, role, status, company, avatarUrl, isVerified } = row;
                                                const isItemSelected = machineSelected.indexOf(id) !== -1;
                                                //    console.log(row)
                                                return (
                                                    <TableRows
                                                        key={id}
                                                        isItemSelected={isItemSelected}
                                                        click={(e: any) => {
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            selectRow && selectRow(row);
                                                        }}
                                                        header={headers}
                                                        row={row}
                                                        selectable={selectable}
                                                        change={() => machineRowChange(id)}
                                                        // route={route}
                                                        // editMenu={editMenu}
                                                        link={link}
                                                        icon={icon}
                                                        // removeFn={() => removeFn(row)}
                                                        // isRequestPage={isRequestPage}
                                                        // isTableImageReqd={isTableImageReqd}
                                                        noAction={noAction}
                                                    />
                                                );
                                            })}
                                        <TableRow style={{ backgroundColor: '#383B5E' }}>
                                            <TableCell colSpan={12} style={{ borderBottom: '0px', color: '#C0C2D8' }}>
                                                Model Parameters
                                            </TableCell>
                                        </TableRow>

                                        {filteredUsers
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: any) => {
                                                const { id, name, role, status, company, avatarUrl, isVerified } = row;
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                //    console.log(row)
                                                return (
                                                    <TableRows
                                                        key={id}
                                                        isItemSelected={isItemSelected}
                                                        click={() => selectRow && selectRow(row)}
                                                        header={headers}
                                                        row={row}
                                                        selectable={selectable}
                                                        change={() => rowChange(id)}
                                                        route={route}
                                                        editMenu={editMenu}
                                                        link={link}
                                                        icon={icon}
                                                        removeFn={() => removeFn(row)}
                                                        isRequestPage={isRequestPage}
                                                        isTableImageReqd={isTableImageReqd}
                                                        noAction={noAction}
                                                        alarmFlag={alarmFlag}
                                                    />
                                                );
                                            })}
                                    </TableBody>
                                ) : type == 'multiple' ? (
                                    <TableBody>
                                        {/* <TableRow style={{ backgroundColor: '#383B5E' }}>
                                        <TableCell colSpan={12} style={{ borderBottom: '0px', color: '#C0C2D8' }}>
                                            Machine Parameters
                                        </TableCell>
                                    </TableRow> */}

                                        {Object.keys(tableDataObj)?.map((arrData: any) => {
                                            return (
                                                <>
                                                    <TableRow style={{ backgroundColor: '#383B5E' }}>
                                                        <TableCell
                                                            colSpan={12}
                                                            style={{ borderBottom: '0px', color: '#C0C2D8' }}
                                                        >
                                                            {arrData}
                                                        </TableCell>
                                                    </TableRow>
                                                    {tableDataObj?.[arrData]?.map((row: any, idx: any) => {
                                                        const indx = idx + 1;
                                                        const { id } = row;
                                                        const isItemSelected = selected.indexOf(id) !== -1;

                                                        return (
                                                            <TableRows
                                                                key={indx}
                                                                isItemSelected={isItemSelected}
                                                                header={headers}
                                                                row={row}
                                                                click={() => selectRow && selectRow(row)}
                                                                selectable={selectable}
                                                                isRadio={isRadio}
                                                                isPermission={isPermission}
                                                                change={() => rowChange(id)}
                                                                route={route}
                                                                editMenu={editMenu}
                                                                link={link}
                                                                icon={icon}
                                                                removeFn={() => removeFn(row)}
                                                                isRequestPage={isRequestPage}
                                                                isTableImageReqd={isTableImageReqd}
                                                                checkDrodownSelection={checkDrodownSelection}
                                                                noAction={noAction}
                                                            />
                                                        );
                                                    })}
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                ) : null}
                                <TableBody>
                                    {/* {filteredUsers && type == 'double' &&
                                    filteredUsers.length > 0 ?
                                    // filteredUsers[0].modelParameters ?
                                    <TableRow style={{ backgroundColor: '#383B5E' }}>
                                        <TableCell colSpan={12} style={{ borderBottom: '0px', color: '#C0C2D8' }}>
                                            Model Parameters
                                        </TableCell>
                                    </TableRow> : null} */}

                                    {addParams === false ?
                                        filteredUsers &&
                                        !type &&
                                        filteredUsers.length > 0 &&
                                        filteredUsers
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: any) => {
                                                const { name, role, status, company, avatarUrl, isVerified } = row;
                                                const id = row[idKey];
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                return (
                                                    <TableRows
                                                        style={pmec ? { "cursor": "pointer" } : undefined}
                                                        key={id}
                                                        isItemSelected={isItemSelected}
                                                        click={pmec ? () => history.push(`/pmec-dashboard/kpi?mdk=${row.MachineModelKey}&mk=${row.MachineKey}&pk=${row.PlantKey}&sd=${formatOnlyDate(inDate)}&ed=${formatOnlyDate(endDate)}`) : fmea && newEntry ? () => { setMachineKey(row?.MachineModelKey) } : () => selectRow && selectRow(row)}
                                                        header={headers}
                                                        row={row}
                                                        selectable={selectable}
                                                        change={() => rowChange(id)}
                                                        route={route}
                                                        editMenu={editMenu}
                                                        link={link}
                                                        icon={icon}
                                                        removeFn={() => removeFn(row)}
                                                        userUnlockFn={() => userUnlockFn(row)}
                                                        isRequestPage={isRequestPage}
                                                        isTableImageReqd={isTableImageReqd}
                                                        noAction={noAction}
                                                        alarmFlag={alarmFlag}
                                                        onlyDeleteModeInner={onlyDeleteModeInner}
                                                        userFlag={userFlag}
                                                        dtlMode={dtlMode}
                                                        workflow={workflow}
                                                        editFn={editFn}
                                                        loadConfig={loadConfig}
                                                        loadMode={loadMode}
                                                        rules={rules}
                                                        trial={trial}
                                                        onClickUpload={onClickUpload}
                                                        onClickDownloadReport={onClickDownloadReport}
                                                        onClickDownload={(key: any, name: any) => onClickDownload(key, name)}
                                                        setInspectionAction={setInspectionAction}
                                                        setAssign={setAssign}
                                                        updateInspectionAction={updateInspectionAction}
                                                        pmec={pmec}
                                                        fmea={fmea}
                                                        failureIndicatorsData={failureIndicatorsData}
                                                        notificationHistory={notificationHistory}
                                                        clc={clc}
                                                        clcWO={clcWO}
                                                        clcOnboard={clcOnboard}
                                                        GBAdmin={GBAdmin}
                                                        GBLimit={GBLimit}
                                                        machineModelName={machineModelName}
                                                        toggleStatus={toggleStatus}
                                                        isPM={isPM}
                                                    />
                                                );
                                            }) :
                                        alreadyAdded &&
                                        !type &&
                                        alreadyAdded.length > 0 &&
                                        addedList
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row: any) => {
                                                const { name, role, status, company, avatarUrl, isVerified } = row;
                                                const id = row[idKey];
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                return (
                                                    <TableRows
                                                        key={id}
                                                        isItemSelected={isItemSelected}
                                                        click={() => selectRow && selectRow(row)}
                                                        header={headers}
                                                        row={row}
                                                        selectable={selectable}
                                                        change={() => rowChange(id)}
                                                        route={route}
                                                        editMenu={editMenu}
                                                        link={link}
                                                        icon={icon}
                                                        removeFn={() => removeFn(row)}
                                                        userUnlockFn={() => userUnlockFn(row)}
                                                        isRequestPage={isRequestPage}
                                                        isTableImageReqd={isTableImageReqd}
                                                        noAction={noAction}
                                                        alarmFlag={alarmFlag}
                                                        onlyDeleteModeInner={onlyDeleteModeInner}
                                                        userFlag={userFlag}
                                                        workflow={workflow}
                                                        editFn={editFn}
                                                        loadMode={loadMode}
                                                        rules={rules}
                                                        trial={trial}
                                                        onClickUpload={onClickUpload}
                                                        onClickDownloadReport={onClickDownloadReport}
                                                        onClickDownload={(key: any, name: any) => onClickDownload(key, name)}
                                                        setInspectionAction={setInspectionAction}
                                                        setAssign={setAssign}
                                                        updateInspectionAction={updateInspectionAction}
                                                        notificationHistory={notificationHistory}
                                                    />
                                                );
                                            })
                                    }
                                    {filteredUsers &&
                                        !type &&
                                        filteredUsers.length > 0 &&
                                        filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length < 10
                                        ? emptyRowDisplay.map((ele) => {
                                            return (
                                                <TableRow
                                                    key={ele}
                                                    style={{
                                                        height: '40px',
                                                        backgroundColor: '#2b2d42',
                                                        borderBottom: '0px'
                                                    }}
                                                >
                                                    <TableCell colSpan={12} style={{ borderBottom: '0px' }} />
                                                </TableRow>
                                            );
                                        })
                                        : null}
                                    {/* {emptyRows > 0 ||
                                    (filteredUsers.length == 0 && (
                                        <TableRow style={{ height: 3 * emptyRows }}></TableRow>
                                    ))} */}

                                    {filteredUsers?.length == 0 ? (
                                        <TableRow className="emptyTable">
                                            <TableCell colSpan={14} style={{ width: '100%' }}>
                                                <div className="nodataRow">
                                                    <img src={NoData} alt="no data" />
                                                    <Typography sx={{ mt: '2%' }} style={{ fontFamily: 'montserrat' }}>
                                                        {noDataText}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                                {/* {filterName?.length > 0 && !type && isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )} */}
                                {filteredUsers && type === 'machineList' && filteredUsers.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '24px',
                                            paddingBottom: '24px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {filteredUsers
                                                //      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row: any, index: number) => {
                                                    return (
                                                        <Grid item xs={12} lg={3} md={3} sm={6}>
                                                            <MachineListCard
                                                                data={row}
                                                                selectedRow={() => route(row)}
                                                                favClick={favClick}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Container>
                                ) : null}

                                {filteredUsers && type === 'alarmList' && filteredUsers.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '24px',
                                            paddingBottom: '24px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {filteredUsers
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row: any, index: number) => {
                                                    const idex = index + 1;
                                                    return (
                                                        <Grid item xs={12} lg={3} md={3} sm={6} key={idex}>
                                                            <MachineMonitoringCard
                                                                alarmList={true}
                                                                data={row}
                                                                selectedRow={() => route(row)}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Container>
                                ) : null}

                                {filteredUsers && type === 'modelList' && filteredUsers.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '24px',
                                            paddingBottom: '24px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {filteredUsers
                                                ?.slice(
                                                    page * machineRowsPerPage,
                                                    page * machineRowsPerPage + machineRowsPerPage
                                                )
                                                ?.map((row: any, index: number) => {
                                                    return (
                                                        <Grid item xs={12} lg={3} md={3} sm={6}>
                                                            <ModalTableCard
                                                                cardType={ModalCardType.Machine}
                                                                data={row}
                                                                selectedRow={() => route(row)}
                                                                removeFn={() => removeFn(row)}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Container>
                                ) : null}
                                {filteredUsers && type === 'User_Reports_List' && filteredUsers.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '24px',
                                            paddingBottom: '24px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {filteredUsers
                                                ?.slice(
                                                    page * machineRowsPerPage,
                                                    page * machineRowsPerPage + machineRowsPerPage
                                                )
                                                ?.map((row: any, index: number) => {
                                                    const idxVal = index + 1;
                                                    return (
                                                        <Grid key={idxVal} item xs={12} lg={3} md={3} sm={6}>
                                                            <ModalTableCard
                                                                cardType={ModalCardType.Report}
                                                                data={row}
                                                                selectedRow={() => selectRow && selectRow(index)}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Container>
                                ) : null}
                                {filteredUsers && type === 'Notification_List' && filteredUsers.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '24px',
                                            paddingBottom: '24px',

                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {filteredUsers
                                                ?.slice(
                                                    page * notificationItemPerPage,
                                                    page * notificationItemPerPage + notificationItemPerPage
                                                )
                                                ?.map((row: any, index: number) => {
                                                    const idxVal = index + 1;
                                                    return (
                                                        <Grid key={idxVal} item xs={12} lg={4} md={4} sm={6}>
                                                            <NotificationCard
                                                                data={row}
                                                                setAckSelectedNotification={setAckSelectedNotification}
                                                                handleCancelAck={handleCancelAck}
                                                                handleSaveAck={handleSaveAck}
                                                                ackSelectedNotification={ackSelectedNotification}
                                                                setSelectedNotification={setSelectedNotification}
                                                                selectedNotification={selectedNotification}
                                                                index={row.CondAlertKey}
                                                                handleChangeAck={handleChangeAck}
                                                                type={row.AlertType}
                                                                notificationDetails={notificationDetails}
                                                                getNotificationDetails={getNotificationDetails}
                                                                setNotificationDetails={setNotificationDetails}
                                                                setAckError={setAckError}
                                                                ackError={ackError}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Container>
                                ) : null}
                                {filteredUsers && type === 'User_DashBoard_List' && filteredUsers.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        style={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '24px',
                                            paddingBottom: '24px'
                                        }}
                                    >
                                        <Grid container spacing={2}>
                                            {filteredUsers
                                                ?.slice(
                                                    page * machineRowsPerPage,
                                                    page * machineRowsPerPage + machineRowsPerPage
                                                )
                                                ?.map((row: any, index: number) => {
                                                    return (
                                                        <Grid item xs={12} lg={3} md={3} sm={6}>
                                                            <ModalTableCard
                                                                cardType={ModalCardType.Dashboard}
                                                                data={row}
                                                                selectedRow={() => selectRow && selectRow(index)}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Container>
                                ) : null}
                                {/* 
                            {(filteredUsers?.length === 0 && type === 'modelList') ||
                            (filteredUsers?.length === 0 && type === 'machineList') ? (
                                <Container
                                    maxWidth="xl"
                                    style={{
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        paddingTop: '24px',
                                        paddingBottom: '24px'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <h5
                                            style={{
                                                marginLeft: '20px',
                                                marginTop: '20px',
                                                fontSize: '12px',
                                                textAlign: 'center',
                                                color: '#c0c2d8'
                                            }}
                                        >
                                            {noDataText}
                                        </h5>
                                    </Grid>
                                </Container>
                            ) : null} */}</>}

                        </Table>
                    </TableContainer>
                </Scrollbar>
                {type !== 'machineList' && isPagination ? (
                    <TablePagination
                        className={!overflow ? 'table_pagination nonescroll' : 'table_pagination '}
                        rowsPerPageOptions={[]}
                        component="div"
                        count={rowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={onPageChange}
                        labelDisplayedRows={() => { }}
                        ActionsComponent={() => (
                            <TablePaginator
                                count={rowCount}
                                rowsPerPage={
                                    type === 'modelList' ||
                                        type === 'User_DashBoard_List' ||
                                        type === 'User_Reports_List' ||
                                        type === 'Notification_List'
                                        ? machineRowsPerPage
                                        : rowsPerPage
                                }
                                page={page}
                                onPageChange={onPageChange}
                                setPageNumber={setPageNumber}
                            />
                        )}
                    />
                ) : null}
            </Card>
        </Container>
    );
}
