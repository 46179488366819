import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'chartjs-plugin-datalabels';
import { useHistory } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const Stacked = (props: any) => {
  const history = useHistory();
  const [labelSet, setLabelSet] = useState<any>([]);
  const [opdata1, setOPData1] = useState<any>([]);
  const [opdata2, setOPData2] = useState<any>([]);
  const [opdata3, setOPData3] = useState<any>([]);

  useEffect(() => {
    const lab: any = [], d1: any = [], d2: any = [], d3: any = [];

    props?.avlMetrics.map((i: any) => {
      if (!lab.includes(i.BatchNo)) lab.push(i.BatchNo);
      if (i.MetricName === "BatchTime") d1.push(i.MetricValuePer);
      if (i.MetricName === "PlannedDT") d2.push(i.MetricValuePer);
      if (i.MetricName === "UnPlannedDT") d3.push(i.MetricValuePer);
    });
    setLabelSet(lab);
    setOPData1(d1);
    setOPData2(d2);
    setOPData3(d3);
  }, [props])

  const options = {
    plugins: {
      datalabels: { color: 'white', display: false },
      legend: {
        events: [],
        labels: { 
          color: "#e0e0e0",
          font: {
            size: 10,
            family: "montserrat",
          }
        }
      }
    },  
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'transparent',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: 'white',
        },
        grid: {
          color: '#878f99',
        },
      },
    },
    onClick: (evt: any, elements: any, chart: any) => {
      const checkedPoint = chart.tooltip.title[0];
      const prKey = props?.productKey.map((i:any) => i.ProductKey);
      history.push(`/pmec-dashboard-downtime/kpi?mdk=${props.machineModelKey}&mk=${props.machineKey}&pk=${props.plantKey}&sd=${moment.utc(props.startDate.toLocaleString()).format("MM/DD/YYYY")}&ed=${moment.utc(props.toDate.toLocaleString()).format("MM/DD/YYYY")}&bk=${checkedPoint}&prk=${JSON.stringify(prKey.toString())}`);
    },
  };

  const labels = labelSet;

  const data = {
    labels,
    datasets: [
      {
        label: 'Run Time %',
        data: opdata1,
        backgroundColor: '#008000',
        borderColor: '#008000',
        borderWidth: 0,
        type: 'bar' as const,
      },
      {
        label: 'Planned Downtime %',
        data: opdata2,
        backgroundColor: '#0dcaf0',
        borderColor: '#0dcaf0',
        borderWidth: 0,
        type: 'bar' as const,
      },
      {
        label: 'Un-Planned Downtime %',
        data: opdata3,
        backgroundColor: '#ffc107',
        borderColor: '#ffc107',
        borderWidth: 0,
        type: 'bar' as const,
      },
    ],
  };

  return <Chart style={{cursor: "pointer"}} type='bar' data={data} options={options} plugins={[ChartDataLabels]} />;
}

export default Stacked;
